import scssVariables from "@/styles/variables.module.scss";

export default function arrowUp() {
  return (
    <svg viewBox="0 0 19 12" fill="none">
      <path
        d="M19 9.79232L17.4421 11.3502L9.50156 3.40968L1.55789 11.3525L1.85776e-08 9.79465L9.50312 0.291529L19 9.79232Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
