import scssVariables from "@/styles/variables.module.scss";

export default function success() {
  return (
    <svg viewBox="0 0 36 36" fill="none">
      <path
        d="M30.7277 5.27231C23.6979 -1.75746 12.3005 -1.75746 5.27224 5.27245C-1.75746 12.3022 -1.75746 23.6995 5.27252 30.7277C12.3005 37.7574 23.6979 37.7574 30.7277 30.7276C37.7574 23.6994 37.7574 12.3022 30.7277 5.27231ZM28.6065 28.6062C22.7482 34.4645 13.2505 34.4645 7.39385 28.6065C1.53555 22.7495 1.53555 13.2519 7.39371 7.39371C13.2505 1.53548 22.7482 1.53548 28.6063 7.39371C34.4645 13.2519 34.4645 22.7495 28.6065 28.6062Z"
        fill={scssVariables.primary}
      />
      <path
        d="M25.939 10.9396L16.4997 20.3789L11.5604 15.4396C10.9746 14.8539 10.0249 14.8539 9.4391 15.4396C8.85332 16.0254 8.85332 16.9752 9.4391 17.561L15.4391 23.5609C16.0248 24.1467 16.9746 24.1467 17.5604 23.5609L28.0604 13.061C28.6461 12.4752 28.6461 11.5254 28.0604 10.9396C27.4746 10.3539 26.5248 10.3539 25.939 10.9396Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
