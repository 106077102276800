import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { IApiError, ICurrency } from "@/models";
import { CurrenciesApi } from "@/apis";
import { CurrencyState } from ".";

export const fecthCurrencies = createAsyncThunk(
  "fetch/currencies",
  async (_, { rejectWithValue }) => {
    try {
      return (await CurrenciesApi.getCurrencies()) as ICurrency[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const currencyExtraReducerBuilder = (
  builder: ActionReducerMapBuilder<CurrencyState>
) => {
  builder.addCase(fecthCurrencies.pending, (state) => {
    state.currencies.error = null;
    state.currencies.loading = true;
  });
  builder.addCase(fecthCurrencies.fulfilled, (state, action) => {
    state.currencies.data = action.payload as ICurrency[];
    state.currencies.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.currencies.error = null;
    state.currencies.loading = false;
  });
  builder.addCase(fecthCurrencies.rejected, (state, action) => {
    state.currencies.data = null;
    state.currencies.error = action.payload as IApiError;
    state.currencies.loading = false;
    state.currencies.lastUpdate = null;
  });
};
