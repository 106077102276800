import Link from "next/link";
import { toast } from "react-toastify";
import { Toaster } from "@/widgets";
import { ToasterTypes } from "@/widgets/Toaster";
import i18n from "../../i18n";

export default function useToast(
  title: string,
  content: any,
  type: ToasterTypes = "error"
) {
  const toastId = toast(
    <Toaster
      type={type}
      title={title}
      content={content}
      onClose={() => {
        toast.dismiss(toastId);
      }}
      footer={
        <>
          {type === "error" && (
            <p>
              {i18n.t("useToast.problemsText")}{" "}
              <Link href="/help-center/contact">{i18n.t("useToast.support")}</Link>.
            </p>
          )}
        </>
      }
    />
  );
}
