import { InlineMenu, PoweredBy } from "@/widgets";
import * as S from "./styles";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <S.FooterContainer>
      <InlineMenu
        items={[
          {
            url: "/help-center/limits-info",
            label: t("footer.footerLimits"),
            id: "data-test-limits",
          },
          {
            url: "/help-center/fees-info",
            label: t("footer.footerTax&Dealines"),
            id: "data.test.TaxDeadlines",
          },
          {
            url: "/help-center/terms",
            label: t("footer.useTerms"),
            id: "data.test.useTerms",
          },
          {
            url: "/help-center/privacy-policies",
            label: t("footer.privacyPolicy"),
            id: "data.test.privacyPolicy",
          },
          {
            url: "/help-center/contact",
            label: t("footer.contact"),
            id: "data.test.contact",
          },
          {
            url: "https://ajudatransfero.zendesk.com/hc/pt-br",
            label: t("footer.faq"),
            id: "data.test.faq",
          },
        ]}
      />
      <PoweredBy />
    </S.FooterContainer>
  );
}
