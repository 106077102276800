export default function arrowUpGreen() {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 11.8333L5 1.16663M5 1.16663L1 5.16663M5 1.16663L9 5.16663"
        stroke="#027A48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
