import * as Sentry from "@sentry/nextjs";

export const sendMessageSentry = (title: string, ev: any) => {
  const scope = Sentry.getCurrentScope();
  scope.setUser({ email: window.localStorage.getItem("email") || "", id: window.localStorage.getItem("id") || "" });
  scope.setTag("ProfileLevel", window.localStorage.getItem("profileLevel"))
  Sentry.captureException(new Error(JSON.stringify(ev)), (scope) => {
    scope.setTransactionName(title);
    return scope;
  });
};
