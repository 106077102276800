import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { IAsyncState, IMarket } from "@/models";
import { marketReducer } from "./reducers";
import { useMarket } from "./hooks";
import { fecthMarkets, marketExtraReducerBuilder } from "./thunks";

interface AsyncMarket extends IAsyncState {
  data: null | IMarket[];
}

export interface MarketState {
  markets: AsyncMarket;
}

export const initialState: MarketState = {
  markets: {
    data: null,
    lastUpdate: null,
    error: null,
    loading: false,
  },
};

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: marketReducer,
  extraReducers: marketExtraReducerBuilder,
});

export const { clearMarket, updateMarket } = marketSlice.actions;

export { useMarket, fecthMarkets };

export const marketSelector = (state: RootState) => state.market;

export default marketSlice.reducer;
