export function isEmail(email: string): boolean {
  return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
    email
  );
}

export function isPhone(phone: string): boolean {
  const sanitizedPhone = phone.replace(/\D/g, "");
  return sanitizedPhone.length >= 10 && sanitizedPhone.length <= 14;
}

export function isRandonPixKey(pixKey: string) {
  return /^([\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12})$/.test(pixKey);
}

export function isEthereum(address: string) {
  return /^0x[a-fA-F0-9]{40}$/.test(address);
}

export function isPolygon(address: string) {
  return isEthereum(address);
}

export function isSolana(address: string) {
  return /^[a-zA-Z0-9]{43,44}$/.test(address);
}

export function isStellar(address: string) {
  return /^[a-zA-Z0-9]{56}$/.test(address);
}

export function isMemo(memo: string) {
  return /^[0-9]{8}$/.test(memo);
}

export function isBitcoin(address: string) {
  return /^(?:[13]{1}[a-km-zA-HJ-NP-Z1-9]{26,33}|(bc1|tb1)[a-z0-9]{39,59})$/.test(
    address
  );
}

export function isCPF(value: string) {
  const cpf = value.replace(/[-.]+/g, "");

  if (cpf == "" || cpf.length !== 11) return false;

  if (
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  )
    return false;

  if (cpf.length > 11) return false;

  let add = 0;
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);

  let rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(9))) return false;

  add = 0;
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);

  rev = 11 - (add % 11);
  if (rev == 10 || rev == 11) rev = 0;
  if (rev != parseInt(cpf.charAt(10))) return false;

  return true;
}

export function isCNPJ(value: string) {
  const cnpj = value.replace(/[-./]+/g, "");

  if (cnpj == "" || cnpj.length != 14) return false;

  if (
    cnpj == "00000000000000" ||
    cnpj == "11111111111111" ||
    cnpj == "22222222222222" ||
    cnpj == "33333333333333" ||
    cnpj == "44444444444444" ||
    cnpj == "55555555555555" ||
    cnpj == "66666666666666" ||
    cnpj == "77777777777777" ||
    cnpj == "88888888888888" ||
    cnpj == "99999999999999"
  )
    return false;

  let size = cnpj.length - 2;
  let numbers = cnpj.substring(0, size);
  let digits = cnpj.substring(size);
  let sum = 0;
  let pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0))) return false;

  size = size + 1;
  numbers = cnpj.substring(0, size);
  sum = 0;
  pos = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i)) * pos--;
    if (pos < 2) pos = 9;
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1))) return false;

  return true;
}
