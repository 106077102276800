import { useEffect, useState } from "react";
import { backToTop } from "@/utils/uiHelpers";
import { IconButton } from "@/widgets";

import styles from "./index.module.scss";

type BottomButtonsProps = {
  onFaqClick(): void;
};

export default function BottomButtons({ onFaqClick }: BottomButtonsProps) {
  const [showUp, setShowUp] = useState(false);

  const handleOnScroll = () => {
    const diff = window.innerWidth * 0.3; // 30% of window height

    if (window.scrollY > diff && !showUp) {
      setShowUp(true);
    } else if (window.scrollY <= diff && showUp) {
      setShowUp(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleOnScroll);

    return () => {
      window.removeEventListener("scroll", handleOnScroll);
    };
  }, [handleOnScroll]);

  return (
    <div className={`BottomButtons ${styles.container}`}>
      {showUp && <IconButton icon="arrowUp" onClick={backToTop} />}
      <IconButton icon="faq" onClick={onFaqClick} />
    </div>
  );
}
