import { ICustomer, IRequiredField } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

type ResetPasswordParamType = {
  username: string;
  newPassword: string;
  newPasswordConfirmation: string;
  resetPasswordCode: string;
};

type DoOnboardingParamType = {
  name: string;
  taxId: string;
  taxCountryCode: string;
  transactionalPassword: string;
  nickname: string;
  dateOfBirth: string;
  phone: string;
  email: string;
  nationality: string;
  mothersName?: string;
  addresses?: {
    city: string;
    state: string;
    country: string;
    zipCode: string;
    address: string;
    addressComplement: string;
  }[];
};

const CustomerApi = {
  getCustomer: async () => {
    try {
      const response = await api.request({
        url: "/customer",
        method: "GET",
      });
      return response.data as ICustomer;
    } catch (error: any) {
      sendMessageSentry(
        `Customer Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getCustomer");
    }
  },
  getRequiredFields: async (type?: string) => {
    try {
      const response = await api.request({
        url: `/customer/required-fields/${type || ""}`,
        method: "GET",
      });
      return response.data as IRequiredField[];
    } catch (error: any) {
      sendMessageSentry(
        `Required Fields Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getRequiredFields");
    }
  },
  resetPassword: async (data: ResetPasswordParamType) => {
    try {
      const response = await api.request({
        url: "/customer/reset-password",
        data,
        method: "POST",
      });
      return response.data as string;
    } catch (error: any) {
      sendMessageSentry(
        `Reset Password Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "resetPassword");
    }
  },
  doOnboarding: async (data: DoOnboardingParamType) => {
    try {
      const response = await api.request({
        url: "/customer/onboarding",
        data,
        method: "POST",
      });
      return response.data as string;
    } catch (error: any) {
      sendMessageSentry(
        `Onboarding Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "doOnboarding");
    }
  },
};

export default CustomerApi;
