export default function usdIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
        fill="#F0F0F0"
      />
      <path
        d="M3.65723 5.81727H28.3427C27.431 4.7132 26.3735 3.73414 25.2004 2.9082H6.79954C5.62648 3.73402 4.56904 4.71314 3.65723 5.81727Z"
        fill="#F0F0F0"
      />
      <path
        d="M0 16.0004C0 16.4908 0.0233125 16.9757 0.0665 17.455H31.9335C31.9767 16.9757 32 16.4908 32 16.0004C32 15.51 31.9767 15.0251 31.9335 14.5459H0.0665C0.0233125 15.0251 0 15.51 0 16.0004Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.74479 23.2733H30.2553C30.7264 22.3516 31.1107 21.3784 31.3975 20.3643H0.602539C0.889352 21.3784 1.27373 22.3517 1.74479 23.2733Z"
        fill="#F0F0F0"
      />
      <path
        d="M6.79954 29.0907H25.2004C26.3734 28.2648 27.4309 27.2857 28.3427 26.1816H3.65723C4.56904 27.2856 5.62648 28.2648 6.79954 29.0907Z"
        fill="#F0F0F0"
      />
      <path
        d="M0.602539 11.6376H31.3977C31.1108 10.6233 30.7265 9.65008 30.2554 8.72852H1.74485C1.27379 9.65008 0.889414 10.6233 0.602539 11.6376Z"
        fill="#F0F0F0"
      />
      <path
        d="M7.65137 2.90906H25.1996C22.5971 1.07669 19.424 0 15.9992 0C13.9122 0 10.2539 1.07669 7.65137 2.90906Z"
        fill="#D80027"
      />
      <path
        d="M15.3047 8.72742H30.2556C29.7235 7.68661 29.0809 6.71167 28.3431 5.81836H15.3047V8.72742Z"
        fill="#D80027"
      />
      <path
        d="M15.3047 14.5448H31.9338C31.8438 13.5459 31.6629 12.5732 31.3979 11.6357H15.3047V14.5448Z"
        fill="#D80027"
      />
      <path
        d="M0.602406 20.3651H31.3974C31.6625 19.4277 31.8434 18.4549 31.9334 17.4561H0.0664062C0.156406 18.4549 0.337281 19.4277 0.602406 20.3651Z"
        fill="#D80027"
      />
      <path
        d="M3.65762 26.1825H28.3431C29.0809 25.2891 29.7236 24.3142 30.2556 23.2734H1.74512C2.27718 24.3142 2.9198 25.2892 3.65762 26.1825Z"
        fill="#D80027"
      />
      <path
        d="M15.9993 32.0009C19.4241 32.0009 22.5972 30.9242 25.1997 29.0918H6.79883C9.40133 30.9242 12.5745 32.0009 15.9993 32.0009Z"
        fill="#D80027"
      />
      <path
        d="M0.602343 11.6354C0.337219 12.5729 0.156406 13.5456 0.0664062 14.5445H15.9999V-0.000976562C8.67615 -0.000976562 2.50184 4.91977 0.602343 11.6354Z"
        fill="#0052B4"
      />
      <path
        d="M9.52443 4.17383L10.5604 7.36227H13.9132L11.2009 9.33302L12.2367 12.5216L9.52443 10.551L6.81199 12.5216L7.84812 9.33302L5.13574 7.36227H8.48843L9.52443 4.17383Z"
        fill="#F0F0F0"
      />
    </svg>
  );
}
