export default function assetIcon() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="27" height="27" rx="13.5" fill="#E8F0FF" />
      <g clipPath="url(#clip0_336_1423)">
        <path
          d="M11.6668 14.7778C11.6668 15.6369 12.3633 16.3334 13.2224 16.3334H14.6668C15.5873 16.3334 16.3335 15.5872 16.3335 14.6667C16.3335 13.7462 15.5873 13 14.6668 13H13.3335C12.413 13 11.6668 12.2538 11.6668 11.3334C11.6668 10.4129 12.413 9.66671 13.3335 9.66671H14.7779C15.637 9.66671 16.3335 10.3632 16.3335 11.2223M14.0002 8.66671V9.66671M14.0002 16.3334V17.3334M20.6668 13C20.6668 16.6819 17.6821 19.6667 14.0002 19.6667C10.3183 19.6667 7.3335 16.6819 7.3335 13C7.3335 9.31814 10.3183 6.33337 14.0002 6.33337C17.6821 6.33337 20.6668 9.31814 20.6668 13Z"
          stroke="#4D4DFF"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_336_1423">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(6 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
