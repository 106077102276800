import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { IAsyncState } from "@/models";
import { IFavorite } from "@/models";
import { favoriteReducer } from "./reducers";
import { useFavorite } from "./hooks";
import { fetchFavorites, favoriteExtraReducerBuilder } from "./thunks";

interface AsyncFavorite extends IAsyncState {
  data: null | IFavorite[];
}

export interface FavoriteState {
  favorites: AsyncFavorite;
}

export const initialState: FavoriteState = {
  favorites: {
    data: null,
    error: null,
    lastUpdate: null,
    loading: false,
  },
};

export const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: favoriteReducer,
  extraReducers: favoriteExtraReducerBuilder,
});

export const { clearFavorite, updateFavorite, addFavorite } =
  favoriteSlice.actions;

export { useFavorite, fetchFavorites };

export const favoriteSelector = (state: RootState) => state.favorite;

export default favoriteSlice.reducer;
