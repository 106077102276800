import styles from "./index.module.scss";

export type CardSizeType = "tiny" | "small" | "medium" | "normal" | "large";
export type CardVariantType = "default" | "error" | "gray";

type CardProps = {
  children: any;
  variant?: CardVariantType;
  size?: CardSizeType;
  hasShadow?: boolean;
  keepOnMobile?: boolean;
  className?: string;
};

export default function Card({
  children,
  variant = "default",
  size = "normal",
  hasShadow = false,
  keepOnMobile = false,
  className = "",
}: CardProps) {
  return (
    <div
      className={`Card ${className} ${styles.container} ${styles[variant]} ${
        styles[size]
      } ${hasShadow ? styles.hasShadow : ""} ${
        keepOnMobile ? "" : styles.notKeepOnMobile
      }`}
    >
      {children}
    </div>
  );
}
