export default function othersStore() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M13.75 20.9062V26.25C13.75 26.4158 13.8158 26.5747 13.9331 26.6919C14.0503 26.8092 14.2092 26.875 14.375 26.875H25.625C25.7908 26.875 25.9497 26.8092 26.0669 26.6919C26.1842 26.5747 26.25 26.4158 26.25 26.25V20.9062"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2188 13.125H25.7812C25.9168 13.1261 26.0485 13.1708 26.1568 13.2523C26.2651 13.3339 26.3443 13.4481 26.3828 13.5781L27.5 17.5H12.5L13.6172 13.5781C13.6557 13.4481 13.7349 13.3339 13.8432 13.2523C13.9515 13.1708 14.0832 13.1261 14.2188 13.125V13.125Z"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 17.5V18.75C17.5 19.413 17.2366 20.0489 16.7678 20.5178C16.2989 20.9866 15.663 21.25 15 21.25C14.337 21.25 13.7011 20.9866 13.2322 20.5178C12.7634 20.0489 12.5 19.413 12.5 18.75V17.5"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 17.5V18.75C22.5 19.413 22.2366 20.0489 21.7678 20.5178C21.2989 20.9866 20.663 21.25 20 21.25C19.337 21.25 18.7011 20.9866 18.2322 20.5178C17.7634 20.0489 17.5 19.413 17.5 18.75V17.5"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 17.5V18.75C27.5 19.413 27.2366 20.0489 26.7678 20.5178C26.2989 20.9866 25.663 21.25 25 21.25C24.337 21.25 23.7011 20.9866 23.2322 20.5178C22.7634 20.0489 22.5 19.413 22.5 18.75V17.5"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
