import scssVariables from "@/styles/variables.module.scss";

export default function last() {
  return (
    <svg viewBox="0 0 13 14" fill="none">
      <path
        d="M1.47395 13.8109L0.341537 12.6785L6.11344 6.9066L0.339844 1.13241L1.47226 0L8.37999 6.90773L1.47395 13.8109Z"
        fill={scssVariables.primary}
      />
      <rect
        x="10.708"
        width="1.55555"
        height="13.9999"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
