import { useEffect, useState } from "react";
import { IBlockchain, ICurrency } from "@/models";
import { useCurrency } from "@/store/features/currency";

export default function useBlockchainByCurrencyId(
  currencyId: string,
  networkId?: string
) {
  const [blockchain, setBlockchain] = useState<IBlockchain | undefined>();
  const [blockchains, setBlockchains] = useState<
    IBlockchain[] | undefined | null
  >();
  const { currencyState } = useCurrency();

  useEffect(() => {
    if (currencyState.currencies.data !== null && currencyId) {
      const theCurrency = currencyState.currencies.data.find(
        (item: ICurrency) => item.id === currencyId
      );

      if (theCurrency && theCurrency.blockchains) {
        setBlockchains(theCurrency.blockchains);

        if (networkId) {
          const theBlockchain = theCurrency.blockchains.find(
            (item: IBlockchain) => item.id === networkId
          );

          if (theBlockchain) {
            setBlockchain(theBlockchain);
          }
        }
      }
    }
  }, [currencyState.currencies.data, currencyId, networkId]);

  return { blockchain, blockchains };
}
