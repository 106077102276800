import MaskedInput from "react-text-mask";
import InputMask from "react-input-mask";
import { Icon, IconButton } from "@/widgets";

export const getInput = (
  fieldType: string,
  placeholder: string,
  mask: string,
  isCurrency: boolean,
  currencyMask: any,
  classError: string,
  variant: string,
  id: string,
  ref: any,
  props: any
) => {
  if (isCurrency && currencyMask) {
    return (
      <MaskedInput
        id={id}
        className={`${classError} ${variant}`}
        ref={ref}
        mask={currencyMask}
        guide={false}
        type={fieldType}
        placeholder={placeholder}
        showMask={false}
        {...props}
      />
    );
  } else if (mask) {
    return (
      <InputMask
        id={id}
        className={`${classError} ${variant}`}
        inputRef={ref}
        type={fieldType}
        placeholder={placeholder}
        mask={mask}
        {...props}
      />
    );
  } else {
    return (
      <input
        type={fieldType}
        id={id}
        className={`${classError} ${variant}`}
        placeholder={placeholder}
        ref={ref}
        {...props}
      />
    );
  }
};

export const getPasswordIcons = (
  passwordIconClass: string,
  handleTogglePassword: () => void
) => {
  return (
    <div className={passwordIconClass}>
      <IconButton icon="eyeHide" onClick={handleTogglePassword} />
      <IconButton icon="eye" onClick={handleTogglePassword} />
    </div>
  );
};

export const getSearchIcon = (searchIconClass: string) => {
  return (
    <div className={searchIconClass}>
      <Icon icon="search" />
    </div>
  );
};
