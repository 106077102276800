import { useCallback, useEffect } from "react";
import { ParsedUrlQuery } from "querystring";
import { useRouter } from "next/router";
import Link from "next/link";

import React from "react";
import { IconButton } from "@/widgets";
import styles from "./index.module.scss";
import i18n from "../../../i18n";

const getItem = (
  currentLocale: string,
  locale: string | undefined,
  pathname: string,
  query: ParsedUrlQuery,
  asPath: string
) => {
  const changeLanguageHandler = () => {
    i18n.changeLanguage(currentLocale);
  };

  if (currentLocale === locale) {
    return (
      <li key={currentLocale} className={styles.active}>
        <span onClick={changeLanguageHandler}>
          {currentLocale.toUpperCase()}
        </span>
      </li>
    );
  }

  return (
    <li key={currentLocale}>
      <Link href={{ pathname, query }} as={asPath} locale={currentLocale}>
        <span onClick={changeLanguageHandler}>
          {currentLocale.toUpperCase()}
        </span>
      </Link>
    </li>
  );
};

interface IProps {
  open: boolean;
  closed: any;
  isShowCase?: boolean;
}

export default function LangSwitcher(props: IProps) {
  const { open, closed, isShowCase } = props;

  const { locales, locale, pathname, query, asPath } = useRouter();

  const closeSwitcher = useCallback(() => {
    closed(false);
  }, []);

  useEffect(() => {
    if (open) {
      document.body.addEventListener("click", closeSwitcher);
    }

    return () => {
      document.body.removeEventListener("click", closeSwitcher);
    };
  }, [open]);

  return (
    <div
      className={`LangSwitcher ${styles.container} ${
        open ? styles.isOpen : ""
      }`}
    >
      <IconButton
        icon={isShowCase ? "languageItemWhite" : "languageNoSelected"}
        onClick={(event) => {
          event?.stopPropagation();
          closed();
        }}
        title={i18n.t("chooseLanguageTitle")}
      />
      <ul>
        {locales &&
          locales.map((currentLocale) =>
            getItem(currentLocale, locale, pathname, query, asPath)
          )}
      </ul>
    </div>
  );
}
