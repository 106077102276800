import scssVariables from "@/styles/variables.module.scss";

export default function orderBy() {
  return (
    <svg viewBox="0 0 284 584" fill="none">
      <path
        d="M239.852 174.796C248.944 185.187 264.738 186.24 275.129 177.148C285.52 168.056 286.573 152.262 277.481 141.871L239.852 174.796ZM160.813 8.53737C151.723 -1.85363 135.927 -2.9063 125.537 6.1857C115.147 15.2777 114.093 31.0717 123.187 41.4627L160.813 8.53737ZM167 25C167 11.193 155.807 3.79004e-05 142 3.72969e-05C128.193 3.66934e-05 117 11.193 117 25L167 25ZM117 558.333C117 572.14 128.193 583.333 142 583.333C155.807 583.333 167 572.14 167 558.333L117 558.333ZM160.813 41.4627C169.907 31.0717 168.853 15.2777 158.463 6.1857C148.073 -2.9063 132.277 -1.85363 123.187 8.53737L160.813 41.4627ZM6.51989 141.871C-2.57344 152.262 -1.52013 168.056 8.86987 177.148C19.2599 186.24 35.0565 185.187 44.1465 174.796L6.51989 141.871ZM123.187 574.797C132.277 585.187 148.073 586.24 158.463 577.147C168.853 568.057 169.907 552.26 160.813 541.87L123.187 574.797ZM44.1465 408.537C35.0565 398.147 19.2599 397.093 8.86986 406.187C-1.52014 415.277 -2.57345 431.073 6.51988 441.463L44.1465 408.537ZM123.187 541.87C114.093 552.26 115.147 568.057 125.537 577.147C135.927 586.24 151.723 585.187 160.813 574.797L123.187 541.87ZM277.481 441.463C286.573 431.073 285.52 415.277 275.129 406.187C264.738 397.093 248.944 398.147 239.852 408.537L277.481 441.463ZM277.481 141.871L160.813 8.53737L123.187 41.4627L239.852 174.796L277.481 141.871ZM117 25L117 558.333L167 558.333L167 25L117 25ZM123.187 8.53737L6.51989 141.871L44.1465 174.796L160.813 41.4627L123.187 8.53737ZM160.813 541.87L44.1465 408.537L6.51988 441.463L123.187 574.797L160.813 541.87ZM160.813 574.797L277.481 441.463L239.852 408.537L123.187 541.87L160.813 574.797Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
