
export default function approvedIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="arrow-up">
        <path id="Icon" d="M8.00016 12.6666V3.33325M8.00016 3.33325L3.3335 7.99992M8.00016 3.33325L12.6668 7.99992" stroke="#7D89B0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
}
