/* eslint-disable no-unused-vars */
// import "@/dependencies/react-dates/initialize";
import { FocusEvent, useCallback, useEffect, useRef } from "react";
// import { DateRangePicker, SingleDatePicker } from "@/dependencies/react-dates";
import moment from "moment";
import { useLocale } from "@/hooks";

import { FieldType } from "./types";
import { datePickerUIModifier, useDatePickerState } from "./hooks";

import styles from "./index.module.scss";
import Icon from "@/widgets/Icons";

export default function DatePicker({
  type = "single",
  variant = "default",
  defaultStartDate,
  startDateLabel,
  startDatePlaceholder,
  startDateId,
  defaultEndDate,
  endDateLabel,
  endDatePlaceholder,
  endDateId,
  defaultDate,
  dateLabel,
  datePlaceholder,
  dateId,
  hasError = false,
  onDateChange,
  minDate,
  maxDate,
  disabled = false,
}: FieldType) {
  const {
    startDate,
    endDate,
    date,
    setDateRange,
    setDate,
    focus,
    focused,
    setFocus,
    setFocused,
  } = useDatePickerState(defaultStartDate, defaultEndDate, defaultDate);
  const { locale, dateFormat } = useLocale();
  const ref = useRef<HTMLDivElement>(null);
  const sdId = startDateId ? startDateId : "startDate";
  const edId = endDateId ? endDateId : "endDate";
  const dId = dateId ? dateId : "dateId";
  const min = minDate ? minDate : moment().subtract(120, "year");
  const max = maxDate ? maxDate : moment();

  datePickerUIModifier(
    ref,
    variant,
    type,
    startDateLabel,
    sdId,
    endDateLabel,
    edId,
    dateLabel,
    dId
  );

  useEffect(() => {
    async function loadMomentLocale() {
      switch (locale) {
        case "es":
          // @ts-ignore
          await import("moment/locale/es");
          break;
        case "pt":
          // @ts-ignore
          await import("moment/locale/pt");
          break;
      }
    }

    if (locale) {
      loadMomentLocale().then(() => moment.locale(locale));
    }
  }, [locale]);

  const returnYears = useCallback(
    (year: any) => {
      let years = [];
      for (let a = year; a >= year - 100; a--) {
        years.push(
          <option key={a} value={a}>
            {a}
          </option>
        );
      }

      return years;
    },
    [min, max]
  );

  const renderMonthElement = useCallback(
    ({ month, onMonthSelect, onYearSelect }: any) => {
      return (
        <div className={styles.monthRenderer}>
          <div>
            <select
              value={
                moment(month).isBefore(moment(min))
                  ? moment(min).month()
                  : month.month()
              }
              onChange={(e) => {
                if (month.isBefore(moment(min))) {
                  onMonthSelect(moment(min), moment(min).month());
                } else {
                  onMonthSelect(month, e.target.value);
                }
              }}
            >
              {moment.months().map((label, value) => (
                <option value={value} key={value}>
                  {label}
                </option>
              ))}
            </select>
          </div>
          <div>
            <select
              value={month.year()}
              onChange={(e) => {
                onYearSelect(month, e.target.value);
              }}
            >
              {returnYears(month.year())}
            </select>
          </div>
        </div>
      );
    },
    [min, max]
  );

  const handleOnBlur = () => {
    // TODO: resolve accessibility
    //setFocus(null);
    //setFocused(false);
  };

  return (
    <div
      className={`DatePicker ${type} ${styles.container} ${styles[type]} ${
        styles[variant]
      } ${hasError ? styles.hasError : ""}`}
      ref={ref}
      onBlur={(event: FocusEvent<HTMLDivElement>) => {
        if (!ref.current?.contains(event.relatedTarget)) {
          handleOnBlur();
        }
      }}
    >
      {/* {type === "single" && (
        <SingleDatePicker
          date={date}
          // readOnly={true}
          onDateChange={(date: any) => {
            setDate(date);
            onDateChange({
              date: date,
            });
          }}
          focused={focused}
          onFocusChange={({ focused }: any) => setFocused(focused)}
          id={dId}
          numberOfMonths={1}
          placeholder={
            datePlaceholder === undefined ? dateFormat : datePlaceholder
          }
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={() => false}
          renderMonthElement={renderMonthElement}
          disabled={disabled}
        />
      )}
      {type === "range" && (
        <DateRangePicker
          startDate={startDate}
          startDateId={sdId}
          endDate={endDate}
          endDateId={edId}
          onDatesChange={({ startDate, endDate }: any) => {
            setDateRange({ startDate, endDate });
            onDateChange({
              startDate: startDate,
              endDate: endDate,
            });
          }}
          focusedInput={focus}
          onFocusChange={(focus: any) => setFocus(focus)}
          displayFormat={dateFormat}
          startDatePlaceholderText={
            startDatePlaceholder === undefined
              ? dateFormat
              : startDatePlaceholder
          }
          endDatePlaceholderText={
            endDatePlaceholder === undefined ? dateFormat : endDatePlaceholder
          }
          numberOfMonths={2}
          hideKeyboardShortcutsPanel={true}
          isOutsideRange={() => false}
          renderMonthElement={renderMonthElement}
          minDate={min}
          maxDate={max}
          disabled={disabled}
        />
      )} */}
      <Icon icon="calendar" />
      {type === "range" && <Icon icon="calendar" />}
    </div>
  );
}
