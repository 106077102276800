import styles from "./index.module.scss";

type TableColProps = {
  children: any;
  className?: string;
  textAlign?: "left" | "center" | "right";
};

export default function TableCol({
  children,
  className = "",
  textAlign = "left",
}: TableColProps) {
  return (
    <div
      className={`TableCol ${styles.container} ${className} ${styles[textAlign]}`}
    >
      {children}
    </div>
  );
}
