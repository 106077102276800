export default function tradeBlue() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#E8F0FF" />
      <path
        d="M16.6665 14.9167L7.33317 14.9167M7.33317 14.9167L9.6665 12.5833M7.33317 14.9167L9.6665 17.25M7.33317 9.08333L16.6665 9.08333M16.6665 9.08333L14.3332 6.75M16.6665 9.08333L14.3332 11.4167"
        stroke="#413FCF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
