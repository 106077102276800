import styled, { css } from "styled-components";

export const Footer = styled.div<{ $isLogged?: boolean }>`
  display: flex;
  justify-content: center;
  margin-top: ${(props) => (props.$isLogged ? "0px" : "80px")};
  width: 100%;
  margin-bottom: 32px;

  ${(props) =>
    props.$isLogged &&
    css`
      padding: 16px 0px;
      margin-left: 215px;
    `}
`;

interface IContentBaseLayout {
  $isScrolled: boolean;
  $isMenuOpen: boolean;
}

export const ContentBaseLayout = styled.div<IContentBaseLayout>`
  overflow: hidden;

  ${(props) =>
    props.$isScrolled &&
    css`
      .content > header {
        position: fixed !important;
      }
    `}
`;

export const Content = styled.div`
  width: 1200px;
  position: relative;
  display: flex;
  margin: 90px auto 0;
  flex-wrap: wrap;
`;

interface IHeader {
  $hasBorder: boolean;
}

export const Header = styled.header<IHeader>`
  left: 0;
  min-height: 90px;
  width: 100%;
  z-index: 100;
  position: fixed;
  left: 0;
  background-color: #fff;
  border-bottom: ${(props) =>
    props.$hasBorder ? " 1px solid #dcdfea" : "none"};

  > div {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1230px;

    @media (max-width: 670px) {
      margin: 0 auto;
      padding: 0;
      max-width: 100%;
    }
  }

  &.contentCard {
    background-color: transparent;
  }

  > div {
    margin: 0 auto;
    padding: 0 15px;
    max-width: 1230px;

    @media (max-width: 670px) {
      margin: 0 auto;
      padding: 0;
      max-width: 100%;
    }
  }
`;

interface IContentHeader {
  $hasContentHeader: string;
}

export const ContentHeader = styled.div<IContentHeader>``;

export const Main = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

interface IContentMain {
  $isLoggedIn: "contentChildrenAndHeader" | "contentChildrenAndHeaderLogin";
}

export const ContentMain = styled.div<IContentMain>`
  width: calc(100% - 300px);
  min-height: 50vh;
  margin: 30px auto;
`;

export const Nav = styled.nav`
  width: 230px;
`;
