import { useI18n } from "@/hooks";
import { HelpCenterLayout } from "../components";
import ContactUsForm from "./forms";

import content from "./index.json";

export default function ContactPage() {
  const { i18n } = useI18n(content);

  return (
    <HelpCenterLayout pageTitle={i18n("title")}>
      <ContactUsForm />
    </HelpCenterLayout>
  );
}
