import scssVariables from "@/styles/variables.module.scss";

export default function switcher() {
  return (
    <svg viewBox="0 0 22 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95189 0.970733C5.35688 0.565745 6.01349 0.565745 6.41848 0.970733L10.5666 5.11888C10.9716 5.52387 10.9716 6.18048 10.5666 6.58547C10.1616 6.99046 9.50503 6.99046 9.10004 6.58547L6.72222 4.20766V18.2966C6.72222 18.8694 6.25792 19.3337 5.68518 19.3337C5.11245 19.3337 4.64815 18.8694 4.64815 18.2966V4.20766L2.27033 6.58547C1.86534 6.99046 1.20873 6.99046 0.803741 6.58547C0.398753 6.18048 0.398753 5.52387 0.803741 5.11888L4.95189 0.970733ZM15.0185 1.70403C15.0185 1.13129 15.4828 0.666992 16.0556 0.666992C16.6283 0.666992 17.0926 1.13129 17.0926 1.70403V15.793L19.4704 13.4152C19.8754 13.0102 20.532 13.0102 20.937 13.4152C21.342 13.8202 21.342 14.4768 20.937 14.8818L16.7889 19.0299C16.3839 19.4349 15.7272 19.4349 15.3223 19.0299L11.1741 14.8818C10.7691 14.4768 10.7691 13.8202 11.1741 13.4152C11.5791 13.0102 12.2357 13.0102 12.6407 13.4152L15.0185 15.793V1.70403Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
