import scssVariables from "@/styles/variables.module.scss";

export default function send() {
  return (
    <svg viewBox="0 0 14 13" fill="none">
      <path
        d="M12.7203 0.313123C12.5796 0.173441 12.4032 0.0752411 12.2103 0.0293201C12.0175 -0.0165935 11.8157 -0.00845785 11.6271 0.0528421L0.747396 3.6819C0.545619 3.74584 0.367319 3.86814 0.235007 4.03335C0.102695 4.19856 0.0223128 4.39928 0.00401136 4.61016C-0.0142901 4.82103 0.0303071 5.03257 0.132174 5.21811C0.234048 5.40366 0.38861 5.55484 0.576354 5.65263L5.09037 7.88361L7.32135 12.4125C7.41104 12.5897 7.54824 12.7386 7.71765 12.8423C7.88705 12.9461 8.08204 13.0007 8.28067 13H8.35504C8.5678 12.9843 8.7709 12.905 8.93799 12.7724C9.1051 12.6398 9.22847 12.4599 9.29205 12.2563L12.9732 1.4063C13.0389 1.21885 13.0501 1.01658 13.0053 0.823043C12.9605 0.629513 12.8617 0.452678 12.7203 0.313123ZM1.20847 4.70815L10.705 1.54016L5.43246 6.81274L1.20847 4.70815ZM8.33273 11.825L6.22074 7.60102L11.4933 2.32844L8.33273 11.825Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
