import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { balanceSelector } from ".";

export function useBalance() {
  const balanceState = useSelector(balanceSelector);
  const balanceDispatch = useDispatch<AppDispatch>();

  return { balanceState, balanceDispatch };
}
