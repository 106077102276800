import styled from "styled-components";

interface FooterProps {
  $collapsed?: boolean;
}

export const FooterContainer = styled.div<FooterProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
