import scssVariables from "@/styles/variables.module.scss";

export default function add() {
  return (
    <svg viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 0.976744C3.67345 0.976744 0.976744 3.67345 0.976744 7C0.976744 10.3265 3.67345 13.0233 7 13.0233C10.3265 13.0233 13.0233 10.3265 13.0233 7C13.0233 3.67345 10.3265 0.976744 7 0.976744ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.134 14 7C14 10.866 10.866 14 7 14C3.134 14 0 10.866 0 7Z"
        fill={scssVariables.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3.74895C7.26972 3.74895 7.48837 3.9676 7.48837 4.23732V9.76262C7.48837 10.0323 7.26972 10.251 7 10.251C6.73028 10.251 6.51163 10.0323 6.51163 9.76262V4.23732C6.51163 3.9676 6.73028 3.74895 7 3.74895Z"
        fill={scssVariables.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.7538 7C3.7538 6.73028 3.97245 6.51163 4.24217 6.51163H9.76744C10.0372 6.51163 10.2558 6.73028 10.2558 7C10.2558 7.26972 10.0372 7.48837 9.76744 7.48837H4.24217C3.97245 7.48837 3.7538 7.26972 3.7538 7Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
