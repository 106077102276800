import Link from "next/link";

export default function Es_i18n() {
  // TODO: atualizar links dessa página quando fluxos estiverem prontos
  return (
    <>
      <p>Estimado usuario,</p>
      <p>
        a Política de Privacidad es una parte integral de los Términos y
        Condiciones de Uso de la Plataforma Transfero (
        <Link href="/" target="_blank">
          app.transfero.com
        </Link>
        ), y describe los términos y condiciones aplicables al uso de la
        información proporcionada por el Usuario a Transfero.
      </p>
      <p>
        Cuando no se defina en esta Política de Privacidad, los conceptos y
        expresiones en mayúsculas utilizados en este documento tendrán el
        significado dado en los Términos y Condiciones de Uso de la Plataforma
        Transfero.
      </p>
      <p>
        Los temas tratados en esta Política de Privacidad son los siguientes
      </p>
      <p>
        <em>- Qué datos recopilamos;</em>
        <br />
        <em>- Cómo recopilamos los datos;</em>
        <br />
        <em>- Cómo utilizamos los datos recopilados;</em>
        <br />
        <em>- Cómo compartimos los datos recopilados;</em>
        <br />
        <em>- Derecho de los titulares de los datos recopilados (Usuarios);</em>
        <br />
        <em>- Cookies y Publicidad</em>
        <br />
      </p>
      <p>
        Con el fin de simplificar la comprensión por parte del Usuario, la
        siguiente tabla presenta de manera concisa y simplificada la
        recopilación sistemática de datos por parte de Transfero, lo que no
        exime al Usuario de la necesidad de leer las demás disposiciones de esta
        Política de Privacidad.
      </p>
      <h1>Datos de registro</h1>
      <br />
      <p>
        <em>1. Datos recopilados</em>
        <br />
        Nombre completo, sexo, fecha de nacimiento, naturalidad, nacionalidad,
        estado civil, nombre del cónyuge o pareja, afiliación, CPF / ID / CNH /
        Pasaporte, e-mail, dirección completa, ocupación profesional, teléfono
        fijo, teléfono móvil, ingresos anuales, datos bancarios (banco, agencia
        y número de cuenta), declaración de persona políticamente expuesta y
        autorretrato, por lo tanto, documento de identificación.
      </p>
      <p>
        <em>2. ¿Para quién recolectamos?</em>
        <br />
        - Verificar la identidad del Usuario;
        <br />
        - Realizar el procedimiento "Conozca su Costumer" para garantizar la
        seguridad del usuario y evitar que el fraude se perpetúe utilizando los
        datos personales del usuario;
        <br />- Asegurar que las transacciones bancarias realizadas se realicen
        únicamente para cuentas bancarias mantenidas por el Usuario.
      </p>
      <p>
        <em>3. ¿Cómo recopilamos?</em>
        <br />
        El propio Usuario informa en el momento de su registro en la Transfero o
      </p>
      <p>
        <em>4. Base jurídica prevista en la LGPD para la recaudación</em>
        <br />
        - Cumplimiento de la obligación legal (art. 7°, II);
        <br />- Garantía de Prevención del Fraude (Art. 11, II, g)
      </p>
      <h1>Datos de identificación digital</h1>
      <br />
      <p>
        <em>1. Datos recopilados</em>
        <br />
        Dirección IP y puerto lógico de origen; IMEI del Dispositivo Móvil por
        el cual el Usuario accedió a la Plataforma Transfero; Dispositivo
        (versión del sistema operativo, fabricante, datos de las aplicaciones
        instaladas, idiomas, permisos y configuraciones de teclados, redes wi-fi
        y red móvil utilizada, información sobre el consumo de Internet,
        memoria, uso de VPN, estado de la batería); Navegador su respectiva
        versión; Geolocalización; Registro de fechas y horas de la acción de
        cada usuario; Pantallas a las que accede el Usuario; Información sobre
        la cantidad de clics e intentos de utilizar la Plataforma Transfero y /
        o la aplicación móvil; ID de sesión; Cookies, Resolución de Pantalla,
        Java, Información sobre los servicios y productos que el Usuario busca y
        utiliza; Páginas y aplicaciones que originaron el acceso a la Plataforma
        Transfero; y aplicaciones a las que se accedió durante y después de
        navegar por la Plataforma Transfero; Contactos registrados en el
        dispositivo, Flash Players instalado; Número de clics y velocidad de
        escritura
      </p>
      <p>
        <em>2. ¿Para quién recolectamos?</em>
        <br />
        - Identificar y autenticar el acceso del Usuario a la Plataforma
        Transfero, evitando que terceros accedan al sistema de forma
        fraudulenta;
        <br />
        - Identificar "bugs", errores e inconsistencias en la Plataforma
        Transfero para que dichos errores puedan ser corregidos y mejorar el
        acceso por parte del Usuario;
        <br />- Analizar el desempeño y medir la audiencia de la Plataforma
        Transfero; - Analizar la seguridad de la Plataforma Transfero y la
        resiliencia ante posibles ataques
      </p>
      <p>
        <em>3. ¿Cómo recopilamos?</em>
        <br />
        Los datos se recopilan a medida que el Usuario accede, permanece y
        utiliza la Plataforma Transfero.
      </p>
      <p>
        <em>4. Base jurídica prevista en la LGPD para la recaudación</em>
        <br />- Consentimiento (Art. 7°, I); y - Cumplimiento de la Obligación
        Legal (Art. 7°, II).I453
      </p>
      <h1>Datos de la wallet del usuario</h1>
      <br />
      <p>
        <em>1. Datos recopilados</em>
        <br />
        Claves de API de trading y dirección de wallet cifrada (wallets)
      </p>
      <p>
        <em>2. ¿Para quién recolectamos?</em>
        <br />- Asegurarse de que los retiros y / o depósitos se realicen de
        manera segura para el destinatario final
      </p>
      <p>
        <em>3. ¿Cómo recopilamos?</em>
        <br />
        El Usuario informa al solicitar un depósito/efectivo
      </p>
      <p>
        <em>4. Base jurídica prevista en la LGPD para la recaudación</em>
        <br />- Ejecución del Contrato (Art. 7°, V)
      </p>
      <h1>Datos de servicio al cliente</h1>
      <br />
      <p>
        <em>1. Datos recopilados</em>
        <br />
        Conversaciones de texto, audio o video cuando se ponen en contacto con
        los canales de servicio de Transfero
      </p>
      <p>
        <em>2. ¿Para quién recolectamos?</em>
        <br />
        - Permitir la resolución de problemas ocurridos con el Usuario;
        <br />- Medición de la calidad del servicio al cliente;
      </p>
      <p>
        <em>3. Base jurídica prevista en la LGPD para la recaudación</em>
        <br />- Cumplimiento de la obligación legal (art. 7, II)
      </p>
      <h1>Transferir datos de acceso a la plataforma</h1>
      <br />
      <p>
        <em>1. Datos recopilados</em>
        <br />
        Nombre de usuario, contraseña de acceso, palabra de seguridad, PIN de
        seguridad e información de seguridad
      </p>
      <p>
        <em>2. ¿Para quién recolectamos?</em>
        <br />- Asegurar la autenticidad del acceso del Usuario a la Plataforma
        Transfero, además de garantizar la seguridad de sus transacciones.
      </p>
      <p>
        <em>3. ¿Cómo recopilamos?</em>
        <br />
        Recogido en el momento del registro del Usuario en la Plataforma
        Transfero
      </p>
      <p>
        <em>4. Base jurídica prevista en la LGPD para la recaudación</em>
        <br />- Cumplimiento de la obligación legal (art. 7, II)
      </p>
      <h1>Comunicación del usuario y datos de marketing</h1>
      <br />
      <p>
        <em>1. Datos recopilados</em>
        <br />
        Nombre completo, e-mail y teléfono móvil
      </p>
      <p>
        <em>2. ¿Para qué recaudamos?</em>
        <br />
        - Ponerse en contacto con el Usuario para cualquier aviso sobre la
        Plataforma Transfero;
        <br />
        -Promocionar productos y/o servicios de Transfero que puedan ser de
        interés para el Usuario.
      </p>
      <p>
        <em>3. ¿Cómo recopilamos?</em>
        <br />
        Recogido en el momento del registro en la Plataforma Transfero
      </p>
      <p>
        <em>4. Base jurídica prevista en la LGPD para la recaudación</em>
        <br />- Consentimiento (Art. 7°, I)
      </p>
      <h1>1. ACEPTAR Y CAMBIOS</h1>
      <p>
        1.1 Al expresar la Aceptación de los Términos de Uso de la Plataforma
        Transfero, que esta Política de Privacidad es un anexo, el Usuario
        declara expresamente y a todos los efectos de la ley que ha leído,
        entendido y entendido todos los derechos y obligaciones establecidos en
        los Términos y Condiciones de Transfero, y también está de acuerdo con
        todas las disposiciones de esta Política de Privacidad. El uso de la
        Plataforma Transfero indica que el Usuario acepta esta Política de
        Privacidad.
      </p>
      <p>
        1.2 Esta Política de Privacidad cubre el procesamiento atribuido por
        Transfer a datos capaces de identificar al Usuario, que de conformidad
        con el Art. 5°, V c/c VI de la Ley 13.709/2018, la Ley General de
        Protección de Datos ("LGPD"), clasifica a Transfero como responsable del
        tratamiento y al Usuario como titular de los datos recogidos.
      </p>
      <p>
        1.3 Esta Política de Privacidad está sujeta a constantes mejoras y
        mejoras. Así te recomendamos su lectura periódica.
      </p>
      <h1>2. USO DE LOS DATOS DEL USUARIO</h1>
      <p>
        2.1 Los datos del Usuario pueden ser recopilados por la Plataforma
        Transfero cuando el Usuario: (i) navega, realiza su registro y
        Transacciones en la Plataforma Transfero; ii) comparte información con
        el Plataforma Transfero con el objetivo de mejorar los sistemas
        mantenidos por Transfero; (iii) entra en contacto con contacto con
        canales de atención.
      </p>
      <p>
        2.2 La Plataforma Transfero puede obtener datos de usuarios a través de
        socios externos, proveedores y redes de información (cuando previamente
        lo autorice cada Usuario), fuentes públicas oficiales, entre otros.
      </p>
      <p>
        2.3 Se solicitarán Datos Personales del Usuario para que cada Usuario
        tenga acceso a la Plataforma Voy a transferir. Entre dichos datos, se
        solicitarán: nombre completo; fecha y lugar de nacimiento; nacionalidad;
        género; CPF/RG/CNH; membresía; e-mail; teléfono celular; teléfono fijo;
        dirección completo; país de residencia; estado civil; nombre del cónyuge
        o pareja; profesión; ingresos mensuales; equidad; declaración de persona
        políticamente expuesta, copia de identificación y copia prueba de
        residencia.
      </p>
      <p>
        2.4 Sin enviar dichos documentos e información a la Plataforma
        Transfero, el uso de canales y Las características de la Transfero
        pueden estar restringidas y, dependiendo del caso, cancelación. El La
        transferencia puede, según sea el caso, solicitar otros documentos para
        el Usuario, con la intención de garantizar el acceso a la Plataforma
        Transfero y garantizar la seguridad del usuario.
      </p>
      <p>
        2.5 El Usuario que se niegue o deje de proporcionar los datos básicos
        necesarios podrá tener acceso a la Plataforma Transfero denegada o
        cancelada.
      </p>
      <p>
        2.6 Al registrarse en la Plataforma Transfero, los siguientes datos e
        información pueden ser recogidos, retenidos y/o guardados por Transfero,
        entre otros:
      </p>
      <p>
        - Datos de contacto: número de teléfono y otros Datos similares.
        <br />
        - Credenciales: incluyendo contraseñas, palabras seguras, PIN de
        seguridad e información de seguridad necesario para el proceso de
        autenticación, acceso a la Plataforma Transfero y cualquier
        Transacciones.
        <br />
        - Datos financieros: recopilamos los datos necesarios para la
        realización de estados de cuenta en moneda local (R$), banco y agencia.
        Además, Transfero tendrá que recibir claves API de negociaciones y
        dirección de wallet encriptada (Wallet).
        <br />
        - Datos demográficos: género, dirección, educación, ingresos.
        <br />
        - Datos de uso: Además de los datos relacionados con la transacción,
        Transfero también puede recopilar datos relacionado con la interacción
        con otros Usuarios, incluido su perfil de usuario, también podemos
        capturar datos relacionados con su interacción con nuestras
        comunicaciones y en nuestros canales, datos de dispositivos, sistema
        operativo e identificador de dispositivo (IMEI) y configuración.
        <br />
        - Datos de servicio: También se registra su interacción en nuestros
        canales de servicio, así como otros detalles de su contacto, y puede
        incluir contenido de chat de chat.
        <br />
        - Datos de relación: Solo cuando se concede un permiso inequívoco,
        podemos capturar los datos de los que provienen contactos en su red de
        relaciones.
        <br />- datos de geolocalización: se pueden recopilar datos de ubicación
        a partir de datos (Sistema Mundial de Navegación por Satélite), GNSS
        (Sistema Mundial de Navegación por Satélite), comunicaciones, puntos de
        acceso Wi-Fi o ubicación derivada de la IP de cada Usuario.
      </p>
      <p>
        2.7 La Plataforma Transfero hará sus mejores esfuerzos para garantizar
        que todos los datos personales almacenado en servidores o medios
        magnéticos de alta seguridad, aplicando siempre el prácticas
        recomendadas para combatir desastres o interrumpir sus actividades.
      </p>
      <p>
        2.8 El registro en la Plataforma Transfero conducirá, además del acceso
        a los sistemas mantenidos por Transfero, la recepción de mensajes
        electrónicos (como e-mails y pushes) de seguridad y protección
        administrativo, además de mensajes publicitarios, y este envío es
        indispensable para la ejecución y desarrollo de las actividades de 3RZ.
        Si no está de acuerdo con este procedimiento, deberá solicitar la
        cancelación de su registro en la Plataforma Transfero.
      </p>
      <h1>3. USO DE LOS DATOS RECOPILADOS</h1>
      <p>
        3.1 Los datos del usuario pueden recopilarse con el fin de mejorar la
        Plataforma Transfero, y protección de dichos datos en base a la Ley
        General de Protección de Datos y a los Términos y Condiciones Voy a
        transferir. Sin perjuicio, dichos datos recopilados podrán utilizarse
        para:
      </p>
      <p>
        - Verificar la identidad del Usuario;
        <br />
        - Realizar el procedimiento "Conozca su Costumer" para garantizar la
        seguridad del Usuario y prevenir el fraude se perpetúa utilizando los
        datos personales del Usuario;I523
        <br />
        - Garantizar que las transacciones bancarias realizadas se realicen
        únicamente para cuentas bancarias de propiedad del Usuario;
        <br />
        - Asegurar que los depósitos y/o depósitos se realicen de forma segura
        para el destinatario final;
        <br />
        - Identificar y autenticar el acceso del Usuario a la Plataforma
        Transfero, evitando que terceros acceder al sistema de forma
        fraudulenta;I527:I52
        <br />
        - Realización de Transacciones en la Plataforma de I530, creación de
        Órdenes de Compra y Órdenes de Venta, generar y permitir el acceso a la
        Cuenta Virtual, entre otras actividades que dependen de la autorización
        y consentimiento del usuario;
        <br />
        - Resolver problemas y dudas;
        <br />
        - Medición de la calidad del servicio al cliente;
        <br />
        - Analizar el rendimiento y medir la audiencia de la Plataforma
        Transfero, comprobar los hábitos de navegación evaluar las estadísticas
        relacionadas con el número de accesos y el uso de la Plataforma
        Transfero, incluyendo sus características y funcionalidades;
        <br />
        - Identificar, errores e inconsistencias en la Plataforma Transfero,
        para que dichos errores puedan ser corregido y mejorar el acceso por
        parte del Usuario;
        <br />
        - Analizar la seguridad de la Plataforma Transfero, y la resiliencia
        ante posibles ataques, con el fin de mejorar las herramientas de
        seguridad;
        <br />
        - Personalizar el contenido para las necesidades del Usuario, incluso
        con respecto al e-mail marketing y promociones;
        <br />
        - Medidas que Transfero considere necesarias o recomendadas para la
        mejora constante de características de la Plataforma Transfero, así como
        para comprender mejor las necesidades de Usuarios;
        <br />
        - Elaboración estadística del uso de la Plataforma Transfero;
        <br />
        - notificación a los Usuarios sobre cambios en la Plataforma Transfero;
        <br />
        - implementación de los mecanismos y políticas de controles internos de
        la Plataforma Transfero;
        <br />
        - el cumplimiento de otras obligaciones legales y reglamentarias
        impuestas a la Plataforma Transfero;
        <br />- Personalizar la experiencia del Usuario en el uso de la
        Plataforma Transfero.
      </p>
      <p>
        3.2 La Plataforma Transfero enviará mensajes por medios electrónicos,
        como e-mails y pushes a actividades de la Plataforma Transfero, y otros
        fines publicitarios y de comunicación. La frecuencia de envío las
        comunicaciones pueden variar.
      </p>
      <p>
        3.3 En cualquier momento el Usuario podrá solicitar la interrupción del
        tratamiento de su información Plataforma Transfero, incluida la
        solicitud de su exclusión total de los sistemas de The Transfero y el
        interrupción del uso de la Plataforma Transfero.
      </p>
      <p>
        3.4 Los datos recopilados serán almacenados por Transfero hasta cinco
        (5) años después de la finalización de la la relación del usuario con la
        Plataforma, y este plazo podrá ser ampliado para el cumplimiento de
        obligación legal o reglamentaria.
      </p>
      <p>
        3.5 La información recopilada de y sobre los Usuarios de la Plataforma
        Transfero también puede ser utilizado para procedimientos de control,
        incluidas las políticas internas de Conozca a su cliente (KYC) y
        Prevención y Lucha contra el Blanqueo de Capitales (AML).
      </p>
      <p>
        3.6 Los datos y la información del usuario y sus actividades también
        pueden compartirse con terceros en los siguientes casos:
      </p>
      <p>
        - los empleados y las empresas del grupo económico al que pertenece
        Transfero y sus socios, o contratados;
        <br />
        - empresas que son miembros del mismo grupo económico de Transfero, ya
        sean brasileñas o extranjeros;
        <br />
        - las autoridades gubernamentales exijan la divulgación, por ley u orden
        judicial, de todos o cualquier parte de la información; y
        <br />- cualquier otro tercero que requiera la información debido a (i)
        obligaciones legales y reglamentarias; y/o (ii) divulgación de
        información necesaria o recomendada para el funcionamiento debido,
        eficaz y eficiente de la Plataforma Transfero y sus funcionalidades.
      </p>
      <h1>4. COMPARTIR DATOS CON TERCEROS</h1>
      <p>
        4.1 Los datos recogidos serán siempre tratados por Transfero o por
        socios contratados exclusivamente para este fin. Al adherirse a los
        Términos y Condiciones de Transfero, el Usuario autoriza a dichos el
        intercambio se lleva a cabo, siempre observando lo dispuesto en la
        legislación aplicable.
      </p>
      <p>
        4.2 La información de identificación personal no se utilizará con fines
        de marketing o cualquier otra finalidad prevista en los Términos y
        Condiciones de transferencia, y será tratada em cumplimiento de la Ley
        General de Protección de Datos.
      </p>
      <p>
        4.3 En todos los casos en que se comparta información, se requerirá que
        la empresa los mismos estándares de confidencialidad, anonimización y
        custodia de la información que el adoptado por Transfero y la Plataforma
        Transfero.
      </p>
      <p>
        4.4 Transfero puede divulgar datos e información de cualquier Usuario,
        si dicha divulgación es requerido por la ley o la autoridad
        gubernamental, incluido el poder judicial, las agencias reguladoras,
        organismos municipales o estatales, sin previo aviso al Usuario. En
        cualquier caso, en caso de divulgación de dicha información, el Usuario
        será informado de dicha divulgación dentro de las 72 (setenta y dos)
        horas, a partir de la fecha en que se divulgue la información.
      </p>
      <p>
        4.5 La Plataforma Transfero puede contener enlaces que conducen a otros
        sitios web y sitios web. osible que las políticas y prácticas adoptadas
        por estos sitios y páginas no sean compatibles con los adoptados por
        Transfero y la Plataforma Transfero. Transfero no se hace responsable de
        la uso y procesamiento de información fuera de su entorno.
      </p>
      <h1>5. COOKIES Y TECNOLOGÍAS SIMILARES</h1>
      <p>
        5.1 La Plataforma Transfero puede hacer uso de cookies y otras
        tecnologías similares para apoyar el proceso de identificación del
        usuario, incluso con fines de marketing y protección de datos Reunido.
      </p>
      <p>
        5.2 Estas tecnologías están destinadas a almacenar información en
        navegadores web, y pueden ser presente en ordenadores, teléfonos y/u
        otros dispositivos móviles compatibles con la Plataforma Transfero.
      </p>
      <p>
        5.3 Las cookies son relevantes para procesos de autenticación,
        publicidad, recomendaciones, mediciones de características y
        funcionalidad de la audiencia y el canal, análisis de seguridad para
        mejorar y desarrollo de herramientas antifraude.
      </p>
      <p>
        5.4 El Usuario que desee desactivar las cookies en las opciones de
        configuración de su navegador, consciente de que las características y
        servicios de la Plataforma Transfero pueden no ser suministrado, o
        parcialmente suministrado.
      </p>
      <h1>6. LEY DE SEGURIDAD DE LA INFORMACIÓN Y PROTECCIÓN DE DATOS</h1>
      <p>
        6.1 Transfero se compromete a utilizar los mejores estándares del
        mercado para proteger los datos de usuarios y cumplir, en todo momento,
        con la Ley General de Protección de Datos.
      </p>
      <p>
        6.2 La Cuenta Virtual está protegida por una contraseña personal e
        intransferible, que existe para privacidad y seguridad de los Usuarios.
      </p>
      <p>
        6.3 En virtud de lo dispuesto en la Ley General de Protección de Datos,
        el tratamiento de los datos por parte de Transfero guiar los siguientes
        motivos:
      </p>
      <ol>
        a) Respeto a la intimidad; b) Autodeterminación informativa; c) la
        libertad de expresión, información, comunicación y opinión; d)
        Inviolabilidad de la intimidad, el honor y la imagen; e) Desarrollo e
        innovación económica y tecnológica; f) la libre empresa, la libre
        competencia y la protección de los consumidores; y g) los derechos
        humanos, el libre desarrollo de la personalidad, la dignidad y el
        ejercicio de la personalidad ciudadanía de las personas físicas.
      </ol>
      <h1>7. DISPOSICIONES FINALES</h1>
      <p>
        7.1 Transfero o podrá, independientemente de cualquier aviso o
        notificación al Usuario, asignar el derechos derivados de esta Política
        de Privacidad, en todo o en parte, a otras empresas, en caso de
        cualquier reorganización corporativa de Transfero, como fusión o
        adquisición, en cualquier momento, siempre que aplicable a esta Política
        de Privacidad.
      </p>
      <p>
        7.2 Transfero se reserva el derecho de retener información durante el
        tiempo que considere necesario para el cumplimiento de su negocio y el
        cumplimiento de la normativa vigente, incluso después de la terminación
        de la cuenta del Usuario.
      </p>
      <p>
        7.3 La tolerancia del Usuario a la Plataforma Transfero y viceversa, con
        respecto al incumplimiento de cualquiera de las obligaciones en virtud
        de esta Política de Privacidad no implicará una nueva o renuncia a la
        ley. En cualquier momento, se puede requerir el cumplimiento fiel y
        pleno de esta Política. Política de Privacidad.
      </p>
      <p>
        7.4 Esta Política de Privacidad vincula al Usuario y a la Plataforma
        Transfero y sus sucesores a cualquier título.
      </p>
      <p>
        7.5 La inviabilidad de la ejecución o la nulidad, incluso si se debe a
        una decisión judicial, en algunos cláusula, no afectará la validez de
        las demás disposiciones de esta Política de Privacidad, que permanecerán
        válido y ejecutable.
      </p>
      <p>
        7.6 Transfero se reserva el derecho de modificar esta Política de
        Privacidad en cualquier momento Hora. En este caso, el Usuario será
        debidamente notificado de dichos cambios, y de su Aceptación. En este
        caso, se observará lo dispuesto en los Términos de Uso.
      </p>
    </>
  );
}
