import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { Collapse } from "react-collapse";
import Icon from "@/widgets/Icons";
import { ComboboxProps } from "./types";
import {
  CustomDropdownIndicator,
  CustomLoadingIndicator,
  CustomMenuList,
  CustomOption,
} from "./partials";

import styles from "./index.module.scss";

export default function ComboBox({
  variant = "default",
  defaultValue,
  label,
  id,
  noOptionsMessage = "No options",
  options,
  disabled,
  hasError,
  isSearchable = true,
  onValueChange,
  placeholder = "",
  searchFieldPlaceholder = "",
  menuPlacement = "bottom",
  idIcon,
  searchId,
  instanceId,
  ...props
}: ComboboxProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState<any>(
    defaultValue || placeholder
  );
  const currentElement = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (disabled) {
      setIsDropdownOpen(false);
    }
  }, [disabled]);

  useEffect(() => {
    document.addEventListener("click", handleDocumentOnClick);

    return () => {
      document.removeEventListener("click", handleDocumentOnClick);
    };
  }, []);

  const handleDocumentOnClick = () => {
    setIsDropdownOpen(false);
  };

  const handleCloseAllNonCurrentComboboxes = (theComponent: any) => {
    document.querySelectorAll(".Combobox").forEach((element: any) => {
      if (!element.isEqualNode(theComponent)) {
        element.querySelector(".button-opened")?.click();
      }
    });
  };

  const handleOnFilter = (option: any, value: string) => {
    if (value === "") {
      return true;
    }

    if (option?.data?.filterByValue !== undefined) {
      return option.data.filterByValue
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    }

    return option.value.toString().toLowerCase().includes(value.toLowerCase());
  };

  useEffect(() => {
    setSelectedLabel(defaultValue || placeholder);
  }, [defaultValue]);

  return (
    <div
      className={`Combobox ${styles.container} ${styles[menuPlacement]}`}
      ref={currentElement}
      onClick={(event: any) => {
        event.stopPropagation();
        handleCloseAllNonCurrentComboboxes(event.currentTarget);
      }}
    >
      {label && <label>{label}</label>}
      <button
        disabled={disabled}
        type="button"
        className={`${isDropdownOpen ? "button-opened" : ""} ${
          styles.dropdown
        } ${styles[variant]}  ${hasError ? styles.hasError : ""}`}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
        onBlur={(event: any) => {
          if (currentElement.current) {
            const searchField = currentElement.current.querySelector("input");
            if (event.relatedTarget !== searchField) {
              setIsDropdownOpen(false);
            }
          }
        }}
      >
        {selectedLabel?.label || placeholder}
        {!disabled && (
          <Icon id={idIcon} icon={isDropdownOpen ? "arrowUp" : "arrowDown"} />
        )}
      </button>
      <Collapse isOpened={isDropdownOpen}>
        <div
          className={`Combobox ${styles.combobox} ${styles[variant]} 
          ${hasError ? styles.hasError : ""}`}
        >
          {isDropdownOpen && (
            <Select
              menuPlacement={menuPlacement}
              autoFocus={true}
              controlShouldRenderValue={false}
              isSearchable={isSearchable}
              className={`${styles.select} ${
                isSearchable ? styles.searchable : ""
              }`}
              isDisabled={disabled}
              options={options as any}
              defaultValue={defaultValue as any}
              id={id}
              noOptionsMessage={() => noOptionsMessage}
              onChange={(selected: any) => {
                setIsDropdownOpen(false);

                if (selected?.value !== defaultValue?.value) {
                  setSelectedLabel(selected.label);
                  if (onValueChange) {
                    onValueChange(selected);
                  }
                }
              }}
              components={{
                IndicatorSeparator: null,
                DropdownIndicator: CustomDropdownIndicator(styles),
                MenuList: CustomMenuList(styles),
                Option: CustomOption(styles),
                LoadingIndicator: CustomLoadingIndicator(),
              }}
              filterOption={handleOnFilter}
              menuIsOpen={true}
              placeholder={searchFieldPlaceholder}
              inputId={searchId}
              instanceId={instanceId}
              {...props}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
}
