import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { IApiError, IHistory } from "@/models";
import { HistoryState } from ".";
import { HistoryApi } from "@/apis";

export const fetchHistory = createAsyncThunk(
  "fetch/history",
  async (_, { rejectWithValue }) => {
    try {
      return (await HistoryApi.getHistory()) as IHistory[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const historyExtraReducerBuilder = (
  builder: ActionReducerMapBuilder<HistoryState>
) => {

  builder.addCase(fetchHistory.pending, (state) => {
    state.history.error = null;
    state.history.loading = true;
  });
  builder.addCase(fetchHistory.fulfilled, (state, action) => {
    state.history.data = action.payload as unknown as IHistory[];
    state.history.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.history.error = null;
    state.history.loading = false;
  });
  builder.addCase(fetchHistory.rejected, (state, action) => {
    state.history.data = null;
    state.history.error = action.payload as IApiError;
    state.history.loading = false;
    state.history.lastUpdate = null;
  });
};
