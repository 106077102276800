import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, type TradeState } from ".";

export const tradeReducer = {
  clearTrade: () => {
    return initialState;
  },
  updateTrade: (_: TradeState, action: PayloadAction<TradeState>) => {
    return action.payload;
  },
};
