import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, type FavoriteState } from ".";
import { IFavorite } from "@/models";

export const favoriteReducer = {
  clearFavorite: () => {
    return initialState;
  },
  updateFavorite: (_: FavoriteState, action: PayloadAction<FavoriteState>) => {
    return action.payload;
  },
  addFavorite: (state: FavoriteState, action: PayloadAction<IFavorite>) => {
    if (state.favorites.data === null) {
      state.favorites.data = [action.payload];
    } else {
      state.favorites.data.push(action.payload);
    }
    return state;
  },
};
