import { AxiosInstance } from "axios";
import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { ISession } from "@/models";
import { REFRESH_TOKEN_COOKIE_KEY, TOKEN_COOKIE_KEY } from "@/const";
import { GetServerSideProps, GetServerSidePropsContext } from "next";

/**
 * Set the token and refresh token on the Cookies api.
 *
 * @param data An ISession object that contains all tokens related data.
 * @param req The request object (comes from Next)
 * @param res The response object (comes from Next)
 */
export function setAuthCookies(data: ISession) {
  const maxAge: number = 30 * 30 * 60 * 60;

  setCookie(TOKEN_COOKIE_KEY, data.accessTokenValue, {
    maxAge,
  });

  setCookie(REFRESH_TOKEN_COOKIE_KEY, data.refreshTokenValue, {
    maxAge,
  });
}

/**
 * Return an object that must to be the return for not permanet redirect on Nextjs Apps.
 *
 * @param destination The path to redirect to.
 *
 * @returns The object that indicate that the redirect is not permanent.
 * See: https://nextjs.org/docs/pages/api-reference/next-config-js/redirects
 */
export function getNotPermanentRedirectObj(destination: string) {
  return {
    redirect: {
      destination,
      permanent: false,
    },
  };
}

/**
 * Prevent the page from being accessed by a non-logged in user.
 *
 * @param context Next context object.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#context-parameter
 *
 * @param callback A callback function.
 *
 * @returns Next return object or a callback function that returns it.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#getserversideprops-return-values
 */

export const routeGuardForNonLoggedUser: GetServerSideProps = async ({
  req,
  res,
}: GetServerSidePropsContext) => {
  const token = getCookie(TOKEN_COOKIE_KEY, {
    req,
    res,
  });
  const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_KEY, {
    req,
    res,
  });

  if (!token || !refreshToken) {
    return {
      redirect: {
        permanent: false,
        destination: "/auth/logout",
      },
      props: {},
    };
  }

  try {
    const response = await fetch(
      `${process.env.NEXT_PUBLIC_API_URL}/sessions/refresh`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ refreshToken }),
      }
    );

    if (response.ok) {
      const { accessTokenValue, refreshTokenValue }: ISession =
        await response.json();

      setCookie(TOKEN_COOKIE_KEY, accessTokenValue, {
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        req,
        res,
      });

      setCookie(REFRESH_TOKEN_COOKIE_KEY, refreshTokenValue, {
        expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
        req,
        res,
      });

      return {
        props: {},
      };
    }
    throw new Error("Failed to refresh token");
  } catch (e) {
    return {
      props: {},
    };
  }
};

/**
 * Prevent the page from being accessed by a logged in user.
 *
 * @param context Next context object.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#context-parameter
 *
 * @param callback A callback function.
 *
 * @returns Next return object or a callback function that returns it.
 * See: https://nextjs.org/docs/pages/api-reference/functions/get-server-side-props#getserversideprops-return-values
 */
export async function routeGuardForLoggedUser(
  context: any,
  // eslint-disable-next-line no-unused-vars
  callback?: (param: any) => any
) {
  const { req, res } = context;
  const pathToRedirect = "/";
  const token = getCookie(TOKEN_COOKIE_KEY, { req, res });

  if (token) {
    return getNotPermanentRedirectObj(pathToRedirect);
  }

  return !callback ? { props: {} } : callback(context);
}

/**
 * Try to add the Barear token to the request header.
 * If the token does not exist or it is invalid, try to refresh it.
 *
 * @param api The AxiosInstance object
 */
export async function addTokenToClientSideRequest(api: AxiosInstance) {
  api.interceptors.request.use(async (config) => {
    // If the token does not exist, try to refresh it (clientside)
    let token = getCookie(TOKEN_COOKIE_KEY);

    // It is not a else for the above condition
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
}

let queueRefresh: boolean = false;

export async function refreshTokenInterceptor(api: AxiosInstance) {
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401 && !queueRefresh) {
        const refreshToken = getCookie(REFRESH_TOKEN_COOKIE_KEY) as string;

        if (refreshToken) {
          try {
            queueRefresh = true;

            const response = await fetch(
              `${process.env.NEXT_PUBLIC_API_URL}/sessions/refresh`,
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({ refreshToken }),
              }
            ).finally(() => {
              queueRefresh = false;
            });

            const data: ISession = await response.json();

            if (data?.accessTokenValue) {
              setAuthCookies(data);
            }
          } catch (error) {
            deleteCookie(TOKEN_COOKIE_KEY);
            deleteCookie(REFRESH_TOKEN_COOKIE_KEY);
            window.location.href = "/auth/login";
            return Promise.reject(error);
          } finally {
            queueRefresh = false;
          }
        }
      }
      return Promise.reject(error);
    }
  );
}
