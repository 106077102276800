export default function infoCircleBlue() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="5" y="5" width="56" height="56" rx="28" fill="#CAD9FF" />
      <rect
        x="5"
        y="5"
        width="56"
        height="56"
        rx="28"
        stroke="#E8F0FF"
        strokeWidth="10"
      />
      <path
        d="M33 37.6667V33M33 28.3333H33.0117M44.6667 33C44.6667 39.4433 39.4434 44.6667 33 44.6667C26.5567 44.6667 21.3334 39.4433 21.3334 33C21.3334 26.5567 26.5567 21.3333 33 21.3333C39.4434 21.3333 44.6667 26.5567 44.6667 33Z"
        stroke="#3F3FCA"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
