export default function profileItemWhite() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42764 16.1988C4.93458 15.0044 6.11816 14.1667 7.4974 14.1667H12.4974C13.8766 14.1667 15.0602 15.0044 15.5671 16.1988M13.3307 7.91675C13.3307 9.7577 11.8383 11.2501 9.9974 11.2501C8.15645 11.2501 6.66406 9.7577 6.66406 7.91675C6.66406 6.0758 8.15645 4.58341 9.9974 4.58341C11.8383 4.58341 13.3307 6.0758 13.3307 7.91675ZM18.3307 10.0001C18.3307 14.6025 14.5998 18.3334 9.9974 18.3334C5.39502 18.3334 1.66406 14.6025 1.66406 10.0001C1.66406 5.39771 5.39502 1.66675 9.9974 1.66675C14.5998 1.66675 18.3307 5.39771 18.3307 10.0001Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
