import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, type CustomerState } from ".";

export const customerReducer = {
  clearCustomer: () => {
    return initialState;
  },
  updateCustomer: (_: CustomerState, action: PayloadAction<CustomerState>) => {
    return action.payload;
  },
};
