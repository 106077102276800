import { Icon } from "..";
import { AvailableIconsType } from "../Icons";
import styles from "./index.module.scss";

type IconTitleProps = {
  title: string;
  icon: AvailableIconsType;
};

export default function IconTitle({ title, icon }: IconTitleProps) {
  return (
    <div className={styles.header}>
      <Icon icon={icon} />
      <h2>{title}</h2>
    </div>
  );
}
