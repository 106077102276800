import scssVariables from "@/styles/variables.module.scss";

export default function security() {
  return (
    <svg viewBox="0 0 25 31" fill="none">
      <path
        d="M12.5 0L0 4.23658V17.0875C0 23.513 9.23611 29.2324 11.8056 30.7858C12.0111 30.9255 12.2528 31 12.5 31C12.7472 31 12.9889 30.9255 13.1944 30.7858C15.8333 29.303 25 23.513 25 17.0875V4.23658L12.5 0ZM22.2222 17.0875C22.2222 20.618 17.2917 25.137 12.5 27.9614C3.75 22.4539 2.77778 18.5703 2.77778 17.0875V6.28426L12.5 2.96561L22.2222 6.28426V17.0875Z"
        fill={scssVariables.primary}
      />
      <path
        d="M8.54167 7.06097C7.83287 7.63793 7.25943 8.36823 6.86302 9.19878C6.46661 10.0293 6.25719 10.9393 6.25 11.8624C6.25 12.237 6.39633 12.5962 6.6568 12.861C6.91726 13.1258 7.27053 13.2746 7.63889 13.2746C8.00725 13.2746 8.36051 13.1258 8.62098 12.861C8.88145 12.5962 9.02778 12.237 9.02778 11.8624C9.04509 11.3521 9.16492 10.8508 9.37989 10.3894C9.59486 9.92797 9.90042 9.51614 10.2778 9.17926C10.688 8.86004 11.1597 8.6322 11.6622 8.51055C12.1647 8.3889 12.6868 8.37616 13.1944 8.47316C13.9142 8.59616 14.5759 8.95119 15.0821 9.48584C15.5882 10.0205 15.9118 10.7064 16.0052 11.4424C16.0986 12.1784 15.9568 12.9254 15.6008 13.5732C15.2447 14.2209 14.6932 14.7351 14.0278 15.0399C12.8494 15.5799 11.9267 16.5688 11.4583 17.7936C11.3209 18.1401 11.3242 18.5279 11.4673 18.872C11.6105 19.2161 11.8819 19.4885 12.2222 19.6295H12.7778C13.0545 19.6291 13.3241 19.5402 13.5484 19.3755C13.7727 19.2108 13.9404 18.9785 14.0278 18.7116C14.273 18.2262 14.6616 17.8312 15.1389 17.5818C16.321 17.024 17.2995 16.1005 17.9345 14.9433C18.5695 13.7861 18.8285 12.4544 18.6744 11.1386C18.5203 9.82275 17.9611 8.59011 17.0767 7.61682C16.1923 6.64353 15.0279 5.97942 13.75 5.71938C12.8394 5.50807 11.8924 5.51949 10.987 5.75271C10.0816 5.98594 9.2435 6.43431 8.54167 7.06097Z"
        fill={scssVariables.primary}
      />
      <path
        d="M12.5 25.3489C13.2671 25.3489 13.8889 24.7166 13.8889 23.9367C13.8889 23.1567 13.2671 22.5245 12.5 22.5245C11.7329 22.5245 11.1111 23.1567 11.1111 23.9367C11.1111 24.7166 11.7329 25.3489 12.5 25.3489Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
