import { Icon } from "@/widgets";
import * as S from "./styles";
import { AvailableIconsType } from "@/widgets/Icons";

interface MenuOptionProps {
  itemName: string;
  iconName: AvailableIconsType;
  type?: "default" | "selected" | "logout";
  onClick?: () => void;
  collapsed: boolean;
  id?: string;
}

const MenuOption = ({
  itemName,
  iconName,
  type,
  onClick,
  collapsed,
  id,
}: MenuOptionProps) => {
  return (
    <S.MainContainer
      $type={type}
      $collapsed={collapsed}
      onClick={onClick}
      id={id}
    >
      <Icon icon={iconName} />
      {!collapsed && <p>{itemName}</p>}
    </S.MainContainer>
  );
};

export default MenuOption;
