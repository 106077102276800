export default function agricultureServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M22.5 17.5L13.125 26.875"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9997 25.0003C11.2497 18.7503 16.2497 12.5003 26.8747 13.1253C27.4997 23.7503 21.2497 28.7503 14.9997 25.0003Z"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
