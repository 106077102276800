import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { IApiError, IBalance, IConvertedBalance } from "@/models";
import { BalancesApi } from "@/apis";
import { BalanceState } from ".";

export const fecthBalances = createAsyncThunk(
  "fetch/balances",
  async (_, { rejectWithValue }) => {
    try {
      return (await BalancesApi.getBalances()) as IBalance[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const fecthConsolidatedBalance = createAsyncThunk(
  "fetch/consolidatedBalance",
  async (_, { rejectWithValue }) => {
    try {
      return (await BalancesApi.getConsolidatedBalance()) as IBalance;
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const fecthConvertedBalances = createAsyncThunk(
  "fetch/fecthConvertedBalances",
  async (_, { rejectWithValue }) => {
    try {
      return (await BalancesApi.getBalancesByFiat()) as IConvertedBalance[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const balanceExtraReducerBuilder = (
  builder: ActionReducerMapBuilder<BalanceState>
) => {
  // fetch balances
  builder.addCase(fecthBalances.pending, (state) => {
    state.balances.error = null;
    state.balances.loading = true;
  });
  builder.addCase(fecthBalances.fulfilled, (state, action) => {
    state.balances.data = action.payload as IBalance[];
    state.balances.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.balances.error = null;
    state.balances.loading = false;
  });
  builder.addCase(fecthBalances.rejected, (state, action) => {
    state.balances.data = null;
    state.balances.error = action.payload as IApiError;
    state.balances.loading = false;
    state.balances.lastUpdate = null;
  });
  // fetch consolidated balance
  builder.addCase(fecthConsolidatedBalance.pending, (state) => {
    state.consolidated.error = null;
    state.consolidated.loading = true;
  });
  builder.addCase(fecthConsolidatedBalance.fulfilled, (state, action) => {
    state.consolidated.data = action.payload as IBalance;
    state.consolidated.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.consolidated.error = null;
    state.consolidated.loading = false;
  });
  builder.addCase(fecthConsolidatedBalance.rejected, (state, action) => {
    state.consolidated.data = null;
    state.consolidated.error = action.payload as IApiError;
    state.consolidated.loading = false;
    state.consolidated.lastUpdate = null;
  });
  // fetch converted balances
  builder.addCase(fecthConvertedBalances.pending, (state) => {
    state.consolidated.error = null;
    state.consolidated.loading = true;
  });
  builder.addCase(fecthConvertedBalances.fulfilled, (state, action) => {
    state.convertedBalances.data = action.payload as IConvertedBalance[];
    state.convertedBalances.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.convertedBalances.error = null;
    state.convertedBalances.loading = false;
  });
  builder.addCase(fecthConvertedBalances.rejected, (state, action) => {
    state.convertedBalances.data = null;
    state.convertedBalances.error = action.payload as IApiError;
    state.convertedBalances.loading = false;
    state.convertedBalances.lastUpdate = null;
  });
};
