import { Card, Icon, IconLabel, Tooltip } from "@/widgets";

export default function Es_i18n() {
  return (
    <>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/deposit.svg"
          label={"Depósito"}
        />
        <div>
          <h2>
            Moneda fiduciaria
            <Tooltip
              content={
                <>
                  * Sólo el real brasileño (BRL) se admite como moneda
                  fiduciaria en Transfero Crypto.
                </>
              }
              placement="right"
            >
              <Icon icon="question" />
            </Tooltip>
          </h2>
          <p>
            El cliente transfiere moneda fiduciaria a su cuenta Transfero Crypto
            sin comisiones dentro de 1 día hábil.
          </p>
          <h2>Criptomoneda </h2>
          <p>
            El cliente envía criptomonedas a su cuenta Transfero Crypto sin
            comisiones y dentro del plazo de confirmación de la red blockchain.
          </p>
        </div>
      </Card>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/withdraw.svg"
          label={"Transferencia"}
        />
        <div>
          <h2>
            Moneda fiduciaria
            <Tooltip
              content={
                <>
                  * Sólo el real brasileño (BRL) se admite como moneda
                  fiduciaria en Transfero Crypto.
                </>
              }
              placement="right"
            >
              <Icon icon="question" />
            </Tooltip>
          </h2>
          <p>
            Transfero Crypto transfiere moneda fiduciaria al cliente con una
            comisione bancaria (5 BRL) dentro de 1 día hábil.
          </p>
          <h2>Criptomoneda</h2>
          <p>
            Transfero Crypto envía criptomonedas a la billetera del cliente
            pagando una tarifa de red y dentro del plazo de confirmación de la
            red blockchain.
          </p>
        </div>
      </Card>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/convert.svg"
          label={"Cambio"}
        />
        <div>
          <p>
            <b>Transfero Crypto</b> convierte entre monedas al instante y sin
            comisiones.
          </p>
        </div>
      </Card>
    </>
  );
}
