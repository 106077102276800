import { useI18n } from "@/hooks";
import PrivacyText from "./components";
import { HelpCenterLayout } from "../components";

import content from "./index.json";

export default function PrivacyPage() {
  const { i18n } = useI18n(content);

  return (
    <HelpCenterLayout pageTitle={i18n("title")}>
      <PrivacyText />
    </HelpCenterLayout>
  );
}
