export default function cardIcon() {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6663 4.66671H1.33301M1.33301 3.46671L1.33301 8.53337C1.33301 9.28011 1.33301 9.65348 1.47833 9.93869C1.60616 10.1896 1.81014 10.3935 2.06102 10.5214C2.34624 10.6667 2.7196 10.6667 3.46634 10.6667L12.533 10.6667C13.2797 10.6667 13.6531 10.6667 13.9383 10.5214C14.1892 10.3936 14.3932 10.1896 14.521 9.93869C14.6663 9.65348 14.6663 9.28011 14.6663 8.53337V3.46671C14.6663 2.71997 14.6663 2.3466 14.521 2.06139C14.3932 1.8105 14.1892 1.60653 13.9383 1.4787C13.6531 1.33337 13.2797 1.33337 12.533 1.33337L3.46634 1.33337C2.7196 1.33337 2.34624 1.33337 2.06102 1.4787C1.81014 1.60653 1.60616 1.8105 1.47833 2.06139C1.33301 2.3466 1.33301 2.71997 1.33301 3.46671Z"
        stroke="#4A5578"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
