import scssVariables from "@/styles/variables.module.scss";

export default function print() {
  return (
    <svg viewBox="0 0 16 16" fill="none">
      <path
        d="M13.4857 12.3429H11.6571C11.4753 12.3429 11.3008 12.2706 11.1723 12.142C11.0437 12.0135 10.9714 11.839 10.9714 11.6572C10.9714 11.4753 11.0437 11.3009 11.1723 11.1723C11.3008 11.0437 11.4753 10.9714 11.6571 10.9714H13.4857C13.7888 10.9714 14.0795 10.851 14.2938 10.6367C14.5082 10.4224 14.6286 10.1317 14.6286 9.82858V6.17144C14.6286 5.86834 14.5082 5.57765 14.2938 5.36333C14.0795 5.149 13.7888 5.02859 13.4857 5.02859H2.51428C2.21118 5.02859 1.92049 5.149 1.70617 5.36333C1.49184 5.57765 1.37143 5.86834 1.37143 6.17144V9.82858C1.37143 10.1317 1.49184 10.4224 1.70617 10.6367C1.92049 10.851 2.21118 10.9714 2.51428 10.9714H4.34285C4.52472 10.9714 4.69913 11.0437 4.82773 11.1723C4.95632 11.3009 5.02857 11.4753 5.02857 11.6572C5.02857 11.839 4.95632 12.0135 4.82773 12.142C4.69913 12.2706 4.52472 12.3429 4.34285 12.3429H2.51428C1.84745 12.3429 1.20794 12.078 0.73642 11.6064C0.264896 11.1349 0 10.4954 0 9.82858V6.17144C0 5.50461 0.264896 4.86509 0.73642 4.39358C1.20794 3.92205 1.84745 3.65716 2.51428 3.65716H13.4857C14.1525 3.65716 14.792 3.92205 15.2635 4.39358C15.7351 4.86509 16 5.50461 16 6.17144V9.82858C16 10.4954 15.7351 11.1349 15.2635 11.6064C14.792 12.078 14.1525 12.3429 13.4857 12.3429Z"
        fill={scssVariables.primary}
      />
      <path
        d="M11.6572 5.02857C11.476 5.0262 11.303 4.95319 11.1749 4.82511C11.0468 4.69702 10.9738 4.52398 10.9714 4.34285V1.37143H5.02859V4.34285C5.02859 4.52472 4.95634 4.69913 4.82775 4.82773C4.69915 4.95632 4.52473 5.02857 4.34287 5.02857C4.16101 5.02857 3.98659 4.95632 3.858 4.82773C3.72941 4.69913 3.65716 4.52472 3.65716 4.34285V1.14286C3.65716 0.839753 3.77757 0.549065 3.9919 0.334738C4.20622 0.120411 4.49691 0 4.80002 0H11.2C11.5031 0 11.7938 0.120411 12.0081 0.334738C12.2225 0.549065 12.3429 0.839753 12.3429 1.14286V4.34285C12.3405 4.52398 12.2675 4.69702 12.1394 4.82511C12.0113 4.95319 11.8383 5.0262 11.6572 5.02857Z"
        fill={scssVariables.primary}
      />
      <path
        d="M11.2 16H4.80002C4.49691 16 4.20622 15.8796 3.9919 15.6653C3.77757 15.451 3.65716 15.1602 3.65716 14.8571V8.45715C3.65716 8.15406 3.77757 7.86332 3.9919 7.64901C4.20622 7.4347 4.49691 7.31429 4.80002 7.31429H11.2C11.5031 7.31429 11.7938 7.4347 12.0081 7.64901C12.2225 7.86332 12.3429 8.15406 12.3429 8.45715V14.8571C12.3429 15.1602 12.2225 15.451 12.0081 15.6653C11.7938 15.8796 11.5031 16 11.2 16ZM5.02859 14.6286H10.9714V8.68572H5.02859V14.6286Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
