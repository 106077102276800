import { useState } from "react";
import { sleep } from "@/utils";

export default function useRerender() {
  const [isRendering, setIsRendering] = useState(false);

  const rerender = async (loaderTimer: number = 1000) => {
    setIsRendering(true);
    await sleep(loaderTimer);
    setIsRendering(false);
  };

  return { isRendering, setIsRendering, rerender };
}
