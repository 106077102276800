import { useRouter } from "next/router";
import i18n from "../../../i18n";
import Link from "next/link";
import * as S from "./styled";

const LanguageSwitcher = () => {
  const { locales, locale, pathname, query, asPath } = useRouter();
  const switchChangeLanguage = (currentLocale: string) => {
    if (currentLocale !== locale) {
      i18n.changeLanguage(currentLocale);
    }
  };

  const translateTitles = (localeItem: string) => {
    const translations: { [key: string]: string } = {
      pt: "Português (Brasil)",
      en: "English",
      es: "Español",
    };
    return translations[localeItem] || "Unknown";
  };

  return (
    <S.SwitcherContainer>
      {locales?.map((currentLocale) => (
        <li key={currentLocale}>
          <Link href={{ pathname, query }} as={asPath} locale={currentLocale}>
            <S.SwitcherList
              onClick={() => switchChangeLanguage(currentLocale)}
              style={{
                color: currentLocale === locale ? "#3a3aff" : "normal",
              }}
            >
              {translateTitles(currentLocale)}
            </S.SwitcherList>
          </Link>
        </li>
      ))}
    </S.SwitcherContainer>
  );
};

export default LanguageSwitcher;
