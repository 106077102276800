import { Logo } from "@/widgets";
import * as S from "./styled";
import LanguageSwitcher from "@/widgets/LanguageSwitcher";
import { useState } from "react";
import DropdownLanguage from "@/widgets/DropdownLanguage";

export default function NonLoggedInHeader() {
  const [showLanguages, setShowLanguages] = useState(false);

  return (
    <S.Container>
      <S.Logo>
        <Logo asLink href="/" alt="Transfero" />
      </S.Logo>
      <S.ContainerHeader>
        <S.ListContainer>
          <S.ListItem>
            <S.LanguageContainer
              $clicked={showLanguages}
              onClick={() => setShowLanguages}
            >
              <S.NoLinkContainer>
                <DropdownLanguage isLogged={false} />
              </S.NoLinkContainer>
              {showLanguages && <LanguageSwitcher />}
            </S.LanguageContainer>
          </S.ListItem>
        </S.ListContainer>
      </S.ContainerHeader>
    </S.Container>
  );
}
