import styled from "styled-components";

export const PerformanceTime = styled.div`
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Karla;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1rem;
  text-align: left;
  color: #4d4dff;
  background-color: transparent;
  padding: 4px 1rem;
  border: 1px solid #4d4dff;
`;
