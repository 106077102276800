import styled from "styled-components";
import Link from "next/link";

interface TextIconProps {
  $isShowCase?: boolean;
  $hideOnMobile?: boolean;
}

interface LanguageContainerProps {
  $clicked?: boolean;
}
export const Container = styled.div<TextIconProps>`
  display: flex;
  padding: 32px 112px;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    padding: 2% 5%;
  }
`;

export const Logo = styled.div`
  display: flex;
  width: 176px;
  height: 32px;
  padding: 2.462px 2.657px 2.462px 2.462px;
  justify-content: center;
  align-items: center;
  gap: 2.94px;
  flex-shrink: 0;
`;

export const ContainerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`;

export const TextIcon = styled.div<TextIconProps>`
  color: ${(props) =>
    props.$isShowCase ? "#fff" : "var(--Gray-500, #5d6b98)"};
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-top: 2px;

  @media screen and (max-width: 768px) {
    display: ${(props) => props.$hideOnMobile && "none"};
  }
`;

export const ListItem = styled.li<TextIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 768px) {
    display: ${(props) => props.$hideOnMobile && "none"};
  }
`;

export const ListContainer = styled.ul`
  display: flex;
  align-items: center;
  gap: 32px;

  @media screen and (max-width: 768px) {
    gap: 0;
  }
`;

export const LinkContainer = styled(Link)<TextIconProps>`
  display: flex;
  align-items: center;
  padding-right: 6px;
  padding-block: 4px;

  &:hover {
    background-color: ${(props) =>
      props.$isShowCase ? "#111179" : "var(--light-80)"};
    border-radius: 8px;
  }
`;
export const NoLinkContainer = styled.div<TextIconProps>`
  display: flex;
  align-items: center;
  padding-right: 7px;
  padding-block: 5px;

  /* &:hover {
    background-color: ${(props) =>
    props.$isShowCase ? "#111179" : "var(--light-80)"};
    border-radius: 8px;
    cursor: pointer;
  } */
`;

export const LanguageContainer = styled.div<LanguageContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${(props) => (props.$clicked ? "var(--light-80)" : "")};
  border-radius: ${(props) => (props.$clicked ? "8px" : "")};
  cursor: ${(props) => (props.$clicked ? "pointer" : "")};
  box-shadow: ${(props) =>
    props.$clicked ? "0 2px 4px rgba(0, 0, 0, 0.1)" : ""};
`;
