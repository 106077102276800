import { useEffect, useState } from "react";
import { hasCookie, setCookie } from "cookies-next";

import { Button } from "@/widgets";
import { ACCEPT_COOKIES_KEY } from "@/const";

import styles from "./index.module.scss";

type CookiesAlertProps = {
  title: string;
  children: any;
  buttonLabel: string;
  onClick?(): void;
};

export default function CookiesAlert({
  title,
  children,
  buttonLabel,
  onClick,
}: CookiesAlertProps) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(!hasCookie(ACCEPT_COOKIES_KEY));
  }, []);

  const handleOnClick = () => {
    onClick && onClick();
    setCookie(ACCEPT_COOKIES_KEY, true);
    setIsVisible(false);
  };

  return (
    <>
      {isVisible && (
        <div className={`CookiesAlert ${styles.container}`}>
          <h4>{title}</h4>
          {children}
          <div className={styles.close}>
            <Button size="medium" onClick={handleOnClick}>
              {buttonLabel}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
