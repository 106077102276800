import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { tradeSelector } from ".";

export function useTrade() {
  const tradeState = useSelector(tradeSelector);
  const tradeDispatch = useDispatch<AppDispatch>();

  return { tradeState, tradeDispatch };
}
