import scssVariables from "@/styles/variables.module.scss";

export default function email() {
  return (
    <svg viewBox="0 0 30 24" fill="none">
      <path
        d="M29.9265 3.1742C29.8018 2.54408 29.5207 1.94854 29.1105 1.44785C29.0272 1.34273 28.9401 1.24922 28.8448 1.15436C28.1151 0.420762 27.1 0 26.0598 0H3.94008C2.88826 0 1.89902 0.410156 1.15459 1.15477C1.06037 1.24875 0.973008 1.3432 0.886523 1.45166C0.478125 1.95023 0.198223 2.54496 0.0765234 3.17596C0.0256641 3.42375 0 3.68051 0 3.94025V19.4756C0 20.0161 0.109805 20.5424 0.32748 21.0427C0.515859 21.487 0.80168 21.9083 1.15436 22.2608C1.24318 22.3492 1.33143 22.4307 1.42564 22.5103C2.13076 23.0942 3.02361 23.4155 3.94008 23.4155H26.0598C26.9822 23.4155 27.8742 23.093 28.5774 22.5038C28.6714 22.4278 28.7585 22.3479 28.8456 22.2608C29.1865 21.9202 29.4575 21.5289 29.6522 21.0974L29.6779 21.0359C29.8915 20.5451 30 20.0205 30 19.4757V3.94025C30 3.68391 29.9753 3.42545 29.9265 3.1742ZM2.04041 2.51461C2.09613 2.43305 2.16727 2.34932 2.25803 2.25785C2.70855 1.80756 3.30604 1.55977 3.94002 1.55977H26.0597C26.6993 1.55977 27.297 1.80797 27.7425 2.25873C27.8194 2.33648 27.8929 2.42279 27.957 2.50904L28.1262 2.73639L16.3195 13.0264C15.9555 13.3455 15.4869 13.5211 14.9998 13.5211C14.5175 13.5211 14.0493 13.3459 13.681 13.0268L1.88613 2.73932L2.04041 2.51461ZM1.56768 19.6128C1.56129 19.5705 1.55982 19.5235 1.55982 19.4756V4.26193L10.7221 12.2548L1.65229 20.1626L1.56768 19.6128ZM27.2402 21.5421C26.8856 21.7467 26.4772 21.8552 26.0598 21.8552H3.94008C3.52248 21.8552 3.11426 21.7467 2.75988 21.5421L2.3891 21.3272L11.7604 13.1604L12.7874 14.0538C13.4034 14.5886 14.1891 14.8835 14.9999 14.8835C15.8138 14.8835 16.6007 14.5886 17.2164 14.0538L18.243 13.16L27.611 21.3277L27.2402 21.5421ZM28.4398 19.4756C28.4398 19.5227 28.4389 19.5692 28.433 19.6106L28.3518 20.1656L19.278 12.259L28.4398 4.2658V19.4756Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
