export default function icoBox() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6666 4.85124L7.99998 7.99938M7.99998 7.99938L2.33331 4.85124M7.99998 7.99938L8 14.3327M14 10.7051V5.2937C14 5.06527 14 4.95106 13.9663 4.84919C13.9366 4.75908 13.8879 4.67635 13.8236 4.60656C13.7509 4.52767 13.651 4.4722 13.4514 4.36127L8.51802 1.62053C8.32895 1.51549 8.23442 1.46297 8.1343 1.44238C8.0457 1.42416 7.95431 1.42416 7.8657 1.44238C7.76559 1.46297 7.67105 1.51549 7.48198 1.62053L2.54865 4.36127C2.34896 4.4722 2.24912 4.52767 2.17642 4.60656C2.11211 4.67636 2.06343 4.75908 2.03366 4.84919C2 4.95106 2 5.06527 2 5.2937V10.7051C2 10.9335 2 11.0478 2.03366 11.1496C2.06343 11.2397 2.11211 11.3225 2.17642 11.3922C2.24912 11.4711 2.34897 11.5266 2.54865 11.6375L7.48198 14.3783C7.67105 14.4833 7.76559 14.5358 7.8657 14.5564C7.95431 14.5747 8.0457 14.5747 8.1343 14.5564C8.23442 14.5358 8.32895 14.4833 8.51802 14.3783L13.4514 11.6375C13.651 11.5266 13.7509 11.4711 13.8236 11.3922C13.8879 11.3225 13.9366 11.2397 13.9663 11.1496C14 11.0478 14 10.9335 14 10.7051Z"
        stroke="#4D4DFF"
        strokeWidth="1.45833"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
