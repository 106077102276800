import * as S from "./styles";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { PercentageInfoProps } from "./interfaces";
import { roundPercentageData } from "@/utils/prime-helpers";

const ColorMap = {
  portfolios: "#B6B6FF",
  currencies: "#4D4DFF",
};

const PercentageInfo = ({
  percentage,
  typeAssetColor,
  cardSize,
}: PercentageInfoProps) => {
  return (
    <S.PercentageInfoContainer $cardSize={cardSize}>
      <CircularProgressbar
        value={percentage}
        text={`${roundPercentageData(percentage)}%`}
        styles={buildStyles({
          textSize: "1.375rem",
          textColor: "#243465",
          trailColor: "#E9EBF3",
          pathColor: `${ColorMap[typeAssetColor]}`,
        })}
      />
    </S.PercentageInfoContainer>
  );
};

export default PercentageInfo;
