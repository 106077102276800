import { useCallback, useEffect, useMemo, useState } from "react";
import { Router, useRouter } from "next/router";
import type { AppProps } from "next/app";
import { ZendeskAPI } from "react-zendesk";
import NProgress from "nprogress";
import {
  Footer,
  HeaderContainer,
  InitialLoader,
  Providers,
  ZendeskSupport,
} from "@/components";
import { BaseLayout } from "@/widgets";
import { BaseLayoutType } from "@/widgets/BaseLayout/types";
import { RootState } from "@/store";
import "nprogress/nprogress.css";
import "@/styles/globals.scss";
import "../../i18n";
import i18n from "../../i18n";
import Sidebar from "@/components/menus/Sidebar";
import Head from "next/head";
import { useTranslation } from "react-i18next";
import { useScreenWidth } from "@/hooks/useScreenWidth";
import MobileAppCard from "../components/MobileAppCard";
import MobileHeader from "../components/headers/MobileHeader";
import MobileFooter from "@/components/Footer/MobileFooter";
import LimitsPage from "./help-center/limits-info/index.page";
import FeesPage from "./help-center/fees-info/index.page";
import TermsPage from "./help-center/terms/index.page";
import PrivacyPage from "./help-center/privacy-policies/index.page";
import ContactPage from "./help-center/contact/index.page";
import React from "react";
import { AbilityProvider } from "@/presentation/libs/casl/context";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/libs/react-query";
import StyledComponentsRegistry from "@/lib/registry";

type LayoutProps = {
  layout: BaseLayoutType;
  initialState: RootState;
};

type PageKey =
  | "help-center/limits-info"
  | "help-center/fees-info"
  | "help-center/terms"
  | "help-center/privacy-policies"
  | "help-center/contact";

interface PageComponent {
  component: React.ReactNode;
}

export default function App({ Component, pageProps }: AppProps<LayoutProps>) {
  const { events } = Router;
  const { locale } = useRouter();
  const location = useRouter();
  const { t } = useTranslation();
  const screenWidth = useScreenWidth();

  const layout = pageProps?.layout ?? "default";
  const [state, setState] = useState(false);

  events.on("routeChangeStart", () => NProgress.start());
  events.on("routeChangeComplete", () => NProgress.done());
  events.on("routeChangeError", () => NProgress.done());

  useEffect(() => {
    i18n.changeLanguage(locale);
    NProgress.configure({ easing: "linear", speed: 500 });
  }, []);

  const isMobile = useMemo(() => {
    return screenWidth <= 780;
  }, [screenWidth]);

  const ReturnMobilePage = useCallback(() => {
    const pageComponents: Record<PageKey, PageComponent> = {
      "help-center/limits-info": { component: <LimitsPage /> },
      "help-center/fees-info": { component: <FeesPage /> },
      "help-center/terms": { component: <TermsPage /> },
      "help-center/privacy-policies": { component: <PrivacyPage /> },
      "help-center/contact": { component: <ContactPage /> },
    };

    const PageComponent = Object.keys(pageComponents).find((key) =>
      location.route.includes(key)
    );

    return (
      <>
        {PageComponent ? (
          <>
            <MobileHeader />
            {pageComponents[PageComponent as PageKey].component}
            <MobileFooter />
          </>
        ) : (
          <MobileAppCard />
        )}
      </>
    );
  }, [location.route]);

  if (screenWidth === 0) {
    return <></>;
  }

  return (
    <>
      <Head>
        <title>{t("authLayout.mainTitleWeb")}</title>
      </Head>
      <StyledComponentsRegistry>
        <QueryClientProvider client={queryClient}>
          {isMobile ? (
            <Providers>
              <ReturnMobilePage />
            </Providers>
          ) : (
            <>
              <AbilityProvider>
                <Providers>
                  <>
                    <InitialLoader />
                    <BaseLayout
                      layout={layout}
                      menu={
                        <Sidebar
                          collapsed={state}
                          setCollapsed={() => setState(!state)}
                        />
                      }
                      header={<HeaderContainer />}
                      footer={<Footer />}
                      onFaq={() => {
                        ZendeskAPI("webWidget", "open");
                      }}
                    >
                      <Component {...pageProps} />
                    </BaseLayout>
                    <ZendeskSupport />
                  </>
                </Providers>
              </AbilityProvider>
            </>
          )}
        </QueryClientProvider>
      </StyledComponentsRegistry>
    </>
  );
}
