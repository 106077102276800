import styled, { keyframes } from "styled-components";

const pulse = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

export type SkeletonProps = {
  width?: string;
  height?: string;
};

export const Skeleton = styled.div<SkeletonProps>`
  width: ${(props) => props.width ?? "100%"};
  height: ${(props) => props.height ?? "20px"};
  background-color: #eee;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border-radius: 8px;

  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      transparent 0%,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 100%
    );
    animation: ${pulse} 1.5s infinite;
  }
`;
