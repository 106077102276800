import scssVariables from "@/styles/variables.module.scss";

export default function ok() {
  return (
    <svg viewBox="0 0 10 7" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.83728 0.157712C10.0542 0.367994 10.0542 0.708929 9.83728 0.919211L3.72617 6.84229C3.50921 7.05257 3.15745 7.05257 2.9405 6.84229L0.162718 4.14998C-0.0542395 3.9397 -0.0542395 3.59876 0.162718 3.38848C0.379676 3.1782 0.731435 3.1782 0.948393 3.38848L3.33333 5.70004L9.05161 0.157712C9.26857 -0.0525706 9.62032 -0.0525706 9.83728 0.157712Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
