import { PayloadAction } from "@reduxjs/toolkit";
import type { BaseState } from ".";

export const baseReducer = {
  updateIsScrolled: (state: BaseState, action: PayloadAction<boolean>) => {
    state.isScrolled = action.payload;
  },
  updateIsMenuOpen: (state: BaseState, action: PayloadAction<boolean>) => {
    state.isMenuOpen = action.payload;
  },
  updateDashFormParams: (
    state: BaseState,
    action: PayloadAction<{ term: string; hideZeros: boolean }>
  ) => {
    state.dashTerm = action.payload.term;
    state.hideZeros = action.payload.hideZeros;
  },
  updateIsBalanceVisible: (
    state: BaseState,
    action: PayloadAction<boolean>
  ) => {
    state.isBalanceVisible = action.payload;
  },
};
