import { useI18n } from "@/hooks";
import yup from "@/validators/validator";
import content from "./useSchema.json";

export default function useSchema() {
  const { i18n } = useI18n(content);

  const schema = yup.object().shape({
    customerName: yup
      .string()
      .min(2, i18n("min"))
      .max(40, i18n("max"))
      .required(i18n("required")),
    customerEmail: yup.string().email(i18n("email")).required(i18n("required")),
    message: yup.string().required(i18n("required")),
    language: yup.string(),
  });

  return schema;
}
