import scssVariables from "@/styles/variables.module.scss";

export default function important() {
  return (
    <svg viewBox="0 0 32 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 2.46154C8.52292 2.46154 2.46154 8.52292 2.46154 16C2.46154 23.4771 8.52291 29.5385 16 29.5385C23.4771 29.5385 29.5385 23.4771 29.5385 16C29.5385 8.52291 23.4771 2.46154 16 2.46154ZM0 16C0 7.16345 7.16345 0 16 0C24.8366 0 32 7.16345 32 16C32 24.8366 24.8366 32 16 32C7.16345 32 0 24.8366 0 16ZM16 6.5641C16.6797 6.5641 17.2308 7.11514 17.2308 7.79487V19.2821C17.2308 19.9618 16.6797 20.5128 16 20.5128C15.3203 20.5128 14.7692 19.9618 14.7692 19.2821V7.79487C14.7692 7.11514 15.3203 6.5641 16 6.5641ZM16 22.9579C16.6797 22.9579 17.2308 23.509 17.2308 24.1887V24.2051C17.2308 24.8849 16.6797 25.4359 16 25.4359C15.3203 25.4359 14.7692 24.8849 14.7692 24.2051V24.1887C14.7692 23.509 15.3203 22.9579 16 22.9579Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
