import { Card, IconLabel } from "..";

import styles from "./index.module.scss";

type CardProfileProps = {
  type: "basic" | "premium";
  title: string;
  children: any;
};

export default function CardProfile({
  type,
  title,
  children,
}: CardProfileProps) {
  return (
    <Card
      className={`CardProfile ${styles.container}`}
      size="tiny"
      keepOnMobile={true}
    >
      <div className={styles.header}>
        <IconLabel src={`/images/icons/profile-${type}.svg`} label={""} />
        <h2>{title}</h2>
      </div>
      <div className={styles.content}>{children}</div>
    </Card>
  );
}
