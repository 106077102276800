import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { currencySelector } from ".";

export function useCurrency() {
  const currencyState = useSelector(currencySelector);
  const currencyDispatch = useDispatch<AppDispatch>();

  return { currencyState, currencyDispatch };
}
