import scssVariables from "@/styles/variables.module.scss";

export default function orderUp() {
  return (
    <svg viewBox="0 0 667 567" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.43 9.76312C156.447 -3.25436 177.553 -3.25436 190.57 9.76312L323.904 143.096C336.921 156.114 336.921 177.219 323.904 190.237C310.886 203.254 289.781 203.254 276.763 190.237L200.333 113.807L200.333 533.333C200.333 551.743 185.41 566.667 167 566.667C148.591 566.667 133.667 551.743 133.667 533.333L133.667 113.807L57.2369 190.237C44.2195 203.254 23.114 203.254 10.0965 190.237C-2.92099 177.219 -2.92099 156.114 10.0965 143.096L143.43 9.76312ZM433.667 133.333C433.667 114.924 448.591 100 467 100H633.667C652.076 100 667 114.924 667 133.333C667 151.743 652.076 166.667 633.667 166.667H467C448.591 166.667 433.667 151.743 433.667 133.333ZM367 300C367 281.591 381.924 266.667 400.333 266.667H633.667C652.076 266.667 667 281.591 667 300C667 318.409 652.076 333.333 633.667 333.333H400.333C381.924 333.333 367 318.409 367 300ZM267 466.667C267 448.257 281.924 433.333 300.333 433.333H633.667C652.076 433.333 667 448.257 667 466.667C667 485.076 652.076 500 633.667 500H300.333C281.924 500 267 485.076 267 466.667Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
