import scssVariables from "@/styles/variables.module.scss";

export default function arrowDown() {
  return (
    <svg viewBox="0 0 19 12" fill="none">
      <path
        d="M1.63369e-07 1.85221L1.55789 0.294321L9.49844 8.23485L17.4421 0.291992L19 1.84988L9.49688 11.353L1.63369e-07 1.85221Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
