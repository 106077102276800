import scssVariables from "@/styles/variables.module.scss";

export default function summary() {
  return (
    <svg viewBox="0 0 23 32" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0.941176C0 0.421379 0.421379 0 0.941176 0H14.8416C15.0912 0 15.3306 0.0991594 15.5071 0.275664L22.4574 7.22589C22.6339 7.4024 22.733 7.64179 22.733 7.8914V31.0588C22.733 31.5786 22.3117 32 21.7919 32H0.941176C0.421379 32 0 31.5786 0 31.0588V0.941176ZM1.88235 1.88235V30.1176H20.8507V8.83258H14.8416C14.3218 8.83258 13.9005 8.4112 13.9005 7.8914V1.88235H1.88235ZM15.7828 3.21338L19.5197 6.95023H15.7828V3.21338ZM7.8914 10.4253C8.4112 10.4253 8.83258 10.8467 8.83258 11.3665V11.5837H9.04977C9.56957 11.5837 9.99095 12.0051 9.99095 12.5249C9.99095 13.0447 9.56957 13.4661 9.04977 13.4661H7.31222C6.90532 13.4661 6.51584 13.8555 6.51584 14.2624C6.51584 14.6693 6.90532 15.0588 7.31222 15.0588H8.47059C9.91708 15.0588 11.1493 16.2911 11.1493 17.7376C11.1493 19.0616 10.1168 20.2062 8.83258 20.3904V20.6335C8.83258 21.1533 8.4112 21.5747 7.8914 21.5747C7.37161 21.5747 6.95023 21.1533 6.95023 20.6335V20.4163H6.73303C6.21323 20.4163 5.79186 19.9949 5.79186 19.4751C5.79186 18.9553 6.21323 18.5339 6.73303 18.5339H8.47059C8.87749 18.5339 9.26697 18.1445 9.26697 17.7376C9.26697 17.3307 8.87749 16.9412 8.47059 16.9412H7.31222C5.86572 16.9412 4.63348 15.7089 4.63348 14.2624C4.63348 12.9384 5.666 11.7938 6.95023 11.6096V11.3665C6.95023 10.8467 7.37161 10.4253 7.8914 10.4253ZM12.7421 13.6833C12.7421 13.1635 13.1635 12.7421 13.6833 12.7421H17.1584C17.6782 12.7421 18.0995 13.1635 18.0995 13.6833C18.0995 14.2031 17.6782 14.6244 17.1584 14.6244H13.6833C13.1635 14.6244 12.7421 14.2031 12.7421 13.6833ZM12.7421 18.3167C12.7421 17.7969 13.1635 17.3756 13.6833 17.3756H17.1584C17.6782 17.3756 18.0995 17.7969 18.0995 18.3167C18.0995 18.8365 17.6782 19.2579 17.1584 19.2579H13.6833C13.1635 19.2579 12.7421 18.8365 12.7421 18.3167ZM10.4253 22.9502C10.4253 22.4304 10.8467 22.0091 11.3665 22.0091H17.1584C17.6782 22.0091 18.0995 22.4304 18.0995 22.9502C18.0995 23.47 17.6782 23.8914 17.1584 23.8914H11.3665C10.8467 23.8914 10.4253 23.47 10.4253 22.9502Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
