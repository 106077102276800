/* eslint-disable no-unused-vars */
import { ReactElement } from "react";
import moment from "moment";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import styles from "./index.module.scss";

type CountDownProps = {
  seconds: number;
  format?: "mm:ss" | "ss";
  label?: string;
  onTimeFinish(): void;
  onTimeUpdate?(param: number): void;
};

export default function CountDown({
  seconds,
  format = "mm:ss",
  label,
  onTimeFinish,
  onTimeUpdate,
}: CountDownProps) {
  const getSeconds = (time: number) => Math.ceil(seconds - time);
  const onlySeconds = format === "ss";
  const size = onlySeconds ? 36 : 56;

  if (format === "ss" && (seconds > 59 || seconds <= 0)) {
    throw "Invalid number range at CoundDown. It is only supported values between 1 and 59 when its format is 'ss'.";
  } else if (seconds > 3600 || seconds <= 0) {
    throw "Invalid number range at CoundDown. It is only supported values between 1 and 3600 when its format is 'mm:ss'.";
  }

  return (
    <div
      className={`CountDown ${styles.container} ${
        onlySeconds ? styles.onlySeconds : ""
      }`}
    >
      <CountdownCircleTimer
        isPlaying={true}
        strokeWidth={3}
        size={size}
        duration={seconds}
        colors="#FFFFFF" // it is changed by css
        onUpdate={(remainingTime: number) => {
          onTimeUpdate && onTimeUpdate(remainingTime);
        }}
        onComplete={() => {
          onTimeFinish();
        }}
      >
        {({ elapsedTime }): ReactElement => {
          return (
            <div className={styles.display}>
              {moment.utc(getSeconds(elapsedTime) * 1000).format(format)}
            </div>
          );
        }}
      </CountdownCircleTimer>
      {label && <span>{label}</span>}
    </div>
  );
}
