import Link from "next/link";
import { IconLabel } from "@/widgets";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

type MenuOperationsProps = {
  depositUrl: string;
  convertUrl: string;
  withdrawlUrl: string;
  depositLabel?: string;
  withdrawlLabel?: string;
  convertLabel?: string;
  className?: string;
  variant?: "default" | "menu";
};

export default function MenuOperations({
  depositUrl,
  depositLabel = "",
  withdrawlUrl,
  withdrawlLabel = "",
  convertUrl,
  convertLabel = "",
  className = "",
  variant = "default",
}: MenuOperationsProps) {
  const { t } = useTranslation()
  return (
    <div
      className={`MenuOperatins ${styles.container} ${styles[variant]} ${className}`}
    >
      <Link href={depositUrl}>
        <IconLabel
          size="small"
          src="/images/operations/deposit.svg"
          label={depositLabel}
          title={t("loggedInMenu.deposit")}
        />
      </Link>
      <Link href={withdrawlUrl}>
        <IconLabel
          size="small"
          src="/images/operations/withdraw.svg"
          label={withdrawlLabel}
          title={t("loggedInMenu.withdraw")}
        />
      </Link>
      <Link href={convertUrl}>
        <IconLabel
          size="small"
          src="/images/operations/convert.svg"
          label={convertLabel}
          title={t("loggedInMenu.convert")}
        />
      </Link>
    </div>
  );
}
