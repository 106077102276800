import scssVariables from "@/styles/variables.module.scss";

export default function link() {
  return (
    <svg viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.84211 1.47368C8.43515 1.47368 8.10526 1.14379 8.10526 0.736842C8.10526 0.329899 8.43515 0 8.84211 0H13.2632C13.6701 0 14 0.329899 14 0.736842V5.15789C14 5.56485 13.6701 5.89474 13.2632 5.89474C12.8562 5.89474 12.5263 5.56485 12.5263 5.15789V2.51573L7.1526 7.88944C6.86486 8.17718 6.39829 8.17718 6.11056 7.88944C5.8228 7.60171 5.8228 7.13514 6.11056 6.8474L11.4843 1.47368H8.84211ZM2.21053 2.94737C1.80358 2.94737 1.47368 3.27727 1.47368 3.68421V11.7895C1.47368 12.1964 1.80358 12.5263 2.21053 12.5263H10.3158C10.7227 12.5263 11.0526 12.1964 11.0526 11.7895V8.42763C11.0526 8.02067 11.3825 7.69079 11.7895 7.69079C12.1964 7.69079 12.5263 8.02067 12.5263 8.42763V11.7895C12.5263 13.0103 11.5367 14 10.3158 14H2.21053C0.98969 14 0 13.0103 0 11.7895V3.68421C0 2.46337 0.98969 1.47368 2.21053 1.47368H5.57237C5.97933 1.47368 6.30921 1.80358 6.30921 2.21053C6.30921 2.61747 5.97933 2.94737 5.57237 2.94737H2.21053Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
