import { Icon } from "@/widgets";
import styles from "./index.module.scss";

type BackButtonProps = {
  label: string;
  onClick: () => void;
};

export default function BackButton({ label, onClick }: BackButtonProps) {
  return (
    <button
      type="button"
      className={`BackButton ${styles.container}`}
      onClick={() => {
        onClick();
      }}
    >
      <Icon icon="arrowBack" /> {label}
    </button>
  );
}
