import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
// import { IAsyncState } from "@/models";
// import { ICustomer } from "@/models";
import { customerReducer } from "./reducers";
import { useCustomer } from "./hooks";
import {
  fetchCustomer,
  fecthRequiredFields,
  customerExtraReducerBuilder,
} from "./thunks";

// interface AsyncCustomer extends IAsyncState {
//   data: null | ICustomer | {
//     [key: string]: any
//   };
// }

// interface AsyncRequiredFields extends IAsyncState {
//   data: null | {
//     [key: string]: any
//   };
// }

export interface CustomerState {
  customer: any;
  requiredFields: any;
}

export const initialState: CustomerState = {
  customer: {
    data: null,
    error: null,
    lastUpdate: null,
    loading: false,
  },
  requiredFields: {
    data: null,
    error: null,
    lastUpdate: null,
    loading: false,
  },
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: customerReducer,
  extraReducers: customerExtraReducerBuilder,
});

export const { clearCustomer, updateCustomer } = customerSlice.actions;

export { useCustomer, fetchCustomer, fecthRequiredFields };

export const customerSelector = (state: RootState) => state.customer;

export default customerSlice.reducer;
