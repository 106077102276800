export default function portfolioDefault() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.354022 9.11787C1.94527 2.68422 8.45032 -1.23758 14.882 0.35431C21.3149 1.94559 25.2372 8.44958 23.646 14.882C22.0541 21.3144 15.5503 25.2381 9.11738 23.6456C2.6857 22.0543 -1.23843 15.5497 0.354629 9.11787"
        fill="#3F3FF9"
      />
      <path
        d="M11.9999 13.3334L9.33341 16L11.9999 18.6667L14.6665 16L11.9999 13.3334Z"
        fill="white"
      />
      <path
        d="M6.66661 8L4 10.6667L6.66661 13.3334L9.33322 10.6667L6.66661 8Z"
        fill="white"
      />
      <path
        d="M17.333 8L14.6664 10.6667L17.333 13.3334L19.9996 10.6667L17.333 8Z"
        fill="white"
      />
      <path
        d="M17.333 13.3334L11.9999 13.3335L6.66661 8L11.9999 8.00005L17.333 13.3334Z"
        fill="url(#paint0_linear_912_12294)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_912_12294"
          x1="5.45074"
          y1="3.93599"
          x2="11.3729"
          y2="28.2838"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#81FEEF" />
          <stop offset="0.45" stopColor="#3D3DFF" />
          <stop offset="1" stopColor="#170636" />
        </linearGradient>
      </defs>
    </svg>
  );
}
