/**
 * It returns input for text entries, textarea and masked inputs
 */

import { forwardRef, useState } from "react";
import { getInput, getPasswordIcons, getSearchIcon } from "./partials";
import { TheFieldType } from "./types";

import styles from "./index.module.scss";

const TheField = (
  {
    label,
    placeholder = "",
    hasError = false,
    type = "text",
    variant = "default",
    mask = "",
    isCurrency = false,
    currencyMask,
    id = "",
    ...props
  }: TheFieldType,
  ref: any
) => {
  const [fieldType, setFieldType] = useState(type);
  const classError = `${hasError ? styles.hasError : ""} ${
    hasError ? "hasError" : ""
  }`;
  const handleTogglePassword = () => {
    setFieldType(fieldType === "password" ? "text" : "password");
  };

  return (
    <div
      className={`Input ${styles.container} ${classError}  ${
        type === "textarea" ? styles.textarea : ""
      } ${styles[variant]}`}
    >
      {label && <label htmlFor={id}>{label}</label>}
      <div className={styles.fieldWrap}>
        {type !== "textarea" &&
          getInput(
            fieldType,
            placeholder,
            mask,
            isCurrency,
            currencyMask,
            classError,
            variant,
            id,
            ref,
            {
              ...props,
            }
          )}
        {type === "textarea" && (
          <textarea
            className={`${classError} ${variant}`}
            id={id}
            ref={ref}
            {...props}
            placeholder={placeholder}
          ></textarea>
        )}
        {type === "password" &&
          getPasswordIcons(
            `${styles.eyeIcon} ${fieldType === "text" ? styles.hide : ""}`,
            handleTogglePassword
          )}
        {type === "search" && getSearchIcon(`${styles.searchIcon}`)}
      </div>
    </div>
  );
};

const Input = forwardRef<HTMLInputElement, TheFieldType>(TheField);

export default Input;
