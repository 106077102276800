export default function collapseLeft() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" fill="#FDFEFF" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="4.5" stroke="#EFF1F5" />
      <path
        d="M16 8.66683L12.6667 12.0002L16 15.3335M11.3333 8.66683L8 12.0002L11.3333 15.3335"
        stroke="#4D4DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
