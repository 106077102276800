import scssVariables from "@/styles/variables.module.scss";

export default function arrowBack() {
  return (
    <svg viewBox="0 0 13 10" fill="none">
      <path
        d="M5.91598 9.7147L6.68643 8.87047L3.21939 5.57707L13 5.57707V4.42436L3.21732 4.42436L6.68525 1.13037L5.91598 0.286133L0.955444 4.99983L5.91598 9.7147Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
