import { LOCALES_TYPE } from "@/const";
import { useRouter } from "next/router";
import { useMemo } from "react";

export default function useLocale() {
  const locale = useRouter().locale as LOCALES_TYPE;
  const dateFormat = useMemo(() => {
    let format = "DD/MM/YYYY";

    if (locale === "en") {
      format = "MM/DD/YYYY";
    }

    return format;
  }, [locale]);

  return { locale, dateFormat };
}
