import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, type HistoryState } from ".";
import { IHistory } from "@/models";

export const historyReducer = {
  clearHistory: () => {
    return initialState;
  },
  updateHistory: (_: HistoryState, action: PayloadAction<HistoryState>) => {
    return action.payload;
  },
  addHistory: (state: HistoryState, action: PayloadAction<IHistory>) => {
    if (state.history.data === null) {
      state.history.data = [action.payload];
    } else {
      state.history.data.push(action.payload);
    }
    return state;
  },
};
