import styled, { keyframes } from "styled-components";

export const HeaderMenuContainer = styled.nav`
  width: 1200px;
  height: 80px;
  display: flex;
  align-items: center;
  z-index: 2;
  background: #ffffff;
  position: fixed;
  top: 0;

  @media (max-width: 1280px) {
    width: 90%;
    margin: 0 auto;
    left: 5%;
  }

  @media (max-width: 670px) {
    width: 90%;
    margin: 0 auto;
    left: 5%;
  }
`;

export const ContentLogo = styled.div`
  width: 55px;
  margin-right: 30px;
`;

export const ContentNavAndDropdownLanguage = styled.div`
  display: flex;
  margin-right: 40px;
`;

export const ContentLogoAndNav = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderButtons = styled.div`
  height: 34px;
  display: flex;
  gap: 13px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ContentLogoAndLanguage = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContentLanguage = styled.div`
  font-size: 14px;
  color: #413fcf;
  font-weight: 500;
  position: relative;

  & > div {
    cursor: pointer;
  }

  img {
    margin-right: 10px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const DropdownLanguage = styled.div<{ $isOpen: boolean }>`
  display: ${({ $isOpen }) => ($isOpen ? "block" : "none")};
  width: 190px;
  position: absolute;
  top: 22px;
  left: 0;
  box-shadow: 0px 4px 6px -2px #10182808;
  box-shadow: 0px 12px 16px -4px #10182814;
  border-radius: 6px;
  border: 1px solid #eaecf0;
  background-color: #fff;
  animation: ${fadeIn} 0.3s ease-in-out; /* Aplica a animação */
`;

export const Item = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  color: #30374f;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    background-color: #ebecef;
  }
`;
