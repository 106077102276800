import scssVariables from "@/styles/variables.module.scss";

export default function logout() {
  return (
    <svg viewBox="0 0 13 12" fill="none">
      <path
        d="M5.83333 11.6667C2.61158 11.6667 0 9.05508 0 5.83333C0 2.61158 2.61158 1.644e-06 5.83333 1.644e-06C6.73905 -0.000678791 7.63244 0.209869 8.44254 0.614918C9.25264 1.01997 9.95711 1.60835 10.5 2.33333H8.91917C8.24555 1.73936 7.41485 1.35238 6.52675 1.21882C5.63865 1.08526 4.73087 1.2108 3.91235 1.58038C3.09383 1.94995 2.39934 2.54786 1.91221 3.30236C1.42508 4.05686 1.16601 4.93589 1.16608 5.83398C1.16616 6.73207 1.42537 7.61106 1.91263 8.36548C2.39988 9.11989 3.09448 9.71769 3.91306 10.0871C4.73164 10.4566 5.63944 10.582 6.52752 10.4482C7.4156 10.3145 8.24623 9.92742 8.91975 9.33333H10.5006C9.95764 10.0584 9.25307 10.6468 8.44286 11.0519C7.63266 11.4569 6.73915 11.6674 5.83333 11.6667ZM9.91667 8.16667V6.41667H5.25V5.25H9.91667V3.5L12.8333 5.83333L9.91667 8.16667Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
