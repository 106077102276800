export default function swapIconNew() {
  return (
    <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="27" height="27" rx="13.5" fill="#E8F0FF" />
      <g clipPath="url(#clip0_288_618)">
        <path d="M20.1285 11.5858L12.586 19.1282M12.586 19.1282L12.586 15.357M12.586 19.1282L16.3572 19.1282M7.87195 14.4142L15.4144 6.87171M15.4144 6.87171H11.6432M15.4144 6.87171V10.6429" stroke="#4D4DFF" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_288_618">
          <rect width="16" height="16" fill="white" transform="translate(6 5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
