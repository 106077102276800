import scssVariables from "@/styles/variables.module.scss";

export default function cellPhone() {
  return (
    <svg viewBox="0 0 20 30" fill="none">
      <path
        d="M16.9586 0.00184473H2.2283C1.66524 -0.0215235 1.11565 0.17788 0.698567 0.55686C0.281488 0.93584 0.0305101 1.46388 0 2.0266V27.9734C0.0305101 28.5361 0.281488 29.0642 0.698567 29.4431C1.11565 29.8221 1.66524 30.0215 2.2283 29.9982H16.9586C17.5217 30.0215 18.0713 29.8221 18.4884 29.4431C18.9054 29.0642 19.1564 28.5361 19.1869 27.9734V2.0266C19.1564 1.46388 18.9054 0.93584 18.4884 0.55686C18.0713 0.17788 17.5217 -0.0215235 16.9586 0.00184473ZM7.23661 1.46416H11.9503C11.9841 1.46273 12.0178 1.46797 12.0496 1.47958C12.0813 1.49119 12.1104 1.50894 12.1353 1.53183C12.1602 1.55471 12.1803 1.58228 12.1945 1.61295C12.2087 1.64362 12.2168 1.67679 12.2181 1.71056C12.2168 1.74411 12.2087 1.77705 12.1944 1.80743C12.1802 1.83782 12.16 1.86504 12.135 1.8875C12.11 1.90996 12.0809 1.92721 12.0491 1.93821C12.0174 1.94922 11.9838 1.95378 11.9503 1.95161H7.23661C7.20311 1.95378 7.16951 1.94922 7.13779 1.93821C7.10607 1.92721 7.07687 1.90996 7.05192 1.8875C7.02696 1.86504 7.00675 1.83782 6.99248 1.80743C6.9782 1.77705 6.97014 1.74411 6.96879 1.71056C6.97016 1.67679 6.97819 1.64362 6.9924 1.61295C7.00661 1.58228 7.02673 1.55471 7.0516 1.53183C7.07648 1.50894 7.10562 1.49119 7.13737 1.47958C7.16911 1.46797 7.20284 1.46273 7.23661 1.46416ZM9.59346 28.9858C9.39714 28.9671 9.21047 28.8918 9.05616 28.769C8.90184 28.6462 8.78653 28.4812 8.72424 28.2941C8.66196 28.107 8.65537 27.9058 8.7053 27.715C8.75522 27.5242 8.8595 27.352 9.00546 27.2194C9.15142 27.0868 9.33276 26.9994 9.52744 26.9679C9.72213 26.9365 9.92176 26.9622 10.1021 27.0421C10.2824 27.122 10.4356 27.2525 10.5431 27.4179C10.6506 27.5832 10.7078 27.7762 10.7076 27.9734C10.6924 28.2548 10.5669 28.5188 10.3583 28.7083C10.1498 28.8978 9.87499 28.9975 9.59346 28.9858ZM17.6282 26.2486H1.55874V3.21574H17.6282V26.2486Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
