export default function languageNoSelected() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M4.16699 6.66663L8.33366 10.8333M3.33366 11.6666L8.33366 6.66663L10.0003 4.16663M1.66699 4.16663H11.667M5.83366 1.66663H6.66699M10.7612 14.1666H16.7395M10.7612 14.1666L9.16699 17.5M10.7612 14.1666L13.1489 9.17415C13.3413 8.77185 13.4375 8.57071 13.5691 8.50714C13.6836 8.45186 13.817 8.45186 13.9315 8.50714C14.0631 8.57071 14.1593 8.77185 14.3517 9.17415L16.7395 14.1666M16.7395 14.1666L18.3337 17.5"
        stroke="#5D6B98"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
