/* eslint-disable no-unused-vars */
import PinField from "react-pin-field";
import styles from "./index.module.scss";

type InputCodeProps = {
  length: number;
  type?: "text" | "password";
  onChange?(param: string): void;
  hasError?: boolean;
  id?: string;
};

export default function InputCode({
  length,
  type = "text",
  onChange,
  hasError = false,
  id,
}: InputCodeProps) {
  return (
    <div
      id={id}
      className={`InputCode ${styles.container} ${
        hasError ? styles.hasError : ""
      }`}
    >
      <PinField
        type={type}
        length={length}
        className="field-a"
        validate={/^[0-9]$/}
        onChange={(code: string) => {
          if (onChange) {
            onChange(code);
          }
        }}
        format={(k) => k.toUpperCase()}
      />
    </div>
  );
}
