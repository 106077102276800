export default function arrowDownGrey() {
  return (
    <svg
      width="10"
      height="13"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 1.16671L5 11.8334M5 11.8334L9 7.83337M5 11.8334L1 7.83337"
        stroke="#5F5F5F"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
