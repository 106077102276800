"use-client";
export default function Pt_i18n() {
  return (
    <div style={{ textAlign: "justify" }}>
      <p>
        Os presentes termos e condições de uso (“Termos de Uso” ou “Termo”)
        representam os termos e condições que regulam as principais obrigações e
        regem a prestação de serviços pelas empresas do grupo Transfero
        (“Transfero”), para os nossos usuários (“Usuário” ou “Usuários”) ao
        adquirirem e utilizarem os serviços aqui descritos, por meio do nosso
        site, disponível no domínio “app.transfero.com” e aplicativo disponível
        na App Store e no Google Play. Antes de utilizar as Plataformas,
        desenvolvidas pela Transfero, é obrigatório a leitura e compreensão
        integral destes Termos de Uso, os quais permanecerão disponíveis nas
        Plataformas. O acesso de Usuários somente é permitido após o aceite
        incondicional e irrevogável dos Termos de Uso, que juntamente com o
        restante dos documentos nele mencionados, constituem um acordo
        vinculativo e exequível entre o Usuário e a Transfero. Ainda, este
        Termo, bem como as informações fornecidas no site ou no aplicativo, não
        deverão ser consideradas como consultoria ou recomendações de
        investimento, e ao aceitá-lo, o Usuário declara tê-lo lido integralmente
        e estar de acordo com as diretrizes nele dispostas. Ao utilizar os
        serviços oferecidos, o Usuário concorda com todas as regras destes
        Termos de Uso, da nossa política de privacidade e demais condições aqui
        mencionadas, responsabilizando-se integralmente por todos e quaisquer
        atos praticados ao utilizar os nossos serviços. Caso o Usuário não
        concorde com qualquer dos termos e condições abaixo estabelecidos, o
        Usuário não deve utilizar os serviços. Desta forma, é importante ler
        atentamente esses documentos e suas eventuais atualizações antes de
        tomar a decisão de usar ou prosseguir com o uso de nossas Plataformas.
        Caso haja qualquer dúvida acerca destes documentos, antes de acessar ou
        utilizar as Plataformas, entre em contato conosco por meio dos nossos
        Canais de Comunicação. A recusa aos presentes Termos de Uso impedirá o
        Usuário de utilizar as Plataformas e, consequentemente, de desfrutar dos
        serviços oferecidos. Os serviços são oferecidos pela Transfero que,
        juntamente com sua parceira operacional de cartões de crédito
        (“Parceira”), disponibilizam uma conta de pagamento digital com o
        intuito de facilitar suas compras em estabelecimentos e que também
        permite a realização de transferência entre contas, bem como transações
        pelo Pix, entre outras funcionalidades (“Serviços”). Os Serviços incluem
        também aqueles prestados por parceiros de negócios, como a corretora de
        câmbio a ser definida pela Transfero para prestação de serviços de
        conversão de moedas fiduciárias e transferências internacionais, além de
        uma corretora de criptoativos integrada para realizar a conversão e
        custódia de Criptoativos.
      </p>
      <h1>1. DEFINIÇÕES </h1>
      <p>
        1.1. Sem prejuízo de outras definições atribuídas nestes Termos de Uso,
        as palavras abaixo, quando utilizadas no singular ou plural, terão os
        seguintes significados:
      </p>
      <p>
        <strong>Aplicações ou Plataformas: </strong> são aplicações de internet
        como, por exemplo, site ou aplicativo mobile (isto é, para uso em
        “smartphones” ou dispositivos móveis em geral), disponibilizados para
        que o Usuário possa gerenciar sua Conta, na forma prevista nestes Termos
        de Uso. Por exemplo, site app.transfero.com ou aplicativo Transfero.
      </p>
      <p>
        <strong>Bandeira: </strong>: é o arranjo de pagamento instituído pela
        Mastercard, empresa responsável pela marca e pelos sistemas físicos e
        digitais que permitem a emissão do Cartão e utilização nos
        Estabelecimentos, de acordo com a Lei nº 12.865/2013 e com o Regulamento
        dos Arranjos de Pagamento da Mastercard do Brasil, disponível no site da
        Mastercard.
      </p>
      <p>
        <strong>BRZ: </strong>é a abreviação para o Brazilian Digital Token, uma
        Stablecoin pareada na proporção de 1:1 ao Real Brasileiro (“BRL”), o que
        significa dizer que a cotação do BRZ será sempre a mesma que a do BRL.
      </p>
      <p>
        <strong>Cartão:</strong> é um cartão físico ou virtual que, a critério
        do Transfero, pode ser oferecido aos Usuários para usufruir dos
        Serviços.
      </p>
      <p>
        <strong>Canais de Comunicação: </strong>
        são os canais oficiais para comunicação entre o Usuário e a Transfero,
        para assuntos relacionados ao uso dos Serviços aqui descritos, sendo que
        qualquer outro canal de comunicação que não esteja descrito aqui, ou nas
        Aplicações, não é considerado um canal oficial, e o Usuário não deve
        utilizá-lo. Os canais são os seguintes:
        <span>1. URL: app.transfero.com;</span>
        <span>2. Chat Online: por meio do aplicativo;</span>
        <span>
          3. E-mail: relacionamento@transfero.com.br; e ajuda@transfero.com.
        </span>
      </p>
      <p>
        <strong> Chargeback: </strong>é o procedimento de contestação de débito
        por meio do qual o Usuário declara não reconhecer uma despesa efetuada
        com seu Cartão. Uma vez aceito o Chargeback, será realizado um depósito
        na Conta do Usuário, no prazo informado pelos Canais de Comunicação.
      </p>
      <p>
        <strong>Cliente: </strong>
        Refere-se aos clientes do Lojista que utilizam o Transfero Checkout para
        efetuarem pagamentos com Criptoativos para o Lojista.
      </p>
      <p>
        <strong>Conta: </strong>é a conta de pagamento pré-paga da Transfero,
        destinada à execução de transações de pagamento em Criptoativos,
        realizadas com base em fundos denominados em reais previamente
        aportados.
      </p>
      <p>
        <strong>Corretora de Câmbio: </strong>é a instituição financeira a ser
        escolhida pela Transfero para prestação de serviços de câmbio, como
        conversão de moedas fiduciárias e pagamentos internacionais com
        contratos de câmbio devidamente registrados no Banco Central do Brasil.
      </p>
      <p>
        <strong>Corretora de Criptoativos: </strong>é a empresa do grupo
        Transfero responsável por realizar as conversões e custódias de
        Criptoativos, prestando as devidas informações à Receita Federal do
        Brasil, conforme as instruções normativas vigentes.
      </p>

      <p>
        <strong>Criptoativos ou Ativos Digitais: </strong>
        Significa qualquer ativo virtual emitido com vinculação, ou não, a
        qualquer banco central de determinado país, que se utiliza de técnicas
        de encriptação para validação de operações, geração de novas unidades
        e/ou transferências entre usuários, com registro distribuído das
        transações de forma pública ou privada.
      </p>
      <p>
        <strong>Estabelecimento: </strong>
        qualquer fornecedor de produtos e/ou serviços que está habilitado a
        aceitar pagamentos com o Cartão, no Brasil e/ou no exterior, em lojas
        físicas ou por meio da internet.
      </p>
      <p>
        <strong>Moeda Corrente: </strong>
        Representa qualquer dos meios oficiais de pagamento de um país. No caso
        do Brasil é o Real (abreviado como R$ ou BRL), emitido pelo Banco
        Central do Brasil.
      </p>
      <p>
        <strong>Pix: </strong>é o pagamento instantâneo brasileiro.
      </p>
      <p>
        <strong>Política de Privacidade: </strong>é o instrumento contratual que
        regula o tratamento e segurança dos dados pessoais dos Usuários
        (“Titulares”), incluindo, mas não se limitando à coleta, uso e
        armazenamento das informações pessoais dos Titulares, e que pode ser
        acessada aqui: www.transfero.com. A Política de Privacidade é parte
        integrante e inseparável destes Termos de Uso. A aceitação destes Termos
        de Uso implica na aceitação da Política de Privacidade, por isso pedimos
        que a leia com atenção.
      </p>
      <p>
        <strong>Usuário: </strong>
        Pessoa Física ou Jurídica, titular da Conta.
      </p>
      <h1>2. SERVIÇOS</h1>
      <p>
        2.1. A Transfero, a Parceira e a Corretora de Câmbio, quando aplicável,
        oferecem ao Usuário diversos serviços que possibilitam a movimentação de
        recursos financeiros e a realização de transações de pagamento.{" "}
      </p>
      <p>
        2.2. O Usuário, após realizar os procedimentos de cadastro descritos no
        item 3, poderá usufruir dos Serviços, por meio de sua Conta de
        pagamento, sempre sujeito à disponibilidade de saldo, conforme a seguir:
      </p>
      <p>
        (i) Compras em Estabelecimentos: o Usuário poderá realizar compras em
        qualquer fornecedor de produtos e/ou serviços que estejam habilitados a
        aceitar pagamentos com o seu Cartão ou em Criptoativos, no Brasil e/ou
        no Exterior, em lojas físicas ou por meio da internet;
      </p>
      <p>
        (ii) Transferências entre Contas: o Usuário poderá transferir recursos
        financeiros da sua Conta para de terceiros, por meio das Plataformas,
        podendo ser cobradas taxas e tributos específicos para essas
        transferências, que serão descontados do saldo do Usuário. A
        transferência entre contas também deverá observar os prazos e
        procedimentos previstos pelo sistema financeiro brasileiro;
      </p>
      <p>
        (iii) Custódia, Transferência e Conversão de Criptoativos: o Usuário
        poderá adquirir Criptoativos, mantê-las sob a custódia da Corretora de
        Criptoativos e realizar transferências destes ativos para terceiros;
      </p>
      <p>
        (iv) Pagamentos Internacionais: o Usuário poderá enviar recursos
        financeiros de sua Conta para o exterior. Todos os serviços referentes a
        câmbio e remessas internacionais serão realizados por meio da Corretora
        de Câmbio;
      </p>
      <p>
        (v) Pix: forma de transação financeira pela qual recursos são
        transferidos entre as contas bancárias do Usuário e a Conta de pagamento
        da Transfero.
      </p>
      <p>
        2.3. O Usuário, ao contratar o serviço indicado no itens (iv), autoriza
        a Transfero a contratar Corretora de Câmbio por livre escolha do
        Transfero, em seu nome, respeitados os Termos de Uso ora dispostos.
      </p>
      <p>
        2.4. Para ativar o uso de sua Conta, o Usuário deve realizar um aporte,
        por meio Transferência Bancária ou Pix, sendo o saldo imediatamente
        convertido e disponibilizado na Conta em BRZ.{" "}
      </p>
      <p>
        2.5. Cada operação aqui descrita pode ter limitações técnicas e
        especificações definidas pela Transfero, bem como uma remuneração
        específica, de acordo com o serviço realizado, que será cobrada mediante
        débito em Conta. O valor de cada tarifa está disponível aos Usuários nas
        Plataformas. A Transfero se reserva o direito de alterar as tarifas a
        qualquer momento, sempre informando previamente os Usuários, de acordo
        com a regulamentação aplicável, sendo facultado ao Usuário concordar com
        tal alteração ou cancelar seu cadastro.
      </p>
      <h1>3. CADASTRO E ACESSO</h1>
      <p>
        3.1. Para utilizar os Serviços, o Usuário deve cadastrar-se por meio das
        Aplicações. O Usuário deverá preencher o cadastro, que contém níveis de
        acesso, a depender do volume financeiro a ser transacionado, e que se
        encontra disponível na página
        (https://app.transfero.com/help-center/limits-info), bem como
        disponibilizar cópia digitalizada dos documentos requeridos e outras
        informações cadastrais, pertinentes à pessoa física ou pessoa jurídica,
        e submeter à Transfero.
      </p>
      <p>
        3.2. Para cadastrar-se na Plataforma Transfero, quando pessoa física, o
        Usuário deverá ter ao menos 18 (dezoito) anos completos.{" "}
      </p>
      <p>
        3.2.1. No caso de Usuários emancipados, o Usuário deverá fornecer cópia
        dos documentos competentes que comprovem que a emancipação se deu em
        caráter irrevogável e irretratável, sendo seu registro na Plataforma
        sujeito à análise e validade de tais documentos e informações.
      </p>
      <p>
        3.3. Em caso de pessoas jurídicas, o cadastro deverá ser feito e
        submetido pelo(s) representante(s) legais da empresa, devidamente
        constituídos e empossados, na forma de seu contrato ou estatuto social.
        Além dos documentos societários pertinentes, deverão ser apresentados os
        documentos pessoais dos representantes legais.
      </p>
      <p>
        3.4. Os seguintes documentos da pessoa jurídica deverão ser
        disponibilizados: (i) cópia da última alteração contratual ou estatuto
        social vigente; (ii) documento de posse do(s) representante(s) legal(is)
        da empresa; (iii) documentos pessoais (RG e CPF) do(s) representante(s)
        legal(is) responsável(is) pelo preenchimento do formulário da
        Plataforma; e (iv) cópia do cartão CNPJ atualizado da empresa. A lista
        de documentos acima poderá ser alterada unilateralmente pela Transfero
        por mera liberalidade.{" "}
      </p>
      <p>
        3.5. Uma vez preenchido o formulário, o Usuário deverá apresentar seu
        aceite a estes Termos de Uso mediante clique no campo “Eu Aceito” ou
        similar.{" "}
      </p>
      <p>
        3.6. O Usuário (e/ou seu representante legal) responderá pela veracidade
        e atualização dos dados informados no cadastro da Plataforma. A
        manutenção do cadastro do Usuário junto à Plataforma estará sujeita à
        análise da equipe de compliance da Transfero. O cadastro poderá ser
        recusado, interrompido ou cancelado, conforme aplicável, em caso de
        erro, informações incongruentes ou desatualização.
      </p>
      <p>
        3.7. A Transfero se reserva ao direito de negar ou cancelar o cadastro
        de interessados que não forneçam informações solicitadas e/ou forneçam
        informações incompletas, não satisfatórias, desatualizadas e/ou
        incompatíveis com estes Termos de Uso.
      </p>
      <p>
        3.8. Ao manifestar seu aceite, o Usuário declara ter lido, interpretado,
        compreendido e aprovado o conteúdo destes Termos de Uso, sujeitando-se a
        suas disposições e obrigações.{" "}
      </p>
      <p>
        3.9. A Transfero poderá, a qualquer momento, reavaliar, verificar ou
        reanalisar o cadastro do Usuário junto à Plataforma. Durante tais
        atividades, a Transfero poderá solicitar informações adicionais,
        documentos, comprovantes ou quaisquer outras informações necessárias
        para aferição do cadastro do Usuário, inclusive no que diz respeito ao
        cumprimento de legislação aplicável.
      </p>
      <p>
        3.10. A Transfero se reserva o direito de recusar qualquer solicitação
        de cadastro e de suspender e/ou revogar qualquer acesso nos casos (i) de
        violação de qualquer das disposições deste Termos de Uso, (ii)
        impossibilidade de verificação da identidade do Usuário ou constatação
        de falsidade em qualquer das informações e/ou declarações por ele
        fornecidas, (iii) prática pelo Usuário de atos fraudulentos ou dolosos
        ou a adoção de qualquer comportamento que, a critério da Transfero, seja
        incompatível com os objetivos da Plataforma ou que possam, de qualquer
        modo, causar danos a terceiros, a Transfero e/ou à Plataforma. Caso o
        cadastro de qualquer Usuário seja suspenso ou cancelado por quaisquer
        das razões previstas neste Termos de Uso, todas as suas ordens de compra
        ou de venda de criptoativos ainda não executadas serão automaticamente
        canceladas.
      </p>
      <p>
        3.11. A qualquer momento, e a exclusivo critério da Transfero, poderá
        ser solicitado aos interessados e/ou a qualquer Usuário o envio de
        documentos comprobatórios para validação das informações fornecidas à
        Plataforma, inclusive cópias de documentos pessoais, comprovantes de
        residência e/ou de renda.
      </p>
      <p>
        3.12. O Usuário autoriza a Transfero a consultar informações e conferir
        dados a seu respeito junto aos órgãos de análise de crédito e demais
        base de dados, incluindo o Sistema de Informações de Crédito do Banco
        Central – SCR, o SERASA, o SPS, bem como quaisquer outros que existam,
        públicos ou privados.
      </p>
      <p>
        3.13. Uma vez que o cadastro tenha sido realizado e aprovado, o Usuário
        passará a ter acesso à Conta, por meio unicamente das Aplicações,
        mediante a utilização do login e senha criados no momento do cadastro. O
        Usuário é o único responsável por sua Conta e por qualquer atividade
        associada a esta Conta. Neste sentido, é proibido o compartilhamento,
        pelo Usuário, de seu login e senha com terceiros, devendo também
        comunicar imediatamente à Transfero no caso de perda, extravio ou furto
        de seu Cartão, login e/ou senha.
      </p>
      <p>
        3.14. A Transfero e a Parceira não serão responsáveis por acessos ou
        movimentações em sua conta nem pelo uso indevido de seu Cartão por
        terceiros, bem como por qualquer dano direto ou indireto que resulte do
        mau uso ou da inabilidade do uso das Aplicações, da Conta ou do Cartão
        pelo Usuário ou por quaisquer terceiros, sendo certo que, havendo
        evidências ou, ainda, meros indícios de uso irregular, inadequado ou
        suspeito do Cartão e/ou da Conta, o Usuário poderá ter seu cadastro
        imediatamente suspenso ou, ainda, cancelado, sem prejuízo das demais
        sanções legais e contratuais
      </p>
      <p>
        3.15. Possibilidades de bloqueio de Conta: a Transfero poderá bloquear a
        sua Conta, os recursos ali mantidos ou o seu Cartão no caso de suspeita
        de: (i) fraudes; (ii) crimes financeiros; (iii) inconsistências
        cadastrais; (iv) operações fora do seu padrão de uso; (v) desrespeito a
        qualquer condição presente no Contrato, em outras contratações
        realizadas com Transfero ou à legislação vigente; (vi) por suspeita de
        fraude em operações recebidas através do Pix ou que seja verificada
        falha operacional nos sistemas das instituições envolvidas na transação;
        (vii) o Usuário deixe de atender a pedido da Tranfero de envio de novos
        documentos ou informações para a comprovação da sua identidade ou de
        atualizações de dados cadastrais; ou (viii) em casos de atraso no
        pagamento de valores devidos pelo Usuário ao Transfero.
      </p>
      <p>
        3.16. O Usuário reconhece que as informações fornecidas voluntariamente
        poderão ser compartilhadas com a Parceira e com a Corretora de Câmbio,
        na medida necessária para disponibilização dos Serviços oferecidos, bem
        como para fins de registro ou qualquer outra finalidade, incluindo, mas
        não se limitando à comercialização de produtos da Transfero.
      </p>
      <h1>4. CARTÃO TRANSFERO</h1>
      <p>
        4.1. Por meio do Cartão Transfero, o Usuário poderá realizar pagamentos,
        no Brasil e/ou exterior, em Estabelecimentos credenciados. O Cartão será
        entregue bloqueado, por medida de segurança e o desbloqueio deverá ser
        realizado por meio das Aplicações, além disso o Usuário ficará
        responsável por conferir os seus dados.
      </p>
      <p>
        4.2. O Usuário deverá se atentar para o regulamento do arranjo de
        pagamento da Mastercard (“Bandeira”), aplicável ao Cartão
        (“Regulamento”), uma vez que cada Bandeira é regida por condições e
        termos específicos.
      </p>
      <p>
        4.3. Ao utilizar o Cartão, o Usuário estará automaticamente submetido
        aos regulamentos específicos da Bandeira, conforme a Lei nº 12.865/2013.
      </p>
      <p>
        4.4. O Usuário poderá contar com o benefício de integrar a Plataforma
        promocional da Mastercard, acessando descontos, ofertas e promoções
        disponibilizadas pela Mastercard e seus parceiros. Para tanto, conforme
        disposto na Política de Privacidade, serão transmitidos os dados
        pessoais do Usuário e número do Cartão do Usuário à Mastercard.
      </p>
      <p>
        4.5. O Usuário é o único responsável pelo uso e guarda do seu Cartão
        físico.{" "}
      </p>
      <p>
        4.6. Em caso de perda, extravio, furto ou roubo do Cartão, o Usuário
        deve entrar em contato imediatamente com qualquer um dos Canais de
        Comunicação. Após seu contato, o acesso e o uso da Conta poderão ser
        temporariamente bloqueados, até que o Usuário receba novo login e senha.
        Um novo Cartão será entregue ao Usuário nos prazos informados pelos
        Canais de Comunicação. Poderão ser cobradas tarifas adicionais para
        gerar um novo Cartão, e, nesta hipótese, tais tarifas serão previamente
        informadas ao Usuário.
      </p>
      <p>
        4.7. Caso o Usuário não reconheça uma operação feita com o seu Cartão,
        deverá entrar em contato com qualquer um dos Canais de Comunicação no
        prazo máximo de 30 (trinta) dias e seguir as orientações para realização
        do procedimento de Chargeback. O procedimento e a documentação exigida
        para o Chargeback seguirão as regras estabelecidas pela Bandeira e pela
        Parceira, em consonância com a legislação aplicável, de forma que a
        aprovação do Chargeback só será efetuada quando comprovado o erro ou
        desacordo comercial e não houver culpa exclusiva do Usuário, nos termos
        das regras supracitadas. Aprovado o Chargeback, o valor será creditado à
        Conta do Usuário, no prazo estabelecido pela Parceira
      </p>
      <p>
        4.8. O Usuário está ciente e de acordo que o procedimento de Chargeback
        somente ocorrerá para transações efetuadas com o uso do Cartão. Não
        serão aceitos procedimentos de Chargeback para transações realizadas por
        meio das aplicações e que tenham sido efetuadas através de login e senha
        corretos.
      </p>
      <h1>5. CORRETORA DE CRIPTOATIVOS</h1>

      <p>
        5.1. A Transfero disponibiliza aos Usuários o serviço de compra, venda e
        conversão de criptoativos, que pode ser acessado por meio das
        Aplicações.{" "}
      </p>
      <p>
        5.2. A Corretora de Criptoativos não será responsável por quaisquer
        perdas, danos, prejuízos e/ou lucros cessantes que possam eventualmente
        ser sofridos por Usuários em decorrência da compra, venda ou conversão
        de ativos digitais por meio de sua plataforma. Eventuais lucros ou
        prejuízos decorrentes de tais transações são de inteira responsabilidade
        dos Usuários.
      </p>
      <p>
        5.3. O Usuário aceita e concorda que toda e qualquer Transação deve ser
        efetuada tão e somente pelo Usuário titular do cadastro e/ou seu
        representante legal, não se responsabilizando a Transfero por transações
        efetuadas por pessoas não autorizadas.
      </p>
      <p>
        5.4. Após a conclusão do cadastro pelo Usuário, a Conta poderá ser
        acessada, consultada e utilizada pelo Usuário. Será através do uso da
        Conta que o usuário irá realizar transações.
      </p>
      <p>
        5.5. O Usuário deverá depositar saldo em Conta (que serão imediatamente
        convertidos para BRZ) para estar apto a emitir ordens de compra ou
        conversão de ativos digitais.
      </p>
      <p>
        5.6. Os BRZ’s serão creditados na Conta e o saldo considerado disponível
        no momento em que a compensação do PIX ou qualquer outra forma de
        transferência bancária aprovada pelo Banco Central do Brasil for
        efetivamente identificada na conta de titularidade da Transfero, o que
        pode ocorrer em até 3 (três) dias úteis.
      </p>
      <p>
        5.7. Para vender, comprar ou converter criptoativos através da Corretora
        de Criptoativos, o Usuário deverá efetuar uma ordem de venda, ordem de
        compra ou ordem de conversão. A realização pelo Usuário de uma das
        ordens de transação será irrevogável e irretratável, para todos os fins
        de direito.
      </p>
      <p>
        5.8. Para adquirir criptoativos, o Usuário deverá incluir na Corretora
        de Criptoativos uma ordem de compra. Para que seja possível emitir uma
        ordem de compra, o Usuário deve ter em sua Conta o valor em BRZ ou
        demais criptoativos equivalente ao valor da compra solicitada.
      </p>
      <p>
        5.9. O Usuário está ciente de que a emissão da ordem de venda representa
        uma proposta válida para todos os fins. Após o aceite, a transação será
        automaticamente efetivada, e não poderá ser desfeita ou modificada,
        devendo o Usuário arcar com tal saldo de criptoativos.
      </p>
      <p>
        5.10. O Usuário poderá solicitar, a qualquer momento, o resgate de seus
        criptoativos, mediante a conversão de seu saldo em Moeda Corrente e a
        subsequente transferência do montante para a conta bancária de sua
        titularidade vinculada ao seu cadastro. A efetivação da transferência de
        recursos poderá ocorrer em até 3 (três) dias úteis, contados da
        solicitação, após validada a transferência. A conta bancária informada,
        além de válida e de titularidade do próprio Usuário, deverá estar no
        mesmo país em que originou os fundos a serem transferidos.
      </p>
      <p>
        5.11. A Corretora de Criptoativos será remunerada quando concluídas e/ou
        efetivadas quaisquer ordens de compra, venda ou conversão, conforme
        taxas de remuneração disponíveis para consulta nas Plataformas.
      </p>
      <p>
        5.12. A Corretora de Criptoativos poderá debitar e/ou reter da Conta do
        Usuário os valores mencionados no item 5.11., bem como as taxas de
        resgates para sua conta bancária e depósitos de BRZ em sua Conta.{" "}
      </p>
      <p>
        5.13. Constitui prerrogativa da Transfero, na hipótese de inadimplemento
        de obrigações por parte do Usuário, reter ou transferir valores,
        compensar créditos de importâncias depositadas, ou liquidar
        antecipadamente determinadas transações, conforme necessário à quitação
        plena de eventuais débitos existentes em nome do Usuário.
      </p>
      <h1>6. TRANSFERO CHECKOUT</h1>
      <p>
        6.1 O Transfero Checkout é um serviço desenvolvido e mantido pela
        Transfero que possibilita que os Estabelecimentos cadastrados possam
        receber em reais os pagamentos efetuados por seus Clientes, também
        Usuários da Plataforma, em criptoativos.
      </p>
      <p>
        6.2. A Transfero disponibiliza o Transfero Checkout como um produto
        multi-channel o qual possibilita negócios online e físicos aceitarem
        pagamentos diretamente em criptoativos. O Transfero Checkout, que pode
        ser utilizado via website, aplicativo móvel (IOS ou Android), ou API,
        permite que os Estabelecimentos cadastrados na Plataforma aceitem
        pagamentos de seus clientes, fornecedores e parceiros com criptoativos
        em apenas duas etapas: (I) o cliente escaneia o QR Code gerado pela
        Plataforma; e (II) confirma a transação.{" "}
      </p>
      <p>
        6.3. A Transfero atuará oferecendo uma solução off ramp por meio da
        Plataforma intermediando as transações realizadas entre os
        Estabelecimentos e seus Clientes. O Transfero Checkout será o
        responsável por converter esses pagamentos recebidos em criptoativos em
        reais, para que o valor integral do produto ou serviço cobrado pelo
        Estabelecimento seja creditado em sua conta bancária em reais.
      </p>
      <p>
        6.4. A Plataforma permite que Estabelecimentos aceitem pagamentos de
        qualquer lugar do mundo, 24 (vinte e quatro) horas, 07 (sete) dias por
        semana, por meio dos principais criptoativos: BRZ; USDT; USDT; BTC; ETH;
        SOL. A Plataforma permite também a realização de pagamentos via PIX,
        mediante a dedução automática de uma taxa a ser cobrada por cada
        transação realizada, diretamente do pagamento efetuado para o
        Estabelecimento e a geração de recibos simplificados pelos produtos ou
        serviços adquiridos por meio de QR Code gerado no Transfero Checkout.
      </p>
      <p>
        6.5. Os Estabelecimentos cadastrados na Plataforma poderão realizar a
        integração da solução: (i) diretamente em seu site por meio da adição de
        snippet de iframe; (ii) por meio da Plataforma; ou (iii) por meio da API
        do Transfero Checkout.{" "}
      </p>
      <p>
        6.6. Os Clientes que desejem efetuar o pagamento para o Estabelecimento
        cadastrado na Plataforma deverão seguir os seguintes passos para efetuar
        a transação:{" "}
      </p>
      <div style={{ overflowX: "scroll" }}>
        <img src="/images/checkout_cap.png" width={900} alt="" />
      </div>
      <p>
        6.7. Ao selecionar a blockchain e o criptoativo a ser utilizada na
        transação, a Plataforma gerará um QR Code com a cotação de mercado
        equivalente em criptoativos ao valor do produto e/ou serviço adquirido
        pelo Cliente.{" "}
      </p>
      <p>
        6.8. O Estabelecimento, que é um Usuário do Serviço, aceita e concorda
        que apenas transações entre a conta de criptoativos do Estabelecimento e
        a conta bancária de mesma titularidade serão aceitas e executadas. Dessa
        forma, a Transfero e Plataformas não se responsabilizarão por transações
        efetuadas para contas de titularidades diversas.
      </p>
      <p>
        6.9. O Estabelecimento está ciente de que é proibido manter mais de um
        cadastro na Plataforma e que, em caso de descumprimento, a Transfero
        poderá cancelar ou suspender todos os cadastros.
      </p>
      <p>
        6.10. O Estabelecimento deverá avisar imediatamente a Transfero, através
        do e-mail relacionamento@transfero.com, em caso de suspeita de acesso
        indevido, ou qualquer atividade que possa dar ensejo a tal.
      </p>
      <p>
        6.11. Após conclusão do cadastro pelo Estabelecimento e a validação de
        sua wallet, a Conta poderá ser acessada, consultada e utilizada pelo
        Estabelecimento. Será através do uso da Conta que o Estabelecimento irá
        realizar transações para sua conta bancária.{" "}
      </p>
      <p>
        6.12. O Estabelecimento deverá receber os pagamentos pelos produtos
        vendidos e/ou serviços prestados aos seus clientes diretamente em
        criptoativos em sua wallet custodiada pela Transfero. Os valores
        recebidos e disponíveis em sua Conta serão segregados de valores
        pertencentes à Transfero, à Plataforma e a demais Estabelecimentos.{" "}
      </p>
      <p>
        6.13. O valor transferido para a wallet estará disponível na Conta do
        Estabelecimento no momento em que a transação blockchain for concluída,
        o que poderá ocorrer em até 02 (dois) minutos após a confirmação na
        blockchain. O tempo de conclusão da transação dependerá do número de
        confirmações necessárias de cada blockchain.
      </p>
      <p>
        6.14. Para efetuar o saque do valor mantido em sua Conta, o
        Estabelecimento deverá realizar na Plataforma uma ordem de
        transferência, informando o montante que deseja transferir para sua
        conta bancária. A realização pelo Estabelecimento de uma ordem de
        transferência será irrevogável e irretratável, para todos os fins de
        direito.
      </p>
      <p>
        6.15. Por outro lado, o Cliente que efetuar o pagamento deverá estar
        ciente que ao conferir a autorização para transação, essa será
        automaticamente efetivada e não poderá ser desfeita ou modificada.{" "}
      </p>
      <p>
        6.16. A Transfero não detém qualquer responsabilidade sobre os produtos
        e/ou serviços ofertados pelo Estabelecimento, bem como pelos preços a
        eles atribuídos, tendo papel de mera mantenedora da Plataforma.
      </p>
      <p>
        6.17. O Estabelecimento poderá a qualquer momento solicitar a
        transferência de seus valores em Moeda Corrente de sua Conta para a
        Conta Bancária de sua titularidade, vinculada inicialmente ao seu
        cadastro na Plataforma. A efetivação de tal transferência de recursos
        poderá ocorrer em até 03 (três) dias úteis, contados de tal solicitação,
        após validada a solicitação de transferência pelos mecanismos de
        controles. A conta bancária informada, além de válida e de titularidade
        do próprio Estabelecimento, deverá estar no mesmo país em que originou
        os fundos a serem transferidos.
      </p>
      <p>
        6.18. A Plataforma será remunerada quando o Cliente efetuar a compra do
        produto e/ou serviço, conforme definido nas regras de remuneração
        disponíveis na Plataforma.
      </p>
      <p>
        6.19. A definição e implementação das normas e procedimentos referentes
        aos reembolsos solicitados pelos Clientes aos Estabelecimentos devem ser
        exclusiva e restritamente estabelecidas pela administração do próprio
        Estabelecimento.{" "}
      </p>
      <p>
        6.20. A Transfero não possui qualquer ingerência ou obrigações
        relacionadas ao procedimento de reembolso de Clientes dos
        Estabelecimentos.
      </p>
      <p>
        6.21. O Estabelecimento será responsável pela origem e legitimidade das
        transações efetuadas no âmbito dos pagamentos realizados pelos seus
        Clientes, incluindo informação acerca de seus valores, para fins de
        recolhimento de possíveis tributos incidentes, respondendo pelos danos e
        prejuízos, diretos ou indiretos, causados à Plataforma e/ou a terceiros,
        em decorrência de fraude, simulação, falsificação e omissão de
        informações ou documentos.
      </p>
      <p>
        6.22. O Estabelecimento será exclusivamente responsável por calcular,
        recolher e contabilizar eventuais tributos aplicáveis às suas operações
        na Plataforma.
      </p>
      <h1>7. CANCELAMENTO DE SERVIÇOS </h1>
      <p>
        7.1. O Usuário poderá, a qualquer momento, solicitar o cancelamento de
        sua Conta, Cartão, ou qualquer Serviço, mediante solicitação realizada
        pelos Canais de Comunicação.
      </p>
      <p>
        7.2. Uma vez solicitado o cancelamento, o Cartão será bloqueado e a
        Conta será definitivamente encerrada dentro do prazo máximo estabelecido
        na regulamentação aplicável, sendo facultado ao Usuário, dentro deste
        prazo: (i) sacar o saldo remanescente; ou (ii) realizar sua
        transferência para outra conta de mesma titularidade.
      </p>
      <p>
        7.3. Informações relacionadas à conta e suas transações bancárias as
        quais a Transfero tenha obrigação legal de manter o histórico
        permanecerão armazenadas em nossos servidores pelo tempo necessário de
        acordo com a legislação aplicável.
      </p>
      <p>
        7.4. A Transfero ou Parceira, quando aplicável, poderão cancelar a
        prestação dos Serviços imediatamente, comunicando o Usuário, nas
        seguintes hipóteses:
      </p>
      <p>
        (i) caso a Conta fique inativa e com saldo zerado por mais de 3 (três)
        meses consecutivos;
      </p>
      <p>
        (ii) caso o Usuário viole qualquer das disposições destes Termos de Uso;
      </p>
      <p>
        (iii) caso sejam verificadas operações fora do padrão de uso, e o
        Usuário deixe de atender ao pedido de envio de documentos para a
        comprovação da regularidade dessas operações;
      </p>
      <p>(iv) em caso de falecimento do Usuário; e</p>
      <p>
        (v) em caso de quaisquer irregularidades observadas que venham a
        conflitar com as legislações aplicáveis de Prevenção à Lavagem de
        Dinheiro e Financiamento do Terrorismo e Lei nº 7.492/1986 (Lei de
        Crimes Financeiros), Lei nº 9.613/1998 (Lei de crimes de lavagem de
        dinheiro ou ocultação de bens) e Lei nº 12.846/2013 (Lei anticorrupção).
      </p>
      <p>
        (vi) Fornecimento de informações e/ou declarações falsas, imprecisas,
        incompletas ou enganosas para a Transfero.
      </p>
      <p>
        7.5. Nas hipóteses acima, o cancelamento poderá abranger todos os
        Serviços prestados.
      </p>
      <p>
        7.6. A prestação dos Serviços, pode, ainda, ser cancelada imotivadamente
        e a qualquer momento, mediante comunicação ao Usuário com, no mínimo, 30
        (trinta) dias de antecedência.
      </p>
      <p>
        7.7. Em caso de suspeita de fraude ou qualquer outra atividade ilícita
        por parte do Usuário, a Transfero poderá, além de recorrer às medidas
        legais cabíveis, no momento efetivo da rescisão, reter eventuais fundos
        em BRZ na Conta do Usuário até a conclusão das respectivas investigações
        e/ou outro procedimento.
      </p>
      <p>
        7.8. Mesmo em caso de rescisão, todas as taxas e remunerações pagas e
        cobranças feitas antes do encerramento não são reembolsáveis.
      </p>
      <h1>8. PROPRIEDADE INTELECTUAL </h1>
      <p>
        8.1. Todos os direitos de propriedade intelectual relativos aos
        Serviços, bem como todas as suas funcionalidades, são de titularidade
        exclusiva da Transfero, inclusive no que diz respeito aos seus textos,
        imagens, layouts, software, códigos, base de dados, gráficos, artigos,
        fotografias e demais conteúdos produzidos direta ou indiretamente pela
        Transfero (“Conteúdo”), quando aplicável. O Conteúdo é protegido pela
        lei brasileira de direitos autorais e de propriedade industrial. É
        proibido usar, copiar, reproduzir, modificar, traduzir, publicar,
        transmitir, distribuir, executar, fazer o upload, exibir, licenciar,
        vender explorar ou fazer engenharia reversa do Conteúdo, para qualquer
        finalidade, sem o consentimento prévio e expresso da Transfero. Qualquer
        uso não autorizado do Conteúdo será considerado como violação dos
        direitos autorais e de propriedade intelectual.
      </p>
      <p>
        8.2. A Transfero, concede ao Usuário uma licença limitada, temporária,
        não exclusiva, não transferível e revogável, para usar os Serviços e as
        Aplicações somente naquilo que seja estritamente necessário para o
        cumprimento das obrigações e exercício dos direitos previstos nestes
        Termos de Uso. É proibido ao Usuário ceder, sublicenciar, doar, alienar,
        alugar, transmitir ou transferir os seus direitos e obrigações a
        terceiros, total ou parcialmente, sob quaisquer modalidades, a qualquer
        título, bem como é vedado adaptar, descompilar, desmontar ou executar
        engenharia reversa das Aplicações, de forma que viole os direitos aqui
        previstos.
      </p>
      <p>
        8.3. A Transfero reserva-se o direito de atualizar a Plataforma sempre
        que desejar, bem como deixar de comercializá-la, atualizá-la, ou mesmo
        descontinuá-la a qualquer tempo, observado um aviso prévio de 30
        (trinta) dias, para o caso de descontinuação a Plataforma.
      </p>
      <p>
        8.4. Em caso de violação pelo Usuário de qualquer direito de propriedade
        intelectual da Transfero, o Usuário assume toda e qualquer
        responsabilidade de caráter civil e/ou penal advindos de referida
        violação, sem prejuízo do direito da Transfero de cancelar o cadastro do
        Usuário e seu acesso à Plataforma.
      </p>
      <p>
        8.5. Estes Termos de Uso são também protegidos pelas disposições da Lei
        nº 9.609/98 e Lei nº 9.610/98, ficando os infratores sujeitos às penas
        previstas no art. 12 da Lei 9.609/98, sem prejuízo da responsabilidade
        civil pelos danos eventualmente causados pelo uso e distribuição de
        cópias não autorizadas da Plataforma ou por qualquer outra violação aos
        direitos decorrentes da propriedade intelectual que protege estes Termos
        de Uso e a Plataforma.{" "}
      </p>
      <h1>9. PROTEÇÃO DE DADOS</h1>
      <p>
        9.1. No processo de cadastro do Usuário e durante a prestação de
        Serviços, coletamos e usamos certas informações de identificação do
        Usuário para única e exclusiva finalidade de prestação dos Serviços da
        Plataforma. A Transfero envidará os melhores esforços para proteger tais
        informações e tomará as precauções necessárias para manter a
        confidencialidade das informações sensíveis compartilhadas no processo
        de cadastro. Para maiores esclarecimentos, sugerimos que seja consultada
        a Política de Privacidade da Transfero.{" "}
      </p>
      <p>
        9.2. O ato de preencher e submeter o cadastro mencionado em 9.1.
        concederá à Transfero acesso aos dados do Usuário. Ao submeter tais
        dados à Plataforma, o Usuário autoriza que a Transfero faça uso de tais
        dados na forma autorizada por Lei e por estes Termos e Condições. Para
        mais informações sobre coleta de dados do Usuário, por favor, observe o
        disposto em nossa Política da Privacidade.{" "}
      </p>
      <p>
        9.2.1. Além do formulário, dados de cada Usuário podem ser obtidos
        quando: (i) o Usuário navega ou faz transações na Plataforma; (ii) o
        Usuário compartilha informações com desenvolvedores ou com membros da
        Plataforma; (iii) o Usuário entra em contato com canais de atendimento
        da Plataforma; (iv) informações sobre o Usuário são compartilhadas com a
        Plataforma por meio de parceiros externos; (v) são compartilhadas com a
        Plataforma informações por meio de redes sociais ou provedores de
        informações; (vi) são recebidas ou compartilhadas informações de
        autoridades governamentais, inclusive Receita Federal.
      </p>
      <p>
        9.3. Os dados fornecidos por cada Usuário são protegidos pela Lei Geral
        de Proteção de Dados (LGPD) e em conformidade com as regulamentações
        emitidas pela ANPD e serão tratados de acordo com o disposto na Política
        de Privacidade.
      </p>
      <p>
        9.4. As informações acima poderão ser utilizadas pela Transfero para
        fins de serviços administrativos, de marketing e de suporte ao cliente.
      </p>
      <p>
        9.5. O Usuário poderá retirar seu consentimento a qualquer momento,
        encerrando sua conta na Plataforma ou entrando em contato com o suporte
        por meio do email relacionamento@transfero.com. Caso o Usuário retire
        seu consentimento para uso de suas informações pessoais, a Transfero irá
        suspender seu fornecimento do serviço Transfero Checkout. Entretanto, a
        Transfero poderá reter e continuar a processar as informações pessoais
        do Usuário se necessário para cumprimento de disposições legais,
        conforme disciplinado no art. 7° da Lei Geral de Proteção de Dados (Lei
        nº 13.709/18).
      </p>
      <p>
        9.6. Em caso de dúvidas com relação ao tratamento de seus dados
        pessoais, solicitamos que o titular entre em contato para o
        esclarecimento por meio do seguinte endereço eletrônico:
        <a> privacidade@transfero.com</a>.{" "}
      </p>
      <p>
        9.7. Para o exercício dos direitos previstos na Lei Geral de Proteção de
        Dados, solicita-se que o titular de dados pessoais entre em contato com
        o encarregado pelo tratamento dos dados pessoais da Transfero, por meio
        do Portal da Privacidade ou pelo endereço eletrônico competente
        (privacidade@transfero.com).
      </p>
      <h1>10. LIMITAÇÕES DE RESPONSABILIDADE </h1>
      <p>
        10.1. A Transfero não é responsável pelas transações realizadas pelos
        Usuários por meio da Conta, seja por meio do Cartão, Corretora de
        Criptoativos ou junto a Estabelecimentos.
      </p>
      <p>
        10.2. A Transfero não se responsabiliza por quaisquer práticas
        realizadas pelos Usuários e/ou suas decisões, e quaisquer
        irregularidades ou ilegalidades perpetradas por Usuários, sejam elas de
        ordem administrativa, negocial, gerencial ou de outra natureza.
      </p>
      <p>
        10.3. A Transfero não será responsabilizada em nenhuma hipótese pela
        ocorrência ou realização de quaisquer dos eventos e/ou fatos abaixo
        narrados:{" "}
      </p>
      <p>
        (i) perdas e danos, lucros cessantes ou danos emergentes, cujas causas
        possam ser atribuídas, de forma direta ou indireta a qualquer transação
        realizada por Usuário, bem como resultados financeiros decorrentes
        destas transações, sejam eles positivos ou negativos;
      </p>
      <p>
        (ii) qualquer falha, interrupção ou indisponibilidade técnica ocorrida
        por fatos imputáveis a terceiros na Plataforma, incluindo, mas não se
        limitando a, falha que impossibilite a realização de transações ou a
        utilização da Plataforma pelo Usuário;
      </p>
      <p>
        (iii) eventos de caso fortuito ou força maior que prejudiquem a
        Plataforma;
      </p>
      <p>
        (iv) atos culposos ou dolosos praticados por terceiros e/ou Usuários;{" "}
      </p>
      <p>
        (v) transações realizadas por Usuário com base em informações incorretas
        obtidas de quaisquer terceiros;
      </p>
      <p>
        (vi) interrupção de sistemas, internet, rede bancária e/ou quaisquer
        outros sistemas de que a Plataforma necessite para suas operações, ou
        para efetivar qualquer transação, ou levar à frente qualquer ordem de
        compra, venda e/ou conversão.
      </p>
      <h1>11. RESPONSABILIDADE E DECLARAÇÕES DO USUÁRIO</h1>
      <p>11.1. É de responsabilidade do Usuário: </p>
      <p>
        (i) manter seguro o ambiente de seus dispositivos de acesso à
        Plataforma, valendo-se de ferramentas específicas para tanto, tais como
        softwares antivírus, de modo a contribuir para a prevenção de riscos
        eletrônicos;{" "}
      </p>
      <p>
        (ii) utilizar sistemas operacionais atualizados e eficientes para a
        plena utilização da Plataforma; e{" "}
      </p>
      <p>
        (iii) equipar-se e responsabilizar-se pelos dispositivos de hardware
        necessários para o acesso à Plataforma, bem como pelo acesso desses à
        Internet.
      </p>
      <p>
        11.2. Por meio de seu simples aceite a esses Termos de Uso, o Usuário se
        compromete a:
      </p>
      <p>
        a. não divulgar a terceiros seu login e senha de acesso, bem como a não
        permitir o uso de sua Conta por terceiros, sendo o usuário o único
        responsável por quaisquer movimentações que sejam feitas a partir de
        seus dados cadastrais;
      </p>
      <p>
        b. fornecer informações exatas em seu cadastro, bem como manter tais
        informações sempre atualizadas, sendo o único e exclusivo responsável
        pelo conteúdo informado; e
      </p>
      <p>
        c. não inserir conteúdo e/ou código ou, então, alterar ou interferir na
        forma como qualquer página é disponibilizada no site ou nas Plataformas.
      </p>
      <p>11.3 Declara, sob as penas da lei, que:</p>
      <p>
        (i) não cometeu, ou está cometendo e/ou cometerá atos que possam ser
        interpretados como criminosos segundo a Lei nº 7.492/1986 (Lei de crimes
        financeiros), Lei nº 9.613/1998 (Lei de crimes de lavagem de dinheiro ou
        ocultação de bens) e Lei nº 12.846/2013 (Lei anticorrupção);{" "}
      </p>
      <p>
        (ii) está ciente de que, diante de suspeitas de práticas de atos de
        crimes financeiros, lavagem de dinheiro, de corrupção, operação ilegal
        de câmbio, evasão de divisas, financiamento ao terrorismo e/ou qualquer
        outra prática ilegal, a Plataforma poderá (a) enviar pedido formal de
        explicações sobre o assunto; (b) se recusar a levar à frente qualquer
        transação; (c) cancelar meu registro; e (d) tomar todas as medidas
        legais recomendáveis, conforme a natureza da prática irregular
        eventualmente adotada;
      </p>
      <p>
        (iii) os recursos financeiros transacionados e utilizados na Plataforma
        foram obtidos de forma lícita, e os recursos eventualmente auferidos em
        transações utilizando a Plataforma terão destinação lícita;
      </p>
      <p>
        (iv) recebeu todas as informações que julga relevantes e/ou necessárias
        para o consentimento e aceitação dos Termos e Condições, inclusive
        quanto aos direitos e obrigações assumidos no âmbito desta declaração;
      </p>
      <p>
        (v) entende que, em decorrência de mudanças legislativas e/ou regras da
        própria Plataforma, poderá vir a ter que assinar outros instrumentos
        e/ou prestar outras informações e documentos, das atividades e/ou
        pessoas relacionadas;
      </p>
      <p>
        (vi) assume responsabilidade civil e/ou criminal (conforme o caso) pelas
        informações prestadas à Plataforma;
      </p>
      <p>
        (vii) não é réu em qualquer processo de natureza criminal, seja no
        Brasil ou no exterior;
      </p>
      <p>
        (viii) reconhece não haver relação de consumo e/ou equiparável a relação
        de consumo entre você e a Plataforma.
      </p>
      <h1>12.VIGÊNCIA</h1>
      <p>
        12.1. O Usuário poderá utilizar a Plataforma por tempo indeterminado e a
        Transfero poderá, de tempos em tempos, fazer modificações no presente
        Termo.
      </p>
      <h1>13. DISPOSIÇÕES GERAIS</h1>
      <p>
        13.1. Estes Termos de Uso poderão sofrer alterações, periódicas ou não.
        Nestes casos, o Usuário será informado sobre tais alterações, e deverá
        apresentar seu aceite a tais alterações, sob risco de interrupção de uso
        da Plataforma, ou cancelamento do seu cadastro.
      </p>
      <p>
        13.1.1. Caso o Usuário não aceite os novos Termos de Uso ou fique
        impossibilitado de acessar às Aplicações pela incompatibilidade com suas
        versões mais recentes, ele deverá entrar em contato com os Canais de
        Comunicação, informados ao fim deste Termo de Uso, para providências de
        suporte na atualização das Aplicações ou movimentação do saldo de sua
        Conta para outra conta de mesma titularidade.
      </p>
      <p>
        13.2. Caso qualquer disposição destes Termos de Uso seja considerada
        inexequível ou inválida, essa disposição será isoladamente invalidada e
        não atingirá o restante das disposições destes Termos de Uso. A
        disposição destes Termos de Uso considerada inexequível ou inválida
        deverá ser substituída por outra com os mesmos objetivos técnicos e/ou
        econômicos, com a maior brevidade possível.
      </p>
      <p>
        13.3. O Usuário declara compreender os riscos associados aos
        criptoativos, sendo este um investimento que envolve alto grau de risco.
        O investimento em criptoativos deve ser feito por investidores
        experientes e de perfil arrojado, bem como com capacidade financeira
        para arcar com eventuais perdas que possam ocorrer em razão dos riscos
        inerentes às operações com criptoativos.
      </p>
      <p>
        13.4. As transações de criptoativos são irreversíveis e, portanto, as
        perdas devido a transações fraudulentas ou acidentais podem não ser
        recuperáveis.
      </p>
      <p>
        13.5. Comunicação ao BACEN. O Usuário desde já concorda que as Partes
        comuniquem ao Banco Central do Brasil, ao Conselho de Controle de
        Atividades Financeiras ou outros órgãos que a legislação previr, as
        operações que possam estar configuradas na Lei 9.613/98 (que dispõe
        sobre os crimes de lavagem ou ocultação de bens, direitos e valores) e
        demais disposições legais pertinentes à matéria.
      </p>
      <p>
        13.6. Cessão. A Transfero poderá, a qualquer momento, ceder quaisquer de
        seus direitos e obrigações previstos nestes Termos de Uso a qualquer
        pessoa, física ou jurídica, mediante simples notificação prévia ao
        Usuário, ficando desde já ressalvado que a cessionária continuará a
        cumprir com todas as obrigações assumidas pela Transfero, bem como
        substituir a Parceira, conforme o caso.
      </p>
      <h1>14. LEGISLAÇÃO APLICÁVEL E FORO</h1>
      <p>
        14.1. Os presentes Termos de Uso serão regidos pelas leis da República
        Federativa do Brasil. Serão aplicáveis a este Termos de Uso os usos e
        costumes adotados, praticados e aceitos no direito contratual e nas
        relações dos mercados financeiro e de capitais brasileiro.
      </p>
    </div>
  );
}
