import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, type CurrencyState } from ".";

export const currencyReducer = {
  clearCurrency: () => {
    return initialState;
  },
  updateCurrency: (_: CurrencyState, action: PayloadAction<CurrencyState>) => {
    return action.payload;
  },
};
