import { ReactNode } from "react";
import Tippy from "@tippyjs/react";
import { Placement } from "tippy.js";

import "tippy.js/dist/tippy.css";
import styles from "./index.module.scss";

type TooltipProps = {
  children: any;
  content: ReactNode;
  placement?: Placement;
  onShow?(): void;
  onHide?(): void;
  element?: "div" | "span";
};

export default function Tooltip({
  children,
  content,
  placement = "top",
  onShow,
  onHide,
  element = "div",
}: TooltipProps) {
  const getClass = `Tooltip ${styles.container}`;
  const getTooltip = () => (
    <Tippy
      content={content}
      trigger="mouseenter focus click"
      placement={placement}
      interactive={true}
      onShow={onShow}
      onHide={onHide}
    >
      <div>{children}</div>
    </Tippy>
  );

  if (element === "span") {
    return <span className={getClass}>{getTooltip()}</span>;
  }

  return <div className={getClass}>{getTooltip()}</div>;
}
