import DropdownLanguage from "@/widgets/DropdownLanguage";
import * as S from "./styles";
import router from "next/router";

const MobileHeader = () => {
  return (
    <S.MobileHeaderContainer>
      <S.LogoArea onClick={() => router.push("/")}>
        <img src="/images/logo-complete.svg" alt="" />
      </S.LogoArea>
      <S.LanguageArea>
        <DropdownLanguage isLogged={false} />
      </S.LanguageArea>
    </S.MobileHeaderContainer>
  );
};

export default MobileHeader;
