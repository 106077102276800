import { ISession } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

type SignUpParamType = {
  username: string;
  password: string;
  confirmationPassword: string;
  confirmationCode: string;
};

type VerifySecurityCodeBody = {
  email?: string;
  phone?: string;
  securityCode: string;
};

const AuthApi = {
  forgotPassword: async (how: string, username: string) => {
    try {
      const body = {
        email: "",
        phone: "",
      };

      if (how === "phone") {
        body.phone = username;
      } else {
        body.email = username;
      }

      const response = await api.request({
        url: "/auth/forgot",
        data: body,
        method: "POST",
      });
      return response.data as string;
    } catch (error: any) {
      sendMessageSentry(
        `Forgot Password Error: ${username}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "sendSecurityCode");
    }
  },
  sendSecurityCode: async (how: string, username: string) => {
    try {
      const body = {
        email: "",
        phone: "",
      };

      if (how === "phone") {
        body.phone = username;
      } else {
        body.email = username;
      }

      const response = await api.request({
        url: "/auth/security-code",
        data: { ...body, language: navigator.language.split("-")[0] || "pt" },
        method: "POST",
      });
      return response.data as string;
    } catch (error: any) {
      sendMessageSentry(
        `Security Code Error: ${username}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "sendSecurityCode");
    }
  },
  verifySecurityCode: async (
    how: string,
    username: string,
    securityCode: string
  ) => {
    try {
      const body: VerifySecurityCodeBody = {
        email: "",
        phone: "",
        securityCode,
      };

      if (how === "phone") {
        body.phone = username;
        delete body.email;
      } else {
        body.email = username;
        delete body.phone;
      }

      const response = await api.request({
        url: "/auth/verify-security-code",
        data: body,
        method: "POST",
      });
      return response.data as string;
    } catch (error: any) {
      sendMessageSentry(
        `Verify security code Error: ${username}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "verifySecurityCode");
    }
  },
  signUp: async (data: SignUpParamType) => {
    try {
      const response = await api.request({
        url: "/auth/sign-up",
        data,
        method: "POST",
      });
      return response.data as ISession;
    } catch (error: any) {
      sendMessageSentry(
        `Sign Up Error: ${error?.message}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "signUp");
    }
  },
};

export default AuthApi;
