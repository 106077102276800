import styled from "styled-components";

export const InfoAssetsButtonContainer = styled.div`
  height: 2rem;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-family: Karla;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1rem;
  text-align: left;
  color: #4d4dff;
  background-color: #e7e7ff;
`;
