import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { IApiError, IFavorite } from "@/models";
import { FavoritesApi } from "@/apis";
import { FavoriteState } from ".";

export const fetchFavorites = createAsyncThunk(
  "fetch/favorites",
  async (_, { rejectWithValue }) => {
    try {
      return (await FavoritesApi.getFavorites()) as IFavorite[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const favoriteExtraReducerBuilder = (
  builder: ActionReducerMapBuilder<FavoriteState>
) => {
  // fetch/favorite
  builder.addCase(fetchFavorites.pending, (state) => {
    state.favorites.error = null;
    state.favorites.loading = true;
  });
  builder.addCase(fetchFavorites.fulfilled, (state, action) => {
    state.favorites.data = action.payload as IFavorite[];
    state.favorites.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.favorites.error = null;
    state.favorites.loading = false;
  });
  builder.addCase(fetchFavorites.rejected, (state, action) => {
    state.favorites.data = null;
    state.favorites.error = action.payload as IApiError;
    state.favorites.loading = false;
    state.favorites.lastUpdate = null;
  });
};
