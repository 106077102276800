export default function transportServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M17.0833 25.8337V27.667C17.0833 27.9003 17.0833 28.017 17.0379 28.1062C16.998 28.1846 16.9342 28.2483 16.8558 28.2882C16.7667 28.3337 16.65 28.3337 16.4167 28.3337H14.8333C14.6 28.3337 14.4833 28.3337 14.3942 28.2882C14.3158 28.2483 14.252 28.1846 14.2121 28.1062C14.1667 28.017 14.1667 27.9003 14.1667 27.667V25.8337M25.8333 25.8337V27.667C25.8333 27.9003 25.8333 28.017 25.7879 28.1062C25.748 28.1846 25.6842 28.2483 25.6058 28.2882C25.5167 28.3337 25.4 28.3337 25.1667 28.3337H23.5833C23.35 28.3337 23.2333 28.3337 23.1442 28.2882C23.0658 28.2483 23.002 28.1846 22.9621 28.1062C22.9167 28.017 22.9167 27.9003 22.9167 27.667V25.8337M12.5 20.0003H27.5M12.5 14.5837H27.5M15.4167 22.917H16.6667M23.3333 22.917H24.5833M16.5 25.8337H23.5C24.9001 25.8337 25.6002 25.8337 26.135 25.5612C26.6054 25.3215 26.9878 24.939 27.2275 24.4686C27.5 23.9339 27.5 23.2338 27.5 21.8337V15.667C27.5 14.2669 27.5 13.5668 27.2275 13.032C26.9878 12.5616 26.6054 12.1792 26.135 11.9395C25.6002 11.667 24.9001 11.667 23.5 11.667H16.5C15.0999 11.667 14.3998 11.667 13.865 11.9395C13.3946 12.1792 13.0122 12.5616 12.7725 13.032C12.5 13.5668 12.5 14.2669 12.5 15.667V21.8337C12.5 23.2338 12.5 23.9339 12.7725 24.4686C13.0122 24.939 13.3946 25.3215 13.865 25.5612C14.3998 25.8337 15.0999 25.8337 16.5 25.8337Z"
        stroke="#5D6B98"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
