/* eslint-disable no-unused-vars */
import { useEffect, useRef } from "react";
import { updateIsMenuOpen, useBase } from "@/store/features/base";
import { scrollIntoView, toggleClassToRootElements } from "@/utils/uiHelpers";
import { BaseLayoutProps } from "./types";

import { useRouter } from "next/router";
import * as S from "./styles";
import { ToastContainer } from "react-toastify";
import BottomButtons from "../BottomButtons";
import Backdrop from "../Backdrop";
import { useCustomer } from "@/store/features/customer/customer-query";

export default function BaseLayout({
  children,
  menu,
  header,
  onFaq,
  footer,
}: BaseLayoutProps) {
  const { baseState, baseDispatch } = useBase();
  const { isScrolled, isMenuOpen } = baseState;
  const { isLoggedIn } = useCustomer();
  const asideContentRef = useRef<HTMLDivElement>(null);

  const route = useRouter();

  const isCardShowcaseRoute = route.pathname.includes("cardshowcase");

  useEffect(() => {
    toggleClassToRootElements("hide-scroll", isMenuOpen);

    if (isMenuOpen) {
      scrollIntoView(asideContentRef, true);
    }
  }, [isMenuOpen]);

  return (
    <S.ContentBaseLayout $isMenuOpen={isMenuOpen} $isScrolled={isScrolled}>
      <S.Header $hasBorder={isLoggedIn}>
        <S.ContentHeader
          $hasContentHeader={
            isLoggedIn ? "contentHeader" : "contentHeaderLogin"
          }
        >
          {header}
        </S.ContentHeader>
      </S.Header>
      <S.Content>
        <S.Main>
          {isLoggedIn && !isCardShowcaseRoute && (
            <S.Nav>{!route.pathname.includes("card") ? menu : ""}</S.Nav>
          )}

          <S.ContentMain
            $isLoggedIn={
              isLoggedIn
                ? "contentChildrenAndHeader"
                : "contentChildrenAndHeaderLogin"
            }
          >
            {children}
          </S.ContentMain>
        </S.Main>
        <S.Footer $isLogged={isLoggedIn}>{footer}</S.Footer>
      </S.Content>

      <BottomButtons onFaqClick={onFaq} />

      {isMenuOpen && (
        <Backdrop
          onClick={() => {
            baseDispatch(updateIsMenuOpen(false));
          }}
          zIndex={900}
        />
      )}
      <ToastContainer
        autoClose={5000}
        closeOnClick={false}
        hideProgressBar={true}
        closeButton={false}
      />
    </S.ContentBaseLayout>
  );
}
