import { useCallback, useEffect, useRef, useState } from "react";
import { Tooltip } from "@/widgets";

import styles from "./index.module.scss";

type EllipsisProps = {
  content: string;
  prefix?: string;
  sufix?: string;
};

export default function Ellipsis({
  content,
  prefix = "",
  sufix = "",
}: EllipsisProps) {
  const [hasEllipsis, setHasEllipsis] = useState(false);
  const refElement = useRef<HTMLDivElement>(null);

  const toggleEllipsis = useCallback(() => {
    if (refElement.current?.offsetWidth && refElement.current?.scrollWidth) {
      setHasEllipsis(
        refElement.current?.offsetWidth < refElement.current?.scrollWidth
      );
    }
  }, []);

  const handleOnResize = useCallback(() => {
    toggleEllipsis();
  }, []);

  useEffect(() => {
    toggleEllipsis();

    window.addEventListener("resize", handleOnResize);

    return () => {
      window.removeEventListener("resize", handleOnResize);
    };
  }, []);

  return (
    <div className={`Ellipsis ${styles.container}`}>
      <div className={styles.hidden}>
        <div ref={refElement}>{content}</div>
      </div>
      <div className={styles.content}>
        {hasEllipsis && (
          <Tooltip
            element="div"
            content={
              <>
                {prefix} {content} {sufix}
              </>
            }
            placement="auto"
          >
            {content}
          </Tooltip>
        )}
        {!hasEllipsis && <>{content}</>}
      </div>
    </div>
  );
}
