import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { favoriteSelector } from ".";

export function useFavorite() {
  const favoriteState = useSelector(favoriteSelector);
  const favoriteDispatch = useDispatch<AppDispatch>();

  return { favoriteState, favoriteDispatch };
}
