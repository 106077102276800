import styled from "styled-components";

export const SwitcherContainer = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 45px;
  padding: 9px;
  border-radius: 8px;
  background-color: var(--light-80);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;
export const SwitcherList = styled.span`
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  color: #5d6b98;
`;