import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
  useCallback,
  useState,
} from "react";
import { Collapse } from "react-collapse";
import Link from "next/link";
import { Icon } from "@/widgets";

import styles from "./index.module.scss";

type ArrowsType = "up" | "right" | "down" | "left" | undefined;

type MenuItemProps = {
  label: any;
  href?: string;
  arrow?: ArrowsType;
  children?: any;
  isOpen?: boolean;
  isHoverable?: boolean;
  isActive?: boolean;
  onClick?(): void;
};

const getArrow = (arrow: ArrowsType) => {
  if (!arrow) {
    return;
  }
  return (
    <div className={`${styles.arrow} ${styles[arrow]}`}>
      <Icon icon="arrowDown" />
    </div>
  );
};

export function MenuItem({
  label,
  href,
  arrow,
  children,
  isOpen = false,
  isHoverable = true,
  isActive = false,
  onClick,
  ...props
}: MenuItemProps &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  ButtonHTMLAttributes<HTMLButtonElement> &
  HTMLAttributes<HTMLDivElement>) {
  if (children && href) {
    throw "The MenuItem with href attribute cannot have children.";
  }

  const hoverableCss = isHoverable ? "hoverable" : "";
  const buttonClass = `${styles.button} ${styles[hoverableCss]} ${isActive ? styles.isActive : ""
    }`;
  const [open, setOpen] = useState(isOpen);

  const handleOnToggle = useCallback(() => {
    if (onClick) {
      onClick();
    }
    setOpen(!open);
  }, [open]);

  return (
    <div className={`MenuItem ${styles.menuItem}`}>
      {href && !isActive && (
        <Link
          className={`${buttonClass}`}
          href={href}
          onClick={handleOnToggle}
          {...props}
        >
          <div>{label}</div>
          {getArrow(arrow)}
        </Link>
      )}
      {href && isActive && (
        <div className={`${buttonClass}`} {...props}>
          <div>{label}</div>
          {getArrow(arrow)}
        </div>
      )}
      {!href && (
        <div>
          <button
            type="button"
            className={`${buttonClass} ${open ? styles.isOpen : ""}`}
            onClick={handleOnToggle}
            {...props}
          >
            <div>{label}</div>
            {getArrow("down")}
          </button>
          {children && (
            <div className={`${styles.children}`}>
              <Collapse isOpened={open}>{children}</Collapse>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

type MenuProps = {
  children: any;
};

export default function Menu({ children }: MenuProps) {
  return <nav className={`Menu ${styles.container}`}>{children}</nav>;
}
