export default function copyNew() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2029_5261)">
        <path
          d="M6.12496 1.16862C5.73118 1.17396 5.49478 1.19676 5.30364 1.29415C5.08411 1.406 4.90564 1.58448 4.79379 1.804C4.69639 1.99515 4.67359 2.23155 4.66826 2.62533M11.375 1.16862C11.7687 1.17396 12.0051 1.19676 12.1963 1.29415C12.4158 1.40601 12.5943 1.58448 12.7061 1.80401C12.8035 1.99515 12.8263 2.23155 12.8317 2.62532M12.8317 7.87532C12.8263 8.2691 12.8035 8.5055 12.7061 8.69665C12.5943 8.91617 12.4158 9.09465 12.1963 9.2065C12.0051 9.3039 11.7687 9.32669 11.375 9.33203M12.8333 4.66699L12.8333 5.83366M8.16666 1.16699L9.33327 1.16699M3.03329 12.8337L7.46662 12.8337C8.12002 12.8337 8.44672 12.8337 8.69628 12.7065C8.9158 12.5946 9.09428 12.4162 9.20613 12.1967C9.33329 11.9471 9.33329 11.6204 9.33329 10.967L9.33329 6.53366C9.33329 5.88027 9.33329 5.55357 9.20613 5.30401C9.09428 5.08448 8.9158 4.90601 8.69628 4.79415C8.44672 4.66699 8.12002 4.66699 7.46663 4.66699L3.03329 4.66699C2.3799 4.66699 2.0532 4.66699 1.80364 4.79415C1.58411 4.906 1.40564 5.08448 1.29378 5.304C1.16663 5.55357 1.16663 5.88026 1.16663 6.53366L1.16662 10.967C1.16662 11.6204 1.16662 11.9471 1.29378 12.1966C1.40564 12.4162 1.58411 12.5946 1.80364 12.7065C2.0532 12.8337 2.3799 12.8337 3.03329 12.8337Z"
          stroke="#5D6B98"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2029_5261">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
