import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { IAsyncState, IPair } from "@/models";
import { tradeReducer } from "./reducers";
import { useTrade } from "./hooks";
import { fecthTrades, tradeExtraReducerBuilder } from "./thunks";

interface AsyncTrade extends IAsyncState {
  data: null | IPair[];
}

export interface TradeState {
  pairs: AsyncTrade;
}

export const initialState: TradeState = {
  pairs: {
    data: null,
    lastUpdate: null,
    error: null,
    loading: false,
  },
};

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: tradeReducer,
  extraReducers: tradeExtraReducerBuilder,
});

export const { clearTrade, updateTrade } = tradeSlice.actions;

export { useTrade, fecthTrades };

export const tradeSelector = (state: RootState) => state.trade;

export default tradeSlice.reducer;
