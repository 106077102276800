export default function companiesServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M11.25 26.875H28.75"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.25 26.875V13.125C21.25 12.9592 21.1842 12.8003 21.0669 12.6831C20.9497 12.5658 20.7908 12.5 20.625 12.5H13.125C12.9592 12.5 12.8003 12.5658 12.6831 12.6831C12.5658 12.8003 12.5 12.9592 12.5 13.125V26.875"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5 26.875V18.125C27.5 17.9592 27.4342 17.8003 27.3169 17.6831C27.1997 17.5658 27.0408 17.5 26.875 17.5H21.25"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3125 15.625H17.5"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.25 20.625H18.4375"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3125 23.75H17.5"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0625 23.75H24.6875"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.0625 20.625H24.6875"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
