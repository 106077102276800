import { IMarket } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

const MarketApi = {
  getMarkets: async () => {
    try {
      const response = await api.request({
        url: "/markets",
        method: "GET",
      });
      return response.data as IMarket[];
    } catch (error: any) {
      sendMessageSentry(
        `Markets Error ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getMarkets");
    }
  },
};

export default MarketApi;
