export default function transactionSuccess() {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="1.31641" width="15" height="15" rx="7.5" fill="#E5FCF0" />
      <rect x="1" y="1.31641" width="15" height="15" rx="7.5" stroke="white" />
      <path
        d="M5.5 9.14941L7.5 11.1494L11.5 7.14941"
        stroke="#0BB193"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
