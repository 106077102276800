import scssVariables from "@/styles/variables.module.scss";

export default function company() {
  return (
    <svg viewBox="0 0 30 28" fill="none">
      <path
        d="M29.0567 25.709H27.1887V8.82244C27.1887 6.19605 25.6905 5.06782 24.7472 4.88286L18.7546 3.0148V0.94328C18.7546 0.573366 18.5697 0.388409 18.3847 0.184957C18.1998 -1.72254e-07 17.8298 0 17.6264 0L5.06782 3.56967C5.06782 3.56967 2.81134 4.12454 2.81134 7.13933V25.709H0.94328C0.388409 25.709 0 26.0789 0 26.6523C0 27.2072 0.369914 27.5956 0.94328 27.5956H29.0567C29.6116 27.5956 30 27.2256 30 26.6523C30 26.0789 29.6301 25.709 29.0567 25.709ZM16.8681 25.709H4.67941V7.13933C4.67941 5.82614 5.43773 5.45623 5.62269 5.45623L16.8681 2.27497V25.709ZM25.3206 25.709H18.7546V4.88286L24.5438 6.80641L24.6732 6.8619C24.6732 6.8619 25.2466 7.10234 25.3391 8.82244V25.709H25.3206ZM9.93218 6.14057H8.06412V8.00863H9.93218V6.14057ZM14.2417 6.14057H12.3736V8.00863H14.2417V6.14057ZM9.93218 10.2281H8.06412V12.0962H9.93218V10.2281ZM14.2417 10.2281H12.3736V12.0962H14.2417V10.2281ZM9.93218 14.3157H8.06412V16.1837H9.93218V14.3157ZM14.2417 14.3157H12.3736V16.1837H14.2417V14.3157ZM9.93218 18.3847H8.06412V20.2713H9.93218V18.3847ZM14.2417 18.3847H12.3736V20.2713H14.2417V18.3847ZM9.93218 22.4723H8.06412V24.3403H9.93218V22.4723ZM14.2417 22.4723H12.3736V24.3403H14.2417V22.4723ZM21.1961 11.6338H23.0641V9.76572H21.1961V11.6338ZM21.1961 15.9433H23.0641V14.0752H21.1961V15.9433ZM21.1961 20.0493H23.0641V18.1813H21.1961V20.0493ZM21.1961 24.2109H23.0641V22.3428H21.1961V24.2109Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
