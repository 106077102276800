/* eslint-disable no-unused-vars */
import Link from "next/link";
import {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  ReactNode,
  MouseEvent,
} from "react";
import { Spinner, Icon } from "@/widgets";
import { AvailableIconsType } from "@/widgets/Icons";

import styles from "./index.module.scss";

type ButtonProps = {
  element?: "button" | "a";
  href?: string;
  children: ReactNode;
  dataQA?: string;
  variant?:
    | "default"
    | "default-outline"
    | "secondary"
    | "secondary-outline"
    | "tertiary"
    | "danger"
    | "danger-outline"
    | "light";
  type?: "submit" | "button";
  size?: "big" | "medium" | "small";
  isLoading?: boolean;
  isBlock?: boolean;
  icon?: AvailableIconsType | undefined;
  iconPosition?: "left" | "right";
  onClick?(param: MouseEvent<HTMLButtonElement>): void;
};

export default function Button({
  element = "button",
  href,
  children,
  variant = "default",
  type = "button",
  isLoading = false,
  isBlock = false,
  size = "big",
  icon,
  iconPosition = "left",
  onClick,
  ...props
}: ButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>) {
  const containerClass = `Button ${styles.container} ${styles[variant]} ${
    styles[size]
  } ${isBlock ? styles.isBlock : ""} ${styles[iconPosition]}`;

  if (isLoading) {
    return (
      <div className={`${containerClass} ${styles.isLoading}`}>
        <Spinner size="small" />
      </div>
    );
  }

  if (element === "a") {
    if (href) {
      return (
        <Link
          className={containerClass}
          href={href}
          onClick={onClick}
          {...props}
        >
          {children}
        </Link>
      );
    } else {
      throw "'href' attribute is necessary to render the Button component with element 'a'";
    }
  }

  return (
    <button type={type} className={containerClass} onClick={onClick} {...props}>
      {icon && <Icon icon={icon} />}
      <span>{children}</span>
    </button>
  );
}
