export default function iconKey() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.3333 4.99996C10.3333 4.65874 10.2031 4.31753 9.94281 4.05719C9.68246 3.79684 9.34123 3.66667 9 3.66667M9 9C11.2091 9 13 7.20914 13 5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5C5 5.18245 5.01222 5.36205 5.03587 5.53802C5.07478 5.82745 5.09424 5.97217 5.08114 6.06373C5.0675 6.1591 5.05013 6.2105 5.00313 6.2946C4.958 6.37533 4.87847 6.45486 4.71942 6.61391L1.31242 10.0209C1.19712 10.1362 1.13947 10.1939 1.09824 10.2611C1.06169 10.3208 1.03475 10.3858 1.01842 10.4538C1 10.5306 1 10.6121 1 10.7752V11.9333C1 12.3067 1 12.4934 1.07266 12.636C1.13658 12.7614 1.23856 12.8634 1.36401 12.9273C1.50661 13 1.6933 13 2.06667 13H3.66667V11.6667H5V10.3333H6.33333L7.38609 9.28058C7.54514 9.12153 7.62467 9.042 7.7054 8.99687C7.7895 8.94987 7.8409 8.9325 7.93627 8.91886C8.02783 8.90576 8.17255 8.92522 8.46198 8.96413C8.63795 8.98778 8.81755 9 9 9Z"
        stroke="#344054"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
