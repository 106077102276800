export default function bankNote() {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.99984 5.33335V8.66669M14.9998 5.33335V8.66669M1.6665 3.83335L1.6665 10.1667C1.6665 11.1001 1.6665 11.5668 1.84816 11.9233C2.00795 12.2369 2.26292 12.4919 2.57652 12.6517C2.93304 12.8334 3.39975 12.8334 4.33317 12.8334L15.6665 12.8334C16.5999 12.8334 17.0666 12.8334 17.4232 12.6517C17.7368 12.4919 17.9917 12.2369 18.1515 11.9233C18.3332 11.5668 18.3332 11.1001 18.3332 10.1667V3.83335C18.3332 2.89993 18.3332 2.43322 18.1515 2.0767C17.9917 1.7631 17.7368 1.50813 17.4232 1.34834C17.0666 1.16669 16.5999 1.16669 15.6665 1.16669L4.33317 1.16669C3.39975 1.16669 2.93304 1.16669 2.57652 1.34834C2.26292 1.50813 2.00795 1.7631 1.84816 2.0767C1.6665 2.43322 1.6665 2.89993 1.6665 3.83335ZM12.0832 7.00002C12.0832 8.15061 11.1504 9.08335 9.99984 9.08335C8.84924 9.08335 7.9165 8.15061 7.9165 7.00002C7.9165 5.84943 8.84924 4.91669 9.99984 4.91669C11.1504 4.91669 12.0832 5.84943 12.0832 7.00002Z"
        stroke="#475467"
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
