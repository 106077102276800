export default function swapIcon() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 3V15M12.75 15L9.75 12M12.75 15L15.75 12M5.25 15V3M5.25 3L2.25 6M5.25 3L8.25 6"
        stroke="#404968"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
