import { AbilityContext } from "@/presentation/libs/casl/context";
import { useCustomer } from "@/store/features/customer/customer-query";

import router, { useRouter } from "next/router";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

export function useSidebarController() {
  const {
    isCompany,
    isPrime,
    isBasicCompany,
    hasTaxId,
    isLoadingCustomer,
    isBasic,
    hasRequiredFields,
    hasSumsub,
  } = useCustomer();
  const ability = useContext(AbilityContext);

  const {
    i18n: { language },
    t,
  } = useTranslation();

  const { route, pathname } = useRouter();

  const handleMenuOptionClick = (route: string) => {
    router.push(route);
  };

  const primeOptions = useMemo(() => {
    return [
      {
        icon: "wallet",
        link: "/wallet",
        text: t("investmentsLayout.wallet"),
        active: pathname === "/wallet",
        id: "data-test-ico-id",
        isVisible: true,
      },
      {
        icon: "portfolioIcon",
        link: "/wallet/portfolio",
        text: t("investmentsLayout.portfolios"),
        active: pathname.includes("portfolio"),
        id: "data-test-ico-portfolio",
        isVisible: true,
      },
      {
        icon: "currenciesIcon",
        link: "/wallet/currencies",
        text: t("investmentsLayout.currencies"),
        active: route.includes("currencies"),
        id: "data-test-ico-currencies",
        isVisible: true,
      },
      {
        icon: "historyIcon",
        link: "/wallet/transactions",
        text: t("investmentsLayout.history"),
        active: pathname.includes("/wallet/transactions"),
        id: "data-test-ico-transactions",
        isVisible: true,
      },
    ];
  }, [language, pathname]);

  const basicOptions = useMemo(() => {
    return [
      {
        icon: "homeIcon",
        link: "/",
        text: t("operationLayout.home"),
        active: route === "/",
        id: "data-test-ico-home",
        isVisible: true,
      },
      {
        icon: "arrowDeposit",
        link: "/operations/deposit",
        text: t("operationLayout.deposit"),
        active: route.includes("deposit"),
        id: "data-test-ico-deposit",
        isVisible: true,
      },
      {
        icon: "swapIcon",
        link: "/operations/convert/brz",
        text: t("operationLayout.convert"),
        active: route.includes("convert"),
        id: "data-test-ico-convert",
        isVisible: true,
      },
      {
        icon: "withdrawIcon",
        link: "/operations/withdraw",
        text: t("operationLayout.withdraw"),
        active: route.includes("withdraw"),
        id: "data-test-ico-withdraw",
        isVisible: true,
      },
      {
        icon: "historyIcon",
        link: "/history",
        text: t("operationLayout.history"),
        active: route.includes("history"),
        id: "data-test-ico-history",
        isVisible: true,
      },
      {
        icon: "userNoSelected",
        link: "/onboarding",
        text: t("operationLayout.finishOnboarding"),
        active: route.includes("onboarding"),
        id: "data-test-ico-onboarding",
        isVisible: hasRequiredFields,
      },
      {
        icon: "wallet",
        link: "/wallet",
        text: t("investmentsLayout.investments"),
        active: route.includes("wallet"),
        id: "data-test-ico-wallet",
        isVisible: isPrime && ability.can("Investments", "All"),
      },
    ].filter((item) => item.isVisible);
  }, [
    hasRequiredFields,
    route,
    hasTaxId,
    isCompany,
    isPrime,
    isBasicCompany,
    hasSumsub,
  ]);

  const items = useMemo(() => {
    return pathname.includes("wallet") ? primeOptions : basicOptions;
  }, [pathname, route, hasTaxId, isCompany, isPrime, isBasicCompany, isBasic]);

  return {
    items,
    handleMenuOptionClick,
    isLoadingCustomer,
  };
}
