export default function arrowSwap() {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_336_1114)">
        <rect x="2" y="1" width="24" height="24" rx="4" fill="white" />
        <rect
          x="2.5"
          y="1.5"
          width="23"
          height="23"
          rx="3.5"
          stroke="#EFF1F5"
        />
        <path
          d="M16.5 9V17M16.5 17L14.5 15M16.5 17L18.5 15M11.5 17V9M11.5 9L9.5 11M11.5 9L13.5 11"
          stroke="#4D4DFF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_336_1114"
          x="0"
          y="0"
          width="28"
          height="28"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_336_1114"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_336_1114"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
