import scssVariables from "@/styles/variables.module.scss";

export default function expired() {
  return (
    <svg viewBox="0 0 30 30" fill="none">
      <path
        d="M8.27448 20.3816C8.37916 20.215 8.44657 20.0277 8.47209 19.8326C8.49761 19.6375 8.48063 19.4392 8.42231 19.2513C8.36399 19.0634 8.26571 18.8903 8.13423 18.7439C8.00274 18.5976 7.84115 18.4814 7.66053 18.4033C6.05136 17.7347 4.70262 16.5619 3.81696 15.0612C2.9313 13.5605 2.55654 11.8129 2.749 10.081C2.93044 8.18854 3.76492 6.41824 5.10923 5.07393C6.45354 3.72963 8.22384 2.89514 10.1163 2.71371C11.6171 2.59308 13.1223 2.88524 14.469 3.55858C15.8156 4.23192 16.9525 5.26078 17.7565 6.53379C17.9325 6.77474 18.1736 6.96048 18.4515 7.06922C18.7294 7.17797 19.0326 7.2052 19.3254 7.14773C19.5277 7.08648 19.713 6.97907 19.8667 6.83398C20.0204 6.68888 20.1382 6.51006 20.211 6.31163C20.2837 6.1132 20.3094 5.90057 20.2859 5.69052C20.2625 5.48048 20.1905 5.27875 20.0758 5.10126C19.0134 3.38668 17.4913 2.00462 15.6824 1.11224C13.8735 0.219859 11.8505 -0.147031 9.84344 0.0532939C7.43766 0.29053 5.17872 1.31976 3.42106 2.97948C1.6634 4.6392 0.506494 6.83549 0.131868 9.22373C-0.242758 11.612 0.186098 14.057 1.35114 16.1752C2.51619 18.2934 4.3515 19.9648 6.56908 20.9273C6.87137 21.0573 7.21044 21.0736 7.52383 20.9734C7.83722 20.8731 8.10381 20.6629 8.27448 20.3816Z"
        fill={scssVariables.primary}
      />
      <path
        d="M9.84344 6.46557V9.87636H6.43265C6.07081 9.87636 5.72379 10.0201 5.46794 10.276C5.21208 10.5318 5.06834 10.8788 5.06834 11.2407C5.06834 11.6025 5.21208 11.9495 5.46794 12.2054C5.72379 12.4612 6.07081 12.605 6.43265 12.605H12.5721V6.46557C12.5721 6.10373 12.4283 5.75672 12.1725 5.50086C11.9166 5.245 11.5696 5.10126 11.2078 5.10126C10.8459 5.10126 10.4989 5.245 10.243 5.50086C9.98718 5.75672 9.84344 6.10373 9.84344 6.46557Z"
        fill={scssVariables.primary}
      />
      <path
        d="M29.7624 27.9535L19.5301 10.2174C19.4077 10.02 19.237 9.85709 19.034 9.7441C18.8311 9.63112 18.6027 9.57182 18.3704 9.57182C18.1381 9.57182 17.9097 9.63112 17.7068 9.7441C17.5038 9.85709 17.3331 10.02 17.2107 10.2174L6.97838 27.9535C6.85952 28.1593 6.79648 28.3926 6.7955 28.6303C6.79453 28.8679 6.85565 29.1017 6.97282 29.3085C7.08999 29.5153 7.25914 29.6878 7.46352 29.8091C7.6679 29.9304 7.90041 29.9962 8.13804 30H28.6028C28.8404 29.9962 29.0729 29.9304 29.2773 29.8091C29.4817 29.6878 29.6508 29.5153 29.768 29.3085C29.8852 29.1017 29.9463 28.8679 29.9453 28.6303C29.9443 28.3926 29.8813 28.1593 29.7624 27.9535ZM18.3704 27.2714C18.1006 27.2714 17.8368 27.1914 17.6124 27.0414C17.3881 26.8915 17.2132 26.6785 17.1099 26.4292C17.0067 26.1799 16.9797 25.9055 17.0323 25.6409C17.0849 25.3762 17.2149 25.1331 17.4057 24.9423C17.5965 24.7515 17.8396 24.6216 18.1042 24.569C18.3689 24.5163 18.6432 24.5433 18.8925 24.6466C19.1418 24.7499 19.3549 24.9247 19.5048 25.1491C19.6547 25.3734 19.7347 25.6372 19.7347 25.9071C19.7347 26.2689 19.591 26.6159 19.3351 26.8718C19.0793 27.1276 18.7322 27.2714 18.3704 27.2714ZM19.7347 21.8141C19.7347 22.176 19.591 22.523 19.3351 22.7788C19.0793 23.0347 18.7322 23.1784 18.3704 23.1784C18.0086 23.1784 17.6615 23.0347 17.4057 22.7788C17.1498 22.523 17.0061 22.176 17.0061 21.8141V16.3569C17.0061 15.995 17.1498 15.648 17.4057 15.3921C17.6615 15.1363 18.0086 14.9925 18.3704 14.9925C18.7322 14.9925 19.0793 15.1363 19.3351 15.3921C19.591 15.648 19.7347 15.995 19.7347 16.3569V21.8141Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
