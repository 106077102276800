import Head from "next/head";
import { useRouter } from "next/router";
import { HeaderBack } from "@/widgets";

import styles from "./index.module.scss";

type HelpCenterLayoutProps = {
  children: any;
  pageTitle: string;
};

export default function HelpCenterLayout({
  children,
  pageTitle,
}: HelpCenterLayoutProps) {
  const router = useRouter();

  return (
    <>
      <div className={styles.background} />
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <div className={styles.container}>
        <HeaderBack onBack={() => router.push("/")} title={pageTitle} />
        <div>{children}</div>
      </div>
    </>
  );
}
