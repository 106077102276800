import scssVariables from "@/styles/variables.module.scss";

export default function remove() {
  return (
    <svg viewBox="0 0 18.972 20.342">
      <g transform="translate(0 -2)">
        <path
          fill={scssVariables.primary}
          d="M984.913,366.711h-4.977a4.191,4.191,0,0,0-8.278,0h-4.386a.665.665,0,1,0,0,1.331h17.641a.665.665,0,0,0,0-1.331Zm-9.116-2.2a2.868,2.868,0,0,1,2.784,2.2h-5.569A2.868,2.868,0,0,1,975.8,364.511Z"
          transform="translate(-966.607 -361.18)"
        />
        <path
          fill={scssVariables.primary}
          d="M985.395,375.93a.664.664,0,0,0-.724.6l-1.1,11.919a.557.557,0,0,0,0,.061,2.775,2.775,0,0,1-2.771,2.771H975.54a2.775,2.775,0,0,1-2.771-2.771.572.572,0,0,0,0-.061l-1.1-11.919a.665.665,0,0,0-1.325.122l1.1,11.89a4.107,4.107,0,0,0,4.1,4.07h5.254a4.107,4.107,0,0,0,4.1-4.07l1.1-11.89A.665.665,0,0,0,985.395,375.93Z"
          transform="translate(-968.681 -370.271)"
        />
        <path
          fill={scssVariables.primary}
          d="M982.858,388.27v-6.233a.665.665,0,0,0-1.331,0v6.233a.665.665,0,0,0,1.331,0Z"
          transform="translate(-974.908 -371.878)"
        />
        <path
          fill={scssVariables.primary}
          d="M993.144,394.441v-6.233a.665.665,0,1,0-1.331,0v6.233a.665.665,0,1,0,1.331,0Z"
          transform="translate(-980.63 -378.048)"
        />
      </g>
    </svg>
  );
}
