import { forwardRef, useImperativeHandle } from "react";
import { Spinner } from "..";
import useRerender from "./hooks/useRerender";

type RerenderableProps = {
  children: any;
  renderingMessage?: string;
};

type RerenderableHandler = {
  rerender: () => void;
};

const TheRerenderable = (
  { children, renderingMessage }: RerenderableProps,
  ref: any
) => {
  const { isRendering, rerender } = useRerender();

  useImperativeHandle(ref, () => ({
    rerender,
  }));

  if (isRendering) {
    return <Spinner size="small">{renderingMessage}</Spinner>;
  }

  return <>{children}</>;
};

const Rerenderable = forwardRef<RerenderableHandler, RerenderableProps>(
  TheRerenderable
);

export type { RerenderableHandler };

export default Rerenderable;
