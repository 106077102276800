import {
  AnyAction,
  CombinedState,
  combineReducers,
  configureStore,
  Reducer,
} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { IApiError } from "@/models";

// features
import balanceReducer, { BalanceState } from "./features/balance";
import baseReducer, { BaseState } from "./features/base";
import currencyReducer, { CurrencyState } from "./features/currency";
import customerReducer, { CustomerState } from "./features/customer";
import favoriteReducer, { FavoriteState } from "./features/favorite";
import historyReducer, { HistoryState } from "./features/history";
import marketReducer, { MarketState } from "./features/market";
import tradeReducer, { TradeState } from "./features/trade";

export interface AsyncState {
  error: null | IApiError;
  loading: boolean;
}

const rootReducer: Reducer<
  CombinedState<{
    balance: BalanceState;
    base: BaseState;
    currency: CurrencyState;
    customer: CustomerState;
    favorite: FavoriteState;
    market: MarketState;
    trade: TradeState;
    history: HistoryState;
  }>,
  AnyAction | any
> = combineReducers({
  balance: balanceReducer,
  base: baseReducer,
  currency: currencyReducer,
  customer: customerReducer,
  favorite: favoriteReducer,
  market: marketReducer,
  trade: tradeReducer,
  history: historyReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  devTools: true,
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
