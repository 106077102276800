import scssVariables from "@/styles/variables.module.scss";

export default function convert() {
  return (
    <svg viewBox="0 0 648 665" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.913 42.2722L196.259 37.5281L197.26 0.0415039L437.645 6.46071L433.029 236.684L395.537 235.933L398.856 70.3621L26.7001 442.519L0.183594 416.002L373.913 42.2722ZM647.01 253.191L274.853 625.347L440.424 622.027L441.175 659.52L210.952 664.136L204.532 423.75L242.019 422.749L246.763 600.404L620.493 226.674L647.01 253.191Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
