import Link from "next/link";
import { Image } from "@/widgets";
import styles from "./index.module.scss";

type AppLinksProps = {
  appStoreUrl: string;
  appStoreAlt: string;
  googlePlayUrl: string;
  googlePlayAlt: string;
  title: string;
  colorText?: string;
};
export default function AppLinks({
  appStoreUrl,
  appStoreAlt,
  googlePlayUrl,
  googlePlayAlt,
  title,
  colorText
}: AppLinksProps) {
  return (
    <div className={`AppLinks ${styles.container}`}>
      <div style={{color: colorText}}>{title}</div>
      <ul>
        <li>
          <Link href={appStoreUrl} title={appStoreAlt} target="_blank">
            <Image src="/images/app-store.svg" alt={appStoreAlt} />
          </Link>
        </li>
        <li>
          <Link href={googlePlayUrl} title={googlePlayAlt} target="_blank">
            <Image src="/images/google-play.svg" alt={googlePlayAlt} />
          </Link>
        </li>
      </ul>
    </div>
  );
}
