import scssVariables from "@/styles/variables.module.scss";

export default function orderDown() {
  return (
    <svg viewBox="0 0 667 567" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M523.904 556.904C510.886 569.921 489.781 569.921 476.763 556.904L343.43 423.57C330.412 410.553 330.412 389.447 343.43 376.43C356.447 363.412 377.553 363.412 390.57 376.43L467 452.86L467 33.3334C467 14.9238 481.924 4.48553e-05 500.333 4.64647e-05C518.743 4.80741e-05 533.667 14.9238 533.667 33.3334L533.667 452.86L610.096 376.43C623.114 363.412 644.219 363.412 657.237 376.43C670.254 389.447 670.254 410.553 657.237 423.57L523.904 556.904ZM233.667 433.333C233.667 451.743 218.743 466.667 200.333 466.667L33.6667 466.667C15.2572 466.667 0.333384 451.743 0.333386 433.333C0.333387 414.924 15.2572 400 33.6667 400L200.333 400C218.743 400 233.667 414.924 233.667 433.333ZM300.333 266.667C300.333 285.076 285.41 300 267 300L33.6667 300C15.2572 300 0.333399 285.076 0.3334 266.667C0.333402 248.257 15.2572 233.333 33.6667 233.333L267 233.333C285.41 233.333 300.333 248.257 300.333 266.667ZM400.333 100C400.333 118.409 385.41 133.333 367 133.333L33.6667 133.333C15.2572 133.333 0.333413 118.409 0.333415 100C0.333416 81.5905 15.2572 66.6666 33.6667 66.6666L367 66.6667C385.41 66.6667 400.333 81.5905 400.333 100Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
