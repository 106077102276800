import scssVariables from "@/styles/variables.module.scss";

export default function deposit() {
  return (
    <svg viewBox="0 0 12 16" fill="none">
      <path
        d="M10.1631 13.8294H2.17924V11.665H0.964844V15.0174H11.3775V11.665H10.1631V13.8294Z"
        fill={scssVariables.primary}
      />
      <path
        d="M10.5893 6.9653L9.79822 6.2453L6.71182 9.4949V0.326904H5.63134V9.4949L2.54446 6.24434L1.75342 6.9653L6.16942 11.6141L10.5893 6.9653Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
