export default function depositBlue() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#E8F0FF" />
      <path
        d="M17.25 13.75V14.45C17.25 15.4301 17.25 15.9201 17.0593 16.2945C16.8915 16.6238 16.6238 16.8915 16.2945 17.0593C15.9201 17.25 15.4301 17.25 14.45 17.25H9.55C8.56991 17.25 8.07986 17.25 7.70552 17.0593C7.37623 16.8915 7.10852 16.6238 6.94074 16.2945C6.75 15.9201 6.75 15.4301 6.75 14.45V13.75M14.9167 10.8333L12 13.75M12 13.75L9.08333 10.8333M12 13.75V6.75"
        stroke="#413FCF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
