import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Icon, Tooltip } from "@/widgets";
import { StepIndicatorHandler, StepIndicatorProps } from "./type";

import styles from "./index.module.scss";

const TheStepIndicator = (
  { steps, currentStep = 1 }: StepIndicatorProps,
  ref: any
) => {
  const total = steps.length;

  if (total < 1 || total > 5) {
    throw "The StepIndicator only supports 1 to 5 items.";
  }

  if (currentStep <= 0 || currentStep > total) {
    throw "The currentStep at StepIndicator cannot be less than zero and greater than the total of stpe items.";
  }

  const gap = Math.round(100 / (total - 1));
  const sizeStyles = total <= 3 ? styles.xs : styles.sm;
  const [current, setCurrent] = useState(currentStep);
  const getPercentage = () => (current === total ? 100 : (current - 1) * gap);
  const [percentage, setPercentage] = useState(getPercentage());
  const [disable, setDisable] = useState(false);
  const [disablePrev, setDisablePrev] = useState(false);
  const [disableNext, setDisableNext] = useState(false);

  useImperativeHandle(ref, () => ({
    goTo,
    goPrev,
    goNext,
    getCurrent,
    getPrev,
    getNext,
    toggleDisable,
    toggleDisablePrev,
    toggleDisableNext,
    isDisabled,
    isPrevDisabled,
    isNextDisabled,
  }));

  const isDisabled = () => disable;
  const isPrevDisabled = () => disablePrev;
  const isNextDisabled = () => disableNext;
  const getCurrent = () => current;
  const getPrev = () => (current <= 1 ? 0 : current - 1);
  const getNext = () => (current >= total ? 0 : current + 1);

  const goTo = (step: number, force = false) => {
    if (
      !force &&
      (step < 1 ||
        step > total ||
        disable ||
        (step < current && disablePrev) ||
        (step > current && disableNext))
    )
      return;

    setCurrent(step);
  };

  const goPrev = () => {
    if (current <= 1 || disable || disablePrev) return;
    setCurrent(getPrev());
  };

  const goNext = () => {
    if (current >= total || disable || disableNext) return;
    setCurrent(getNext());
  };

  const toggleDisable = (isDisabled: boolean) => {
    setDisable(isDisabled);
  };

  const toggleDisablePrev = (isDisabled: boolean) => {
    setDisablePrev(isDisabled);
  };

  const toggleDisableNext = (isDisabled: boolean) => {
    setDisableNext(isDisabled);
  };

  const isStepDisabled = (step: number) =>
    disable ||
    (step < current && disablePrev) ||
    (step > current && disableNext);

  useEffect(() => {
    setPercentage(getPercentage());
  }, [current]);

  return (
    <>
      <div
        className={`StepIndicator ${styles.container} ${
          styles[`percentage-${percentage}`]
        } ${sizeStyles} ${disable ? styles.disabled : ""}`}
      >
        {steps.map((step, index) => {
          const theStep = index + 1;
          const activeClass = current === theStep ? styles.active : "";
          const doneClass = current <= theStep ? "" : styles.done;
          const isDisabled = isStepDisabled(theStep);

          return (
            <button
              key={index}
              type="button"
              className={`${styles.button} ${activeClass} ${doneClass} ${sizeStyles}`}
              disabled={isDisabled}
              onClick={() => {
                goTo(theStep);
              }}
            >
              <Tooltip element="span" content={step.label}>
                <span>{doneClass ? <Icon icon="ok" /> : theStep}</span>
              </Tooltip>
              <span>{step.label}</span>
            </button>
          );
        })}
      </div>
      <div className={`${styles.body} ${sizeStyles}`}>
        {steps[current - 1]?.body && steps[current - 1].body}
      </div>
    </>
  );
};

const StepIndicator = forwardRef<StepIndicatorHandler, StepIndicatorProps>(
  TheStepIndicator
);

export type { StepIndicatorHandler };

export default StepIndicator;
