import NonLoggedInHeader from "./NonLoggedInHeader";
import HeaderMenu from "./HeaderMenu";
import { useCustomer } from "@/store/features/customer/customer-query";

export default function HeaderContainer() {
  const { isLoggedIn } = useCustomer();

  return (
    <>
      {isLoggedIn && <HeaderMenu />}
      {!isLoggedIn && <NonLoggedInHeader />}
    </>
  );
}
