import { TypeAssetsInfosProps } from "./interfaces";
import * as S from "./styles";

const TypeAssetsInfos = ({
  assetLabel,
  assetBalance,
  cardSize,
}: TypeAssetsInfosProps) => {
  return (
    <S.TypeAssetsInfos $cardSize={cardSize}>
      <S.AssetType $cardSize={cardSize}>{assetLabel}</S.AssetType>
      <S.AssetBalance $cardSize={cardSize}>{assetBalance}</S.AssetBalance>
    </S.TypeAssetsInfos>
  );
};

export default TypeAssetsInfos;
