export default function icoDolarDepositPix() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="37.3333" height="37.3333" rx="10.8333" fill="#F4F4FF" />
      <path
        d="M15.7507 20.8895C15.7507 21.9634 16.6212 22.834 17.6951 22.834H19.5007C20.6512 22.834 21.584 21.9012 21.584 20.7507C21.584 19.6001 20.6512 18.6673 19.5007 18.6673H17.834C16.6834 18.6673 15.7507 17.7346 15.7507 16.584C15.7507 15.4334 16.6834 14.5007 17.834 14.5007H19.6395C20.7134 14.5007 21.584 15.3712 21.584 16.4451M18.6673 13.2507V14.5007M18.6673 22.834V24.084M27.0007 18.6673C27.0007 23.2697 23.2697 27.0007 18.6673 27.0007C14.0649 27.0007 10.334 23.2697 10.334 18.6673C10.334 14.0649 14.0649 10.334 18.6673 10.334C23.2697 10.334 27.0007 14.0649 27.0007 18.6673Z"
        stroke="#413FCF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
