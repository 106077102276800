import axios from "axios";
import {
  addTokenToClientSideRequest,
  refreshTokenInterceptor,
} from "@/utils/auth";
import { IApiError } from "@/models";

const config = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Accept: "*/*",
  },
};

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  ...config,
});

export const apiPrime = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL_PRIME,
  ...config,
});

addTokenToClientSideRequest(api);
refreshTokenInterceptor(api);
addTokenToClientSideRequest(apiPrime);
refreshTokenInterceptor(apiPrime);

export function throwApiError(
  error: any,
  optionalId = "generalError"
): IApiError {
  if (error?.response?.data?.traceId) {
    throw error?.response?.data as IApiError;
  }

  throw {
    title: optionalId,
    type: "client",
    status: error?.response?.status,
  } as IApiError;
}
