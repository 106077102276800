import styled from "styled-components";
import { SizeOptionsProps } from "./interfaces";

export const TypeAssetsInfos = styled.div<SizeOptionsProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AssetType = styled.p<SizeOptionsProps>`
  font-family: Karla;
  font-size: ${(props) => (props.$cardSize ? ".9rem" : "1rem")};
  font-weight: 500;
  text-align: left;
  color: #30374f;
`;

export const AssetBalance = styled.div<SizeOptionsProps>`
  font-family: Karla;
  font-size: ${(props) => (props.$cardSize ? "1rem" : "1.25rem")};
  font-weight: 400;
  text-align: left;
  color: #7d89b0;
`;
