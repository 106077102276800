import scssVariables from "@/styles/variables.module.scss";

export default function copy() {
  return (
    <svg
      fill="#000000"
      viewBox="0 0 36 36"
      version="1.1"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M29.5,7h-19A1.5,1.5,0,0,0,9,8.5v24A1.5,1.5,0,0,0,10.5,34h19A1.5,1.5,0,0,0,31,32.5V8.5A1.5,1.5,0,0,0,29.5,7ZM29,32H11V9H29Z"
        fill={scssVariables.primary}
      />
      <path
        d="M26,3.5A1.5,1.5,0,0,0,24.5,2H5.5A1.5,1.5,0,0,0,4,3.5v24A1.5,1.5,0,0,0,5.5,29H6V4H26Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
