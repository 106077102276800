export default function utilsPublicServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M21.6654 11.8916V15.3337C21.6654 15.8004 21.6654 16.0338 21.7562 16.212C21.8361 16.3688 21.9636 16.4963 22.1204 16.5762C22.2986 16.6671 22.532 16.6671 22.9987 16.6671H26.4408M21.6654 24.167H16.6654M23.332 20.8337H16.6654M26.6654 18.3238V24.3337C26.6654 25.7338 26.6654 26.4339 26.3929 26.9686C26.1532 27.439 25.7707 27.8215 25.3003 28.0612C24.7656 28.3337 24.0655 28.3337 22.6654 28.3337H17.332C15.9319 28.3337 15.2318 28.3337 14.6971 28.0612C14.2266 27.8215 13.8442 27.439 13.6045 26.9686C13.332 26.4339 13.332 25.7338 13.332 24.3337V15.667C13.332 14.2669 13.332 13.5668 13.6045 13.032C13.8442 12.5616 14.2266 12.1792 14.6971 11.9395C15.2318 11.667 15.9319 11.667 17.332 11.667H20.0085C20.62 11.667 20.9257 11.667 21.2134 11.7361C21.4685 11.7973 21.7124 11.8983 21.9361 12.0354C22.1884 12.19 22.4046 12.4062 22.8369 12.8386L25.4938 15.4954C25.9262 15.9278 26.1424 16.144 26.297 16.3963C26.434 16.62 26.535 16.8638 26.5963 17.1189C26.6654 17.4066 26.6654 17.7124 26.6654 18.3238Z"
        stroke="#5D6B98"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
