import { IConvertedBalance } from "@/models";

export function toggleScrollVisibility(opened: boolean) {
  const elements = [document.body, document.getElementsByTagName("html")[0]];
  if (opened) {
    elements.map((element) => {
      element.classList.add("scroll-hidden");
      element.classList.remove("scroll-auto");
    });
  } else {
    elements.map((element) => {
      element.classList.remove("scroll-hidden");
      element.classList.add("scroll-auto");
    });
  }
}

export function hasScrollbar() {
  return !document.body.classList.contains("scroll-hidden");
}

export function backToTop() {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
}

export function scrollIntoView(elementRef: any, hard = false) {
  if (elementRef?.current) {
    const el = elementRef.current as HTMLElement;
    const behavior: any = hard ? undefined : { behavior: "smooth" };
    el.scrollIntoView(behavior);
  }
}

export function toggleClassToRootElements(
  className: string,
  haveToAdd: boolean
) {
  if (haveToAdd) {
    document.body.classList.add(className);
    document.documentElement.classList.add(className);
  } else {
    document.body.classList.remove(className);
    document.documentElement.classList.remove(className);
  }
}

export function orderMaxBalances(balances: IConvertedBalance[]) {
  balances.sort((a, b) => {
    if (a.baseCurrency.id === "BRZ" && b.baseCurrency.id !== "BRZ") return -1;
    if (a.baseCurrency.id !== "BRZ" && b.baseCurrency.id === "BRZ") return 1;
    return b.fiatAmount - a.fiatAmount;
  });
}

export function orderZeroBalances(balances: IConvertedBalance[]) {
  const specificOrder = [
    "BRZ",
    "USDT",
    "ETH",
    "BTC",
    "USDC",
    "AVAX",
    "ARZ",
    "SOL",
    "DOT",
  ];

  return balances.sort((a, b) => {
    const indexA = a.baseCurrency?.id
      ? specificOrder.indexOf(a.baseCurrency.id)
      : -1;
    const indexB = b.baseCurrency?.id
      ? specificOrder.indexOf(b.baseCurrency.id)
      : -1;

    if (indexA !== -1 && indexB !== -1) return indexA - indexB;
    if (indexA !== -1) return -1;
    if (indexB !== -1) return 1;

    return 0;
  });
}
