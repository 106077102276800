import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, type MarketState } from ".";

export const marketReducer = {
  clearMarket: () => {
    return initialState;
  },
  updateMarket: (_: MarketState, action: PayloadAction<MarketState>) => {
    return action.payload;
  },
};
