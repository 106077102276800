import { Actions, AppAbility, Subjets } from "./context";
import { FeatureFlag } from "@/domains/models";
import { NextRouter } from "next/router";

type AbilityPermission = { action: Actions; subject: Subjets }[];

export function generateAbility(
  param: FeatureFlag,
  hasRequiredFields?: boolean,
  isPrime?: boolean
): AbilityPermission {
  const features: AbilityPermission = [];

  for (const [featureName, isEnabled] of Object.entries(param?.features)) {
    if (isEnabled) {
      features.push({
        action: featureName as Actions,
        subject: "All",
      });
    }
  }

  return features.filter((i) => {
    if (i.action === "Investments") {
      return isPrime && !hasRequiredFields;
    }
    return true;
  });
}

export function redirectNotAbility(
  abilities: AppAbility,
  navigate: NextRouter
): void {
  const whitelist = {
    "/card": "Cards",
    "/cardshowcase": "Cards",
    "/wallet": "Investments",
    "/wallet/transactions": "Investments",
    "/wallet/currencies": "Investments",
    "/wallet/portfolio": "Investments",
  };

  const path = navigate.pathname;
  const isWhiteList = whitelist[path as keyof typeof whitelist];

  if (!isWhiteList) return;

  if (
    !abilities.can(whitelist[path as keyof typeof whitelist] as Actions, "All")
  ) {
    navigate.push("/");
  }
}
