import scssVariables from "@/styles/variables.module.scss";

export default function withdraw() {
  return (
    <svg viewBox="0 0 11 16" fill="none">
      <path
        d="M9.19825 14.1643H1.2144V12H0V15.3523H10.4127V12H9.19825V14.1643Z"
        fill={scssVariables.primary}
      />
      <path
        d="M0.753516 4.97561L1.54456 5.69561L4.63096 2.44601L4.63096 11.614L5.71144 11.614L5.71144 2.44601L8.79832 5.69657L9.58936 4.97561L5.17336 0.326813L0.753516 4.97561Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
