import { ForwardedRef, InputHTMLAttributes, forwardRef } from "react";
import styles from "./index.module.scss";

type CheckboxProps = {
  label?: any;
  display?: "inline" | "block";
  hasError?: boolean;
};

type FieldType = CheckboxProps & InputHTMLAttributes<HTMLInputElement>;

const TheField = (
  { label, display = "inline", hasError = false, ...props }: FieldType,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <label
      className={`Checkbox ${styles.container} ${styles[display]} ${
        hasError ? styles.hasError : ""
      }`}
    >
      <input type="checkbox" ref={ref} {...props} />
      {label && <span className={`${styles.label}`}>{label}</span>}
      <span className={`${styles.checkmark}`}></span>
    </label>
  );
};

const Checkbox = forwardRef<HTMLInputElement, FieldType>(TheField);

export default Checkbox;
