// cookies prefix
const transferoPrefix = "transfero.crypto.web.";

/**
 * Cookie keys saved by the app
 */
export const ACCEPT_COOKIES_KEY = `${transferoPrefix}.acceptCookies`;
export const TOKEN_COOKIE_KEY = `${transferoPrefix}.token`;
export const REFRESH_TOKEN_COOKIE_KEY = `${transferoPrefix}.refreshToken`;
export const SLA_DEPOSIT_REAL_COOKIE_KEY = `${transferoPrefix}.SLADepositReal`;
export const SLA_DEPOSIT_CRYPTO_COOKIE_KEY = `${transferoPrefix}.SLADepositCrypto`;
export const SLA_WITHDRAW_REAL_COOKIE_KEY = `${transferoPrefix}.SLAWithdrawReal`;
export const SLA_WITHDRAW_PIX_COOKIE_KEY = `${transferoPrefix}.SLAWithdrawPix`;
export const SLA_WITHDRAW_CRYPTO_COOKIE_KEY = `${transferoPrefix}.SLAWithdrawCrypto`;

export const OPERATIONS = ["deposit", "withdraw", "convert/brz"];

export const LOCALES = ["en", "es", "pt"] as const;
export type LOCALES_TYPE = (typeof LOCALES)[number];

export const CURRENCY_FORMATS: any = {
  en: {
    thousandsSeparatorSymbol: ",",
    decimalSymbol: ".",
  },
  es: {
    thousandsSeparatorSymbol: ".",
    decimalSymbol: ",",
  },
  pt: {
    thousandsSeparatorSymbol: ",",
    decimalSymbol: ".",
  },
};
