import { Icon } from "@/widgets";
import styles from "./index.module.scss";

type AlertProps = {
  children: any;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "danger"
    | "success"
    | "warning"
    | "dark"
    | "light";
  invert?: boolean;
  isBordered?: boolean; // if invert is true it wont make difference
  isRounded?: boolean;
  hasShadow?: boolean;
  textAlign?: "left" | "center" | "right";
  onClose?(): void;
  className?: string;
};

export default function Alert({
  children,
  variant = "primary",
  invert = false,
  isBordered = false,
  isRounded = false,
  hasShadow = false,
  textAlign = "center",
  onClose,
  className = "",
}: AlertProps) {
  return (
    <div
      className={`Alert ${styles.container} ${styles[variant]} ${
        styles[textAlign]
      } ${invert ? styles.invert : ""} ${isBordered ? styles.isBordered : ""} ${
        hasShadow ? styles.hasShadow : ""
      } ${isRounded ? styles.isRounded : ""} ${className}`}
    >
      {onClose && (
        <button className={styles.icon} onClick={onClose}>
          <Icon icon="close" />
        </button>
      )}
      <div className={styles.content}>{children}</div>
    </div>
  );
}
