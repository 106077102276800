import styled from "styled-components";

const colorMap = {
  default: "#4a5578",
  selected: "#413FCF",
};

interface ButtonProps {
  $type?: "default" | "selected";
  $collapsed?: boolean;
}

export const HeaderButtonContainer = styled.div<ButtonProps>`
  width: 163px;
  height: 34px;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => props.$type === "selected" && "#E8F0FF"};

  p {
    font-family: Karla;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.37px;
    text-align: left;
    color: ${(props) => colorMap[props.$type ?? "default"]};
    margin-right: 12px;
  }

  &:hover {
    background: #e8f0ff;

    p {
      color: #413fcf;
    }
  }
`;