import { Modal, Spinner } from "@/widgets";

type SpinnerModalProps = {
  message: string;
  open?: boolean;
};

export default function SpinnerModal({
  message,
  open = true,
}: SpinnerModalProps) {
  return (
    <Modal open={open} showCloseIcon={false} size="small">
      <Spinner size="small">{message}</Spinner>
    </Modal>
  );
}
