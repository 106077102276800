import { hideSensitiveData } from "@/utils";
import { sendMessageSentry } from "./Sentry";
import { api, throwApiError } from "./axios";
import { ISumsubToken } from "@/models/ISumsubToken";

const SumsubApi = {
  createToken: async () => {
    try {
      const response = await api.request({
        url: "/sumsub/token",
        method: "POST",
      });
      return response.data as ISumsubToken;
    } catch (error: any) {
      sendMessageSentry(
        `Sumsub Token Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "createSumsubToken");
    }
  },
};

export default SumsubApi;
