import * as S from "./styles";

type IInfoAssetButtonsProps = {
  label: string;
};

const InfoAssetsButton = ({ label }: IInfoAssetButtonsProps) => {
  return <S.InfoAssetsButtonContainer>{label}</S.InfoAssetsButtonContainer>;
};

export default InfoAssetsButton;
