import { Icon } from "@/widgets";

import styles from "./index.module.scss";

type VariationProps = {
  value: number;
  showNumber?: boolean;
  showPercentage?: boolean;
};

const getIcon = (isNegative: boolean) =>
  isNegative ? <Icon icon="graphDown" /> : <Icon icon="graphUp" />;

export default function Variation({
  value,
  showNumber = true,
  showPercentage = true,
}: VariationProps) {
  if (value === 0) {
    return null;
  }

  const isNegative = value < 0;

  return (
    <div
      className={`Variation ${styles.container} ${
        isNegative ? styles.negative : styles.positive
      }`}
    >
      {showNumber && (
        <span>{`${isNegative ? "-" : "+"}${Math.abs(value)}${
          showPercentage ? "%" : ""
        }`}</span>
      )}
      {getIcon(isNegative)}
    </div>
  );
}
