import scssVariables from "@/styles/variables.module.scss";

export default function info() {
  return (
    <svg viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 8.84046 1.76554 9.67269 2.08717 10.4492C2.4088 11.2257 2.88022 11.9312 3.47452 12.5255C4.06881 13.1198 4.77434 13.5912 5.55083 13.9128C6.32731 14.2345 7.15954 14.4 8 14.4C8.84046 14.4 9.67269 14.2345 10.4492 13.9128C11.2257 13.5912 11.9312 13.1198 12.5255 12.5255C13.1198 11.9312 13.5912 11.2257 13.9128 10.4492C14.2345 9.67269 14.4 8.84046 14.4 8C14.4 6.30262 13.7257 4.67475 12.5255 3.47452C11.3252 2.27428 9.69739 1.6 8 1.6ZM2.34315 2.34315C3.84344 0.842855 5.87827 0 8 0C10.1217 0 12.1566 0.842855 13.6569 2.34315C15.1571 3.84344 16 5.87827 16 8C16 9.05058 15.7931 10.0909 15.391 11.0615C14.989 12.0321 14.3997 12.914 13.6569 13.6569C12.914 14.3997 12.0321 14.989 11.0615 15.391C10.0909 15.7931 9.05058 16 8 16C6.94943 16 5.90914 15.7931 4.93853 15.391C3.96793 14.989 3.08601 14.3997 2.34315 13.6569C1.60028 12.914 1.011 12.0321 0.608964 11.0615C0.206926 10.0909 0 9.05058 0 8C0 5.87827 0.842854 3.84344 2.34315 2.34315ZM6.8 4.4C6.8 3.95817 7.15817 3.6 7.6 3.6H8C8.44183 3.6 8.8 3.95817 8.8 4.4V4.8C8.8 5.24183 8.44183 5.6 8 5.6H7.6C7.15817 5.6 6.8 5.24183 6.8 4.8V4.4ZM6.4 7.2C6.4 6.75817 6.75817 6.4 7.2 6.4H8C8.44183 6.4 8.8 6.75817 8.8 7.2V10.8H9.2C9.64183 10.8 10 11.1582 10 11.6C10 12.0418 9.64183 12.4 9.2 12.4H6.8C6.35817 12.4 6 12.0418 6 11.6C6 11.1582 6.35817 10.8 6.8 10.8H7.2V8C6.75817 8 6.4 7.64183 6.4 7.2Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
