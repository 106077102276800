import styled from "styled-components";

export const BalanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BalanceTitle = styled.p`
  font-family: "Karla", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.067rem;
  text-align: left;
  color: rgba(48, 55, 79, 1);
`;

export const BalanceValue = styled.div`
  font-family: Karla;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.754rem;
  text-align: left;
  color: rgba(48, 55, 79, 1);
`;

export const DataBalanceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
`;

export const DataContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
