import { IHistory } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

const HistoryApi = {
  getHistory: async () => {
    try {
      const response = await api.request({
        url: "/receipts",
        method: "GET",
      });

      return response.data as IHistory[];
    } catch (error: any) {
      sendMessageSentry(
        `Receipt Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getHistory");
    }
  },
};

export default HistoryApi;
