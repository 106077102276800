import styles from "./index.module.scss";

export type StatusesType = string

type StatusLabelProps = {
  label?: string;
  status?: StatusesType;
};

export default function StatusLabel({
  label,
  status = "pending",
}: StatusLabelProps) {
  return (
    <div className={`StatusLabel ${styles.container} ${styles[status]}`}>
      {label && <span>{label}</span>}
    </div>
  );
}
