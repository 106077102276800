import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ptBRTranslation from "./src/i18n/pt-BR.json";
import enUSTranslation from "./src/i18n/en-US.json";
import esESTranslation from "./src/i18n/es-ES.json";

i18n.use(initReactI18next).init({
  lng: "pt",
  fallbackLng: "pt",
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    pt: {
      translation: ptBRTranslation,
    },
    en: {
      translation: enUSTranslation,
    },
    es: {
      translation: esESTranslation,
    },
  },
});

export default i18n;
