import {
  default as RRModal,
  ModalProps as RRModalProps,
} from "react-responsive-modal";
import { ReactElement } from "react";
import { Card, Icon } from "@/widgets";
import { CardSizeType } from "@/widgets/Card";

import "react-responsive-modal/styles.css";

type ModalProps = {
  open?: boolean;
  closeOnOverlayClick?: boolean;
  hideScrollbar?: boolean;
  children: ReactElement;
  size?: CardSizeType;
  showCloseIcon?: boolean;
  onClose?: () => void;
};

export default function Modal({
  open = false,
  onClose,
  closeOnOverlayClick,
  children,
  size = "normal",
  showCloseIcon = true,
}: ModalProps & Omit<RRModalProps, "onClose">) {
  return (
    <RRModal
      open={open}
      onClose={() => {
        if (onClose) {
          onClose();
        }
      }}
      closeOnOverlayClick={closeOnOverlayClick}
      center={true}
      showCloseIcon={showCloseIcon}
      closeIcon={<Icon icon="close" />}
    >
      <Card keepOnMobile={true} size={size} hasShadow={true}>
        {children}
      </Card>
    </RRModal>
  );
}
