import scssVariables from "@/styles/variables.module.scss";

export default function eyeHide() {
  return (
    <svg viewBox="0 0 26.709 23.592">
      <g transform="translate(-1767.092 -463.651)">
        <g transform="translate(1767.092 463.651)">
          <path
            d="M1600.77-83.6a1.1,1.1,0,0,0-1.56,0l-3.424,3.424a14.06,14.06,0,0,0-5.848-1.207,14.247,14.247,0,0,0-13.28,9.215.938.938,0,0,0,0,.609,14.181,14.181,0,0,0,4.434,6.079l-3.267,3.267a1.1,1.1,0,0,0,0,1.558,1.1,1.1,0,0,0,.781.323,1.1,1.1,0,0,0,.779-.323L1600.77-82.04A1.1,1.1,0,0,0,1600.77-83.6ZM1578.58-71.862a12.073,12.073,0,0,1,11.357-7.616,11.766,11.766,0,0,1,4.349.8l-2.483,2.483c-.112-.049-.209-.119-.325-.157l-.191-.057a4.6,4.6,0,0,0-1.3-.181,4.76,4.76,0,0,0-4.76,4.76h0a4.7,4.7,0,0,0,.379,1.836l-3.187,3.187A11.91,11.91,0,0,1,1578.58-71.862Zm8.547.038a2.857,2.857,0,0,1,2.857-2.857,2.338,2.338,0,0,1,.267.04l-3.095,3.093C1587.148-71.64,1587.128-71.728,1587.128-71.823Z"
            transform="translate(-1576.606 83.923)"
            fill={scssVariables.primary}
          />
          <path
            d="M1600.668-74.1a16.206,16.206,0,0,0-4-5.719l-1.337,1.336a12.021,12.021,0,0,1,3.365,4.688,12.072,12.072,0,0,1-11.356,7.616h0a12.011,12.011,0,0,1-3.754-.559l-1.455,1.454a14.2,14.2,0,0,0,5.209,1.007c10.091,0,13.252-9.13,13.329-9.215A.958.958,0,0,0,1600.668-74.1Z"
            transform="translate(-1574.009 85.853)"
            fill={scssVariables.primary}
          />
          <path
            d="M1586.6-72.474l-1.758,1.757a4.683,4.683,0,0,0,1.267.193,4.761,4.761,0,0,0,4.76-4.76,4.584,4.584,0,0,0-.176-1.283l-1.772,1.773A2.847,2.847,0,0,1,1586.6-72.474Z"
            transform="translate(-1572.734 87.381)"
            fill={scssVariables.primary}
          />
        </g>
      </g>
    </svg>
  );
}
