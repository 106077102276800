import { ICurrency } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

const CurrencyApi = {
  getCurrencies: async () => {
    try {
      const response = await api.request({
        url: "/currencies",
        method: "GET",
      });
      return response.data as ICurrency[];
    } catch (error: any) {
      sendMessageSentry(
        `Currencies Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getCurrencies");
    }
  },
};

export default CurrencyApi;
