export default function clothesStore() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M25 19.375H27.0859C27.2091 19.3744 27.3294 19.3378 27.4321 19.2698C27.5348 19.2018 27.6154 19.1053 27.6641 18.9922L28.8594 16.1172C28.9152 15.9794 28.9207 15.8264 28.875 15.685C28.8293 15.5436 28.7352 15.4228 28.6094 15.3438L25 13.125"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9994 19.375H12.9135C12.7903 19.3744 12.67 19.3378 12.5673 19.2698C12.4646 19.2018 12.384 19.1053 12.3353 18.9922L11.14 16.1172C11.0842 15.9794 11.0787 15.8264 11.1244 15.685C11.1701 15.5436 11.2642 15.4228 11.39 15.3438L14.9994 13.125"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 13.125C22.5 13.788 22.2366 14.4239 21.7678 14.8928C21.2989 15.3616 20.663 15.625 20 15.625C19.337 15.625 18.7011 15.3616 18.2322 14.8928C17.7634 14.4239 17.5 13.788 17.5 13.125H15V26.25C15 26.4158 15.0658 26.5747 15.1831 26.6919C15.3003 26.8092 15.4592 26.875 15.625 26.875H24.375C24.5408 26.875 24.6997 26.8092 24.8169 26.6919C24.9342 26.5747 25 26.4158 25 26.25V13.125H22.5Z"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
