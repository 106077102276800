import { hideSensitiveData } from "@/utils";
import { sendMessageSentry } from "./Sentry";
import { api, throwApiError } from "./axios";

const NotificationsSupportApi = {
  getSupportMessage: async (
    customerName: string,
    customerEmail: string,
    message: string,
    language: string | undefined
  ) => {
    try {
      const response = await api.request({
        url: "v1/notifications/support",
        data: { customerName, customerEmail, message, language },
        method: "POST",
      });
      return response;
    } catch (error: any) {
      sendMessageSentry(
        `Notifications support Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getSupportMessage");
    }
  },
};

export default NotificationsSupportApi;
