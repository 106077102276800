export default function collapseRight() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="23.5"
        y="23.5"
        width="23"
        height="23"
        rx="4.5"
        transform="rotate(180 23.5 23.5)"
        fill="#FDFEFF"
      />
      <rect
        x="23.5"
        y="23.5"
        width="23"
        height="23"
        rx="4.5"
        transform="rotate(180 23.5 23.5)"
        stroke="#EFF1F5"
      />
      <path
        d="M8 15.3332L11.3333 11.9998L8 8.6665M12.6667 15.3332L16 11.9998L12.6667 8.6665"
        stroke="#4D4DFF"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
