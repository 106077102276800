import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { baseSelector } from ".";

export function useBase() {
  const baseState = useSelector(baseSelector);
  const baseDispatch = useDispatch<AppDispatch>();

  return { baseState, baseDispatch };
}
