import scssVariables from "@/styles/variables.module.scss";

export default function question() {
  return (
    <svg viewBox="0 0 10 10" fill="none">
      <path
        d="M5 9.0625C7.24365 9.0625 9.0625 7.24365 9.0625 5C9.0625 2.75635 7.24365 0.9375 5 0.9375C2.75635 0.9375 0.9375 2.75635 0.9375 5C0.9375 7.24365 2.75635 9.0625 5 9.0625ZM5 10C2.23857 10 0 7.76143 0 5C0 2.23857 2.23857 0 5 0C7.76143 0 10 2.23857 10 5C10 7.76143 7.76143 10 5 10Z"
        fill={scssVariables.primary}
      />
      <path
        d="M4.4417 6.09984V5.82298C4.4417 5.70641 4.45118 5.60197 4.47015 5.50968C4.48911 5.41738 4.51892 5.32996 4.55955 5.24738C4.60019 5.1648 4.65438 5.08467 4.72211 5.00694C4.78984 4.92922 4.87519 4.84665 4.97813 4.75922L5.34389 4.44592C5.44684 4.36334 5.53489 4.27105 5.60804 4.16905C5.68118 4.06705 5.71776 3.94318 5.71776 3.79747C5.71776 3.60804 5.65139 3.44896 5.51863 3.32024C5.38588 3.19153 5.19759 3.12717 4.95375 3.12717C4.82912 3.12717 4.71669 3.14781 4.61645 3.1891C4.5162 3.23039 4.4295 3.28625 4.35636 3.35668C4.2832 3.42711 4.22767 3.50847 4.18974 3.60076C4.15181 3.69306 4.13014 3.78776 4.12472 3.88491L3.125 3.80477C3.15751 3.57161 3.2266 3.36396 3.33226 3.18182C3.43791 2.99967 3.57474 2.84545 3.7427 2.71916C3.91068 2.59287 4.10304 2.49693 4.31978 2.43137C4.53651 2.3658 4.76951 2.33301 5.01877 2.33301C5.25177 2.33301 5.47257 2.36337 5.68118 2.42408C5.88979 2.48479 6.07402 2.57466 6.23388 2.69366C6.39372 2.81267 6.51971 2.9596 6.61182 3.13446C6.70393 3.30933 6.74999 3.51091 6.74999 3.7392C6.74999 3.89464 6.73103 4.03063 6.6931 4.14722C6.65517 4.2638 6.60098 4.37065 6.53054 4.4678C6.4601 4.56495 6.37476 4.65845 6.27451 4.74831C6.17427 4.83817 6.06184 4.93167 5.93721 5.02882C5.82884 5.1114 5.74078 5.18425 5.67306 5.2474C5.60533 5.31056 5.55114 5.37369 5.5105 5.43684C5.46986 5.49998 5.44142 5.56798 5.42516 5.64084C5.4089 5.7137 5.40077 5.80356 5.40077 5.91042V6.09985H4.44169L4.4417 6.09984ZM4.27915 7.11259C4.27915 6.95715 4.34282 6.82357 4.47016 6.71186C4.59749 6.60014 4.75057 6.54428 4.92938 6.54428C5.10277 6.54428 5.25448 6.59771 5.38453 6.70457C5.51458 6.81144 5.5796 6.94258 5.5796 7.09802C5.5796 7.25346 5.51593 7.38703 5.38859 7.49875C5.26126 7.61047 5.10818 7.66633 4.92938 7.66633C4.84268 7.66633 4.76005 7.65176 4.68147 7.62261C4.6029 7.59346 4.53382 7.55461 4.47422 7.50604C4.41462 7.45746 4.36721 7.39917 4.33198 7.33117C4.29676 7.26317 4.27915 7.19031 4.27915 7.11259Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
