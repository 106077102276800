import PerformanceTime from "../PerformanceTime";
import { ConsolidatedBalanceProps } from "./interfaces";
import * as S from "./styles";

const ConsolidatedBalance = (balanceData: ConsolidatedBalanceProps) => {
  return (
    <S.BalanceContainer>
      <S.DataContent>
        <S.BalanceTitle>{balanceData.title}</S.BalanceTitle>
        <S.BalanceValue>{balanceData.balance}</S.BalanceValue>
      </S.DataContent>
      {!!balanceData.performance && (
        <S.DataBalanceContainer>
          {balanceData.time && <PerformanceTime time={balanceData.time} />}
        </S.DataBalanceContainer>
      )}
    </S.BalanceContainer>
  );
};

export default ConsolidatedBalance;
