import { IDepositInfo } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

type GetDepositInfoParamType = {
  currencyId: string;
  blockchainId?: string;
};

const DepoistsApi = {
  getDepositInfo: async (data: GetDepositInfoParamType) => {
    try {
      const response = await api.request({
        url: "/deposits/infos",
        method: "POST",
        data,
      });
      return response.data as IDepositInfo;
    } catch (error: any) {
      sendMessageSentry(
        `Deposit Infos Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getDepositInfo");
    }
  },
};

export default DepoistsApi;
