import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import { hasCookie } from "cookies-next";
import { TOKEN_COOKIE_KEY } from "@/const";
import {
  fetchCustomer,
  fecthRequiredFields,
  useCustomer,
} from "@/store/features/customer";
import {
  fecthBalances,
  fecthConsolidatedBalance,
  fecthConvertedBalances,
  useBalance,
} from "@/store/features/balance";
import { fecthCurrencies, useCurrency } from "@/store/features/currency";
import { fecthMarkets, useMarket } from "@/store/features/market";

/**
 * A component that fetch and store all common data used by the app.
 *
 * @returns null
 */
export default function InitialLoader() {
  const router = useRouter();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // redux features
  const { balanceState, balanceDispatch } = useBalance();
  const { currencyState, currencyDispatch } = useCurrency();
  const { customerState, customerDispatch } = useCustomer();
  const { marketState, marketDispatch } = useMarket();

  const loadBalances = () => {
    if (balanceState.balances.data === null) {
      balanceDispatch(fecthBalances());
    }
  };

  const loadConvertedBalances = () => {
    if (balanceState.convertedBalances.data === null) {
      balanceDispatch(fecthConvertedBalances());
    }
  };

  const loadConsolidatedBalance = () => {
    if (balanceState.consolidated.data === null) {
      balanceDispatch(fecthConsolidatedBalance());
    }
  };

  const loadCurrencies = () => {
    if (currencyState.currencies.data === null) {
      currencyDispatch(fecthCurrencies());
    }
  };

  const loadCustomer = () => {
    if (customerState.customer.data === null) {
      customerDispatch(fetchCustomer());
    }
  };

  const loadRequiredFields = () => {
    if (customerState.customer.data?.type === "Company") {
      customerDispatch(fecthRequiredFields(customerState.customer.data?.type));
    } else {
      if (customerState.requiredFields.data === null) {
        customerDispatch(
          fecthRequiredFields(customerState.customer.data?.type)
        );
      }
    }
  };

  const loadMarkets = () => {
    if (marketState.markets.data === null) {
      marketDispatch(fecthMarkets());
    }
  };

  // run on every application load
  useEffect(() => {
    loadCurrencies();
    loadMarkets();
  }, []);

  // run on every router path change
  useEffect(() => {
    // checking if there is a token in the cookie
    const hasToken = hasCookie(TOKEN_COOKIE_KEY);
    setIsLoggedIn(hasToken);
  }, [router.pathname]);

  // run on every isLoggedIn change
  useEffect(() => {
    // if logged in, fetch the global customer data
    if (isLoggedIn) {
      loadCustomer();
      loadBalances();
      loadConvertedBalances();
      loadConsolidatedBalance(); // TODO: consider change it to another moment...
    }
  }, [isLoggedIn]);

  useEffect(() => {
    // if logged in, fetch the global customer data
    if (isLoggedIn && !!customerState.customer.data?.type) {
      loadRequiredFields();
    }
  }, [isLoggedIn, customerState.customer.data?.type]);

  useEffect(() => {
    const requiredFields = customerState?.requiredFields?.data;
    const hasRequiredFieldsSumsub =
      requiredFields?.length === 1 &&
      requiredFields?.filter((e: any) => e.name === "sumsub");
    const hasRequiredFields =
      requiredFields?.length >= 1 && !hasRequiredFieldsSumsub;

    const redirect = hasRequiredFieldsSumsub
      ? "/sumsub"
      : hasRequiredFields
      ? "/onboarding"
      : null;

    if (router.pathname !== "/onboarding" && router.pathname !== "/sumsub") {
      if (redirect) {
        router.push(redirect);
      }
    }
  }, [customerState.requiredFields.data]);

  return null;
}
