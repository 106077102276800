import useLocale from "./useLocale";

type I18nType = {
  en: any;
  es: any;
  pt: any;
};

export default function useI18n(content: I18nType) {
  const { locale, dateFormat } = useLocale();
  const lang = content[locale] !== undefined ? locale : "pt";

  return {
    i18n: (key: string) => {
      return content[lang][key] !== undefined ? content[lang][key] : "";
    },
    i18nComp: (components: I18nType) => {
      return components[lang] !== undefined ? components[lang] : "";
    },
    locale,
    dateFormat,
  };
}
