import { ISession } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

const SessionsApi = {
  getSession: async (username: string, password: string) => {
    try {
      const response = await api.request({
        url: "/sessions",
        data: { username, password },
        method: "POST",
      });
      return response.data as ISession;
    } catch (error: any) {
      sendMessageSentry(
        `Login Session Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getSession");
    }
  },
  refreshToken: async (refreshToken: string) => {
    try {
      const response = await api.request({
        url: "/sessions/refresh",
        data: { refreshToken },
        method: "POST",
      });
      return response.data as ISession;
    } catch (error: any) {
      sendMessageSentry(
        `Login Refresh Session Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "refreshToken");
    }
  },
};

export default SessionsApi;
