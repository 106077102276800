import styles from "./index.module.scss";

type SpinnerProps = {
  position?: "left" | "center" | "right";
  size?: "small" | "medium";
  children?: any;
  className?: string;
};

export default function Spinner({
  position = "center",
  size = "medium",
  children,
  className = "",
}: SpinnerProps) {
  return (
    <div
      className={`Spinner ${styles.container} ${styles[position]} ${styles[size]} ${className}`}
    >
      <svg viewBox="0 0 38 38">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(1 1)" strokeWidth="2">
            <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
            <path d="M36 18c0-9.94-8.06-18-18-18">
              <animateTransform
                attributeName="transform"
                type="rotate"
                from="0 18 18"
                to="360 18 18"
                dur="1s"
                repeatCount="indefinite"
              />
            </path>
          </g>
        </g>
      </svg>
      {children && <div className={styles.description}>{children}</div>}
    </div>
  );
}
