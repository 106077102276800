import Link from "next/link";

export default function Pt_i18n() {
  // TODO: atualizar links dessa página quando fluxos estiverem prontos
  return (
    <>
      <p>Prezado Usuário,</p>
      <p>
        Esta Política de Privacidade é parte integrante dos Termos e Condições
        de Uso da Plataforma Transfero (
        <Link
          href="https://app.transfero.com/"
          target="_blank"
          rel="noreferrer"
        >
          app.transfero.com
        </Link>
        ), e descreve os termos e as condições aplicáveis ao uso das informações
        fornecidas pelo Usuário à Transfero.
      </p>
      <p>
        Quando não definido nesta Política de Privacidade, os conceitos e
        expressões em letras maiúsculas utilizados aqui terão o significado dado
        nos Termos e Condições de Uso da Plataforma Transfero.
      </p>
      <p>
        Os assuntos tratados nesta Política de Privacidade são os seguintes:
      </p>
      <p>
        <em>- Quais dados coletamos;</em>
        <br />
        <em>- Como coletamos os dados;</em>
        <br />
        <em>- Como usamos os dados coletados;</em>
        <br />
        <em>- Como compartilhamos os dados coletados;</em>
        <br />
        <em>- Direito dos titulares dos dados coletados (Usuários);</em>
        <br />
        <em>- Cookies e Publicidade</em>
        <br />
      </p>
      <p>
        De forma a simplificar o entendimento pelo Usuário, a tabela abaixo
        apresenta de forma concisa e simplificada a sistemática da coleta de
        dados pela Transfero, o que não isenta o Usuário da necessidade de
        leitura das demais disposições desta presente Política de Privacidade.
      </p>
      <h1>Dados cadastrais</h1>
      <br />
      <p>
        <em>1. Dados coletados</em>
        <br />
        Nome completo, gênero, data de nascimento, naturalidade, nacionalidade,
        estado civil, nome do cônjuge ou companheiro, filiação,
        CPF/ID/CNH/Passaporte, e-mail, endereço completo, ocupação profissional,
        telefone fixo, celular, renda anual, dados bancários (banco, agência e
        número de conta), declaração de pessoa politicamente exposta e
        autorretrato portanto documento de identificação.
      </p>
      <p>
        <em>2. Para quem coletamos?</em>
        <br />
        - Verificar a identidade do Usuário; <br />
        - Realizar procedimento de “Know Your Costumer” para garantir a
        segurança do Usuário e prevenir que fraudes sejam perpetuadas utilizando
        os dados pessoais do Usuário;
        <br />- Garantir que as transações bancárias efetuadas são realizadas
        apenas para contas bancárias de titularidade do Usuário.
      </p>
      <p>
        <em>3. Como coletamos?</em>
        <br />O próprio Usuário informa no momento de seu cadastro junto à
        Plataforma da Transfero
      </p>
      <p>
        <em>4. Base Legal prevista na LGPD para a coleta</em>
        <br />
        - Cumprimento de Obrigação Legal (Art. 7°, II); <br />- Garantia de
        Prevenção à Fraude (Art. 11, II, g)
      </p>
      <h1>Dados de identificação Digital</h1>
      <br />
      <p>
        <em>1. Dados coletados</em>
        <br />
        Endereço de IP e Porta Lógica de Origem; IMEI do Dispositivo Móvel pelo
        qual o Usuário acessou a Plataforma Transfero; Dispositivo (versão do
        sistema operacional, fabricante, dados dos aplicativos instalados,
        linguagens, permissões e configurações de teclados, redes wi-fi e rede
        móvel utilizadas, informações sobre o consumo de internet, memória,
        utilização de VPN, status da bateria); Navegador sua respectiva versão;
        Geolocalização; Registro de datas e horários de cada ação do Usuário;
        Telas acessadas pelo Usuário; Informações referentes às quantidades de
        cliques e tentativas de uso da Plataforma Transfero e/ou aplicativo
        móvel; ID da sessão; Cookies, Resolução da Tela, Java, Informações sobre
        os serviços e produtos que o Usuário procura e usa; Páginas e
        aplicativos que originaram o acesso à Plataforma Transfero; e
        aplicativos que foram acessados durante e após a navegação na Plataforma
        Transfero; Contatos cadastrados no dispositivo, Reprodutores de Flash
        instalado; Quantidade de cliques e velocidade de digitação
      </p>
      <p>
        <em>2. Para quem coletamos?</em>
        <br />
        - Identificar e autenticar o acesso do Usuário à Plataforma Transfero,
        impedindo que terceiros acessem o sistema de forma fraudulenta; <br />
        - Identificar “bugs”, erros e inconsistências na Plataforma Transfero
        para que tais erros possam ser corrigidos e aperfeiçoar o acesso pelo
        Usuário; <br />- Analisar o desempenho e medir a audiência da Plataforma
        Transfero; - Analisar a segurança da Plataforma Transfero e resiliência
        a eventuais ataques
      </p>
      <p>
        <em>3. Como coletamos?</em>
        <br />
        Os dados são coletados conforme o Usuário acessa, permanece e utiliza a
        Plataforma Transfero.
      </p>
      <p>
        <em>4. Base Legal prevista na LGPD para a coleta</em>
        <br />- Consentimento (Art. 7°, I); e - Cumprimento de Obrigação Legal
        (Art. 7°, II).
      </p>
      <h1>Dados da Wallet do Usuário</h1>
      <br />
      <p>
        <em>1. Dados coletados</em>
        <br />
        Chaves de API de negociação e endereço de carteira criptografada
        (wallets)
      </p>
      <p>
        <em>2. Para quem coletamos?</em>
        <br />- Garantir que os saques e/ou depósitos sejam realizados com
        segurança para o destinatário final
      </p>
      <p>
        <em>3. Como coletamos?</em>
        <br />O Usuário informa ao solicitar um depósito/saque
      </p>
      <p>
        <em>4. Base Legal prevista na LGPD para a coleta</em>
        <br />- Execução de Contrato (Art. 7°, V)
      </p>
      <h1>Dados do Atendimento</h1>
      <br />
      <p>
        <em>1. Dados coletados</em>
        <br />
        Conversas em texto, áudio ou vídeo quando realizadas em contato com os
        canais de atendimento da Transfero
      </p>
      <p>
        <em>2. Para quem coletamos?</em>
        <br />
        - Permitir a resolução de problemas ocorridos com o Usuário;
        <br />- Aferição da qualidade do atendimento ao Usuário
      </p>
      <p>
        <em>3. Base Legal prevista na LGPD para a coleta</em>
        <br />- Cumprimento de Obrigação Legal (Art. 7, II)
      </p>
      <h1>Dados de Acesso à Plataforma Transfero</h1>
      <br />
      <p>
        <em>1. Dados Coletados</em>
        <br />
        Nome de usuário, senha de acesso, palavra de segurança, PIN de segurança
        e informações de segurança
      </p>
      <p>
        <em>2. Para quem coletamos?</em>
        <br />- Garantir a autenticidade do acesso do Usuário à Plataforma
        Transfero, além de garantir a segurança de suas transações.
      </p>
      <p>
        <em>3. Como coletamos?</em>
        <br />
        Coletados no momento de cadastro do Usuário na Plataforma Transfero
      </p>
      <p>
        <em>4. Base Legal prevista na LGPD para a coleta</em>
        <br />- Cumprimento de Obrigação Legal (Art. 7, II)
      </p>
      <h1>Dados de Comunicação com o Usuário e Marketing</h1>
      <br />
      <p>
        <em>1. Dados coletados</em>
        <br />
        Nome completo, e-mail e telefone celular
      </p>
      <p>
        <em>2. Para que coletamos?</em>
        <br />
        - Contactar o Usuário para eventuais avisos sobre a Plataforma
        Transfero;
        <br />- Promover produtos e/ou serviços da Transfero que podem ser de
        interesse do Usuário.
      </p>
      <p>
        <em>3. Como Coletamos?</em>
        <br />
        Coletado no momento do cadastro junto à Plataforma Transfero
      </p>
      <p>
        <em>4. Base Legal prevista na LGPD para a coleta</em>
        <br />- Consentimento (Art. 7°, I)
      </p>
      <h1>1. ACEITE E ALTERAÇÕES</h1>
      <p>
        1.1 Ao manifestar Aceite aos Termos de Uso da Plataforma Transfero, os
        quais esta Política de Privacidade integra como anexo, o Usuário declara
        expressamente e para todos os fins de direito que leu, entendeu e
        compreendeu todos os direitos e obrigações determinados no Termos e
        Condições da Transfero, e, ainda, anui com todas as disposições desta
        Política de Privacidade. A utilização da Plataforma Transfero indica que
        o Usuário concorda com a presente Política de Privacidade.
      </p>
      <p>
        1.2 Esta Política de Privacidade abrange o tratamento atribuído pela
        Transfero aos dados capazes de identificar o Usuário, o que nos termos
        do Art. 5°, V c/c VI da Lei 13.709/2018, a Lei Geral de Proteção de
        Dados (“LGPD”), classifica a Transfero como controladora dos dados e o
        Usuário como titular dos dados coletados.
      </p>
      <p>
        1.3 Esta Política de Privacidade está sujeita a constantes melhorias e
        aprimoramentos. Assim, recomendamos sua leitura periódica.
      </p>

      <h1>2. USO DE DADOS DE USUÁRIOS</h1>
      <p>
        2.1 Os dados de Usuários podem ser coletados pela Plataforma Transfero
        quando o Usuário: (i) navega, realiza seu cadastro e Transações na
        Plataforma Transfero; (ii) compartilha informações com a Plataforma
        Transfero objetivando a melhoria dos sistemas mantidos pela Transfero;
        (iii) entra em contato com canais de atendimento.
      </p>
      <p>
        2.2 A Plataforma Transfero poderá obter dados de Usuários por meio de
        parceiros externos, provedores de informação, redes sociais (quando
        previamente autorizado por cada Usuário), fontes públicas oficiais,
        dentre outros.
      </p>
      <p>
        2.3 Dados pessoais de Usuários serão solicitados para que cada Usuário
        tenha acesso à Plataforma Transfero. Dentre tais dados, serão
        solicitados: nome completo; data e local de nascimento; nacionalidade;
        gênero; CPF/RG/CNH; filiação; e-mail; telefone celular; telefone fixo;
        endereço completo; país de residência; estado civil; nome do cônjuge ou
        companheiro; profissão; renda mensal; patrimônio; declaração de pessoa
        politicamente exposta, cópia de documento de identificação e cópia de
        comprovante de residência.
      </p>
      <p>
        2.4 Sem o envio de tais documentos e informações à Plataforma Transfero,
        o uso dos canais e funcionalidades da Transfero poderá sofrer restrições
        e, dependendo do caso, cancelamento. A Transfero poderá, conforme o
        caso, solicitar outros documentos para o Usuário, com o intuito de
        garantir o acesso à Plataforma Transfero e garantir a segurança do
        Usuário.
      </p>
      <p>
        2.5 O Usuário que se recusar ou deixar de fornecer dados básicos
        necessários poderá ter seu acesso à Plataforma Transfero negado ou
        cancelado.
      </p>
      <p>
        2.6 Ao realizar cadastro junto à Plataforma Transfero, os seguintes
        dados e informações poderão ser coletados, retidos e/ou salvo pela
        Transfero, entre outros:
      </p>
      <p>
        - Dados de contato: número de telefone e outros dados semelhantes.
        <br />
        - Credenciais: inclusive senhas, palavras seguras, PIN de segurança e
        informações de segurança necessárias para o processo de autenticação,
        acesso à Plataforma Transfero e quaisquer Transações.
        <br />
        - Dados financeiros: coletamos dados necessários para a realização de
        saques em Moeda Corrente (R$), como banco e agência. Além disto, será
        necessário que a Transfero receba chaves de API de negociações e
        endereço de carteira criptografada (Wallet).
        <br />
        - Dados demográficos: sexo, endereço, escolaridade, renda.
        <br />
        - Dados de uso: Além de dados relacionados às Transações, a Transfero
        também poderá captar dados relacionados à interação com outros Usuários
        incluindo seu perfil de Usuário, também podemos captar dados
        relacionados a sua interação com nossas comunicações e em nossos canais,
        dados de dispositivo, sistema operacional e identificador de dispositivo
        (IMEI) e configuração.
        <br />
        - Dados de atendimento: Sua interação em nossos canais de atendimento
        também fica registrada, assim como outros detalhes de seu contato,
        podendo incluir conteúdo de conversas em chat.
        <br />
        - Dados de relações: Apenas quando concedida inequívoca permissão,
        podemos captar dados de quais são os contatos de sua rede de
        relacionamento.
        <br />- Dados de geolocalização: É possível que sejam coletados dados de
        localização originados de dados de GPS (Sistema de Navegação Global),
        GNSS (Global Navigation Satellite System), de torres de comunicações
        móveis, pontos de acesso Wi-fi ou localização decorrente do IP de cada
        Usuário.
      </p>
      <p>
        2.7 A Plataforma Transfero desempenhará seus melhores esforços para que
        todos os dados pessoais coletados sejam armazenados em servidores ou
        meios magnéticos de alta segurança, sempre aplicando práticas
        recomendáveis de combate a desastres ou interrupção de suas atividades.
      </p>
      <p>
        2.8 O cadastro na Plataforma Transfero ensejará, além de acesso aos
        sistemas mantidos pela Transfero, o recebimento de mensagens eletrônicas
        (como, por exemplo, e-mails e pushs) de cunhos de segurança e
        administrativo, além de mensagens publicitárias, sendo esse envio
        imprescindível à execução e desenvolvimento das atividades da 3RZ. Caso
        não concorde com este procedimento, será necessário solicitar o
        cancelamento de seu cadastro à Plataforma Transfero.
      </p>
      <h1>3. USO DOS DADOS COLETADOS</h1>
      <p>
        3.1 Os dados de Usuários poderão ser coletados para fins de
        aprimoramento da Plataforma Transfero, e proteção de tais dados com base
        na Lei Geral de Proteção de Dados e nos Termos e Condições Transfero.
        Sem prejuízo disto, tais dados coletados poderão ser utilizados para:
      </p>
      <p>
        - Verificar a identidade do Usuário;
        <br />
        - Realizar procedimento de “Know Your Costumer” para garantir a
        segurança do Usuário e prevenir que fraudes sejam perpetuadas utilizando
        os dados pessoais do Usuário;
        <br />
        - Garantir que as transações bancárias efetuadas são realizadas apenas
        para contas bancárias de titularidade do Usuário;
        <br />
        - Garantir que os saques e/ou depósitos sejam realizados com segurança
        para o destinatário final;
        <br />
        - Identificar e autenticar o acesso do Usuário à Plataforma Transfero,
        impedindo que terceiros acessem o sistema de forma fraudulenta;
        <br />
        - Realização de Transações na Plataforma Transfero, criação Ordens de
        Compra e Ordens de Venda, gerar e permitir acesso à Conta Virtual,
        dentre outras atividades que dependam de autorização e anuência do
        Usuário;
        <br />
        - Solucionar problemas e dúvidas;
        <br />
        - Aferição da qualidade do atendimento ao Usuário;
        <br />
        - Analisar o desempenho e medir a audiência da Plataforma E376 verificar
        hábitos de navegação de E376 avaliar estatísticas relacionadas ao número
        de acessos e uso da Plataforma Transfero, inclusive seus recursos e
        funcionalidades;
        <br />
        - Identificar “bugs”, erros e inconsistências na Plataforma Transfero,
        para que tais erros possam ser corrigidos e aperfeiçoar o acesso pelo
        Usuário;
        <br />
        - Analisar a segurança da Plataforma Transfero, e resiliência a
        eventuais ataques, com o fim de aprimorar ferramentas de segurança;
        <br />
        - Personalizar conteúdo para as necessidades do Usuário, inclusive no
        que diz respeito a e-mail marketing e promoções;
        <br />
        - Medidas julgadas necessárias ou recomendáveis pela Transfero para a
        constante melhoria das funcionalidades da Plataforma Transfero, bem como
        para melhor entender as necessidades dos Usuários;
        <br />
        - Elaboração de estatística do uso da Plataforma Transfero;
        <br />
        - notificação dos Usuários a respeito de alterações na Plataforma
        Transfero;
        <br />
        - implementação dos mecanismos e políticas de controles internos da
        Plataforma Transfero;
        <br />
        - cumprimento de outras obrigações legais e regulatórias impostas à
        Plataforma Transfero;
        <br />- Personalizar a experiência do Usuário no uso da Plataforma
        Transfero.
      </p>
      <p>
        3.2 A Plataforma Transfero enviará mensagens por meios eletrônicos como
        e-mails e pushs para atividades da Plataforma Transfero, e outros fins
        publicitários e comunicados. A frequência de envio de tais comunicações
        pode variar.
      </p>
      <p>
        3.3 A qualquer momento o Usuário poderá solicitar a interrupção do
        processamento de suas informações pela Plataforma Transfero, inclusive
        solicitar sua total exclusão dos sistemas da Transfero e a interrupção
        de uso da Plataforma Transfero.
      </p>
      <p>
        3.4 Os dados coletados serão armazenados pela Transfero por até 5
        (cinco) anos após o término da relação do Usuário com a Plataforma,
        podendo esse prazo ser estendido para o cumprimento de obrigação legal
        ou regulatória.
      </p>
      <p>
        3.5 As informações coletadas de e sobre os Usuários da Plataforma
        Transfero também poderão ser (KYC) e Prevenção e Combate à Lavagem de
        Dinheiro (AML).
      </p>
      <p>
        3.6 Os dados e informações do Usuário e suas atividades também poderão
        ser compartilhados com terceiros, nos seguintes casos:
      </p>
      <p>
        - funcionários e empresas do grupo econômico ao qual a Transfero
        pertence e respectivos parceiros ou contratados;
        <br />
        - sociedades integrantes do mesmo grupo econômico da Transfero, sejam
        brasileiras ou estrangeiras;
        <br />
        - autoridades governamentais que exijam a divulgação, por lei ou ordem
        judicial, da totalidade ou qualquer parte das informações; e
        <br />- quaisquer outros terceiros que exijam as informações devido a
        (i) obrigações legais e regulatórias; e/ou (ii) necessária ou
        recomendável divulgação das informações para a devida, efetiva e
        eficiente operação da Plataforma Transfero e suas funcionalidades.
      </p>
      <h1>4. COMPARTILHAMENTO DE DADOS COM TERCEIROS</h1>
      <p>
        4.1 Os dados coletados serão tratados sempre pela Transfero ou por
        parceiros contratados exclusivamente para este fim. Ao aderir aos Termos
        e Condições Transfero o Usuário autoriza que tal compartilhamento seja
        realizado, sempre observando o disposto na legislação aplicável.
      </p>
      <p>
        4.2 As informações pessoalmente identificáveis não serão utilizadas para
        fins de ações de marketing ou qualquer outra finalidade prevista nos
        Termos e Condições Transfero, e serão tratadas em conformidade com a Lei
        Geral de Proteção de Dados.
      </p>
      <p>
        4.3 Em todos os casos em que haja o compartilhamento de informações,
        será exigido da empresa contratada os mesmos padrões de
        confidencialidade, anonimização e guarda das informações que os adotados
        pela Transfero e pela Plataforma Transfero.
      </p>
      <p>
        4.4 A Transfero poderá revelar dados e informações de qualquer Usuário,
        caso tal revelação seja exigida por lei ou por autoridade governamental,
        inclusive poder judiciário, agências reguladoras, órgãos municipais ou
        estaduais, sem prévio aviso ao Usuário. Em todo caso, na hipótese de
        revelação de tais informações, o Usuário será informado sobre tal
        revelação em até 72 (setenta e duas) horas, contadas da data em que a
        informação for revelada.
      </p>
      <p>
        4.5 A Plataforma Transfero pode conter links que levam a outros sítios e
        páginas de internet. É possível que as políticas e práticas adotadas por
        estes sítios e páginas não sejam compatíveis com aquelas adotadas pela
        Transfero e na Plataforma Transfero. A Transfero não se responsabiliza
        pela guarda, uso e tratamento de informações fora de seu ambiente.
      </p>
      <h1>5. COOKIES E TECNOLOGIAS SEMELHANTES</h1>
      <p>
        5.1 A Plataforma Transfero pode fazer uso de cookies e outras
        tecnologias semelhantes para suportar o processo de identificação de
        Usuários, inclusive para fins de ações de marketing e proteção de dados
        coletados.
      </p>
      <p>
        5.2 Estas tecnologias têm como propósito armazenar informações em
        navegadores web, e podem estar presentes em computadores, telefones e/ou
        outros dispositivos móveis compatíveis com a Plataforma Transfero.
      </p>
      <p>
        5.3 Cookies são relevantes para processos de autenticação, publicidade,
        recomendações, mensurações de audiência e recursos e funcionalidades dos
        canais, análises de segurança para aperfeiçoamento e desenvolvimento de
        ferramentas antifraude.
      </p>
      <p>
        5.4 O Usuário que desejar poderá desabilitar os cookies nas opções de
        configuração do seu navegador, estando cientes de que as funcionalidades
        e serviços da Plataforma Transfero podem não ser fornecidos, ou
        fornecidos parcialmente.
      </p>
      <h1>6. SEGURANÇA DA INFORMAÇÃO E LEI DE PROTEÇÃO DE DADOS</h1>
      <p>
        6.1 A Transfero se compromete a utilizar os melhores padrões de mercado
        para proteger os dados de seus Usuários e cumprir, a todo momento, a Lei
        Geral de Proteção de Dados.
      </p>
      <p>
        6.2 A Conta Virtual é protegida por uma senha pessoal e intransferível,
        a qual existe para privacidade e segurança dos Usuários.
      </p>
      <p>
        6.3 Em virtude do disposto na Lei Geral de Proteção de Dados, o
        tratamento de dados pela Transfero se orientará pelos seguintes
        fundamentos:
      </p>
      <ol>
        a) Respeito à privacidade; b) Autodeterminação informativa; c) Liberdade
        de expressão, de informação, de comunicação e de opinião; d)
        Inviolabilidade da intimidade, da honra e da imagem; e) Desenvolvimento
        econômico e tecnológico e a inovação; f) Livre iniciativa, a livre
        concorrência e a defesa do consumidor; e g) Direitos humanos, o livre
        desenvolvimento da personalidade, a dignidade e o exercício da cidadania
        pelas pessoas naturais.
      </ol>
      <h1>7. DISPOSIÇÕES FINAIS</h1>
      <p>
        7.1 A Transfero poderá, independentemente de qualquer aviso ou
        notificação ao Usuário, ceder os direitos oriundos desta Política de
        Privacidade, no todo ou em parte, a outras empresas, caso ocorra algum
        rearranjo societário da Transfero, tais como fusão ou aquisição, a
        qualquer momento, desde que observada a legislação vigente, regulamentos
        e normas aplicáveis a esta Política de Privacidade.
      </p>
      <p>
        7.2 A Transfero se reserva ao direito de reter informações pelo período
        que entender necessário para o bom cumprimento de seus negócios e
        atendimento às regulamentações vigentes, mesmo após o encerramento da
        conta do Usuário.
      </p>
      <p>
        7.3 A tolerância do Usuário com a Plataforma Transfero e vice-versa,
        quanto ao descumprimento de qualquer uma das obrigações assumidas nesta
        Política de Privacidade não implicará em novação ou renúncia de direito.
        Poder-se-á, a qualquer tempo, exigir o fiel e cabal cumprimento desta
        Política de Privacidade.
      </p>
      <p>
        7.4 A presente Política de Privacidade vincula o Usuário e a Plataforma
        Transfero e seus sucessores a qualquer título.
      </p>
      <p>
        7.5 A inviabilidade da execução ou invalidade, ainda que em virtude de
        decisão judicial, de alguma cláusula, não afetará a validade das demais
        cláusulas desta Política de Privacidade, que permanecerá válida e
        executável.
      </p>
      <p>
        7.6 A Transfero se reserva o direito de modificar a presente Política de
        Privacidade a qualquer tempo. Nesta hipótese, o Usuário será devidamente
        notificado sobre tais alterações, e seu Aceite. Será observado, neste
        caso, o disposto nos Termos de Uso.
      </p>
    </>
  );
}
