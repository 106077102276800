import styles from "./index.module.scss";

type TableHeadProps = {
  cols: any[];
  className?: string;
};

export default function TableHead({ cols, className = "" }: TableHeadProps) {
  return (
    <div className={`TableHead ${styles.container} ${className}`}>
      {cols.map((item: any, index: number) => (
        <div key={index} style={{ width: `${100 / cols.length}%` }}>
          {item}
        </div>
      ))}
    </div>
  );
}
