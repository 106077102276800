import { QRCodeSVG } from "qrcode.react";
import { Image } from "@/widgets";

import styles from "./index.module.scss";

type QRCodeProps = {
  value: string;
  isImage?: boolean;
  alt?: string;
  size?: number;
  label?: string;
  position?: "left" | "center" | "right";
};

const getQRCode = (
  isImage: boolean,
  value: string,
  alt: string,
  size: number
) => {
  if (isImage) {
    return (
      <div style={{ width: `${size}px`, height: `${size}px` }}>
        <Image src={value} alt={alt} />
      </div>
    );
  }

  return (
    <QRCodeSVG
      value={value}
      size={size}
      bgColor={"#ffffff"}
      fgColor={"#000000"}
      level={"L"}
      includeMargin={false}
    />
  );
};

export default function QRCode({
  value,
  isImage = false,
  alt = "QR code image",
  size = 150,
  label,
  position = "center",
}: QRCodeProps) {
  return (
    <div className={`QRCode ${styles.qrcode} ${styles[position]}`}>
      {label && <span>{label}</span>}
      <figure>{getQRCode(isImage, value, alt, size)}</figure>
    </div>
  );
}
