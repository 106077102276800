import { api, throwApiError } from "./axios";
import { IWalletInvestments } from "@/models";

const InvestmentsApi = {
  getWallet: async () => {
    try {
      const response = await api.request({
        url: "/prime/wallet",
        method: "GET",
      });
      return response.data as IWalletInvestments;
    } catch (error: any) {
      throwApiError(error, "walletError");
    }
  },
};

export default InvestmentsApi;
