export default function lineChartUp() {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="27" height="27" rx="13.5" fill="#EBEBFF" />
      <path
        d="M18.25 18.25H8.68333C8.35664 18.25 8.19329 18.25 8.06851 18.1864C7.95874 18.1305 7.86951 18.0413 7.81358 17.9315C7.75 17.8067 7.75 17.6434 7.75 17.3167V7.75M18.25 10.0833L15.08 13.2534C14.9645 13.3689 14.9067 13.4266 14.8401 13.4482C14.7816 13.4673 14.7184 13.4673 14.6599 13.4482C14.5933 13.4266 14.5355 13.3689 14.42 13.2534L13.33 12.1633C13.2145 12.0478 13.1567 11.9901 13.0901 11.9684C13.0316 11.9494 12.9684 11.9494 12.9099 11.9684C12.8433 11.9901 12.7855 12.0478 12.67 12.1633L10.0833 14.75M18.25 10.0833H15.9167M18.25 10.0833V12.4167"
        stroke="#4D4DFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
