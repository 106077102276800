import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { IApiError, IPair } from "@/models";
import { TradesApi } from "@/apis";
import { TradeState } from ".";

export const fecthTrades = createAsyncThunk(
  "fetch/pairs",
  async (_, { rejectWithValue }) => {
    try {
      return (await TradesApi.getPairs()) as IPair[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const tradeExtraReducerBuilder = (
  builder: ActionReducerMapBuilder<TradeState>
) => {
  builder.addCase(fecthTrades.pending, (state) => {
    state.pairs.error = null;
    state.pairs.loading = true;
  });
  builder.addCase(fecthTrades.fulfilled, (state, action) => {
    state.pairs.data = action.payload as IPair[];
    state.pairs.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.pairs.error = null;
    state.pairs.loading = false;
  });
  builder.addCase(fecthTrades.rejected, (state, action) => {
    state.pairs.data = null;
    state.pairs.error = action.payload as IApiError;
    state.pairs.loading = false;
    state.pairs.lastUpdate = null;
  });
};
