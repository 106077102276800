import scssVariables from "@/styles/variables.module.scss";

export default function support() {
  return (
    <svg viewBox="0 0 11 11" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64169 4.51384C9.47378 2.79134 8.45544 0.0830078 5.30836 0.0830078C2.16128 0.0830078 1.14294 2.79134 0.975026 4.51384C0.38597 4.7374 -0.00258079 5.30296 1.29062e-05 5.93301V6.69134C1.29062e-05 7.52897 0.679061 8.20801 1.51669 8.20801C2.35432 8.20801 3.03336 7.52897 3.03336 6.69134V5.93301C3.03064 5.31656 2.65636 4.76263 2.08544 4.53009C2.19378 3.53342 2.72461 1.16634 5.30836 1.16634C7.89211 1.16634 8.41753 3.53342 8.52586 4.53009C7.9561 4.76313 7.58371 5.31743 7.58336 5.93301V6.69134C7.58571 7.27362 7.92006 7.80354 8.44461 8.05634C8.21711 8.48426 7.63753 9.06384 6.21294 9.23717C5.92817 8.80476 5.37723 8.63429 4.89802 8.83032C4.4188 9.02635 4.14528 9.53408 4.24522 10.0421C4.34515 10.5501 4.7906 10.9163 5.30836 10.9163C5.7175 10.9141 6.09046 10.6815 6.27253 10.3151C8.59628 10.0497 9.33836 8.85259 9.57128 8.14842C10.2014 7.94431 10.6252 7.35365 10.6167 6.69134V5.93301C10.6193 5.30296 10.2307 4.7374 9.64169 4.51384ZM1.95003 6.69134C1.95003 6.93066 1.75602 7.12467 1.51669 7.12467C1.27737 7.12467 1.08336 6.93066 1.08336 6.69134V5.93301C1.08336 5.77819 1.16595 5.63514 1.30003 5.55773C1.4341 5.48032 1.59929 5.48032 1.73336 5.55773C1.86743 5.63514 1.95003 5.77819 1.95003 5.93301V6.69134ZM8.66669 5.93301C8.66669 5.69368 8.8607 5.49967 9.10003 5.49967C9.33935 5.49967 9.53336 5.69368 9.53336 5.93301V6.69134C9.53336 6.93066 9.33935 7.12467 9.10003 7.12467C8.8607 7.12467 8.66669 6.93066 8.66669 6.69134V5.93301Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
