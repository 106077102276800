import { ReactNode } from "react";
import styles from "./index.module.scss";

type TextBannerProps = {
  title: ReactNode;
  text: ReactNode;
};

export default function TextBanner({ title, text }: TextBannerProps) {
  return (
    <div className={`TextBanner ${styles.container}`}>
      <h1>{title}</h1>
      <p>{text}</p>
    </div>
  );
}
