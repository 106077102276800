export default function icoLogoTransferGray() {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.49723 1L7.49447 4.00405L4.49723 7.00809L1.5 4.00405L4.49723 1ZM4.49723 1H10.4917L16.4862 7.00809M4.49723 1L10.4917 7.00809M16.4862 7.00809L19.4834 4.00405L16.4862 1L13.4889 4.00405L16.4862 7.00809ZM16.4862 7.00809H10.4917M10.4917 7.00809L13.4889 10.0121L10.4917 13.0162L7.49447 10.0121L10.4917 7.00809Z"
        stroke="#B9C0D4"
      />
    </svg>
  );
}
