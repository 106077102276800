import scssVariables from "@/styles/variables.module.scss";

export default function edit() {
  return (
    <svg viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0041 0.166237C10.2257 -0.0554122 10.5851 -0.0554122 10.8067 0.166237L13.8338 3.19326C14.0554 3.41491 14.0554 3.77428 13.8338 3.99593L3.99593 13.8338C3.88949 13.9402 3.74512 14 3.59459 14H0.567568C0.254109 14 0 13.7459 0 13.4324V10.4054C0 10.2549 0.0597971 10.1105 0.166237 10.0041L10.0041 0.166237ZM8.9378 2.83784L11.1622 5.0622L12.6298 3.59459L10.4054 1.37023L8.9378 2.83784ZM10.3595 5.86486L8.13514 3.6405L1.13514 10.6405V12.8649H3.3595L10.3595 5.86486Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
