import { ForwardedRef, InputHTMLAttributes, forwardRef } from "react";
import Icon, { AvailableIconsType } from "@/widgets/Icons";

import styles from "./index.module.scss";

type NiceSelectorProps = {
  icon?: AvailableIconsType;
  label: string;
  type?: "radio" | "checkbox";
  hasError?: boolean;
};

type FieldType = NiceSelectorProps & InputHTMLAttributes<HTMLInputElement>;

const TheSelector = (
  { icon, label, type = "radio", hasError, ...props }: FieldType,
  ref: ForwardedRef<HTMLInputElement>
) => {
  return (
    <label
      className={`NiceSelector ${styles.container} ${
        hasError ? styles.hasError : ""
      }`}
    >
      <input type={type} ref={ref} {...props} />
      <div className={styles.box}>
        {icon && (
          <div className={styles.iconContainer}>
            <Icon icon={icon} />
          </div>
        )}
        <div>{label}</div>
      </div>
      <div className={styles.checkmark}>
        <Icon icon="ok" />
      </div>
    </label>
  );
};

const NiceSelector = forwardRef<HTMLInputElement, FieldType>(TheSelector);

export default NiceSelector;
