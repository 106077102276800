import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { IAsyncState, IBalance, IConvertedBalance } from "@/models";
import { balanceReducer } from "./reducers";
import { useBalance } from "./hooks";
import {
  fecthBalances,
  balanceExtraReducerBuilder,
  fecthConsolidatedBalance,
  fecthConvertedBalances,
} from "./thunks";

interface AsyncBalance extends IAsyncState {
  data: null | IBalance[];
}

interface AsyncConsolidated extends IAsyncState {
  data: null | IBalance;
}

interface AsyncConvertedBalance extends IAsyncState {
  data: null | IConvertedBalance[];
}

export interface BalanceState {
  balances: AsyncBalance;
  consolidated: AsyncConsolidated;
  convertedBalances: AsyncConvertedBalance;
}

export const initialState: BalanceState = {
  balances: {
    data: null,
    error: null,
    lastUpdate: null,
    loading: false,
  },
  consolidated: {
    data: null,
    error: null,
    lastUpdate: null,
    loading: false,
  },
  convertedBalances: {
    data: null,
    error: null,
    lastUpdate: null,
    loading: false,
  },
};

export const balanceSlice = createSlice({
  name: "balance",
  initialState,
  reducers: balanceReducer,
  extraReducers: balanceExtraReducerBuilder,
});

export const { clearBalance, updateBalance } = balanceSlice.actions;

export {
  useBalance,
  fecthBalances,
  fecthConsolidatedBalance,
  fecthConvertedBalances,
};

export const balanceSelector = (state: RootState) => state.balance;

export default balanceSlice.reducer;
