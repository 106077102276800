import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { marketSelector } from ".";

export function useMarket() {
  const marketState = useSelector(marketSelector);
  const marketDispatch = useDispatch<AppDispatch>();

  return { marketState, marketDispatch };
}
