/* eslint-disable no-unused-vars */
import { AnchorHTMLAttributes, ButtonHTMLAttributes, MouseEvent } from "react";
import Link from "next/link";
import Icon, { AvailableIconsType } from "@/widgets/Icons";

import styles from "./index.module.scss";

type IconButtonProps = {
  icon: AvailableIconsType;
  element?: "button" | "a";
  href?: string;
  onClick?(param: MouseEvent<HTMLButtonElement>): void;
};

export default function IconButton({
  icon,
  element = "button",
  href,
  onClick,
  ...props
}: IconButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement>) {
  const containerClass = `IconButton ${styles.container}`;

  if (element === "a") {
    if (href) {
      return (
        <Link
          href={href}
          className={containerClass}
          onClick={onClick}
          {...props}
        >
          <Icon icon={icon} />
        </Link>
      );
    } else {
      throw "'href' attribute is necessary to render the IconButton component with element 'a'";
    }
  }

  return (
    <button
      type="button"
      className={containerClass}
      onClick={onClick}
      {...props}
    >
      <Icon icon={icon} />
    </button>
  );
}
