export default function retailServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M23.3341 16.6667C23.3341 17.5507 22.9829 18.3986 22.3578 19.0237C21.7327 19.6488 20.8849 20 20.0008 20C19.1167 20 18.2689 19.6488 17.6438 19.0237C17.0187 18.3986 16.6675 17.5507 16.6675 16.6667M13.0285 16.1678L12.4451 23.1678C12.3198 24.6716 12.2572 25.4235 12.5114 26.0035C12.7347 26.5131 13.1217 26.9336 13.611 27.1985C14.1679 27.5 14.9223 27.5 16.4313 27.5H23.5703C25.0792 27.5 25.8337 27.5 26.3906 27.1985C26.8799 26.9336 27.2669 26.5131 27.4902 26.0035C27.7444 25.4235 27.6818 24.6716 27.5564 23.1678L26.9731 16.1678C26.8653 14.874 26.8114 14.227 26.5249 13.7374C26.2725 13.3062 25.8968 12.9605 25.4462 12.7449C24.9344 12.5 24.2853 12.5 22.9869 12.5L17.0147 12.5C15.7163 12.5 15.0671 12.5 14.5554 12.7449C14.1047 12.9605 13.729 13.3062 13.4767 13.7374C13.1902 14.227 13.1363 14.874 13.0285 16.1678Z"
        stroke="#5D6B98"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
