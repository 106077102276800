export default function cryptoNote() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="coins-03" clipPath="url(#clip0_7798_4375)">
        <path
          id="Icon"
          d="M8.41735 3.33335C9.46947 2.30236 10.9104 1.66669 12.4998 1.66669C15.7215 1.66669 18.3332 4.27836 18.3332 7.50002C18.3332 9.08947 17.6975 10.5304 16.6665 11.5826M13.3332 12.5C13.3332 15.7217 10.7215 18.3334 7.49984 18.3334C4.27818 18.3334 1.6665 15.7217 1.6665 12.5C1.6665 9.27836 4.27818 6.66669 7.49984 6.66669C10.7215 6.66669 13.3332 9.27836 13.3332 12.5Z"
          stroke="#475467"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7798_4375">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
