import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { historySelector } from ".";

export function useHistory() {
  const historyState = useSelector(historySelector);
  const historyDispatch = useDispatch<AppDispatch>();

  return { historyState, historyDispatch };
}
