import Link from "next/link";
import { useMemo } from "react";
import { Icon, Card, Button } from "@/widgets";
import { CardSizeType } from "../Card";
import { AvailableIconsType } from "../Icons";

import styles from "./index.module.scss";

export type ButtonConfigType = {
  label: string;
  icon?: AvailableIconsType | undefined;
  onClick: () => void;
};

export type LinkConfigType = {
  href: string;
  label: string;
};

export type CardFeedbackTypes = "error" | "success";

type CardFeedbackProps = {
  size?: CardSizeType;
  type?: CardFeedbackTypes;
  title: string;
  message: string;
  footer?: any;
  primaryButton?: ButtonConfigType;
  secondaryButton?: ButtonConfigType;
  tertiaryLink?: LinkConfigType;
  className?: string;
};

export default function CardFeedback({
  size = "normal",
  type = "error",
  title,
  message,
  primaryButton,
  secondaryButton,
  tertiaryLink,
  className = "",
}: CardFeedbackProps) {
  const cardType = useMemo(() => {
    return type === "success" ? "default" : "error";
  }, [type]);

  return (
    <div
      className={`CardFeedback ${styles.container} ${styles[type]} ${styles[size]} ${className}`}
    >
      <Card variant={cardType} size={size}>
        <div>
          <Icon icon={type} />
          <h2>{title}</h2>
          <p>{message}</p>
          {(primaryButton || secondaryButton || tertiaryLink) && (
            <div className={styles.footer}>
              {(primaryButton || secondaryButton) && (
                <div>
                  {primaryButton && (
                    <Button
                      type="button"
                      size="medium"
                      icon={primaryButton?.icon}
                      onClick={primaryButton.onClick}
                    >
                      {primaryButton.label}
                    </Button>
                  )}
                  {secondaryButton && (
                    <Button
                      type="button"
                      variant="default-outline"
                      size="medium"
                      icon={secondaryButton?.icon}
                      onClick={secondaryButton.onClick}
                    >
                      {secondaryButton.label}
                    </Button>
                  )}
                </div>
              )}
              {tertiaryLink && (
                <Link href={tertiaryLink.href}>{tertiaryLink.label}</Link>
              )}
            </div>
          )}
        </div>
      </Card>
    </div>
  );
}
