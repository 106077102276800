import styles from "./index.module.scss";

type ErrorProps = {
  message: any;
  element?: "div" | "span";
  className?: string;
};

export default function Error({
  message,
  element = "div",
  className = "",
}: ErrorProps) {
  if (message) {
    return element === "div" ? (
      <div className={`${styles.container} ${className}`}>{message}</div>
    ) : (
      <span className={`${styles.container} ${className}`}>{message}</span>
    );
  }

  return null;
}
