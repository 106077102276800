import { useCallback, useEffect, useState } from "react";
import { IconButton } from "@/widgets";
import styles from "./index.module.scss";

type CopyToClipboardProps = {
  label: string;
  value: string | number;
  duration?: number;
  labelPosition?: "left" | "right";
};

export default function CopyToClipboard({
  label,
  value,
  duration = 3000,
  labelPosition = "right",
}: CopyToClipboardProps) {
  const [isCopied, setIsCopied] = useState(false);
  let timer: any;

  const copy = useCallback(() => {
    navigator.clipboard.writeText(value?.toString());
    setIsCopied(true);
    timer = setTimeout(() => {
      setIsCopied(false);
    }, duration);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <div
      className={`CopyToClipboard ${styles.container} ${
        isCopied ? styles.copied : ""
      } ${styles[labelPosition]}`}
      data-value={value}
    >
      <IconButton
        icon={isCopied ? "ok" : "copy"}
        disabled={isCopied}
        onClick={copy}
      />
      {isCopied && <span>{label}</span>}
    </div>
  );
}
