import { Icon } from "@/widgets";
import * as S from "./styles";

interface HeaderButtonTypes {
  itemName: string;
  isPrime?: boolean;
  type?: "default" | "selected";
  onClick: () => void;
  id?: string;
}

const HeaderButton = ({
  itemName,
  isPrime,
  onClick,
  type,
  id,
}: HeaderButtonTypes) => {
  return (
    <>
      <S.HeaderButtonContainer id={id} $type={type} onClick={onClick}>
        <p>{itemName}</p> {isPrime && <Icon icon={"newFeature"} />}
      </S.HeaderButtonContainer>
    </>
  );
};

export default HeaderButton;
