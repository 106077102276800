export default function withdrawIconInv() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#E7E7FF" />
      <path
        d="M15.9997 11.3333V20.6666M15.9997 20.6666L20.6663 15.9999M15.9997 20.6666L11.333 15.9999"
        stroke="#4D4DFF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
