import { useEffect, useState } from "react";
import { hasCookie } from "cookies-next";
import { TOKEN_COOKIE_KEY } from "@/const";
import { useCustomer } from "@/store/features/customer";
import LoggedInMenu from "./LoggedInMenu";
import NonLoggedInMenu from "./NonLoggedInMenu";

export default function MenuContainer() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { customerState } = useCustomer();

  useEffect(() => {
    setIsLoggedIn(
      customerState.customer !== null && hasCookie(TOKEN_COOKIE_KEY)
    );
  }, [customerState.customer]);

  return (
    <>
      {isLoggedIn && <LoggedInMenu />}
      {!isLoggedIn && <NonLoggedInMenu />}''
    </>
  );
}
