import { IPair, ITrade } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";
import { t } from "i18next";
type DoTradeParamType = {
  fromCurrencyId: string;
  toCurrencyId: string;
  fromAmount?: number;
  toAmount?: number;
};

const TradesApi = {
  getPairs: async () => {
    try {
      const response = await api.request({
        url: "/trades/pairs",
        method: "GET",
      });
      return response.data as IPair[];
    } catch (error: any) {
      sendMessageSentry(
        `Get Trade Pairs Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getPairs");
    }
  },
  doTrade: async (data: DoTradeParamType) => {
    try {
      const response = await api.request({
        url: "/trades",
        data,
        method: "POST",
      });

      return response.data as ITrade;
    } catch (error: any) {
      sendMessageSentry(`Trades Error: ${error}`, hideSensitiveData(error));
      throwApiError(error, "Error");
    }
  },
  completeTrade: async (transactionId: string) => {
    try {
      const response = await api.request({
        url: `/trades/complete/${transactionId}`,
        method: "POST",
      });
      return response.data as any;
    } catch (error: any) {
      sendMessageSentry(
        `Complete Transaction Trade: ${error}`,
        hideSensitiveData(error)
      );

      throwApiError(t("fastSwap.error"), "completeTrade");
    }
  },
};

export default TradesApi;
