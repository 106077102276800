export default function noneProductsAndServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M28.3346 18.3337H11.668M11.668 16.8337L11.668 23.167C11.668 24.1004 11.668 24.5671 11.8496 24.9236C12.0094 25.2372 12.2644 25.4922 12.578 25.652C12.9345 25.8337 13.4012 25.8337 14.3346 25.8337L25.668 25.8337C26.6014 25.8337 27.0681 25.8337 27.4246 25.652C27.7382 25.4922 27.9932 25.2372 28.153 24.9236C28.3346 24.5671 28.3346 24.1004 28.3346 23.167V16.8337C28.3346 15.9002 28.3346 15.4335 28.153 15.077C27.9932 14.7634 27.7382 14.5084 27.4246 14.3486C27.0681 14.167 26.6014 14.167 25.668 14.167L14.3346 14.167C13.4012 14.167 12.9345 14.167 12.578 14.3486C12.2644 14.5084 12.0094 14.7634 11.8496 15.077C11.668 15.4335 11.668 15.9002 11.668 16.8337Z"
        stroke="#5D6B98"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
