import AuthApi from "./authApi";
import BalancesApi from "./balancesApi";
import CurrenciesApi from "./currenciesApi";
import CustomerApi from "./customerApi";
import DepoistsApi from "./depositsApi";
import FavoritesApi from "./favoritesApi";
import MarketApi from "./marketsApi";
import SessionsApi from "./sessionsApi";
import TradesApi from "./tradesApi";
import WithdrawalsApi from "./withdrawalsApi";
import HistoryApi from "./historyApi";
import NotificationsSupportApi from "./notificationSupportApi";
import InvestmentsApi from "./investments";

export {
  AuthApi,
  BalancesApi,
  CurrenciesApi,
  DepoistsApi,
  FavoritesApi,
  CustomerApi,
  MarketApi,
  SessionsApi,
  TradesApi,
  WithdrawalsApi,
  HistoryApi,
  NotificationsSupportApi,
  InvestmentsApi,
};
