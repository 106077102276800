import { updateIsMenuOpen, useBase } from "@/store/features/base";
import { Icon, Margin, Menu, MenuItem } from "@/widgets";
import { useTranslation } from "react-i18next";

export default function NonLoggedInMenu() {
  const { t } = useTranslation();
  const { baseDispatch } = useBase();

  const onCloseMenu = () => {
    baseDispatch(updateIsMenuOpen(false));
  };

  return (
    <>
      <Margin bottom={20} />
      <Menu>
        <MenuItem
          label={t("nonLoggedInMenu.initialScreen")}
          href="/auth/login"
          onClick={onCloseMenu}
        />
        <MenuItem
          label={t("nonLoggedInMenu.createAccount")}
          href="/auth/sign-up"
          onClick={onCloseMenu}
        />
        <MenuItem label={t("nonLoggedInMenu.helpCenter")}>
          <MenuItem
            label={t("nonLoggedInMenu.limits")}
            href="/help-center/limits-info"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("nonLoggedInMenu.feesInfo")}
            href="/help-center/fees-info"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("nonLoggedInMenu.userTerms")}
            href="/help-center/terms"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("nonLoggedInMenu.privacyPolicy")}
            href="/help-center/privacy-policies"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("nonLoggedInMenu.contact")}
            href="/help-center/contact"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={
              <>
                FAQ <Icon icon="link" />
              </>
            }
            href="https://suporteplataforma.zendesk.com/hc/pt-br"
            target="_blank"
            onClick={onCloseMenu}
          />
        </MenuItem>
      </Menu>
    </>
  );
}
