export default function transactionLoading() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#FFFAEB" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="white" />
      <g clipPath="url(#clip0_4403_33244)">
        <path
          d="M7.99984 4.66667V6.00001M7.99984 10V11.3333M5.99984 8.00001H4.6665M11.3332 8.00001H9.99984M10.3593 10.3595L9.4165 9.41667M10.3593 5.66665L9.4165 6.60946M5.64036 10.3595L6.58317 9.41667M5.64036 5.66665L6.58317 6.60946"
          stroke="#F79009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_4403_33244">
          <rect x="4" y="4" width="8" height="8" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
