import { Table, TableCol, TableHead, TableRow } from "@/widgets/tables";
import { useTranslation } from "react-i18next";
import { LimitValue, UserLimit } from "./types";

export type LimitsTableProps = {
  limits: UserLimit[]
}

export function LimitsTable({ limits }: LimitsTableProps) {
  const { t, i18n } = useTranslation();

  const formatCurrency = ({ value, currency }: LimitValue): string => {
    if (typeof (value) === 'number') {
      return new Intl.NumberFormat(i18n.language, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2
      }).format(value);
    } else {
      return value
    }
  };

  return (
    <Table
      header={
        <TableHead
          cols={[
            <TableCol key={1}>{t("userLimits.level")}</TableCol>,
            <TableCol key={2}>{t("userLimits.fiatMonthlyLimit")}</TableCol>,
            <TableCol key={3}>{""}</TableCol>,
            <TableCol key={4}>{t("userLimits.cryptoMonthlyLimit")}</TableCol>,
            <TableCol key={5}>
              {""}
            </TableCol>,
          ]}
        />
      }
    >
      {limits.map((limit, index) => (
        <TableRow
          key={index}
          cols={[
            <TableCol key={1}>{limit.level}</TableCol>,
            <TableCol key={2}>
              {limit.fiat !== undefined
                ? formatCurrency(limit.fiat.monthly) + limit.fiat.monthly.periodic
                : limit.noLimitFiat &&
                t("userLimits.noLimits")}
            </TableCol>,
            <TableCol key={3}>
              {limit.fiat !== undefined
                ? formatCurrency(limit.fiat.semiannual) + limit.fiat.semiannual.periodic
                : limit.noLimitFiat &&
                t("userLimits.noLimits")}
            </TableCol>,
            <TableCol key={4}>
              {limit.crypto !== undefined
                ? formatCurrency(limit.crypto.monthly) + limit.crypto.monthly.periodic
                : limit.noLimitCrypto &&
                t("userLimits.noLimits")}
            </TableCol>,
            <TableCol key={5}>
              {limit.crypto !== undefined
                ? formatCurrency(limit.crypto.semiannual) + limit.crypto.semiannual.periodic
                : limit.noLimitCrypto &&
                t("userLimits.noLimits")}
            </TableCol>,
          ]}
        />
      ))}
    </Table>
  );
}
