import styled, { css, keyframes } from "styled-components";

interface CollapseProps {
  $collapsed: boolean;
}

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MainContainer = styled.div<CollapseProps>`
  position: fixed;
  width: ${(props) => (props.$collapsed ? "83px" : "230px")};
  transition: width 0.4s ease;
  height: 100%;
  gap: 0px;
  border: 1px solid #dcdfea;
  border-bottom: none;
  border-top: none;
  background-color: #ffffff;
  // top: 60px;
  height: calc(100vh - 40px);
`;

export const Logo = styled.div<CollapseProps>`
  max-width: 98px;
  max-height: 17.82px;
  margin-top: 16px;
  margin-left: ${(props) => (props.$collapsed ? "29px" : "16px")};
  ${(props) =>
    !props.$collapsed &&
    css`
      animation: ${fadeInAnimation} 0.3s ease-in-out;
    `};
`;

export const IconArrow = styled.div<CollapseProps>`
  width: 24px;
  height: 24px;
  margin: 40px 0px 0px 190px;
  margin-left: ${(props) => (props.$collapsed ? "30px" : "190px")};
  cursor: pointer;
  transition: margin-left 0.4s ease;
  animation: ${fadeInAnimation} 1s ease-in-out;
`;

export const MainList = styled.ul`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const LogoutContainer = styled.div`
  position: absolute;
  bottom: 200px;
`;

export const SkeletonContainer = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
