export default function languageItemWhite() {
  return (
    <svg
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16406 6.66675L8.33073 10.8334M3.33073 11.6667L8.33073 6.66675L9.9974 4.16675M1.66406 4.16675H11.6641M5.83073 1.66675H6.66406M10.7583 14.1667H16.7365M10.7583 14.1667L9.16406 17.5001M10.7583 14.1667L13.146 9.17427C13.3384 8.77197 13.4346 8.57083 13.5662 8.50726C13.6807 8.45198 13.8141 8.45198 13.9286 8.50726C14.0602 8.57083 14.1564 8.77197 14.3488 9.17427L16.7365 14.1667M16.7365 14.1667L18.3307 17.5001"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
