interface IRouterI18nHome {
  [key: string]: string;
}

export function routerI18nHome(): string {
  if (!window) return "/";

  const { pathname } = window.location;

  const a: IRouterI18nHome = {
    en: "/en",
    es: "/es",
    pt: "/",
  };

  return a[pathname.split("/")[1]] || a["pt"];
}
