import styled from "styled-components";

export const MobileHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px;
`;

export const LogoArea = styled.div`
  cursor: pointer;
`;

export const LanguageArea = styled.div``;
