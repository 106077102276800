import { Icon } from "@/widgets";
import {
  DropdownIndicatorProps,
  MenuListProps,
  components,
} from "react-select";

export const CustomMenuList = (styles: any) => {
  const interalComp = (props: MenuListProps<true>) => {
    return (
      <components.MenuList className={`${styles.menuList}`} {...props}>
        {props.children}
      </components.MenuList>
    );
  };
  return interalComp;
};

export const CustomOption = (styles: any) => {
  const internalComp = ({ children, ...props }: any) => {
    const isSelectedCss = props?.isSelected ? styles.active : styles.nonActive;

    return (
      <components.Option
        className={`${styles.option} ${isSelectedCss}`}
        {...props}
      >
        {children}
      </components.Option>
    );
  };
  return internalComp;
};

export const CustomDropdownIndicator = (styles: any) => {
  const internalComp = (props: DropdownIndicatorProps<true>) => {
    return (
      <components.DropdownIndicator
        className={styles.dropdownIndicator}
        {...props}
      >
        <Icon icon="search" />
      </components.DropdownIndicator>
    );
  };
  return internalComp;
};

export const CustomLoadingIndicator = () => {
  const interalComp = () => {
    return <></>;
  };
  return interalComp;
};
