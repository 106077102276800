import Icon, { AvailableIconsType } from "../Icons";
import * as S from "./styles";

type CryptoMarketProps = {
  name: string;
  value: string;
  absoluteImageUrl?: string;
  icon: AvailableIconsType;
};

export default function CryptoMarket({
  name,
  value,
  absoluteImageUrl,
  icon,
}: CryptoMarketProps) {
  const src = absoluteImageUrl
    ? absoluteImageUrl
    : `/images/currencies/${name.toLowerCase()}.svg`;
  return (
    <S.ContentCryptoMarketProps>
      <S.Icon>
        <img src={src} alt={name} />
      </S.Icon>
      <S.Info>
        <S.Currency>{name}</S.Currency>
        <Icon icon={icon} />
        <S.Amount>{value}</S.Amount>
      </S.Info>
    </S.ContentCryptoMarketProps>
  );
}
