import { RefObject, useCallback, useEffect, useState } from "react";
// import { FocusedInputShape } from "react-dates";
import { InpuntVariantType } from "@/widgets/forms/Input/types";
import { DateType } from "./types";

export const useDatePickerState = (
  defaultStartDate: DateType | undefined,
  defaultEndDate: DateType | undefined,
  defaultDate: DateType | undefined
) => {
  const [dateRange, setDateRange] = useState<{
    startDate: DateType;
    endDate: DateType;
  }>({
    startDate: defaultStartDate === undefined ? null : defaultStartDate,
    endDate: defaultEndDate === undefined ? null : defaultEndDate,
  });
  const [date, setDate] = useState<DateType>(
    defaultDate === undefined ? null : defaultDate
  );
  const [focus, setFocus] = useState<any | null>(null);
  const [focused, setFocused] = useState<boolean>(false);
  const { startDate, endDate } = dateRange;

  return {
    startDate,
    endDate,
    date,
    setDateRange,
    setDate,
    focus,
    focused,
    setFocus,
    setFocused,
  };
};

export const datePickerUIModifier = (
  ref: RefObject<HTMLDivElement>,
  variant: InpuntVariantType,
  type: string,
  startDateLabel: string | undefined,
  startdDateId: string,
  endDateLabel: string | undefined,
  endDateId: string,
  dateLabel: string | undefined,
  dateId: string
) => {
  const setInputVariantType = useCallback(
    ($field: Element) => {
      ref.current?.classList.add(variant);
      $field.classList.add(variant);
    },
    [variant, ref]
  );

  const createLabel = useCallback(
    ($field: Element, label: string, id: string) => {
      if (!$field.parentElement?.querySelector("label")) {
        const $label = document.createElement("label");
        $label.setAttribute("for", id);
        $label.innerHTML = label;
        $field.parentElement?.prepend($label);
      }
    },
    []
  );

  const addCreatedLabel = useCallback(($fields: Element[]) => {
    if (type === "single" && $fields[0] && dateLabel) {
      createLabel($fields[0], dateLabel, dateId);
    } else {
      if ($fields[0] && startDateLabel) {
        createLabel($fields[0], startDateLabel, startdDateId);
      }
      if ($fields[1] && endDateLabel) {
        createLabel($fields[1], endDateLabel, endDateId);
      }
    }
  }, []);

  useEffect(() => {
    if (ref.current) {
      const $fields = ref.current.querySelectorAll("input[type='text']");
      $fields.forEach(($field) => {
        setInputVariantType($field);
      });

      addCreatedLabel($fields as any);
    }
  });
};
