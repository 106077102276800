import { CopyToClipboard, Card } from "@/widgets";
import { CardSizeType } from "../Card";

import styles from "./index.module.scss";

export type KeyValueType = {
  title: any;
  value: any;
};

type KeyValueViewProps = {
  title?: string;
  data: KeyValueType[];
  isCopyable?: boolean;
  copiedLabel?: string;
  size?: CardSizeType;
  className?: string;
  variant?: "default" | "table";
};

export default function KeyValueView({
  title,
  data,
  isCopyable = false,
  copiedLabel = "",
  size = "normal",
  className = "",
  variant = "default",
}: KeyValueViewProps) {
  return (
    <div
      className={`KeyValueView ${styles.container} ${!title ? styles.noTitle : ""
        } ${styles[variant]} ${className}`}
    >
      <Card size={size}>
        {title && <h3>{title}</h3>}
        <div>
          {data.map((item: KeyValueType, index: number) => {
            return (
              <div key={index} className={styles.row}>
                <div>
                  <div>{item.title}</div>
                  <div>{item.value}</div>
                </div>
                {isCopyable && (
                  <CopyToClipboard
                    label={copiedLabel}
                    value={item.value}
                    labelPosition="left"
                  />
                )}
              </div>
            );
          })}
        </div>
      </Card>
    </div>
  );
}
