export default function cardItemWhite() {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3307 5.33341H1.66406M9.16406 8.66675H4.9974M1.66406 3.83341L1.66406 10.1667C1.66406 11.1002 1.66406 11.5669 1.84572 11.9234C2.00551 12.237 2.26047 12.492 2.57408 12.6518C2.9306 12.8334 3.39731 12.8334 4.33073 12.8334L15.6641 12.8334C16.5975 12.8334 17.0642 12.8334 17.4207 12.6518C17.7343 12.492 17.9893 12.237 18.1491 11.9234C18.3307 11.5669 18.3307 11.1002 18.3307 10.1667V3.83342C18.3307 2.89999 18.3307 2.43328 18.1491 2.07677C17.9893 1.76316 17.7343 1.50819 17.4207 1.34841C17.0642 1.16675 16.5975 1.16675 15.6641 1.16675L4.33073 1.16675C3.39731 1.16675 2.9306 1.16675 2.57408 1.3484C2.26048 1.50819 2.00551 1.76316 1.84572 2.07676C1.66406 2.43328 1.66406 2.89999 1.66406 3.83341Z"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
