export default function newFeature() {
  return (
    <svg
      width="32"
      height="18"
      viewBox="0 0 32 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="18" rx="5" fill="url(#paint0_linear_82_4371)" />
      <path
        d="M5.2 5.464H6.316L10.144 11.668H10.06V5.464H10.996V13H9.952L5.992 6.592H6.136V13H5.2V5.464ZM15.4071 13.144C14.9111 13.144 14.4671 13.018 14.0751 12.766C13.6871 12.51 13.3791 12.156 13.1511 11.704C12.9271 11.252 12.8151 10.732 12.8151 10.144C12.8151 9.516 12.9271 8.976 13.1511 8.524C13.3751 8.072 13.6811 7.726 14.0691 7.486C14.4611 7.242 14.9071 7.12 15.4071 7.12C15.8431 7.12 16.2151 7.208 16.5231 7.384C16.8311 7.56 17.0791 7.798 17.2671 8.098C17.4551 8.398 17.5851 8.738 17.6571 9.118C17.7331 9.494 17.7551 9.884 17.7231 10.288H13.5711V9.712H17.1051L16.8291 9.892C16.8411 9.656 16.8271 9.42 16.7871 9.184C16.7511 8.948 16.6811 8.732 16.5771 8.536C16.4731 8.336 16.3271 8.176 16.1391 8.056C15.9511 7.936 15.7111 7.876 15.4191 7.876C15.0511 7.876 14.7431 7.97 14.4951 8.158C14.2471 8.346 14.0611 8.594 13.9371 8.902C13.8131 9.206 13.7511 9.536 13.7511 9.892V10.276C13.7511 10.676 13.8111 11.034 13.9311 11.35C14.0551 11.666 14.2391 11.916 14.4831 12.1C14.7271 12.284 15.0351 12.376 15.4071 12.376C15.7791 12.376 16.0851 12.286 16.3251 12.106C16.5651 11.926 16.7111 11.66 16.7631 11.308H17.7351C17.6751 11.688 17.5391 12.016 17.3271 12.292C17.1151 12.564 16.8451 12.774 16.5171 12.922C16.1891 13.07 15.8191 13.144 15.4071 13.144ZM18.5832 7.264H19.5552L21.0192 12.052H20.8992L22.3272 7.54H23.1072L24.5232 12.064H24.4152L25.8792 7.264H26.8152L24.9312 13H24.0192L22.6272 8.764H22.7712L21.3912 13H20.4792L18.5832 7.264Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_82_4371"
          x1="12.8889"
          y1="9"
          x2="38.6667"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4D4DFF" />
          <stop offset="1" stopColor="#212069" />
        </linearGradient>
      </defs>
    </svg>
  );
}
