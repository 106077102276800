/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useState } from "react";
import Button from "../Button";

import styles from "./index.module.scss";

type TabType = {
  label: string;
  children: any;
  disabled?: boolean;
};

type TabsProps = {
  tab1: TabType;
  tab2: TabType;
  currentIndex: 1 | 2;
  hasBody?: boolean;
  customHeader?: any;
  onChange: (param: 1 | 2) => void;
  disabled?: boolean;
  className?: string;
};

export default function Tabs({
  tab1,
  tab2,
  currentIndex = 1,
  hasBody = true,
  customHeader,
  disabled,
  onChange,
  className,
}: TabsProps) {
  const [current, setCurrent] = useState(currentIndex);

  const getVariant = useCallback(
    (index: number) => {
      return current === index ? "default" : "light";
    },
    [current]
  );

  useEffect(() => {
    setCurrent(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    onChange(current);
  }, [current]);

  return (
    <div className={`Tabs ${styles.container} ${className}`}>
      {!customHeader && (
        <div className={styles.tabButtons}>
          <Button
            size="medium"
            variant={getVariant(1)}
            onClick={() => {
              setCurrent(1);
            }}
            disabled={tab1.disabled || disabled}
          >
            {tab1.label}
          </Button>
          <Button
            size="medium"
            variant={getVariant(2)}
            onClick={() => {
              setCurrent(2);
            }}
            disabled={tab2.disabled || disabled}
          >
            {tab2.label}
          </Button>
        </div>
      )}
      {customHeader && <>{customHeader}</>}
      {hasBody && (
        <div className={styles.tabBody}>
          {current === 1 && <div>{tab1.children}</div>}
          {current === 2 && <div>{tab2.children}</div>}
        </div>
      )}
    </div>
  );
}
