import { ImageProps as NextImageProps, default as NextImage } from "next/image";

import styles from "./index.module.scss";

type ImageProps = {
  src: string;
  alt: string;
  fill?: boolean;
};

export default function Image({
  src,
  alt,
  fill = true,
  ...props
}: ImageProps & NextImageProps) {
  return (
    <div className={`Image ${styles.container}`}>
      <NextImage
        alt={alt}
        fill={fill}
        priority={true}
        src={src}
        sizes="(max-width: 576px) 576px (max-width: 768px) 768px (max-width: 992px) 992px"
        {...props}
      />
    </div>
  );
}
