import scssVariables from "@/styles/variables.module.scss";

export default function history() {
  return (
    <svg viewBox="0 0 15 18" fill="none">
      <path
        d="M14.7612 4.58122V16.2685C14.7612 17.2232 13.9844 18 13.0297 18H5.12993C4.17522 18 3.39847 17.2232 3.39847 16.2685V8.66922C3.64546 8.7103 3.89741 8.73694 4.15598 8.73694C4.33935 8.73694 4.51932 8.72332 4.69707 8.70229V16.2685C4.69707 16.5072 4.89121 16.7014 5.12993 16.7014H13.0297C13.2683 16.7014 13.4625 16.5071 13.4625 16.2685V4.58122C13.4625 4.34249 13.2683 4.14836 13.0297 4.14836H8.7446C8.7446 3.69691 8.67676 3.2618 8.55518 2.8498H13.0297C13.9844 2.84976 14.7612 3.6265 14.7612 4.58122ZM4.08367 8.16724C1.83186 8.16724 0 6.33538 0 4.08367C3.57583e-05 1.83186 1.83186 0 4.08367 0C6.33541 0 8.16735 1.83186 8.16735 4.08367C8.16735 6.33538 6.33541 8.16724 4.08367 8.16724ZM4.08367 7.3015C5.85793 7.3015 7.30153 5.85815 7.30153 4.08367C7.30153 2.3091 5.85815 0.865745 4.08367 0.865745C2.30913 0.865745 0.865745 2.3091 0.865745 4.08367C0.865745 5.85815 2.30935 7.3015 4.08367 7.3015ZM6.5328 4.51657C6.77174 4.51657 6.96565 4.32265 6.96565 4.08371C6.96565 3.84477 6.77174 3.65086 6.5328 3.65086H4.51653V1.95162C4.51653 1.71268 4.32261 1.51876 4.08367 1.51876C3.84474 1.51876 3.65082 1.71268 3.65082 1.95162V4.08371C3.65082 4.32265 3.84474 4.51657 4.08367 4.51657H6.5328ZM11.5288 8.02618H6.83946C6.60053 8.02618 6.40661 8.22009 6.40661 8.45903C6.40661 8.698 6.60053 8.89188 6.83946 8.89188H11.5288C11.7679 8.89188 11.9617 8.69797 11.9617 8.45903C11.9617 8.22009 11.7679 8.02618 11.5288 8.02618ZM11.5364 10.6519H6.84708C6.60814 10.6519 6.41422 10.8457 6.41422 11.0847C6.41422 11.3238 6.60814 11.5176 6.84708 11.5176H11.5364C11.7755 11.5176 11.9692 11.3238 11.9692 11.0847C11.9692 10.8457 11.7757 10.6519 11.5364 10.6519ZM11.5364 13.3862H6.84708C6.60814 13.3862 6.41422 13.5803 6.41422 13.8191C6.41422 14.0581 6.60814 14.2519 6.84708 14.2519H11.5364C11.7755 14.2519 11.9692 14.0581 11.9692 13.8191C11.9693 13.5802 11.7757 13.3862 11.5364 13.3862Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
