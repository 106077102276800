export default function iconCheckBGBlue() {
  return (
    <svg
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" y="4" width="35" height="35" rx="17.5" fill="#CAD9FF" />
      <rect
        x="4"
        y="4"
        width="35"
        height="35"
        rx="17.5"
        stroke="#E8F0FF"
        strokeWidth="6.25"
      />
      <path
        d="M27.3337 17.125L19.3128 25.1458L15.667 21.5"
        stroke="#3F3FCA"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
