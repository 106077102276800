import { api } from "@/apis/axios";
import { FeatureFlag } from "@/domains/models";
import { GetFeatureFlagUseCase } from "@/domains/use-cases";

interface IFeatureFlag {
  getFeatureFlag: (
    params: GetFeatureFlagUseCase.Params
  ) => Promise<FeatureFlag>;
}

export class FlagFeature implements IFeatureFlag {
  async getFeatureFlag(
    params: GetFeatureFlagUseCase.Params
  ): Promise<FeatureFlag> {
    try {
      const { data } = await api.get<GetFeatureFlagUseCase.Response>(
        "/featureflags",
        {
          headers: {
            clientType: params.clientType,
            customerType: params.customerType,
          },
        }
      );

      return data[0];
    } catch (e: any) {
      throw new Error(e);
    }
  }
}
export const FeatureFlagFactory = new FlagFeature();
