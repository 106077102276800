export default function operateIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="16" fill="#E7E7FF" />
      <g clipPath="url(#clip0_422_9571)">
        <path
          d="M22.1285 14.5858L14.586 22.1282M14.586 22.1282L14.586 18.357M14.586 22.1282L18.3572 22.1282M9.87195 17.4142L17.4144 9.87171M17.4144 9.87171H13.6432M17.4144 9.87171V13.6429"
          stroke="#4D4DFF"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_422_9571">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
