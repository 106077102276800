import { HTMLAttributes } from "react";
import styles from "./index.module.scss";

type BackdropProps = {
  zIndex: number;
};

export default function Backdrop({
  zIndex,
  ...props
}: BackdropProps & HTMLAttributes<HTMLElement>) {
  return (
    <div
      className={`Backdrop ${styles.container}`}
      style={{
        zIndex: zIndex,
      }}
      {...props}
    ></div>
  );
}
