import styles from "./index.module.scss";

type TableRowProps = {
  cols: any[];
  className?: string;
};

export default function TableRow({ cols, className = "" }: TableRowProps) {
  return (
    <div className={`TableRow ${styles.container} ${className}`}>
      {cols.map((item: any, index: number) => (
        <div key={index} style={{ width: `${100 / cols.length}%` }}>
          {item}
        </div>
      ))}
    </div>
  );
}
