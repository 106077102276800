import { Card, Icon, IconLabel, Tooltip } from "@/widgets";

export default function En_i18n() {
  return (
    <>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/deposit.svg"
          label={"Deposit"}
        />
        <div>
          <h2>
            Fiat currency
            <Tooltip
              content={
                <>
                  *Only Brazilian real is supported as fiat currency on
                  Transfero Crypto. .
                </>
              }
              placement="right"
            >
              <Icon icon="question" />
            </Tooltip>
          </h2>
          <p>
            The customer transfers fiat currency to their Transfero Crypto
            account without fees within 1 business day.
          </p>
          <h2>Crypto</h2>
          <p>
            The customer sends cryptocurrency to their Transfero Crypto account
            without fees and within the deadline of blockchain network
            confirmations.
          </p>
        </div>
      </Card>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/withdraw.svg"
          label={"Transfer"}
        />
        <div>
          <h2>
            Fiat currency
            <Tooltip
              content={
                <>
                  *Only Brazilian real is supported as fiat currency on
                  Transfero Crypto.
                </>
              }
              placement="right"
            >
              <Icon icon="question" />
            </Tooltip>
          </h2>
          <p>
            Transfero Crypto transfers fiat currency to the customer with a bank
            fees (5 BRL) within 1 business day.
          </p>
          <h2>Crypto</h2>
          <p>
            Transfero Crypto sends cryptocurrency to the customer's wallet at a
            network fee and within the deadline for blockchain network
            confirmations.
          </p>
        </div>
      </Card>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/convert.svg"
          label={"Trade"}
        />
        <div>
          <p>
            <b>Transfero Crypto</b> converts between currencies instantly and
            without fees.
          </p>
        </div>
      </Card>
    </>
  );
}
