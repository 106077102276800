import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { IApiError, IRequiredField, ICustomer } from "@/models";
import { CustomerApi } from "@/apis";
import { CustomerState } from ".";

export const fetchCustomer = createAsyncThunk(
  "fetch/customer",
  async (_, { rejectWithValue }) => {
    try {
      return (await CustomerApi.getCustomer()) as ICustomer;
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const fecthRequiredFields = createAsyncThunk(
  "fetch/requiredFields",
  async (type: string, { rejectWithValue }) => {
    let get =
      type === "Company"
        ? CustomerApi.getRequiredFields("Company")
        : CustomerApi.getRequiredFields();
    try {
      return (await get) as IRequiredField[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const customerExtraReducerBuilder = (
  builder: ActionReducerMapBuilder<CustomerState>
) => {
  // fetch/customer
  builder.addCase(fetchCustomer.pending, (state) => {
    state.customer.error = null;
    state.customer.loading = true;
  });
  builder.addCase(fetchCustomer.fulfilled, (state, action) => {
    state.customer.data = action.payload as ICustomer;
    state.customer.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.customer.error = null;
    state.customer.loading = false;
  });
  builder.addCase(fetchCustomer.rejected, (state, action) => {
    state.customer.data = null;
    state.customer.error = action.payload as IApiError;
    state.customer.loading = false;
    state.customer.lastUpdate = null;
  });
  // fetch/requiredFields
  builder.addCase(fecthRequiredFields.pending, (state) => {
    state.requiredFields.error = null;
    state.requiredFields.loading = true;
  });
  builder.addCase(fecthRequiredFields.fulfilled, (state, action) => {
    state.requiredFields.data = action.payload as IRequiredField[];
    state.requiredFields.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.requiredFields.error = null;
    state.requiredFields.loading = false;
  });
  builder.addCase(fecthRequiredFields.rejected, (state, action) => {
    state.requiredFields.data = null;
    state.requiredFields.error = action.payload as IApiError;
    state.requiredFields.loading = false;
    state.requiredFields.lastUpdate = null;
  });
};
