import scssVariables from "@/styles/variables.module.scss";

export default function graphDown() {
  return (
    <svg viewBox="0 0 11 7" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2758 5.28564L6.46953 0.527833L3.80898 3.19111L0.617969 9.82285e-05L0 0.618066L3.80898 4.42705L6.39844 1.84033L9.59219 5.83252L10.2758 5.28564Z"
        fill={scssVariables.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43408 5.99658C6.43408 6.23721 6.63096 6.43408 6.87158 6.43408H10.3716C10.6122 6.43408 10.8091 6.23721 10.8091 5.99658V2.49658C10.8091 2.25596 10.6122 2.05908 10.3716 2.05908C10.131 2.05908 9.93408 2.25596 9.93408 2.49658V5.55908H6.87158C6.63096 5.55908 6.43408 5.75596 6.43408 5.99658Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
