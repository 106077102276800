import scssVariables from "@/styles/variables.module.scss";

export default function filter() {
  return (
    <svg viewBox="0 0 10 10" fill="none">
      <path
        d="M5.74995 10H5.57495L3.82495 9.625C3.65981 9.58089 3.51389 9.48343 3.4099 9.34779C3.30591 9.21214 3.24967 9.04592 3.24995 8.875V5.75L0.249946 2.95C0.166866 2.88541 0.10071 2.80161 0.0571632 2.70581C0.0136168 2.61001 -0.00601793 2.50507 -5.37852e-05 2.4V0.75C-5.37852e-05 0.551088 0.0789639 0.360322 0.219616 0.21967C0.360268 0.0790177 0.551034 0 0.749946 0H9.24995C9.44886 0 9.63962 0.0790177 9.78028 0.21967C9.92093 0.360322 9.99995 0.551088 9.99995 0.75V2.65C10.0029 2.76077 9.9795 2.87065 9.93169 2.97062C9.88388 3.07058 9.81302 3.15776 9.72495 3.225L6.49995 5.775V9.25C6.50289 9.36077 6.4795 9.47065 6.43169 9.57062C6.38388 9.67058 6.31302 9.75776 6.22495 9.825C6.09399 9.94055 5.92457 10.003 5.74995 10ZM4.24995 8.65L5.49995 8.95V5.3L8.99995 2.525V1H0.999946V2.3L4.24995 5.325V8.65Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
