import { Card, Icon, IconLabel, Tooltip } from "@/widgets";

export default function Pt_i18n() {
  return (
    <>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/deposit.svg"
          label={"Depósito"}
        />
        <div>
          <h2>
            Moeda fiduciária
            <Tooltip
              content={
                <>
                  * Apenas o real brasileiro (BRL) é suportado como moeda
                  fiduciária na Transfero Crypto.
                </>
              }
              placement="right"
            >
              <Icon icon="question" />
            </Tooltip>
          </h2>
          <p>
            O cliente transfere moeda fiduciária para a sua conta Transfero
            Crypto sem taxa em até 1 dia útil.
          </p>
          <h2>Criptomoeda </h2>
          <p>
            O cliente envia criptomoeda para sua conta Transfero Crypto sem taxa
            e dentro do prazo das confirmações da rede blockchain.
          </p>
        </div>
      </Card>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/withdraw.svg"
          label={"Transferência"}
        />
        <div>
          <h2>
            Moeda fiduciária
            <Tooltip
              content={
                <>
                  * Apenas o real brasileiro (BRL) é suportado como moeda
                  fiduciária na Transfero Crypto.
                </>
              }
              placement="right"
            >
              <Icon icon="question" />
            </Tooltip>
          </h2>
          <p>
            A Transfero Crypto transfere moeda fiduciária para o cliente com uma
            taxa bancária de R$ 5,00 em até 1 dia de útil.
          </p>
          <h2>Criptomoeda</h2>
          <p>
            A Transfero Crypto envia criptomoeda para a carteira do cliente com
            taxa da rede e dentro do prazo das confirmações da rede blockchain.
          </p>
        </div>
      </Card>
      <Card>
        <IconLabel
          size="small"
          src="/images/operations/convert.svg"
          label={"Troca"}
        />
        <div>
          <p>
            A <b>Transfero Crypto</b> realiza a conversão entre moedas
            instantâneamente e sem taxa.
          </p>
        </div>
      </Card>
    </>
  );
}
