import styled, { css, keyframes } from "styled-components";

interface ButtonProps {
  $type?: "default" | "selected" | "logout";
  $collapsed?: boolean;
}

const colorMap = {
  default: "#4a5578",
  selected: "#413fcf",
  logout: "#D92D20",
};

const fadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const MainContainer = styled.li<ButtonProps>`
  width: ${(props) => (props.$collapsed ? "80%" : "198px")};
  height: 34px;
  padding: 8px;
  display: flex;
  flex-direction: row;
  gap: 14px;
  border-radius: 8px;
  cursor: pointer;
  background-color: ${(props) => props.$type === "selected" && "#ebebff"};
  margin-top: 4px;
  justify-content: ${(props) => (props.$collapsed ? "center" : "unset")};

  &:first-child {
    margin-top: 20px;
  }

  ${(props) =>
    !props.$collapsed &&
    css`
      animation: ${fadeInAnimation} 0.5s ease-in-out;
    `};

  p {
    font-size: 14px;
    font-weight: 600;
    text-align: left;
    color: ${(props) => colorMap[props.$type ?? "default"]};
    ${(props) =>
      !props.$collapsed &&
      css`
        animation: ${fadeInAnimation} 0.5s ease-in-out;
      `};
  }

  svg {
    path {
      stroke: ${(props) => colorMap[props.$type ?? "default"]};
    }
  }

  &:hover {
    background-color: ${(props) => (props.$type === "logout" ? "" : "#ebebff")};
    width: ${(props) => (props.$collapsed ? "80%" : "198px")};

    p {
      color: ${(props) => (props.$type === "logout" ? "" : "#413fcf")};
    }

    svg {
      path {
        stroke: ${(props) => (props.$type === "logout" ? "" : "#413fcf")};
      }
    }
  }
`;
