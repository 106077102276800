import { Image } from "@/widgets";
import styles from "./index.module.scss";

type IconLabelProps = {
  label: string;
  src: string;
  info?: any;
  alt?: string;
  size?: "default" | "large" | "small" | "tiny";
  className?: string;
  title?: string;
  isCurrency?: boolean;
  currencyTitle?: string;
  id?: string;
};

export default function IconLabel({
  label,
  src,
  info,
  alt,
  size = "default",
  className = "",
  title,
  isCurrency,
  currencyTitle,
  id,
}: IconLabelProps) {
  return (
    <div
      className={`IconLabel ${styles.container} ${styles[size]} ${className}`}
      id={id}
    >
      <div className={styles.imageContainer}>
        <Image title={title} src={src} alt={alt ? alt : label} />
      </div>
      <div className={`${styles.titleContainer}`}>
        <span>{label}</span>
        {isCurrency && (
          <span className={`${styles.currencyTitle}`}>{currencyTitle}</span>
        )}
        {info && <span className={`${styles.info}`}>{info}</span>}
      </div>
    </div>
  );
}
