

export default function currencyTest() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#EF6820" />
      <path d="M17.2206 10.2286C17.4546 8.73129 16.2633 7.92641 14.6343 7.38948L15.1628 5.36119L13.8725 5.05354L13.358 7.02842C13.0188 6.94747 12.6705 6.87119 12.3242 6.79557L12.8424 4.80765L11.5529 4.5L11.0242 6.52762C10.7435 6.46647 10.4678 6.40602 10.2003 6.34234L10.2018 6.33596L8.42246 5.91078L8.07923 7.22949C8.07923 7.22949 9.03651 7.43947 9.01634 7.45239C9.53883 7.57718 9.63332 7.90815 9.61763 8.17049L9.01565 10.4812C9.05163 10.4899 9.0983 10.5026 9.14979 10.5224C9.10675 10.5121 9.06094 10.501 9.01341 10.4901L8.16964 13.727C8.10578 13.8789 7.94371 14.1069 7.5784 14.0203C7.59133 14.0383 6.6406 13.7964 6.6406 13.7964L6 15.2097L7.6791 15.6102C7.99147 15.6852 8.29757 15.7636 8.59902 15.8374L8.06509 17.8889L9.35388 18.1966L9.88264 16.1668C10.2347 16.2583 10.5764 16.3426 10.9109 16.4222L10.3839 18.4423L11.6743 18.75L12.2081 16.7023C14.4084 17.1008 16.0627 16.9401 16.7591 15.0358C17.3202 13.5026 16.7312 12.6182 15.5736 12.0415C16.4167 11.8555 17.0518 11.3249 17.2211 10.2288L17.2207 10.2285L17.2206 10.2286ZM14.2726 14.1843C13.8739 15.7175 11.1761 14.8887 10.3015 14.6809L11.01 11.963C11.8846 12.1719 14.6893 12.5854 14.2727 14.1843H14.2726ZM14.6716 10.2064C14.3079 11.601 12.0626 10.8925 11.3342 10.7188L11.9766 8.25375C12.705 8.42749 15.0506 8.75175 14.6718 10.2064H14.6716Z" fill="white" />
    </svg>

  );
}
