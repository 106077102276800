export default function professionalServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M23.3346 15.8333C23.3346 15.0584 23.3346 14.6709 23.2495 14.353C23.0183 13.4902 22.3444 12.8164 21.4817 12.5852C21.1638 12.5 20.7763 12.5 20.0013 12.5C19.2263 12.5 18.8388 12.5 18.5209 12.5852C17.6582 12.8164 16.9843 13.4902 16.7532 14.353C16.668 14.6709 16.668 15.0584 16.668 15.8333M14.3346 27.5H25.668C26.6014 27.5 27.0681 27.5 27.4246 27.3183C27.7382 27.1586 27.9932 26.9036 28.153 26.59C28.3346 26.2335 28.3346 25.7668 28.3346 24.8333V18.5C28.3346 17.5666 28.3346 17.0999 28.153 16.7433C27.9932 16.4297 27.7382 16.1748 27.4246 16.015C27.0681 15.8333 26.6014 15.8333 25.668 15.8333H14.3346C13.4012 15.8333 12.9345 15.8333 12.578 16.015C12.2644 16.1748 12.0094 16.4297 11.8496 16.7433C11.668 17.0999 11.668 17.5666 11.668 18.5V24.8333C11.668 25.7668 11.668 26.2335 11.8496 26.59C12.0094 26.9036 12.2644 27.1586 12.578 27.3183C12.9345 27.5 13.4012 27.5 14.3346 27.5Z"
        stroke="#5D6B98"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
