import Link from "next/link";

export default function En_i18n() {
  return (
    // TODO: atualizar links dessa página quando fluxos estiverem prontos
    <>
      <p>Dear User,</p>
      <p>
        This Privacy Policy is an integral part of the Terms and Conditions of
        Use of the Transfero Platform (
        <Link href="/" target="_blank">
          app.transfero.com
        </Link>
        ), and describes the terms and conditions applicable to the use of the
        information provided by the User to Transfero.
      </p>
      <p>
        When not defined in this Privacy Policy, the capitalized concepts and
        expressions used herein will have the meaning given in the Terms and
        Conditions of Use of the Transfero Platform.
      </p>
      <p>The topics dealt with in this Privacy Policy are as follows:</p>
      <p>
        <em>- What data we collect;</em>
        <br />
        <em>- How we collect the data;</em>
        <br />
        <em>- How we use the collected data;</em>
        <br />
        <em>- How we share the collected data;</em>
        <br />
        <em>- Right of the holders of the collected data (Users);</em>
        <br />
        <em>- Cookies and Advertising</em>
        <br />
      </p>
      <p>
        In order to simplify the understanding by the User, the table below
        presents in a concise and simplified way the systematic collection of
        data by Transfero, which does not exempt the User from the need to read
        the other provisions of this Privacy Policy.
      </p>

      <h1>Registration data</h1>
      <br />

      <p>
        <em>1. Data collected</em>
        <br />
        Full name, gender, date of birth, naturalness, nationality, marital
        status, name of spouse or partner, affiliation, CPF/ID/CNH/Passport,
        e-mail, full address, professional occupation, landline, mobile phone,
        annual income, bank details (bank, agency and account number),
        politically exposed and self-portrait person statement therefore
        identification document.
      </p>

      <p>
        <em>2. Who do we collect for?</em>
        <br />
        - Verify the identity of the User; <br />
        - Perform "Know Your Custumer" procedure to ensure the user's security
        and prevent fraud from being perpetuated using the User's personal data;
        <br />- Ensure that bank transactions carried out are carried out only
        for bank accounts held by the User.
      </p>

      <p>
        <em>3. How do we collect?</em>
        <br />
        The User himself informs at the time of his registration with the
        Transfero o
      </p>

      <p>
        <em>4. Legal basis provided in the LGPD for collection</em>
        <br />
        - Compliance with Legal Obligation (Art. 7°, II); <br />- Fraud
        Prevention Guarantee (Art. 11, II, g)
      </p>

      <h1>Digital identification data</h1>
      <br />

      <p>
        <em>1. Data collected</em>
        <br />
        IP address and Source Logical Port; IMEI of the Mobile Device by which
        the User accessed the Transfero Platform; Device (operating system
        version, manufacturer, data of installed applications, languages,
        permissions and settings of keyboards, wi-fi networks and mobile network
        used, information about internet consumption, memory, VPN usage, battery
        status); Browser its respective version; Geolocation; Record of dates
        and times of each user's action; Screens accessed by the User;
        Information regarding the amounts of clicks and attempts to use the
        Transfero Platform and/or mobile application; Session ID; Cookies,
        Screen Resolution, Java, Information about the services and products
        that the User searches for and uses; Pages and applications that
        originated access to the Transfero Platform; and applications that were
        accessed during and after browsing the Transfero Platform; Contacts
        registered on the device, Flash Players installed; Number of clicks and
        typing speed
      </p>

      <p>
        <em>2. Who do we collect for?</em>
        <br />
        - Identify and authenticate the User's access to the Transfero Platform,
        preventing third parties from accessing the system fraudulently; <br />-
        Identify "bugs", errors and inconsistencies in the Transfero Platform so
        that such errors can be corrected and improve access by the User; <br />
        - Analyze the performance and measure the audience of the Transfero
        Platform; - Analyze the security of the Transfero Platform and
        resilience to possible attacks
      </p>

      <p>
        <em>3. How do we collect?</em>
        <br />
        The data is collected as the User accesses, remains and uses the
        Transfero Platform.
      </p>

      <p>
        <em>4. Legal basis provided in the LGPD for collection</em>
        <br />- Consent (Art. 7°, I); e - Compliance with Legal Obligation (Art.
        7°, II).
      </p>

      <h1>User Wallet Data</h1>
      <br />

      <p>
        <em>1. Data collected</em>
        <br />
        Trading API keys and encrypted wallet address (wallets)
      </p>

      <p>
        <em>2. Who do we collect for?</em>
        <br />- Ensure that the cashouts and/or deposits are made safely for the
        final recipient
      </p>

      <p>
        <em>3. How do we collect?</em>
        <br />
        The User informs when requesting a deposit/cash
      </p>

      <p>
        <em>4. Legal basis provided in the LGPD for collection</em>
        <br />- Execution of Contract (Art. 7°, V)
      </p>

      <h1>Customer Service Data</h1>
      <br />

      <p>
        <em>1. Data collected</em>
        <br />
        Text, audio or video conversations when made in contact with Transfero's
        service channels
      </p>

      <p>
        <em>2. Who do we collect for?</em>
        <br />
        - Allow the resolution of problems that occurred with the User;
        <br />- Measurement of the quality of customer service
      </p>

      <p>
        <em>3. Legal basis provided in the LGPD for collection</em>
        <br />- Compliance with Legal Obligation (Art. 7, II)
      </p>

      <h1>Transfero Platform Access Data</h1>
      <br />

      <p>
        <em>1. Data Collected</em>
        <br />
        Username, access password, security word, security PIN, and security
        information
      </p>

      <p>
        <em>2. Who do we collect for?</em>
        <br />- Ensure the authenticity of the User's access to the Transfero
        Platform, in addition to ensuring the security of their transactions.
      </p>

      <p>
        <em>3. How do we collect?</em>
        <br />
        Collected at the time of registration of the User in the Transfero
        Platform
      </p>

      <p>
        <em>4. Legal basis provided in the LGPD for collection</em>
        <br />- Compliance with Legal Obligation (Art. 7, II)
      </p>

      <h1>User Communication and Marketing Data</h1>
      <br />

      <p>
        <em>1. Data collected</em>
        <br />
        Full name, email and mobile phone
      </p>

      <p>
        <em>2. What do we collect for?</em>
        <br />
        - Contact the User for any notices about the Transfero Platform;
        <br />- Promote Transfero products and/or services that may be of
        interest to the User.
      </p>

      <p>
        <em>3. How Do We Collect?</em>
        <br />
        Collected at the time of registration with the Transfero Platform
      </p>

      <p>
        <em>4. Legal basis provided in the LGPD for collection</em>
        <br />- Consent (Art. 7°, I)
      </p>

      <h1>1. ACCEPT AND CHANGES</h1>
      <p>
        1.1 By expressing Acceptance of the Terms of Use of the Transfero
        Platform, which this Privacy Policy is an annex, the User expressly
        declares and for all purposes of law that he has read, understood and
        understood all the rights and obligations set out in the Transfero Terms
        and Conditions, and also agrees with all provisions of this Privacy
        Policy. The use of the Transfero Platform indicates that the User agrees
        to this Privacy Policy.
      </p>
      <p>
        1.2 This Privacy Policy covers the processing attributed by Transfero to
        data capable of identifying the User, which under Art. 5°, V c/c VI of
        Law 13.709/2018, the General Data Protection Law ("LGPD"), classifies
        Transfero as the controller of the data and the User as the holder of
        the collected data.
      </p>
      <p>
        1.3 This Privacy Policy is subject to constant improvements and
        improvements. Therefore, we recommend your periodic reading.
      </p>

      <h1>2. USE OF USER DATA</h1>
      <p>
        2.1 User data may be collected by the Transfero Platform when the User:
        (i) browses, registers and Transactions on the Transfero Platform; (ii)
        shares information with the Transfero Platform aiming at improving the
        systems maintained by Transfero; (iii) contacts service channels.
      </p>
      <p>
        2.2 The Transfero Platform may obtain user data through external
        partners, information providers, social networks (when previously
        authorized by each User), official public sources, among others.
      </p>
      <p>
        2.3 Personal User Data will be requested for each User to have access to
        the Transfero Platform. Among such data, will be requested: full name;
        date and place of birth; nationality; gender; CPF/RG/CNH; membership;
        e-mail; cell phone; landline; full address; country of residence;
        marital status; name of the spouse or partner; profession; monthly
        income; equity; politically exposed person statement, copy of ID
        document and copy of proof of residence.
      </p>
      <p>
        2.4 Without sending such documents and information to the Transfero
        Platform, the use of Transfero's channels and functionalities may be
        restricted and, depending on the case, cancellation. Transfero may, as
        the case may be, request other documents for the User, in order to
        ensure access to the Transfero Platform and ensure the user's security.
      </p>
      <p>
        2.5 The User who refuses or ceases to provide necessary basic data may
        have his/her access to the Transfero Platform denied or cancelled.
      </p>
      <p>
        2.6 When registering with the Transfero Platform, the following data and
        information may be collected, retained and/or saved by Transfero, among
        others:
      </p>
      <p>
        - Contact details: phone number and other similar data.
        <br />
        - Credentials: including passwords, secure words, security PIN and
        security information necessary for the authentication process, access to
        the Transfero Platform and any Transactions.
        <br />
        - Financial data: we collect data necessary for the realization of
        losses in Local Currency (R$), such as bank and agency. In addition,
        Transfero owes trading API keys and encrypted wallet address.
        <br />
        - Demographic data: gender, address, education, income.
        <br />
        - Usage Data: In addition to transaction-related data, Transfero may
        also capture data related to interaction with other Users including your
        User profile, we may also capture data related to your interaction with
        our communications and on our channels, device data, operating system
        and device identifier (IMEI) and configuration.
        <br />
        - Service data: Your interaction in our service channels is also
        recorded, as well as other details of your contact, and may include
        content of chat conversations.
        <br />
        - Relationship data: Only when granted unambiguous permission, we can
        capture data from what are the contacts of your relationship network.
        <br />- Geolocation data: Location data from GPS (Global Navigation
        Satellite System), mobile towers, Wi-fi access points or location
        resulting from each User's IP data may be collected.
      </p>
      <p>
        2.7 The Transfero Platform will make its best efforts to ensure that all
        personal data collected is stored on servers or magnetic means of high
        security, always applying recommended practices to combat disasters or
        interrupt its activities.
      </p>
      <p>
        2.8 The registration in the Transfero Platform will, in addition to
        access to the systems maintained by Transfero, the receipt of electronic
        messages (such as e-mails and pushes) of security and administrative
        purposes, in addition to advertising messages, being this sending
        essential to the execution and development of 3RZ's activities. If you
        do not agree to this procedure, you will need to request the
        cancellation of your registration with the Transfero Platform.
      </p>

      <h1>3. USE OF COLLECTED DATA</h1>
      <p>
        3.1 User data may be collected for the purpose of improving the
        Transfero Platform, and protecting such data based on the General Data
        Protection Law and the Transfero Terms and Conditions. Without
        prejudice, such collected data may be used to:
      </p>
      <p>
        - Verify the identity of the User;
        <br />
        - Perform "Know Your Custumer" procedure to ensure the user's security
        and prevent fraud from being perpetuated using the User's personal data;
        <br />
        - Ensure that bank transactions carried out are carried out only for
        bank accounts held by the User;
        <br />
        - Ensure that the deposits and /or deposits are made safely for the
        final recipient;
        <br />
        - Identify and authenticate the User's access to the Transfero Platform,
        preventing third parties from accessing the system fraudulently;
        <br />
        - Realization of Transactions on the Transfero Platform, creation of
        Purchase Orders and Sales Orders, generate and allow access to the
        Virtual Account, among other activities that depend on authorization and
        consent of the User;
        <br />
        - Solve problems and doubts;
        <br />
        - Measurement of the quality of customer service;
        <br />
        - Analyze the performance and measure the audience of the E376 Platform
        verify navigation habits of E376 evaluate statistics related to the
        number of accesses and use of the Transfero Platform, including its
        features and functionalities;
        <br />
        - Identify "bugs", errors and inconsistencies in the Transfero Platform,
        so that such errors can be corrected and improve access by the User;
        <br />
        - Analyze the security of the Transfero Platform, and resilience to
        possible attacks, in order to improve security tools;
        <br />
        - Customize content for the user's needs, including with regard to
        e-mail marketing and promotions;
        <br />
        - Measures deemed necessary or recommended by Transfero for the constant
        improvement of the functionalities of the Transfero Platform, as well as
        to better understand the needs of users;
        <br />
        - Statistical elaboration of the use of the Transfero Platform;
        <br />
        - notification of Users regarding changes in the Transfero Platform;
        <br />
        - implementation of the mechanisms and policies of internal controls of
        the Transfero Platform;
        <br />
        - compliance with other legal and regulatory obligations imposed on the
        Transfero Platform;
        <br />- Customize the User experience in the use of the Transfero
        Platform.
      </p>
      <p>
        3.2 The Transfero Platform will send messages by electronic means such
        as emails and pushes for activities of the Transfero Platform, and other
        advertising and communications purposes. The frequency of sending such
        communications may vary.
      </p>
      <p>
        3.3 At any time the User may request the interruption of the processing
        of his information by the Transfero Platform, including requesting
        his/her complete deletion of the Transfero systems and the interruption
        of use of the Transfero Platform.
      </p>
      <p>
        3.4 The data collected will be stored by Transfero for up to five (5)
        years after the end of the User's relationship with the Platform, and
        this period may be extended for compliance with a legal or regulatory
        obligation.
      </p>
      <p>
        3.5 The information collected from and about Users of the Transfero
        Platform may also be (KYC) and Prevention and Combating Money Laundering
        (AML).
      </p>
      <p>
        3.6 The User's data and information and its activities may also be
        shared with third parties in the following cases:
      </p>
      <p>
        - employees and companies of the economic group to which Transfero
        belongs and their partners or contractors;
        <br />
        - companies that are members of the same economic group of Transfero,
        whether Brazilian or foreign;
        <br />
        - government authorities requiring the disclosure, by law or court
        order, of all or any part of the information; and
        <br />- any other third parties who require the information due to (i)
        legal and regulatory obligations; and/or (ii) necessary or recommended
        disclosure of information for the due, effective and efficient operation
        of the Transfero Platform and its functionalities.
      </p>

      <h1>4. SHARING DATA WITH THIRD PARTIES</h1>
      <p>
        4.1 The data collected will always be processed by Transfero or by
        partners contracted exclusively for this purpose. By adhering to the
        Transfero Terms and Conditions the User authorizes such sharing to be
        made, always observing the provisions of applicable law.
      </p>
      <p>
        4.2 Personally identifiable information will not be used for marketing
        purposes or any other purpose provided for in the Transfero Terms and
        Conditions, and will be treated in accordance with the General Data
        Protection Act.
      </p>
      <p>
        4.3 In all cases where information is shared, the contracted company
        will be required to have the same standards of confidentiality,
        anonymization and custody of the information as those adopted by
        Transfero and the Transfero Platform.
      </p>
      <p>
        4.4 Transfer o May disclose data and information of any User, if such
        disclosure is required by law or governmental authority, including
        judiciary, regulatory agencies, municipal or state agencies, without
        prior notice to the User. In any case, in the event of disclosure of
        such information, the User will be informed of such disclosure within 72
        (seventy-two) hours, counted from the date on which the information is
        disclosed.
      </p>
      <p>
        4.5 The Transfero Platform may contain links that lead to other websites
        and websites. It is possible that the policies and practices adopted by
        these sites and pages are not compatible with those adopted by Transfero
        and the Transfero Platform. Transfero is not responsible for the
        custody, use and processing of information outside its environment.
      </p>

      <h1>5. COOKIES AND SIMILAR TECHNOLOGIES</h1>
      <p>
        5.1 The Transfero Platform may use cookies and other similar
        technologies to support the user identification process, including for
        the purposes of marketing and data protection actions collected.
      </p>
      <p>
        5.2 These technologies are intended to store information in web
        browsers, and may be present on computers, phones and/or other mobile
        devices compatible with the Transfero Platform.
      </p>
      <p>
        5.3 Cookies are relevant for authentication processes, advertising,
        recommendations, audience measurements and channel features and
        functionality, security analysis for improvement and development of
        anti-fraud tools.
      </p>
      <p>
        5.4 The User who wishes to disable cookies in the configuration options
        of his browser, being aware that the features and services of the
        Transfero Platform may not be provided, or provided in part.
      </p>

      <h1>6. INFORMATION SECURITY AND DATA PROTECTION LAW</h1>
      <p>
        6.1 Transfero undertakes to use the best market standards to protect the
        data of its Users and comply, at all times, with the General Data
        Protection Law.
      </p>
      <p>
        6.2 The Virtual Account is protected by a personal and non-transferable
        password, which exists for the privacy and security of users.
      </p>
      <p>
        6.3 Due to the provisions of the General Data Protection Law, the
        processing of data by Transfero will be guided by the following grounds:
      </p>
      <ol>
        a) Respect for privacy; b) Informative self-determination; (c) freedom
        of expression, information, communication and opinion; d) Inviolability
        of intimacy, honor and image; e) Economic and technological development
        and innovation; (f) free enterprise, free competition and consumer
        protection; and g) Human rights, the free development of personality,
        dignity and the exercise of citizenship by natural persons.
      </ol>

      <h1>7. FINAL PROVISIONS</h1>
      <p>
        7.1 Transfero may, regardless of any notice or notice to the User,
        assign the rights arising from this Privacy Policy, in whole or in part,
        to other companies, in the event of any corporate rearrangement of
        Transfero, such as merger or acquisition, at any time, provided that the
        legislation in force, regulations and regulations applicable to this
        Privacy Policy is observed.
      </p>
      <p>
        7.2 Transfero reserves the right to withholds information for as long as
        it deems necessary for the proper fulfillment of its business and
        compliance with current regulations, even after the termination of the
        User's account.
      </p>
      <p>
        7.3 The User's tolerance of the Transfero Platform and vice versa,
        regarding the non-compliance with any of the obligations assumed in this
        Privacy Policy will not imply a renewal or waiver of law. Compliance
        with this Privacy Policy may be required at any time.
      </p>
      <p>
        7.4 This Privacy Policy binds the User and the Transfero Platform and
        its successors in any way.
      </p>
      <p>
        7.5 The unfeasibility of enforcement or invalidity, even if due to a
        judicial decision, of some clause, will not affect the validity of the
        other clauses of this Privacy Policy, which will remain valid and
        enforceable.
      </p>
      <p>
        7.6 Transfero reserves the right to modify this Privacy Policy at any
        time. In this case, the User will be duly notified of such changes, and
        their Acceptance. In this case, the provisions of the Terms of Use will
        be observed.
      </p>
    </>
  );
}
