import { Icon, Image } from "@/widgets";
import styles from "./index.module.scss";

type ProfileInfoProps = {
  fullname?: string;
  nickname: string;
  profile: string;
  label: string;
};

export default function ProfileInfo({
  fullname,
  nickname,
  profile,
  label,
}: ProfileInfoProps) {
  return (
    <div className={`ProfileInfo ${styles.container}`}>
      <Icon icon="icoUser" />
      <p>{fullname}</p>
      <p>@{nickname}</p>
      <div>
        {/* TODO: implement icons here */}
        {false && (
          <Image src={`/images/icons/profile-${profile}.svg`} alt={profile} />
        )}
        <span>{label}</span>
      </div>
    </div>
  );
}
