import scssVariables from "@/styles/variables.module.scss";

export default function receipt() {
  return (
    <svg viewBox="0 0 17 22" fill="none">
      <path
        d="M16.3961 5.53679L12.5257 1.40717C12.5017 1.38127 12.4726 1.36055 12.4403 1.34628C12.408 1.33202 12.3731 1.32451 12.3378 1.32422H2.66442C2.20891 1.33978 1.77811 1.53517 1.46632 1.86761C1.15453 2.20005 0.987137 2.64247 1.00077 3.09804V18.2905C0.998225 18.327 1.00321 18.3636 1.01541 18.398C1.02761 18.4325 1.04676 18.464 1.07169 18.4908C1.09661 18.5175 1.12676 18.5388 1.16028 18.5534C1.19379 18.5679 1.22994 18.5755 1.26649 18.5755C1.30303 18.5755 1.33919 18.5679 1.3727 18.5534C1.40621 18.5388 1.43637 18.5175 1.46129 18.4908C1.48621 18.464 1.50537 18.4325 1.51757 18.398C1.52977 18.3636 1.53475 18.327 1.5322 18.2905V3.09804C1.52294 2.78804 1.63688 2.487 1.84908 2.26083C2.06129 2.03466 2.35446 1.90179 2.66442 1.8913H12.0721V4.24386C12.0584 4.69943 12.2258 5.14184 12.5376 5.47428C12.8494 5.80672 13.2802 6.00211 13.7357 6.01767H15.9392V18.9023C15.9485 19.2122 15.8346 19.5131 15.6226 19.7393C15.4105 19.9654 15.1175 20.0984 14.8076 20.1091H2.66442C2.35457 20.0984 2.06157 19.9654 1.8495 19.7393C1.63744 19.5131 1.52359 19.2122 1.53285 18.9023V17.9153C1.5354 17.8788 1.53042 17.8422 1.51822 17.8078C1.50602 17.7733 1.48686 17.7418 1.46194 17.715C1.43702 17.6883 1.40686 17.667 1.37335 17.6524C1.33983 17.6379 1.30368 17.6303 1.26714 17.6303C1.23059 17.6303 1.19444 17.6379 1.16093 17.6524C1.12741 17.667 1.09726 17.6883 1.07233 17.715C1.04741 17.7418 1.02825 17.7733 1.01605 17.8078C1.00385 17.8422 0.998872 17.8788 1.00142 17.9153V18.9049C0.987784 19.3605 1.15518 19.8029 1.46697 20.1353C1.77876 20.4678 2.20956 20.6632 2.66506 20.6787H14.8102C15.2657 20.6632 15.6965 20.4678 16.0083 20.1353C16.3201 19.8029 16.4875 19.3605 16.4739 18.9049V5.73122C16.4742 5.65704 16.4464 5.58549 16.3961 5.53096V5.53679ZM12.6035 4.2471V2.29246L15.5659 5.45124H13.7357C13.4259 5.44059 13.1329 5.30764 12.9208 5.08148C12.7087 4.85533 12.5949 4.55439 12.6041 4.2445L12.6035 4.2471Z"
        fill={scssVariables.primary}
        stroke={scssVariables.primary}
      />
      <path d="M5.88867 9.70605H11.5867" stroke={scssVariables.primary} />
      <path d="M5.88867 11.7642H11.5867" stroke={scssVariables.primary} />
      <path d="M5.88867 14.1558H11.5867" stroke={scssVariables.primary} />
    </svg>
  );
}
