/* eslint-disable no-unused-vars */
// Original you lib from: https://github.com/jquense/yup
import * as yup from "yup";
import { isCNPJ, isCPF } from ".";

//#region Add custom methods

yup.addMethod(yup.string, "cpf", function (error: string) {
  return this.test("cpf", error, function (value: any) {
    const { path, createError } = this;
    return isCPF(value) || createError({ path, message: error });
  });
});

yup.addMethod(yup.string, "cnpj", function (error: string) {
  return this.test("cnpj", error, function (value: any) {
    const { path, createError } = this;
    return isCNPJ(value) || createError({ path, message: error });
  });
});

//#endregion Add custom methods

// Include the custom methods into the yup schema
declare module "yup" {
  // eslint-disable-next-line no-unused-vars
  interface Schema {
    cpf(param: string): this;
    cnpj(param: string): this;
  }
}

// Return the yup object with our added methods
export default yup;
