import Link from "next/link";
import styles from "./index.module.scss";

type itemType = {
  url: string;
  label: string;
  id?: string;
};

type InlineMenuProps = {
  items: itemType[];
};

export default function InlineMenu({ items }: InlineMenuProps) {
  return (
    <ul className={`InlineMenu ${styles.container}`}>
      {items.map((item: itemType, index: number) => (
        <li key={index}>
          <Link href={item.url} id={item.id}>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );
}
