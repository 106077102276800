import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { IAsyncState, IHistory } from "@/models";
import { historyReducer } from "./reducers";
import { useHistory } from "./hooks";
import { fetchHistory, historyExtraReducerBuilder } from "./thunks";

interface AsyncHistory extends IAsyncState {
  data: null | IHistory[];
}

export interface HistoryState {
  history: AsyncHistory;
}

export const initialState: HistoryState = {
  history: {
    data: null,
    error: null,
    lastUpdate: null,
    loading: false,
  },
};

export const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: historyReducer,
  extraReducers: historyExtraReducerBuilder,
});


export const { clearHistory, updateHistory, addHistory } =
  historySlice.actions;

export { useHistory, fetchHistory };

export const historySelector = (state: RootState) => state.history

export default historySlice.reducer;
