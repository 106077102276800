import scssVariables from "@/styles/variables.module.scss";

export default function attention() {
  return (
    <svg viewBox="0 0 63 60" fill="none">
      <path
        d="M28.0205 23.0488H34.7386V39.8442H28.0205V23.0488ZM28.0171 43.2033H34.7353V49.9215H28.0171V43.2033Z"
        fill={scssVariables.primary}
      />
      <path
        d="M37.3153 3.56734C36.1463 1.36715 33.8689 0 31.3764 0C28.884 0 26.6065 1.36715 25.4376 3.57071L0.788609 50.1377C0.241643 51.1603 -0.0292985 52.3078 0.00251043 53.4671C0.0343194 54.6263 0.36778 55.7573 0.969999 56.7483C1.56354 57.7439 2.40648 58.5675 3.41558 59.1377C4.42468 59.7079 5.56505 60.0051 6.72411 59.9999H56.0287C58.407 59.9999 60.5601 58.7839 61.7862 56.7483C62.3875 55.7569 62.7204 54.6261 62.7522 53.467C62.784 52.308 62.5136 51.1606 61.9676 50.1377L37.3153 3.56734ZM6.72411 53.2818L31.3764 6.71481L56.0455 53.2818H6.72411Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
