import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { baseReducer } from "./reducers";
import { useBase } from "./hooks";

export interface BaseState {
  isScrolled: boolean;
  isMenuOpen: boolean;
  hideZeros: boolean;
  dashTerm: string;
  isBalanceVisible: boolean;
}

export const initialState: BaseState = {
  isScrolled: false,
  isMenuOpen: false,
  hideZeros: false,
  dashTerm: "",
  isBalanceVisible: true,
};

export const baseSlice = createSlice({
  name: "base",
  initialState,
  reducers: baseReducer,
});

export const {
  updateIsScrolled,
  updateIsMenuOpen,
  updateDashFormParams,
  updateIsBalanceVisible,
} = baseSlice.actions;

export { useBase };

export const baseSelector = (state: RootState) => state.base;

export default baseSlice.reducer;
