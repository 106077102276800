import { IFavorite } from "@/models";
import { api, throwApiError } from "./axios";
import { sendMessageSentry } from "./Sentry";
import { hideSensitiveData } from "@/utils";

const FavoritesApi = {
  getFavorites: async () => {
    try {
      const response = await api.request({
        url: "/favorites?PageSize=1000",
        method: "GET",
      });
      return response.data as IFavorite[];
    } catch (error: any) {
      sendMessageSentry(
        `Get Favorites Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "getFavorites");
    }
  },
  addFavorite: async (favorite: IFavorite) => {
    try {
      const response = await api.request({
        url: "/favorites",
        method: "POST",
        data: favorite,
      });
      return response.data as IFavorite;
    } catch (error: any) {
      throwApiError(error, "addFavorite");
      sendMessageSentry(
        `Post Favorites Error: ${error}`,
        hideSensitiveData(error)
      );
    }
  },
  deleteFavorite: async (idFavorite: string | number) => {
    try {
      const response = await api.request({
        url: `/favorites/${idFavorite}`,
        method: "delete",
      });
      return response.data as IFavorite;
    } catch (error: any) {
      sendMessageSentry(
        `Delete Favorites Error: ${error}`,
        hideSensitiveData(error)
      );
      throwApiError(error, "deleteFavorite");
    }
  },
};

export default FavoritesApi;
