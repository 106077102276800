import scssVariables from "@/styles/variables.module.scss";

export default function graphUp() {
  return (
    <svg viewBox="0 0 11 7" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2758 1.14844L6.46953 5.90625L3.80898 3.24297L0.617969 6.43398L0 5.81602L3.80898 2.00703L6.39844 4.59375L9.59219 0.601562L10.2758 1.14844Z"
        fill={scssVariables.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.43408 0.4375C6.43408 0.196875 6.63096 0 6.87158 0H10.3716C10.6122 0 10.8091 0.196875 10.8091 0.4375V3.9375C10.8091 4.17812 10.6122 4.375 10.3716 4.375C10.131 4.375 9.93408 4.17812 9.93408 3.9375V0.875H6.87158C6.63096 0.875 6.43408 0.678125 6.43408 0.4375Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
