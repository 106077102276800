export default function keyPix() {
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="key-01">
        <path
          id="Icon"
          d="M16.0833 8.24995C16.0833 7.78077 15.9043 7.3116 15.5464 6.95364C15.1884 6.59566 14.7192 6.41667 14.25 6.41667M14.25 13.75C17.2876 13.75 19.75 11.2876 19.75 8.25C19.75 5.21243 17.2876 2.75 14.25 2.75C11.2124 2.75 8.75 5.21243 8.75 8.25C8.75 8.50087 8.7668 8.74782 8.79933 8.98978C8.85283 9.38775 8.87958 9.58673 8.86157 9.71262C8.84282 9.84377 8.81893 9.91444 8.7543 10.0301C8.69225 10.1411 8.5829 10.2504 8.3642 10.4691L3.67958 15.1538C3.52104 15.3123 3.44177 15.3916 3.38508 15.4841C3.33482 15.5661 3.29778 15.6555 3.27533 15.749C3.25 15.8545 3.25 15.9666 3.25 16.1908V17.7833C3.25 18.2967 3.25 18.5534 3.34991 18.7495C3.43779 18.922 3.57803 19.0622 3.75051 19.1501C3.94659 19.25 4.20329 19.25 4.71667 19.25H6.91667V17.4167H8.75V15.5833H10.5833L12.0309 14.1358C12.2496 13.9171 12.3589 13.8078 12.4699 13.7457C12.5856 13.6811 12.6562 13.6572 12.7874 13.6384C12.9133 13.6204 13.1123 13.6472 13.5102 13.7007C13.7522 13.7332 13.9991 13.75 14.25 13.75Z"
          stroke="#404968"
          strokeWidth="1.6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
