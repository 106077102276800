import scssVariables from "@/styles/variables.module.scss";

export default function eye() {
  return (
    <svg viewBox="0 0 22 15" fill="none">
      <g>
        <path
          d="M21.0016 7.26052C20.9417 7.19277 18.4514 2.28882e-05 10.5016 2.28882e-05C2.55176 2.28882e-05 0.0614702 7.19277 0.0386235 7.26052C-0.0138511 7.41614 -0.0138511 7.58468 0.0386235 7.7403C0.0614702 7.80806 2.55097 15.0008 10.5016 15.0008C18.4522 15.0008 20.9417 7.80806 21.0016 7.7403C21.054 7.58467 21.054 7.41615 21.0016 7.26052ZM10.5016 13.5008C8.57809 13.5239 6.69286 12.9627 5.09516 11.8914C3.49745 10.8201 2.2625 9.28906 1.5536 7.50081C2.26352 5.71325 3.4987 4.18294 5.09618 3.11176C6.69366 2.04059 8.57836 1.4789 10.5016 1.50081C12.4251 1.47769 14.3103 2.0389 15.908 3.11022C17.5058 4.18155 18.7407 5.71255 19.4496 7.50081C18.7397 9.28834 17.5045 10.8186 15.907 11.8897C14.3095 12.9607 12.4248 13.523 10.5016 13.5008Z"
          fill={scssVariables.primary}
        />
        <path
          d="M14.064 6.32224C13.8805 5.76605 13.5692 5.26061 13.1551 4.84648C12.741 4.43234 12.2355 4.12103 11.6793 3.93752L11.5297 3.89262C11.1969 3.79613 10.852 3.74811 10.5055 3.75002C9.51093 3.75002 8.5571 4.14511 7.85384 4.84837C7.15058 5.55163 6.75549 6.50546 6.75549 7.50002C6.7557 8.49452 7.15085 9.44823 7.85407 10.1514C8.55728 10.8547 9.51099 11.2498 10.5055 11.25C11.5 11.25 12.4539 10.8549 13.1571 10.1517C13.8604 9.44841 14.2555 8.49458 14.2555 7.50002C14.2581 7.15351 14.2101 6.80847 14.1129 6.47586C14.1014 6.42325 14.0851 6.37182 14.064 6.32224ZM10.5015 9.75002C9.90481 9.75002 9.33252 9.51297 8.91056 9.09101C8.48861 8.66906 8.25154 8.09676 8.25154 7.50002C8.25154 6.90328 8.48861 6.33099 8.91056 5.90903C9.33252 5.48708 9.90481 5.25002 10.5015 5.25002C10.7254 5.24784 10.9481 5.28081 11.1617 5.34771H11.2145C11.5466 5.45788 11.8484 5.6441 12.0959 5.89152C12.3433 6.13895 12.5295 6.44075 12.6397 6.77287C12.6351 6.79017 12.6351 6.80835 12.6397 6.82565C12.7134 7.04315 12.7515 7.27115 12.7523 7.50081C12.7522 7.79628 12.6939 8.08885 12.5808 8.36179C12.4676 8.63473 12.3018 8.88271 12.0928 9.09157C11.8838 9.30043 11.6357 9.46607 11.3626 9.57905C11.0896 9.69203 10.797 9.75013 10.5015 9.75002Z"
          fill={scssVariables.primary}
        />
      </g>
    </svg>
  );
}
