import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useI18n, useCustomerInfo, useToast } from "@/hooks";
import { getError } from "@/utils";
import { Button, Card, IconLabel, InlineMessage, Spinner } from "@/widgets";
import { Error, Input } from "@/widgets/forms";
import { useCustomer } from "@/store/features/customer";
import { useSchema } from "./hooks";
import content from "./index.json";
import styles from "./index.module.scss";
import { useRouter } from "next/router";
import { NotificationsSupportApi } from "@/apis";
import Link from "next/link";

export default function ContactUsForm() {
  const { i18n } = useI18n(content);
  const schema = useSchema();
  const { hasCustomer } = useCustomerInfo();
  const { customerState } = useCustomer();
  const router = useRouter();
  const { locale } = useRouter();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  0;

  const onSubmitHandler = async (data: any) => {
    try {
      await NotificationsSupportApi.getSupportMessage(
        data.customerName,
        data.customerEmail,
        data.message,
        locale
      );
      router.push("/");
      useToast(i18n("success"), <p>{i18n("successMsg")}</p>, "success");
    } catch (error) {
      useToast(
        i18n("error"),
        <p>
          {i18n("errorMsg")}
          <Link
            href={"https://suporteplataforma.zendesk.com/hc/pt-br/requests/new"}
          >
            {i18n("support")}
          </Link>
        </p>,
        "error"
      );
    }
  };

  const fetchDefaultData = async () => {
    if (hasCustomer) {
      reset((formValues) => {
        return {
          ...formValues,
          customerName: customerState.customer.data?.fullName!,
          customerEmail: customerState.customer.data?.email!,
        };
      });
    }
  };

  useEffect(() => {
    fetchDefaultData();
  }, []);

  return (
    <Card className={styles.container}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <IconLabel
          size="large"
          src="/images/icons/circle-mail-us.svg"
          label={i18n("cardTitle")}
        />
        <div>
          <div className={styles.rowField}>
            <Input
              type="text"
              label={i18n("labelName")}
              placeholder={i18n("yourName")}
              {...register("customerName")}
            />
            <Error message={getError(errors, "name")} />
          </div>
          <div className={styles.rowField}>
            <Input
              type="email"
              label={i18n("labelEmail")}
              placeholder={i18n("yourEmail")}
              {...register("customerEmail")}
            />
            <Error message={getError(errors, "email")} />
          </div>
          <div className={styles.rowField}>
            <Input
              type="textarea"
              label={i18n("labelMessage")}
              placeholder={i18n("yourMessage")}
              {...register("message")}
            />
            <Error message={getError(errors, "message")} />
          </div>
          <InlineMessage
            className={styles.infoMessage}
            message={i18n("businessDays")}
          />
          <div className={styles.rowAction}>
            {isSubmitting && <Spinner size="small">{i18n("loading")}</Spinner>}
            {!isSubmitting && <Button type="submit">{i18n("send")}</Button>}
          </div>
        </div>
      </form>
    </Card>
  );
}
