import { useRouter } from "next/router";
import * as S from "./styles";
import { useEffect, useRef, useState } from "react";
import Link from "next/link";
import i18n from "../../../i18n";
import { t } from "i18next";

interface IProps {
  isLogged: boolean;
}

const DropdownLanguage = ({ isLogged }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { locales, locale, pathname, query, asPath } = useRouter();

  const switchChangeLanguage = (currentLocale: string) => {
    if (currentLocale !== locale) {
      i18n.changeLanguage(currentLocale);
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const translateTitles = (localeItem: string) => {
    const translations: { [key: string]: string } = {
      pt: "Português (Brasil)",
      en: "English",
      es: "Español",
    };
    return translations[localeItem] || "Unknown";
  };

  const toggleDropdown = () => setIsOpen(!isOpen);

  return (
    <S.ContentLogoAndLanguage id="data-test-language">
      {isLogged && (
        <Link href="/">
          <img src="/images/logo-complete.svg" alt="" />
        </Link>
      )}
      <S.ContentLanguage ref={dropdownRef}>
        <div onClick={() => toggleDropdown()}>
          <img src="/images/icon-language.svg" alt="" />
          {t("operationLayout.language")}
        </div>
        <S.DropdownLanguage open={isOpen ? "block" : "none"}>
          {locales?.map((currentLocale, i) => (
            <Link
              href={{ pathname, query }}
              as={asPath}
              locale={currentLocale}
              key={i}
            >
              <S.Item
                onClick={() => {
                  switchChangeLanguage(currentLocale);
                  toggleDropdown();
                }}
              >
                {translateTitles(currentLocale)}
              </S.Item>
            </Link>
          ))}
        </S.DropdownLanguage>
      </S.ContentLanguage>
    </S.ContentLogoAndLanguage>
  );
};

export default DropdownLanguage;
