import { useI18n } from "@/hooks";
import { En_i18n, Es_i18n, Pt_i18n } from "./Translations";

import styles from "./index.module.scss";

export default function FeesText() {
  const TranslatedComp = {
    en: <En_i18n />,
    es: <Es_i18n />,
    pt: <Pt_i18n />,
  };

  const { i18nComp } = useI18n(TranslatedComp);

  return <div className={styles.container}>{i18nComp(TranslatedComp)}</div>;
}
