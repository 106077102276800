import scssVariables from "@/styles/variables.module.scss";

export default function maintenance() {
  return (
    <svg viewBox="0 0 60 60" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 0C46.5412 0 60 13.4588 60 30C60 46.5412 46.5412 60 30 60C13.4588 60 0 46.5412 0 30C0 13.4588 13.4588 0 30 0ZM30 6C16.7193 6 6 16.7193 6 30C6 43.2807 16.7193 54 30 54C43.2807 54 54 43.2807 54 30C54 16.7193 43.2807 6 30 6ZM30.1285 16.2349C32.3978 19.1856 33.1634 22.8155 32.4362 25.4657C32.3454 25.9017 35.5438 29.3023 42.0312 35.6673C44.1525 37.7886 44.1525 39.9099 42.0312 42.0312C40.0347 44.0278 38.0382 44.1452 36.0416 42.3836L25.5131 32.3887C22.8628 33.1159 19.2329 32.3504 16.2824 30.081C14.1183 27.2355 13.3718 23.5161 13.9747 20.8503L18.5901 25.4657L23.2055 23.158L25.5131 18.5426L20.8978 13.9272C23.5635 13.3244 27.2831 14.0708 30.1285 16.2349Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
