export default function governmentServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M14.1667 17.5002V24.1668M17.9167 17.5002V24.1668M22.0833 17.5002V24.1668M25.8333 17.5002V24.1668M12.5 25.5002L12.5 26.1668C12.5 26.6336 12.5 26.8669 12.5908 27.0452C12.6707 27.202 12.7982 27.3295 12.955 27.4094C13.1333 27.5002 13.3666 27.5002 13.8333 27.5002H26.1667C26.6334 27.5002 26.8667 27.5002 27.045 27.4094C27.2018 27.3295 27.3293 27.202 27.4092 27.0452C27.5 26.8669 27.5 26.6336 27.5 26.1668V25.5002C27.5 25.0335 27.5 24.8001 27.4092 24.6219C27.3293 24.4651 27.2018 24.3376 27.045 24.2577C26.8667 24.1668 26.6334 24.1668 26.1667 24.1668H13.8333C13.3666 24.1668 13.1333 24.1668 12.955 24.2577C12.7982 24.3376 12.6707 24.4651 12.5908 24.6219C12.5 24.8001 12.5 25.0335 12.5 25.5002ZM19.7108 12.5645L13.5441 13.9348C13.1715 14.0176 12.9853 14.059 12.8462 14.1592C12.7236 14.2475 12.6273 14.3676 12.5676 14.5065C12.5 14.664 12.5 14.8548 12.5 15.2364L12.5 16.1668C12.5 16.6336 12.5 16.8669 12.5908 17.0452C12.6707 17.202 12.7982 17.3295 12.955 17.4094C13.1333 17.5002 13.3666 17.5002 13.8333 17.5002H26.1667C26.6334 17.5002 26.8667 17.5002 27.045 17.4094C27.2018 17.3295 27.3293 17.202 27.4092 17.0452C27.5 16.8669 27.5 16.6336 27.5 16.1668V15.2364C27.5 14.8548 27.5 14.664 27.4324 14.5065C27.3727 14.3676 27.2764 14.2475 27.1538 14.1592C27.0147 14.059 26.8285 14.0176 26.4559 13.9348L20.2892 12.5645C20.1813 12.5405 20.1273 12.5285 20.0728 12.5237C20.0244 12.5194 19.9756 12.5194 19.9272 12.5237C19.8727 12.5285 19.8187 12.5405 19.7108 12.5645Z"
        stroke="#5D6B98"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
