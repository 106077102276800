import scssVariables from "@/styles/variables.module.scss";

export default function arrowRight() {
  return (
    <svg viewBox="0 0 9 14" fill="none">
      <path
        d="M1.57307 13.8114L0.440658 12.679L6.21256 6.90709L0.438965 1.1329L1.57138 0.000488281L8.47911 6.90822L1.57307 13.8114Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
