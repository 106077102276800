import scssVariables from "@/styles/variables.module.scss";

export default function none() {
  return (
    <svg viewBox="0 0 36 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 0C8.05889 0 0 8.05888 0 18C0 22.4816 1.63783 26.5807 4.34761 29.7312L2.09117 31.9876C1.56048 32.5184 1.56048 33.3789 2.09117 33.9094C2.62189 34.4402 3.48232 34.4402 4.01301 33.9094L6.26947 31.653C9.42 34.3625 13.5188 36 18 36C27.941 36 36.0001 27.941 36.0001 18C36.0001 13.5188 34.3626 9.42 31.6531 6.26947L33.9095 4.01293C34.4403 3.48221 34.4403 2.62178 33.9095 2.09109C33.379 1.5604 32.5185 1.5604 31.9877 2.09109L29.7313 4.3476C26.5808 1.63783 22.4816 0 18 0ZM27.8995 6.1794C25.221 3.93397 21.7685 2.58197 18 2.58197C9.48487 2.58197 2.58197 9.48487 2.58197 18C2.58197 21.7685 3.93397 25.2212 6.1794 27.8994L27.8995 6.1794ZM8.10129 29.8212C10.7795 32.0662 14.2319 33.418 18 33.418C26.515 33.418 33.4181 26.5152 33.4181 18C33.4181 14.2319 32.0662 10.7795 29.8213 8.10128L8.10129 29.8212Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
