import { PayloadAction } from "@reduxjs/toolkit";
import { initialState, type BalanceState } from ".";

export const balanceReducer = {
  clearBalance: () => {
    return initialState;
  },
  updateBalance: (_: BalanceState, action: PayloadAction<BalanceState>) => {
    return action.payload;
  },
};
