import { IconButton, Image } from "@/widgets";

import "react-toastify/dist/ReactToastify.css";
import styles from "./index.module.scss";

export type ToasterTypes =
  | "success"
  | "error"
  | "warning"
  | "info"
  | "notification";

type ToasterProps = {
  type: ToasterTypes;
  title: string;
  content: any;
  footer?: any;
  onClose?(): void;
};

export default function Toaster({
  type,
  title,
  content,
  footer,
  onClose,
}: ToasterProps) {
  return (
    <div className={`Toaster ${styles.container} ${styles[type]}`}>
      <Image src={`/images/icons/circle-${type}.svg`} alt={type} />
      <div className={styles.wrap}>
        <div className={styles.content}>
          <h4>{title}</h4>
          <div>{content}</div>
        </div>
        <div className={styles.footer}>{footer}</div>
      </div>
      {onClose && (
        <div className={styles.close}>
          <IconButton icon="close" onClick={onClose} />
        </div>
      )}
    </div>
  );
}
