import { useTranslation } from "react-i18next";
import { IconButton } from "..";
import styles from "./index.module.scss";

type HeaderBackProps = {
  onBack: () => void;
  title?: string;
  children?: any;
  className?: string;
  size?: "default" | "wide";
};

export default function HeaderBack({
  onBack,
  title,
  children,
  className = "",
  size = "default",
}: HeaderBackProps) {
  const { t } = useTranslation();
  return (
    <div className={`${styles.container} ${styles[size]} ${className}`}>
      <IconButton
        id="data-test-header-back"
        onClick={onBack}
        icon={"arrowBack"}
        title={t("back")}
      />
      {title && <h1>{title}</h1>}

      {children && children}
    </div>
  );
}
