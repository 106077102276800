import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@/store";
import { customerSelector } from ".";

export function useCustomer() {
  const customerState = useSelector(customerSelector);
  const customerDispatch = useDispatch<AppDispatch>();
  const isCompany = customerState.customer.data?.type === "Company";

  return { customerState, customerDispatch, isCompany };
}
