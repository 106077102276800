import scssVariables from "@/styles/variables.module.scss";

export default function repeat() {
  return (
    <svg viewBox="0 0 512 512">
      <path
        fill={scssVariables.primary}
        d="M69.816,256H0l93.096,93.096L186.2,256h-69.816c0.224-77.016,62.6-139.4,139.616-139.632  c22.672,0.432,44.952,6,65.16,16.296l34.896-34.896C325.6,80.144,291.176,70.528,256,69.832  C153.296,70.112,70.104,153.296,69.816,256z M395.616,256c-0.224,77.016-62.6,139.4-139.616,139.632  c-22.672-0.432-44.952-6-65.16-16.296l-34.896,34.896c30.456,17.624,64.88,27.24,100.056,27.936  C358.696,441.872,441.88,358.696,442.184,256H512l-93.096-93.096L325.8,256H395.616z"
      />
    </svg>
  );
}
