export default function cardSelected() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M18.3337 8.33329H1.66699M9.16699 11.6666H5.00033M1.66699 6.83329L1.66699 13.1666C1.66699 14.1 1.66699 14.5668 1.84865 14.9233C2.00844 15.2369 2.2634 15.4918 2.57701 15.6516C2.93353 15.8333 3.40024 15.8333 4.33366 15.8333L15.667 15.8333C16.6004 15.8333 17.0671 15.8333 17.4236 15.6516C17.7372 15.4918 17.9922 15.2369 18.152 14.9233C18.3337 14.5668 18.3337 14.1 18.3337 13.1666V6.83329C18.3337 5.89987 18.3337 5.43316 18.152 5.07664C17.9922 4.76304 17.7372 4.50807 17.4236 4.34828C17.0671 4.16663 16.6004 4.16663 15.667 4.16663L4.33366 4.16663C3.40024 4.16663 2.93353 4.16663 2.57701 4.34828C2.2634 4.50807 2.00844 4.76304 1.84865 5.07664C1.66699 5.43316 1.66699 5.89987 1.66699 6.83329Z"
        stroke="#3F3FF9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
