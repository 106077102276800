import { BaseLayoutType } from "../BaseLayout/types";
import styles from "./index.module.scss";

type BackgroundProps = {
  layout?: BaseLayoutType;
};

export default function Background({ layout = "default" }: BackgroundProps) {
  return (
    <div className={`Background ${styles.container}`}>
      {layout === "unregistered" && <div className={`${styles.effect}`}></div>}
    </div>
  );
}
