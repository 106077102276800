export default function icoDepositWallet() {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="37.3333" height="37.3333" rx="10.8333" fill="#F4F4FF" />
      <path
        d="M22.0003 15.3333V12.4172C22.0003 11.724 22.0003 11.3775 21.8543 11.1645C21.7268 10.9784 21.5292 10.852 21.3068 10.8142C21.0522 10.7709 20.7376 10.9161 20.1082 11.2065L12.7162 14.6183C12.1549 14.8773 11.8743 15.0068 11.6687 15.2077C11.487 15.3853 11.3483 15.6021 11.2632 15.8415C11.167 16.1123 11.167 16.4213 11.167 17.0395V21.1666M22.417 20.7499H22.4253M11.167 17.9999L11.167 23.4999C11.167 24.4334 11.167 24.9001 11.3486 25.2566C11.5084 25.5702 11.7634 25.8252 12.077 25.9849C12.4335 26.1666 12.9002 26.1666 13.8337 26.1666H23.5003C24.4337 26.1666 24.9005 26.1666 25.257 25.9849C25.5706 25.8252 25.8255 25.5702 25.9853 25.2566C26.167 24.9001 26.167 24.4334 26.167 23.4999V17.9999C26.167 17.0665 26.167 16.5998 25.9853 16.2433C25.8255 15.9297 25.5706 15.6747 25.257 15.5149C24.9005 15.3333 24.4337 15.3333 23.5003 15.3333L13.8337 15.3333C12.9002 15.3333 12.4335 15.3333 12.077 15.5149C11.7634 15.6747 11.5084 15.9297 11.3486 16.2433C11.167 16.5998 11.167 17.0665 11.167 17.9999ZM22.8337 20.7499C22.8337 20.9801 22.6471 21.1666 22.417 21.1666C22.1869 21.1666 22.0003 20.9801 22.0003 20.7499C22.0003 20.5198 22.1869 20.3333 22.417 20.3333C22.6471 20.3333 22.8337 20.5198 22.8337 20.7499Z"
        stroke="#413FCF"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
