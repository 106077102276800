/* eslint-disable no-unused-vars */
import * as S from "./styles";
import MenuOption from "./MenuOption";
import Icon, { AvailableIconsType } from "@/widgets/Icons";
import { Skeleton } from "@/components/Skeleton";
import { useSidebarController } from "./controller";
import { ISidebarProps } from "./types";

const Sidebar = ({
  collapsed,
  setCollapsed,
  isVisible = true,
}: ISidebarProps) => {
  const { handleMenuOptionClick, isLoadingCustomer, items } =
    useSidebarController();

  if (!isVisible) {
    return <></>;
  }

  return (
    <S.MainContainer $collapsed={collapsed}>
      <S.IconArrow $collapsed={collapsed} onClick={setCollapsed}>
        <Icon icon={collapsed ? "collapseRight" : "collapseLeft"} />
      </S.IconArrow>
      <S.MainList>
        {isLoadingCustomer && (
          <S.SkeletonContainer>
            {Array.from({ length: 6 }).map((_, index) => (
              <Skeleton key={index} height="34px" width="198px" />
            ))}
          </S.SkeletonContainer>
        )}
        {!isLoadingCustomer &&
          items?.map((option, index) => {
            return (
              <MenuOption
                key={index}
                id={option?.id}
                iconName={(option?.icon as AvailableIconsType) || "wallet"}
                itemName={option?.text || ""}
                type={option.active ? "selected" : "default"}
                onClick={() => handleMenuOptionClick(option.link || "")}
                collapsed={collapsed}
              />
            );
          })}
      </S.MainList>
    </S.MainContainer>
  );
};

export default Sidebar;
