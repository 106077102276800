export default function transactionNarrowDown() {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1" y="0.5" width="15" height="15" rx="7.5" fill="#E5FCF0" />
      <rect x="1" y="0.5" width="15" height="15" rx="7.5" stroke="white" />
      <path
        d="M8.5 5.33331V10.6666M8.5 10.6666L10.5 8.66665M8.5 10.6666L6.5 8.66665"
        stroke="#0BB193"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
