import scssVariables from "@/styles/variables.module.scss";

export default function arrowLeft() {
  return (
    <svg viewBox="0 0 9 14" fill="none">
      <path
        d="M7.3449 0.000594684L8.47731 1.13301L2.70541 6.90492L8.479 12.6791L7.34659 13.8115L0.438855 6.90379L7.3449 0.000594684Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
