export default function transactionNarrowUp() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#E5FCF0" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="white" />
      <path
        d="M8 10.6667V5.33333M8 5.33333L6 7.33333M8 5.33333L10 7.33333"
        stroke="#0BB193"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
