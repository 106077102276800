import { useEffect, useState } from "react";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { useLocale } from "@/hooks";
import scssVariables from "@/styles/variables.module.scss";

export default function ZendeskSupport() {
  const { locale } = useLocale();
  const [isLoaded, setIsLoaded] = useState(false);

  const key: string = process.env.NEXT_PUBLIC_ZENDESK_KEY as string;

  const setting = {
    webWidget: {
      zIndex: 999,
      contactOptions: {
        enabled: true,
      },
      color: {
        theme: scssVariables.primary,
      },
      offset: {
        horizontal: "-7px",
        vertical: "-7px",
      },
      chat: {
        profileCard: {
          avatar: true,
          rating: false,
          title: false,
        },
      },
    },
  };

  function handleLoaded() {
    ZendeskAPI("webWidget", "close");
    ZendeskAPI("webWidget", "setLocale", locale);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    if (isLoaded) {
      handleLoaded();
    }
  }, [isLoaded, locale]);

  return (
    <div>
      {isLoaded && (
        <Zendesk defer zendeskKey={key} {...setting} onLoaded={handleLoaded} />
      )}
    </div>
  );
}
