import scssVariables from "@/styles/variables.module.scss";

export default function error() {
  return (
    <svg viewBox="0 0 36 36" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1064 35.9994C22.7411 35.9552 27.3291 34.0369 30.6287 30.7796C34.5476 26.91 36.5654 21.1859 35.8617 15.721C34.6551 6.35509 26.9495 0 17.9907 0C11.1849 0 4.56934 4.25396 1.66752 10.4225C-0.553959 15.1448 -0.557718 20.8464 1.66752 25.5777C4.5483 31.7028 11.0556 35.9342 17.8749 35.9994C17.9523 36.0002 18.029 36.0002 18.1064 35.9994ZM17.8944 32.9999C10.9722 32.9332 4.51371 27.5844 3.22894 20.7273C2.32381 15.8926 4.03333 10.6615 7.59296 7.22806C11.7502 3.21913 18.2831 1.87558 23.7146 4.14831C28.5756 6.18199 32.2126 10.9028 32.8832 16.1039C33.4576 20.5617 31.8563 25.2338 28.7101 28.4529C25.9496 31.2756 22.0795 32.9609 18.0869 32.9999C18.023 32.9999 17.9583 32.9999 17.8944 32.9999ZM8.58604 25.2892L15.9038 17.9952L8.57853 10.6937L10.6865 8.59335L18.0117 15.8948L25.588 8.34307L27.699 10.4464L20.1227 17.999L27.7141 25.5657L25.6068 27.6669L18.0147 20.0994L10.697 27.3934L8.58604 25.2892Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
