import scssVariables from "@/styles/variables.module.scss";

export default function calendar() {
  return (
    <svg viewBox="0 0 12 12" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7827 4.8H1.19808V2.98257C1.19808 2.66097 1.45867 2.4 1.77975 2.4H2.39617V3.6H3.59425V2.4H8.38658V3.6H9.58467V2.4H10.1837C10.5144 2.4 10.7827 2.6688 10.7827 3V4.8ZM10.7827 10.2C10.7827 10.53 10.5132 10.8 10.1837 10.8H1.79712C1.46645 10.8 1.19808 10.5312 1.19808 10.2V6H10.7827V10.2ZM0.0383386 10.6566C0.0383386 11.319 0.61282 12 1.27416 12H10.8588C11.5208 12 12 11.3874 12 10.6566C12 10.4232 11.9808 3.216 11.9808 2.8248C11.9808 1.5756 11.8125 1.2 9.58467 1.2V0H8.38658V1.2H3.59425V0H2.39617V1.2H1.19808C0.539137 1.2 0 1.74 0 2.4L0.0383386 10.6566Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
