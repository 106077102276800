/* eslint-disable no-unused-vars */
import { IApiError, ITransaction, IWithdrawal } from "@/models";
import { api, throwApiError } from "./axios";
import { sleep } from "@/utils";

const transaction___mock: ITransaction = {
  amount: 3.00003,
  formattedAmount: "BTC 3.00003000",
  expiresAt: "2023-09-11T18:57:47.8158604+00:00",
  transactionHash: null,
  transactionURL: null,
  fee: {
    id: "a1c05bda-5b54-4037-bd86-b1764b551fee",
    amount: 0.00003,
    formattedAmount: "BTC 0.00003000",
    currency: {
      id: "BTC",
      title: "Bitcoin",
      symbol: "BTC",
      logoUrl:
        "https://sttransferoonedev.blob.core.windows.net/svgs-currency/btc.svg",
      blurHash: "U1M44f02~pJ9D$IVRjj[5QtRIpWAogj]",
      colorHex: "#F7931A",
      operationDecimalDigits: 8,
      decimalDigits: 8,
      type: "Crypto",
    },
    expiresAt: "2023-09-11T19:02:47.7930025+00:00",
  },
  account: {
    blockchain: "Bitcoin",
    address: "1MWVooDGRH1ezaGeS95R3UNQNeToVN2hAb",
    memo: null,
    label: null,
    message: null,
    transactionHash: null,
    transactionURL: null,
    type: "Crypto",
    currency: {
      id: "BTC",
      title: "Bitcoin",
      symbol: "BTC",
      logoUrl:
        "https://sttransferoonedev.blob.core.windows.net/svgs-currency/btc.svg",
      blurHash: "U1M44f02~pJ9D$IVRjj[5QtRIpWAogj]",
      colorHex: "#F7931A",
      operationDecimalDigits: 8,
      decimalDigits: 8,
      type: "Crypto",
    },
  },
  id: "91490",
  status: "Draft",
  createdAt: "2023-09-11T18:52:47.8158603+00:00",
  updatedAt: "2023-09-11T18:52:47.8158603+00:00",
  type: "Withdrawal",
  location: null,
};

const error___mock = {
  type: "https://www.rfc-editor.org/rfc/rfc7231#section-6.5.1",
  title: "One or more validation errors occurred.",
  status: 400,
  instance: "/api/withdrawals/complete/91490",
  traceId: "0HMTIUBSVKLAG:00000001",
  errors: [
    {
      name: "insufficient balance",
      reason: "Insufficient balance",
      code: "Insufficient balance",
    },
  ],
} as IApiError;

type GetWithdrawalFeeParamType = {
  currencyId: string;
  blockchainId: string;
  address: string;
};

type CreateWithdrawalCryptoParamType = {
  amount: number;
  blockchainId: string;
  currencyId: string;
  address: string;
  memo?: string;
  label?: string;
  message?: string;
  feeId?: string;
};

type CompleteWithdrawalParamType = {
  transactionId: string;
  transactionalPassword: string;
};

const WithdrawalsApi = {
  getWithdrawalFee: async (data: GetWithdrawalFeeParamType) => {
    try {
      const response = await api.request({
        url: "/withdrawals/fee",
        method: "POST",
        data,
      });
      return response.data as IWithdrawal;
    } catch (error: any) {
      throwApiError(error, "getWithdrawalFee");
    }
  },
  createWithdrawalCrypto: async (data: CreateWithdrawalCryptoParamType) => {
    try {
      const response = await api.request({
        url: "/withdrawals/crypto",
        method: "POST",
        data,
      });
      return response.data as ITransaction;
    } catch (error: any) {
      throwApiError(error, "createWithdrawalCrypto");
    }
  },
  completeWithdrawal: async ({
    transactionId,
    transactionalPassword,
  }: CompleteWithdrawalParamType) => {
    try {
      const response = await api.request({
        url: `/withdrawals/complete/${transactionId}`,
        method: "POST",
        data: {
          transactionalPassword,
        },
      });
      return response.data as ITransaction;
    } catch (error: any) {
      throwApiError(error, "completeWithdrawal");
    }
  },
  completeWithdrawal___mock: async ({
    transactionId,
    transactionalPassword,
  }: CompleteWithdrawalParamType): Promise<ITransaction> => {
    const type = "success";
    await sleep(1000);
    if (type !== "success") {
      throw error___mock;
    }
    return transaction___mock;
  },
};

export default WithdrawalsApi;
