export default function contractServices() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#F2F4F7" />
      <path
        d="M28.8047 19.5156L26.875 20.4765L24.375 15.6952L26.3281 14.7187C26.4733 14.6447 26.6418 14.6311 26.7969 14.6809C26.9521 14.7306 27.0812 14.8397 27.1563 14.9843L29.0781 18.664C29.1171 18.7377 29.1409 18.8184 29.1483 18.9014C29.1556 18.9844 29.1462 19.068 29.1208 19.1474C29.0953 19.2267 29.0542 19.3002 28.9999 19.3634C28.9456 19.4266 28.8793 19.4784 28.8047 19.5156V19.5156Z"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1268 20.3981L11.1971 19.4293C11.1228 19.3929 11.0566 19.3419 11.0024 19.2794C10.9482 19.2169 10.9071 19.1441 10.8816 19.0654C10.8561 18.9867 10.8467 18.9036 10.8539 18.8212C10.8611 18.7388 10.8849 18.6586 10.9237 18.5856L12.8456 14.9059C12.9208 14.7614 13.0494 14.6519 13.2041 14.6009C13.3587 14.5498 13.5272 14.5611 13.6737 14.6324L15.6268 15.609L13.1268 20.3981Z"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.875 20.4766L25.625 21.9453L22.75 24.8203C22.6716 24.8936 22.5767 24.9468 22.4733 24.9754C22.3699 25.0039 22.2611 25.007 22.1563 24.9844L17.625 23.8516C17.5422 23.8285 17.4651 23.7886 17.3984 23.7344L13.125 20.3984"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.6248 21.9453L22.1873 19.4453L21.1873 20.1953C20.7541 20.5187 20.2279 20.6934 19.6873 20.6934C19.1467 20.6934 18.6206 20.5187 18.1873 20.1953L17.7655 19.875C17.6941 19.8208 17.6351 19.752 17.5923 19.6732C17.5496 19.5945 17.524 19.5076 17.5174 19.4182C17.5107 19.3288 17.5231 19.2391 17.5538 19.1548C17.5844 19.0706 17.6326 18.9939 17.6951 18.9297L20.7576 15.875C20.8152 15.8178 20.8834 15.7724 20.9585 15.7416C21.0336 15.7108 21.114 15.695 21.1951 15.6953H24.3748"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6719 15.6092L19.6797 14.4373C19.8221 14.3965 19.9744 14.4075 20.1094 14.4686L22.8125 15.6951"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.75 26.6328L16.3984 26.0391C16.3026 26.0174 16.2139 25.9717 16.1406 25.9063L14.375 24.375"
        stroke="#5D6B98"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
