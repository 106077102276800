import { Provider } from "react-redux";
import { store } from "@/store";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export default function Providers({ children }: any) {
  return (
    <>
      <Provider store={store}>
        <>{children}</>
      </Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}
