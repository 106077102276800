export default function transactionX() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#FEF3F2" />
      <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" stroke="white" />
      <path
        d="M10 6L6 10M6 6L10 10"
        stroke="#D92D20"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
