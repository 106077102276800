import CopyToClipboard from "../CopyToClipboard";

import styles from "./index.module.scss";

type CopyableValueProps = {
  value: string;
  label?: string;
  copiedLabel?: string;
  isRight?: boolean;
  id?: string;
};

export default function CopyableValue({
  value,
  label,
  copiedLabel = "",
  isRight = false,
  id,
}: CopyableValueProps) {
  return (
    <div className={`CopyableValue ${styles.container}`} id={id}>
      <div className={styles["info-display"]}>
        {label && <label>{label}</label>}
        <div className={styles["copy-container"]}>
          <span className={styles["qr-code-hash"]}>{value}</span>
          {isRight && <CopyToClipboard value={value} label={copiedLabel} />}
        </div>
      </div>
      {!isRight && (
        <span className={styles["icon-clipboard"]}>
          <CopyToClipboard value={value} label={copiedLabel} />
        </span>
      )}
    </div>
  );
}
