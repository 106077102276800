import styled from "styled-components";

export const MobileFooter = styled.div`
  background-color: #3f3fca;
  height: 100%;
`;

export const ItemsContainer = styled.ul`
  padding: 48px;
`;

export const ListItem = styled.li`
  font-family: Karla;
  font-size: 20px;
  font-weight: 400;
  line-height: 43px;
  letter-spacing: 0.02em;
  text-align: left;
  cursor: pointer;
  a {
    text-decoration: none;
    color: #f7f8fb;
  }
`;

export const PoweredByContainer = styled.div`
  display: flex;
  justify-content: center;
  p {
    font-family: Karla;
    font-size: 20px;
    font-weight: 400;
    line-height: 43px;
    text-align: left;
    color: #f7f8fb;
  }
`;

export const Logo = styled.div`
  margin: 9px 0px 30px 9px;
`;
