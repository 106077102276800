import scssVariables from "@/styles/variables.module.scss";

export default function first() {
  return (
    <svg viewBox="0 0 13 14" fill="none">
      <path
        d="M11.1296 0.189071L12.262 1.32149L6.49008 7.0934L12.2637 12.8676L11.1313 14L4.22352 7.09227L11.1296 0.189071Z"
        fill={scssVariables.primary}
      />
      <rect
        x="1.89551"
        y="14"
        width="1.55555"
        height="13.9999"
        transform="rotate(-180 1.89551 14)"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
