import styled from "styled-components";
import { SizePercentageProps } from "./interfaces";

export const PercentageInfoContainer = styled.div<SizePercentageProps>`
  width: 5.3125rem;
  width: ${(props) => (props.$cardSize ? "3.4375rem" : "5.3125rem")};
  height: 5.3125rem;
  font-family: Karla;
  font-size: 1.3375rem;
  font-weight: 500;
  line-height: 1.5637rem;
`;
