import scssVariables from "@/styles/variables.module.scss";

export default function noConnection() {
  return (
    <svg viewBox="0 0 40 32" fill="none">
      <path
        d="M0 19C0 22.2 1.8 25.2 4.4 26.8L2.6 28.6C1.8 29.4 1.8 30.6 2.6 31.4C3 31.8 3.4 32 4 32C4.6 32 5 31.8 5.4 31.4L33.4 3.4C34.2 2.6 34.2 1.4 33.4 0.6C32.6 -0.2 31.4 -0.2 30.6 0.6L27 4.2C25.8 4 24.6 4 23.6 4C23.4 3.6 23 3.4 22.8 3.2C20.8 1.2 18 0 15 0C12 0 9.2 1.2 7.2 3.2C5.2 5.2 4 8 4 11C4 11.2 4 11.4 4 11.6C3.6 12 3 12.2 2.6 12.8C1 14.4 0 16.6 0 19ZM5.4 15.4C5.8 15 6.4 14.6 6.8 14.4C7.6 14 8.2 13.2 8 12.2C8 11.8 8 11.4 8 11C8 9.2 8.8 7.4 10 6C12.6 3.4 17.2 3.4 19.8 6C20.2 6.4 20.6 6.8 20.8 7.4C21.2 8 22 8.4 22.8 8.2L7.4 23.6C5.4 23 4 21 4 18.8C4 17.6 4.6 16.4 5.4 15.4Z"
        fill={scssVariables.primary}
      />
      <path
        d="M35.2 11.1998C34.8 10.3998 34.4 9.5998 34 8.7998C33.4 7.7998 32.2 7.59981 31.2 8.19981C30.2 8.79981 30 9.9998 30.6 10.9998C31 11.5998 31.4 12.3998 31.6 12.9998C31.8 13.5998 32.2 13.9998 32.8 14.3998C34.6 15.1998 35.8 16.9998 35.8 18.9998C35.8 20.3998 35.2 21.5998 34.4 22.5998C33.4 23.5998 32.2 23.9998 30.8 23.9998H16C14.8 23.9998 14 24.7998 14 25.9998C14 27.1998 14.8 27.9998 16 27.9998H31C33.4 27.9998 35.6 26.9998 37.4 25.3998C39.2 23.7998 40 21.3998 40 18.9998C40 15.5998 38.2 12.5998 35.2 11.1998Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
