import { Ellipsis, IconButton, Tooltip } from "@/widgets";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

type BalanceProps = {
  title: string;
  info: string;
  currency: string;
  currencySymbol?: string;
  amount: string;
  isVisible?: boolean;
  onChangeCurrency(): void;
  onToggleVisibility(): void;
};

export default function Balance({
  title,
  info,
  currency,
  currencySymbol = "",
  amount,
  isVisible = true,
  onChangeCurrency,
  onToggleVisibility,
}: BalanceProps) {
  const { t } = useTranslation()
  return (
    <div className={`Balance ${styles.container}`}>
      <div className={styles.head}>
        <span>{title}</span>
        <Tooltip content={<p>{info}</p>}>
          <sup></sup>
        </Tooltip>
      </div>
      <div className={styles.body}>
        <button
          type="button"
          className={styles.currencyBtn}
          onClick={onChangeCurrency}
        >
          {currency.toUpperCase()}
        </button>
        <div className={isVisible ? "" : styles.hasMask}>
          {isVisible && <Ellipsis content={amount} prefix={currencySymbol} />}
          {!isVisible && <div className={styles.mask}></div>}
          <IconButton
            icon={isVisible ? "eye" : "eyeHide"}
            onClick={onToggleVisibility}
            title={isVisible ? t("hide") : t("view")}
          />
        </div>
      </div>
    </div>
  );
}
