export default function En_i18n() {
  return (
    <div style={{ textAlign: 'justify'}}>
      These terms and conditions (“Terms of Use” or “Terms”) represent the terms
      and conditions that regulate the main obligations and govern the services
      rendered by companies of Transfero group (“Transfero”), for our users
      (“User” or “Users”) when using the services described here, through our
      website, available at the domain “app.transfero.com” and application
      available on the App Store and Google Play. Before using the Platforms,
      developed by Transfero, it is mandatory to read and fully understand these
      Terms of Use, which will remain available on the Platforms. User access is
      only permitted after unconditional and irrevocable acceptance of the Terms
      of Use, which together with the rest of the documents mentioned therein,
      constitute a binding and enforceable agreement between the User and
      Transfero. Furthermore, this Term, as well as the information provided on
      the website or in the application, should not be considered as investment
      advice or recommendations, and by accepting it, the User declares to have
      read it in full and to be in agreement with the guidelines set out
      therein. When using the services offered, the User agrees with all the
      rules of these Terms of Use, our privacy policy and other conditions
      mentioned here, being fully responsible for any and all acts carried out
      when using our services. If the User does not agree with any of the terms
      and conditions set out below, the User must not use the services.
      Therefore, it is important to carefully read these documents and any
      updates before making the decision to use or continue using our Platforms.
      If you have any questions about these documents, before accessing or using
      the Platforms, please contact us through our Communication Channels.
      Refusal to comply with these Terms of Use will prevent the User from using
      the Platforms and, consequently, from enjoying the services offered. The
      services are offered by Transfero, which, together with its credit card
      operating partner (“Partner”), provides a digital payment account with the
      aim of facilitating your purchases in Retailers and which also allows
      transfers between accounts, as well as such as transactions via Pix, among
      other features (“Services”). The Services also include those provided by
      business partners, such as the foreign exchange broker to be defined by
      Transfero to provide fiat currency conversion services and international
      transfers, in addition to an integrated crypto-asset broker to carry out
      the conversion and custody of cryptoassets.
      <p></p>
      <h1>1. DEFINITIONS </h1>
      <p>
        1.1. Without prejudice to other definitions given in these Terms of Use,
        the words below, when used in the singular or plural, will have the
        following meanings:
      </p>
      <p>
        <strong>Applications or Platforms:</strong> are internet applications
        such as, for example, a website or mobile application (that is, for use
        on “smartphones” or mobile devices in general), made available so that
        the User can manage their Account, in the manner provided for in these
        Terms of Use. For example, <a>app.transfero.com</a>, website or
        Transfero app.
      </p>
      <p>
        <strong>Card Network:</strong> is the payment arrangement established by
        Mastercard, the company responsible for the brand and the physical and
        digital systems that allow the Card to be issued and used in Retailers,
        in accordance with Law No. 12,865/2013 and the Regulation on Payment
        Arrangements of the Mastercard of Brazil, available on the Mastercard
        website.
      </p>
      <p>
        <strong>BRZ:</strong> is the abbreviation for the Brazilian Digital
        Token, a Stablecoin paired in a 1:1 ratio to the Brazilian Real (“BRL”),
        which means that the BRZ exchange rate will always be the same as the
        BRL.{" "}
      </p>
      <p>
        <strong>Card:</strong> is a physical or virtual card that, at
        Transfero's discretion, may be offered to Users to enjoy the Services.{" "}
      </p>
      <p>
        <strong>Communication Channels:</strong> these are the official channels
        for communication between the User and Transfero, for matters related to
        the use of the Services described here, and any other communication
        channel that is not described here, or in the Applications, is not
        considered an official channel, and the User must not use it. The
        channels are as follows:{" "}
      </p>
      <p>
        <strong>1. URL:</strong> <a>app.transfero.com</a>;{" "}
      </p>
      <p>
        <strong>2. Online Chat:</strong> through the application;{" "}
      </p>
      <p>
        <strong>3. Email:</strong> <a>contato@transfero.com.br</a> and{" "}
        <a>help@transfero.com</a>.{" "}
      </p>
      <p>
        <strong>Chargeback:</strong> is the debit dispute procedure through
        which the User declares that they do not recognize an expense made with
        their Card. Once the Chargeback is accepted, a deposit will be made to
        the User's Account, within the deadline informed by the Communication
        Channels.{" "}
      </p>
      <p>
        <strong>Customer:</strong> Refers to the Retailer's customers who use
        Transfero Checkout to make payments with Cryptoassets to the Retailer.{" "}
      </p>
      <p>
        <strong>Account:</strong> is Transfero's prepaid payment account,
        intended for executing payment transactions in Cryptoassets, carried out
        based on funds denominated in reais previously contributed.{" "}
      </p>
      <p>
        <strong>Foreign Exchange Broker:</strong> this is the financial
        institution to be chosen by Transfero to provide exchange services, such
        as conversion of fiat currencies and international payments with
        exchange contracts duly registered with the Central Bank of Brazil.{" "}
      </p>
      <p>
        <strong>Cryptoasset Exchange:</strong> is the Transfero group company
        responsible for carrying out the conversions and custody of
        Cryptoassets, providing the necessary information to the Brazilian
        Federal Revenue Service, in accordance with current regulatory
        instructions.{" "}
      </p>
      <p>
        <strong>Cryptoassets or Digital Assets:</strong> Means any virtual asset
        issued with or without linkage to any central bank of a given country,
        which uses encryption techniques to validate operations, generate new
        units and/or transfers between users, with a distributed record of
        transactions publicly or privately.{" "}
      </p>
      <p>
        <strong>Retailer:</strong> any supplier of products and/or services that
        is authorized to accept payments with the Card, in Brazil and/or abroad,
        in physical stores or via the internet.{" "}
      </p>
      <p>
        <strong>Fiat currency:</strong> Represents any of the official means of
        payment in a country. In the case of Brazil, it is the Real (abbreviated
        as R$ or BRL), issued by the Central Bank of Brazil.{" "}
      </p>
      <p>
        <strong>Pix:</strong> is the Brazilian instant payment.{" "}
      </p>
      <p>
        <strong>Privacy Policy:</strong> is the contractual instrument that
        regulates the processing and security of the personal data of Users
        (“Holders”), including, but not limited to, the collection, use and
        storage of Holders’ personal information, and which can be accessed
        here: <a>www.transfero.com</a>. The Privacy Policy is an integral and
        inseparable part of these Terms of Use. Acceptance of these Terms of Use
        implies acceptance of the Privacy Policy, so we ask that you read it
        carefully.
      </p>
      <p>
        <strong>User:</strong> Individual or Legal Entity, Account holder.{" "}
      </p>
      <h1>2. SERVICES </h1>
      <p>
        2.1. Transfero, the Partner and the Foreign Exchange Broker, when
        applicable, offer the User services that enable transfers of financial
        resources and carrying out payment transactions.{" "}
      </p>
      <p>
        2.2. The User, after carrying out the registration procedures described
        in item 3, will be able to use the Services through their Account,
        always subject to balance availability, as follows:{" "}
      </p>
      <p>
        (i) Purchases at Retailers: the User may make purchases at any supplier
        of products and/or services that are authorized to accept payments with
        their Card or in Cryptoassets, in Brazil and/or abroad, in physical
        stores or through Internet;{" "}
      </p>
      <p>
        (ii) Transfers between Accounts: the User may transfer financial
        resources from their Account to third parties, through the Platforms,
        and specific fees and taxes may be charged for these transfers, which
        will be deducted from the User's balance. Transfers between Accounts
        must also comply with the deadlines and procedures provided for by the
        Brazilian financial system;{" "}
      </p>
      <p>
        (iii) Custody, Transfer and Conversion of Cryptoassets: the User may
        acquire Cryptoassets, keep them in the custody of the Cryptoasset
        Exchange and transfer these assets to third parties;{" "}
      </p>
      <p>
        (iv) International Payments: the User may send financial resources from
        their Account abroad. All services relating to foreign exchange and
        international remittances will be carried out through the Foreign
        Exchange Broker;{" "}
      </p>
      <p>
        (v) Pix: form of financial transaction through which resources are
        transferred between the User's bank accounts and the Transfero´s payment
        account.{" "}
      </p>
      <p>
        2.3. The User, when contracting the service indicated in items (iv),
        authorizes Transfero to hire a Foreign Exchange Broker of Transfero's
        free choice, on his behalf, respecting the Terms of Use set out herein.{" "}
      </p>
      <p>
        2.4. To activate the use of your Account, the User must make a deposit,
        via Bank Transfer or Pix, with the balance immediately converted and
        made available in the Account in BRZ.{" "}
      </p>
      <p>
        2.5. Each operation described here may have technical limitations and
        specifications defined by Transfero, as well as a specific remuneration,
        according to the service performed, which will be charged by debiting
        the Account. The value of each fee is available to Users on the
        Platforms. Transfero reserves the right to change rates at any time,
        always informing Users in advance, in accordance with applicable
        regulations, with the User being entitled to agree to such change or
        cancel their registration.{" "}
      </p>
      <h1>3. REGISTRATION AND ACCESS </h1>
      <p>
        3.1. To use the Services, the User must register through the
        Applications. The User must complete the registration, which contains
        access levels, depending on the financial volume to be transacted, which
        is available on the page{" "}
        <a>(https://app.transfero.com/help-center/limits-info)</a>, also must
        provide copies of the required documents and other registration
        information, relevant to the individual or legal entity, and submit to
        Transfero.{" "}
      </p>
      <p>
        3.2. To register on the Transfero Platform, as an individual, the User
        must be at least 18 (eighteen) years old.{" "}
      </p>
      <p>
        3.2.1. In the case of emancipated Users, the User must provide a copy of
        the relevant documents that prove that the emancipation was irrevocable
        and irreversible, with their registration on the Platform subject to the
        analysis and validity of such documents and information.{" "}
      </p>
      <p>
        3.3. In the case of legal entities, the registration must be made and
        submitted by the company's legal representative(s), duly constituted in
        accordance with its contract or bylaws. In addition to the relevant
        corporate documents, the personal documents of the legal representatives
        must be presented.{" "}
      </p>
      <p>
        3.4. The following documents of the legal entity must be made available:
        (i) copy of the last contractual amendment or bylaws in force; (ii)
        document held by the company's legal representative(s); (iii) personal
        documents (RG and CPF) of the legal representative(s) responsible for
        filling out the Platform´s form; and (iv) copy of the company's updated
        Tax ID. The list of documents above may be changed unilaterally by
        Transfero on a purely voluntary basis.{" "}
      </p>
      <p>
        3.5. Once the form has been completed, the User must present their
        acceptance of these Terms of Use by clicking on the “I Accept” field or
        similar.{" "}
      </p>
      <p>
        3.6. The User (and/or his legal representative) will be responsible for
        the veracity and updating of the data provided when registering on the
        Platform. The maintenance of the User's registration with the Platform
        will be subject to analysis by Transfero's compliance team. Registration
        may be refused, interrupted or canceled, as applicable, in the event of
        an error, incongruous information or out-of-date information.{" "}
      </p>
      <p>
        3.7. Transfero reserves the right to deny or cancel the registration of
        interested parties who do not provide requested information and/or
        provide incomplete, unsatisfactory, outdated and/or incompatible
        information with these Terms of Use.{" "}
      </p>
      <p>
        3.8. By expressing acceptance, the User declares to have read,
        interpreted, understood and approved the content of these Terms of Use,
        being subject to its provisions and obligations.{" "}
      </p>
      <p>
        3.9. Transfero may, at any time, re-evaluate, verify or re-analyze the
        User's registration with the Platform. During such activities, Transfero
        may request additional information, documents, proof or any other
        information necessary to verify the User's registration, including with
        regard to compliance with applicable legislation.{" "}
      </p>
      <p>
        3.10. Transfero reserves the right to refuse any registration request
        and to suspend and/or revoke any access in cases (i) of violation of any
        of the provisions of these Terms of Use, (ii) impossibility of verifying
        the User's identity or discovery of false information and/or statements
        provided by the User, (iii) practice by the User of fraudulent or
        willful acts or the adoption of any behavior that, at Transfero's
        discretion, is incompatible with the objectives of the Platform or that
        may, in any way, cause damage to third parties, Transfero and/or the
        Platform. If any User's registration is suspended or canceled for any of
        the reasons set out in these Terms of Use, all orders to buy or sell
        cryptoassets that have not yet been executed will be automatically
        cancelled.{" "}
      </p>
      <p>
        3.11. At any time, and at Transfero's sole discretion, interested
        parties and/or any User may be asked to send supporting documents to
        validate the information provided to the Platform, including copies of
        personal documents, proof of residence and/or income.{" "}
      </p>
      <p>
        3.12. The User authorizes Transfero to consult information and check
        data about him with credit analysis entities and other databases,
        including the Central Bank Credit Information System – SCR, SERASA, SPS,
        as well as any other that exist, public or private.
      </p>
      <p>
        3.13. Once the registration has been carried out and approved, the User
        will have access to the Account, through the Applications only, using
        the login and password created at the time of registration. The User is
        solely responsible for their Account and any activity associated with
        this Account. Therefore, the User is prohibited from sharing their login
        and password with third parties, and they must also immediately notify
        Transfero in the event of loss, misplacement or theft of their Card,
        login and/or password.{" "}
      </p>
      <p>
        3.14. Transfero and the Partner will not be responsible for access or
        transactions in your account or for the misuse of your Card by third
        parties, as well as for any direct or indirect damage resulting from
        misuse or inability to use the Applications, the Account or the Card by
        the User or any third party, given that, if there is evidence or even
        mere indications of irregular, inappropriate or suspicious use of the
        Card and/or Account, the User may have their registration immediately
        suspended or even canceled, without prejudice to other legal and
        contractual sanctions.{" "}
      </p>
      <p>
        3.15. Account blocking possibilities: Transfero may block your Account,
        the resources held there or your Card in the event of suspicion of: (i)
        fraud; (ii) financial crimes; (iii) registration inconsistencies; (iv)
        operations outside your typical usage; (v) disrespect for any condition
        contained in the contract, in other contracts carried out with Transfero
        or current legislation; (vi) due to suspected fraud in transactions
        received through Pix or an operational failure in the systems of the
        institutions involved in the transaction; (vii) the User fails to
        respond to Tranfero's request to send new documents or information to
        prove their identity or update their registration data; or (viii) in
        cases of delay in payment of amounts owed by the User to Transfero.{" "}
      </p>
      <p>
        3.16. The User acknowledges that the information voluntarily provided
        may be shared with the Partner and the Foreign Exchange Broker, to the
        extent necessary to provide the Services offered, as well as for
        registration purposes or any other purpose, including, but not limited
        to, the marketing of Transfero products.{" "}
      </p>
      <h1>4. TRANSFER CARD </h1>
      <p>
        4.1. Using the Transfero Card, the User will be able to make payments,
        in Brazil and/or abroad, at accredited Retailers. The Card will be
        delivered blocked, as a security measure and unblocking must be carried
        out through the Applications, in addition the User will be responsible
        for checking their data.{" "}
      </p>
      <p>
        4.2. The User must pay attention to the Mastercard payment arrangement
        regulations (“Card Network”), applicable to the Card (“Regulation”), as
        each Card Network is governed by specific conditions and terms.{" "}
      </p>
      <p>
        4.3. When using the Card, the User will automatically be subject to the
        specific regulations of the Card Network, in accordance with Law No.
        12,865/2013.{" "}
      </p>
      <p>
        4.4. The User will be able to count on the benefit of integrating
        Mastercard's promotional platform, accessing discounts, offers and
        promotions made available by Mastercard and its partners. To this end,
        as set out in the Privacy Policy, the User's personal data and User Card
        number will be transmitted to Mastercard.{" "}
      </p>
      <p>
        4.5. The User is solely responsible for the use and storage of their
        physical Card.{" "}
      </p>
      <p>
        4.6. In case of loss, misplacement, theft or robbery of the Card, the
        User must immediately contact any of the Communication Channels. After
        contact, access to and use of the Account may be temporarily blocked
        until the User receives a new login and password. A new Card will be
        delivered to the User within the deadlines informed by the Communication
        Channels. Additional fees may be charged to generate a new Card, and, in
        this case, such fees will be informed to the User in advance.{" "}
      </p>
      <p>
        4.7. If the User does not recognize an operation made with their Card,
        they must contact any of the Communication Channels within a maximum
        period of 30 (thirty) days and follow the instructions for carrying out
        the Chargeback procedure. The procedure and documentation required for
        the Chargeback will follow the rules established by the Card Network and
        the Partner, in accordance with applicable legislation, so that the
        approval of the Chargeback will only be carried out when the error or
        commercial disagreement is proven and there is no exclusive fault of the
        User, in accordance with the aforementioned rules. Once the Chargeback
        is approved, the amount will be credited to the User's Account, within
        the period established by the Partner.{" "}
      </p>
      <p>
        4.8. The User is aware and agrees that the Chargeback procedure will
        only occur for transactions carried out using the Card. Chargeback
        procedures will not be accepted for transactions carried out through the
        applications and which have been carried out using a correct login and
        password.{" "}
      </p>
      <h1>5. CRYPTOASSET EXCHANGE </h1>
      <p>
        5.1. Transfero provides Users with the service of buying, selling and
        converting crypto assets, which can be accessed through the
        Applications.{" "}
      </p>
      <p>
        5.2. The Cryptoasset Exchange will not be responsible for any losses,
        damages and/or lost profits that may eventually be suffered by Users as
        a result of the purchase, sale or conversion of digital assets through
        its platform. Any profits or losses arising from such transactions are
        the sole responsibility of the Users.{" "}
      </p>
      <p>
        5.3. The User accepts and agrees that any and all transactions must be
        carried out solely by the registered User and/or their legal
        representative, and Transfero is not responsible for transactions
        carried out by unauthorized persons.{" "}
      </p>
      <p>
        5.4. After the User has completed registration, the Account may be
        accessed, consulted and used by the User. It will be through the use of
        the Account that the User will carry out transactions.{" "}
      </p>
      <p>
        5.5. The User must deposit a balance in the Account (which will be
        immediately converted to BRZ) to be able to issue purchase orders or
        conversion of digital assets.{" "}
      </p>
      <p>
        5.6. The BRZ's will be credited to the Account and the balance
        considered available at the moment the PIX compensation or any other
        form of bank transfer approved by the Central Bank of Brazil is
        effectively identified in the account held by Transfero, which can occur
        within 3 (three) working days.{" "}
      </p>
      <p>
        5.7. To sell, buy or convert Cryptoassets through the Cryptoasset
        Exchange, the User must place a sell order, buy order or conversion
        order. The User's execution of one of the transaction orders will be
        irrevocable and irreversible, for all legal purposes.{" "}
      </p>
      <p>
        5.8. To purchase Cryptoassets, the User must include a purchase order
        with the Cryptoasset Exchange. To be able to issue a purchase order, the
        User must have in their Account the amount in BRZ or other Cryptoassets
        equivalent to the value of the requested purchase.{" "}
      </p>
      <p>
        5.9. The User is aware that the issuance of the sales order represents a
        valid proposal for all purposes. After acceptance, the transaction will
        be automatically carried out, and cannot be undone or modified, and the
        User must bear such balance of Cryptoassets.{" "}
      </p>
      <p>
        5.10. The User may request, at any time, the redemption of their
        Cryptoassets, by converting their balance into Fiat Currency and
        subsequently transferring the amount to their bank account linked to
        their registration. The transfer of resources may take place within 3
        (three) business days, counting from the request, after the transfer has
        been validated. The bank account provided, in addition to being valid
        and owned by the User, must be in the same country in which the funds to
        be transferred originated.{" "}
      </p>
      <p>
        5.11. The Cryptoasset Exchange will be remunerated when any purchase,
        sale or conversion orders are completed and/or carried out, according to
        the remuneration rates available for consultation on the Platforms.{" "}
      </p>
      <p>
        5.12. The Cryptoasset Exchange may debit and/or withhold from the User's
        Account the amounts mentioned in item 5.11., as well as the redemption
        fees for your bank account and BRZ deposits into your Account.{" "}
      </p>
      <p>
        5.13. It is Transfero's prerogative, in the event of non-compliance with
        obligations by the User, to retain or transfer amounts, offset credits
        of amounts deposited, or settle certain transactions in advance, as
        necessary to fully settle any debts existing in the User's name.{" "}
      </p>
      <h1>6. TRANSFERO CHECKOUT </h1>
      <p>
        6.1 Transfero Checkout is a service developed and maintained by
        Transfero that enables registered Retailers to receive payments in
        Brazilian reais for purschases made in Cryptoassets by their Customers,
        who are also Platform Users.{" "}
      </p>
      <p>
        6.2. Transfero offers Transfero Checkout as a multi-channel product that
        enables online and physical businesses to accept payments directly in
        Cryptoassets. Transfero Checkout can be used via website, mobile
        application (IOS or Android), or API, and allows Retailers registered on
        the Platform to accept payments in Cryptoassets in just two steps: (I)
        the Customer scans the QR Code generated by the Platform; and (II)
        confirms the transaction.{" "}
      </p>
      <p>
        6.3. Transfero will act by offering an off ramp solution through the
        Platform, intermediating transactions carried out between Retailers and
        their Customers. Transfero Checkout will be responsible for converting
        these payments received in Cryptoassets into Brazilian reais, so that
        the full value of the product or service charged by the Retailer is
        credited to your bank account in reais.{" "}
      </p>
      <p>
        6.4. The Platform enables Retailers to accept payments from anywhere in
        the world, 24 (twenty-four) hours, 7 (seven) days a week, through the
        main Cryptoassets: BRZ; USDT; USDT; BTC; ETH; SOL. The Platform also
        allows payments to be made via PIX, through the automatic deduction of a
        fee to be charged for each transaction carried out, directly from the
        payment made to the Retailer and the generation of simplified receipts
        for products or services purchased through a QR Code generated at
        Transfero Checkout.{" "}
      </p>
      <p>
        6.5. Retailers registered on the Platform will be able to integrate the
        solution: (i) directly on their website by adding an iframe snippet ;
        (ii) through the Platform; or (iii) through the Transfero Checkout API.{" "}
      </p>
      <p>
        6.6. Customers who wish to make payment to the Retailer registered on
        the Platform must follow the following steps to carry out the
        transaction:{" "}
      </p>
      <img src="/images/checkout_cap.png" width={900} alt="" />
      <p>
        6.7. When selecting the blockchain and Cryptoasset to be used in the
        transaction, the Platform will generate a QR Code with the market price
        equivalent in Cryptoassets to the value of the product and/or service
        purchased by the Customer
      </p>
      <p>
        6.8. The Retailer, who is a User of the Service, accepts and agrees that
        only transactions between the Retailer´s Cryptoasset Account and the
        bank account of the same ownership will be accepted and executed.
        Therefore, Transfero and the Plataform will not be responsible for
        transactions carried out for Accounts held by different owners.{" "}
      </p>
      <p>
        6.9. The Merchant is aware that it is prohibited to maintain more than
        one registration on the Platform. In case of non-compliance, Transfero
        may cancel or suspend all registrations.{" "}
      </p>
      <p>
        6.10. The Retailer must immediately notify Transfero, via email at {" "}
        <a>contato@transfero.com</a>, in case of suspicion of improper access,
        or any activity that may give rise to such.{" "}
      </p>
      <p>
        6.11. After registration has been completed by the Retailer and your
        wallet has been validated, the Account can be accessed, consulted and
        used by the Retailer. It will be through the use of the Account that the
        Retailer will carry out transactions to your bank account.{" "}
      </p>
      <p>
        6.12. The Retailer must receive payments for products sold and/or
        services provided to its customers directly in Cryptoassets in its
        wallet held by Transfero. The amounts received and available in your
        Account will be segregated from amounts belonging to Transfero, the
        Platform and other Retailers.{" "}
      </p>
      <p>
        6.13. The amount transferred to the wallet will be available in the
        Retailer´s Account when the blockchain transaction is completed, which
        may occur within 02 (two) minutes after confirmation on the blockchain.
        The transaction completion time will depend on the number of
        confirmations required from each blockchain.{" "}
      </p>
      <p>
        6.14. To withdraw the amount held in your Account, the Retailer must
        place a transfer order on the Platform, informing the amount to be
        transferred to the bank account. The Retailer´s transfer order will be
        irrevocable and irreversible, for all legal purposes.{" "}
      </p>
      <p>
        6.15. On the other hand, the Customer who makes the payment must be
        aware that when authorizing the transaction, it will be automatically
        carried out and cannot be undone or modified.{" "}
      </p>
      <p>
        6.16. Transfero does not hold any responsibility for the products and/or
        services offered by the Retailer, as well as for the prices attributed
        to them, having the role of mere maintainer of the Platform.{" "}
      </p>
      <p>
        6.17. The Retailer may, at any time, request the transfer of its Fiat
        Current amounts from its Account to the bank account, initially linked
        to its registration on the Platform. The completion of such transfer of
        funds may occur within 03 (three) business days, counting from such
        request, after the transfer request has been validated by the control
        mechanisms. The bank account informed, in addition to being valid and
        owned by the Retailer itself, must be in the same country in which the
        funds were originally transferred.{" "}
      </p>
      <p>
        6.18. The Platform will be remunerated when the Customer purchases the
        product and/or service, as defined in the remuneration rules available
        on the Platform.{" "}
      </p>
      <p>
        6.19. The definition and implementation of rules and procedures relating
        to refunds requested by Customers from Retailers must be exclusively and
        strictly established by the management of the Retailer itself.{" "}
      </p>
      <p>
        6.20. Transfero does not have any interference or obligations related to
        the refund procedure for Retailer Customers.{" "}
      </p>
      <p>
        6.21. The Retailer will be responsible for the origin and legitimacy of
        transactions carried out within the scope of payments made by its
        Customers, including information about their values, for the purpose of
        collecting possible applicable taxes, responding for direct or indirect
        damages and losses caused to the Platform and/or to third parties, as a
        result of fraud, simulation, forgery and omission of information or
        documents.{" "}
      </p>
      <p>
        6 .22. The Retailer will be exclusively responsible for calculating,
        collecting and accounting for any taxes applicable to its operations on
        the Platform.{" "}
      </p>
      <h1>7. CANCELLATION OF SERVICES </h1>
      <p>
        7.1. The User may, at any time, request the cancellation of their
        Account, Card, or any Service, upon request made through the
        Communication Channels.{" "}
      </p>
      <p>
        7.2. Once cancellation is requested, the Card will be blocked and the
        Account will be definitively closed within the maximum period
        established in the applicable regulations, with the User being entitled,
        within this period: (i) to withdraw the remaining balance; or (ii)
        transfer it to another account with the same ownership.{" "}
      </p>
      <p>
        7.3. Information related to the account and its banking transactions for
        which Transfero has a legal obligation to keep the records will remain
        stored on our servers for the time necessary in accordance with
        applicable legislation.{" "}
      </p>
      <p>
        7.4. Transfero or Partner, when applicable, may cancel the provision of
        Services immediately, in the following cases:{" "}
      </p>
      <p>
        (i) if the Account is inactive and has a zero balance for more than 3
        (three) consecutive months;{" "}
      </p>
      <p>
        (ii) if the User violates any of the provisions of these Terms of Use;{" "}
      </p>
      <p>
        (iii) if operations outside the typical usage are verified, and the User
        fails to comply with the request to send documents to prove the
        regularity of these operations;{" "}
      </p>
      <p>(iv) in the event of the User’s death; </p>
      <p>
        (v) in case of any irregularities observed that may conflict with the
        applicable legislation on the Prevention of Money Laundering and
        Terrorism Financing and Law No. 7,492/1986 (Financial Crimes Law), Law
        No. 9,613/1998 (Law on crimes money laundering or concealment of assets)
        and Law No. 12,846/2013 (Anti-Corruption Law).{" "}
      </p>
      <p>
        (vi) Providing false, inaccurate, incomplete or misleading information
        and/or statements to Transfero.{" "}
      </p>
      <p>
        7.5. In the above cases, all the Services provided may be cancelled.{" "}
      </p>
      <p>
        7.6. The provision of Services may also be canceled without reason and
        at any time, by notifying the User with at least 30 (thirty) days in
        advance.{" "}
      </p>
      <p>
        7.7. In case of suspicion of fraud or any other illicit activity of the
        User, Transfero may, in addition to resorting to applicable legal
        measures, at the effective time of termination, retain any funds in BRZ
        in the User's Account until the conclusion of the respective
        investigations and /or other procedure.{" "}
      </p>
      <p>
        7 .8. Even in the event of termination, all fees and remunerations paid
        and charges made prior to termination are non-refundable.{" "}
      </p>
      <h1>8. INTELLECTUAL PROPERTY </h1>
      <p>
        8.1. All intellectual property rights relating to the Services, as well
        as all their functionalities, are the exclusive property of Transfero,
        including with regard to their texts, images, layouts, software, codes,
        databases, graphics, articles, photographs and other content produced
        directly or indirectly by Transfero (“Content”), when applicable. The
        Content is protected by Brazilian copyright and industrial property law.
        It is prohibited to use, copy, reproduce, modify, translate, publish,
        transmit, distribute, perform, upload, display, license, sell, explore
        or reverse engineer the Content, for any purpose, without the prior and
        express consent of Transfero. Any unauthorized use of the Content will
        be considered a violation of copyright and intellectual property rights.{" "}
      </p>
      <p>
        8.2. Transfero grants the User a limited, temporary, non-exclusive,
        non-transferable and revocable license to use the Services and
        Applications only to the extent strictly necessary to fulfill the
        obligations and exercise the rights set forth in these Terms of Use. It
        is prohibited the User assigns, sublicenses, donates, sells, rents,
        transmits or transfers its rights and obligations to third parties, in
        whole or in part, in any form, for any reason, and it is also prohibited
        to adapt, decompile, disassemble or execute reverse engineering of the
        Applications, in a way that violates the rights set forth herein.{" "}
      </p>
      <p>
        8.3. Transfero reserves the right to update the Platform whenever it
        wishes, as well as to stop selling it, update it, or even discontinue it
        at any time, subject to a prior notice of 30 (thirty) days, in the event
        that discontinuation of the Platform.{" "}
      </p>
      <p>
        8.4. In case of violation by the User of any of Transfero's intellectual
        property rights, the User assumes any and all liability of a civil
        and/or criminal nature arising from said violation, without prejudice to
        Transfero's right to cancel the User's registration and access to the
        Platform.{" "}
      </p>
      <p>
        8.5. These Terms of Use are also protected by the provisions of Law No.
        9,609/98 and Law No. 9,610/98, with violators subject to the penalties
        provided for in art. 12 of Law 9,609/98, without prejudice to civil
        liability for damages possibly caused by the use and distribution of
        unauthorized copies of the Platform or by any other violation of the
        rights arising from the intellectual property that protects these Terms
        of Use and the Platform.{" "}
      </p>
      <h1>9. DATA PROTECTION </h1>
      <p>
        9.1. In the User registration process and during the provision of
        Services, we collect and use certain User identification information for
        the sole and exclusive purpose of providing the Platform Services.
        Transfero will make every effort to protect such information and will
        take the necessary precautions to maintain the confidentiality of
        sensitive information shared during the registration process. For
        further clarification, we suggest that you consult Transfero's Privacy
        Policy.{" "}
      </p>
      <p>
        9.2. The act of completing and submitting the registration will grant
        Transfero access to the User's data. By submitting such data to the
        Platform, the User authorizes Transfero to use such data in the manner
        authorized by Law and these Terms and Conditions. For more information
        about collecting User data, please observe the provisions of our Privacy
        Policy.{" "}
      </p>
      <p>
        9.2.1. In addition to the form, data from each User can be obtained
        when: (i) the User browses or makes transactions on the Platform; (ii)
        the User shares information with developers or members of the Platform;
        (iii) the User contacts the Platform’s customer service channels; (iv)
        information about the User is shared with the Platform through external
        partners; (v) information is shared with the Platform through social
        networks or information providers; (vi) information is received or
        shared from government authorities, including the Federal Revenue.{" "}
      </p>
      <p>
        9.3. The data provided by each User is protected by the General Data
        Protection Law (LGPD) and in accordance with the regulations issued by
        the ANPD and will be treated in accordance with the provisions of the
        Privacy Policy.{" "}
      </p>
      <p>
        9.4. The information above may be used by Transfero for administrative,
        marketing and customer support services.{" "}
      </p>
      <p>
        9.5. The User may withdraw their consent at any time by closing their
        account on the Platform or by contacting support via email{" "}
        <a>contato@transfero.com</a>. If the User withdraws their consent to the
        use of their personal information, Transfero will suspend its provision
        of the Transfero Checkout service. However, Transfero may retain and
        continue to process the User's personal information if necessary to
        comply with legal provisions, as regulated in article 7 of the General
        Data Protection Law (Law nº 13,709/18).{" "}
      </p>
      <p>
        9.6. If you have any questions regarding the processing of your personal
        data, we ask that the holder contact us for clarification via the
        following email address: <a>privacy@transfero.com</a> .{" "}
      </p>
      <p>
        9.7. To exercise the rights provided for in the General Data Protection
        Law, the holder of personal data is requested to contact the person
        responsible for processing Transfero's personal data, through the
        Privacy Portal or via the competent email address (
        <a>privacy@transfero.com</a>).{" "}
      </p>
      <h1>10. LIMITATIONS OF LIABILITY </h1>
      <p>
        10.1. Transfero is not responsible for transactions carried out by Users
        through the Account, whether through the Card, Crypto Asset Broker or
        with Retailers.{" "}
      </p>
      <p>
        10.2. Transfero is not responsible for any practices carried out by
        Users and/or their decisions, and any irregularities or illegalities
        perpetrated by Users, whether of an administrative, business, managerial
        or other nature.{" "}
      </p>
      <p>
        10.3. Transfero will not be held responsible under any circumstances for
        the occurrence or realization of any of the events and/or facts narrated
        below:{" "}
      </p>
      <p>
        (i) losses and damages, lost profits or emerging damages, the causes of
        which can be attributed, directly or indirectly, to any transaction
        carried out by the User, as well as financial results resulting from
        these transactions, whether positive or negative;{" "}
      </p>
      <p>
        (ii) any failure, interruption or technical unavailability caused by
        facts attributable to third parties on the Platform, including, but not
        limited to, failure that makes it impossible for the User to carry out
        transactions or use the Platform;{" "}
      </p>
      <p>
        (iii) unforeseeable events or force majeure that harm the Platform;{" "}
      </p>
      <p>
        (iv) culpable or intentional acts carried out by third parties and/or
        Users;{" "}
      </p>
      <p>
        (v) transactions carried out by the User based on incorrect information
        obtained from any third party;{" "}
      </p>
      <p>
        (vi) interruption of systems, internet, banking network and/or any other
        systems that the Platform needs for its operations, or to carry out any
        transaction, or carry out any purchase, sale and/or conversion order.{" "}
      </p>
      <h1>11. USER RESPONSIBILITY AND REPRESENTATIONS </h1>
      <p>11.1. It is the User's responsibility: </p>
      <p>
        (i) keep the environment of your devices accessing the Platform safe,
        using specific tools for this purpose, such as antivirus software, in
        order to contribute to the prevention of electronic risks;{" "}
      </p>
      <p>
        (ii) use updated and efficient operating systems to fully use the
        Platform;{" "}
      </p>
      <p>
        (iii) equip and be responsible for the hardware devices necessary to
        access the Platform, as well as their access to the Internet.{" "}
      </p>
      <p>
        11.2. By simply accepting these Terms of Use, the User undertakes to:{" "}
      </p>
      <p>
        A. not disclose your login and password to third parties, as well as not
        allow the use of your Account by third parties. The User is solely
        responsible for any transactions made using their registration data;{" "}
      </p>
      <p>
        B. provide accurate information in your registration, as well as keep
        such information always up to date, being the sole and exclusive
        responsible for the content provided;{" "}
      </p>
      <p>
        C. not insert content and/or code or otherwise alter or interfere with
        the way any page is made available on the website or Platforms.{" "}
      </p>
      <p>11.3 Declares, under penalty of law, that: </p>
      <p>
        (i) has not committed, or is committing and/or will commit, acts that
        could be interpreted as criminal according to Law nº. 7.492/1986, Law nº
        9.613/1998 and Law nº. 12,846/2013 (Anti-Corruption Law);{" "}
      </p>
      <p>
        (ii) is aware that, in the event of suspected acts of financial crime,
        money laundering, corruption, illegal exchange operations, currency
        evasion, terrorist financing and/or any other illegal practice, the
        Platform may (a) send a formal request for explanations on the matter;
        (b) refuse to carry out any transaction; (c) cancel my registration; and
        (d) take all recommended legal measures, depending on the nature of the
        irregular practice eventually adopted;{" "}
      </p>
      <p>
        (iii) the financial resources transacted and used on the Platform were
        obtained lawfully, and the resources eventually obtained in transactions
        using the Platform will have a lawful destination;{" "}
      </p>
      <p>
        (iv) received all information that it deems relevant and/or necessary
        for consent and acceptance of the Terms and Conditions, including the
        rights and obligations assumed within the scope of this declaration;{" "}
      </p>
      <p>
        (v) understands that, as a result of legislative changes and/or rules of
        the Platform itself, it may be necessary to sign other instruments
        and/or provide other information and documents, related activities
        and/or people;{" "}
      </p>
      <p>
        (vi) assumes civil and/or criminal liability (as applicable) for the
        information provided to the Platform;{" "}
      </p>
      <p>
        (vii) is not a defendant in any criminal proceedings, whether in Brazil
        or abroad;{" "}
      </p>
      <p>
        (viii) acknowledges that there is no consumer relationship and/or
        comparable to a consumer relationship between you and the Platform.{" "}
      </p>
      <h1>1 2. TERM </h1>
      <p>
        12.1. The User may use the Platform for an indefinite period of time and
        Transfero may, from time to time, make changes to these Terms.{" "}
      </p>
      <h1>13. GENERAL PROVISIONS </h1>
      <p>
        13.1. These Terms of Use may be subject to change, periodically or
        otherwise. In these cases, the User will be informed about such changes,
        and must present their acceptance to such changes, under the risk of
        interruption of use of the Platform, or cancellation of their
        registration.{" "}
      </p>
      <p>
        13.1.1. If the User does not accept the new Terms of Use or is unable to
        access the Applications due to incompatibility with their most recent
        versions, they must contact the Communication Channels, informed at the
        end of this Terms of Use, for support measures in the update of the
        Applications or moving your Account balance to another account with the
        same ownership.{" "}
      </p>
      <p>
        13.2. If any provision of these Terms of Use is considered unenforceable
        or invalid, that provision will be invalidated in isolation and will not
        affect the rest of the provisions of these Terms of Use. The provision
        of these Terms of Use considered unenforceable or invalid must be
        replaced by another with the same technical objectives and/or
        economical, as quickly as possible.{" "}
      </p>
      <p>
        13.3. The User declares to understand the risks associated with
        Cryptoassets, as this is an investment that involves a high degree of
        risk. Investment in Cryptoassets must be made by experienced investors
        with an experienced profile, as well as those with the financial
        capacity to bear any losses that may occur due to the risks inherent to
        operations with Cryptoassets.{" "}
      </p>
      <p>
        13.4. Cryptoasset transactions are irreversible and therefore losses due
        to fraudulent or accidental transactions may not be recoverable.{" "}
      </p>
      <p>
        13.5. Communication to BACEN. The User hereby agrees that the Parties
        communicate to the Central Bank of Brazil, the Financial Activities
        Control Council or other bodies that the legislation provides for, the
        operations that may be configured in Law 9,613/98 (which provides for
        the crimes of laundering or concealment of assets, rights and values)
        and other legal provisions relevant to the matter.{" "}
      </p>
      <p>
        13.6. Assignment. Transfero may, at any time, assign any of its rights
        and obligations set out in these Terms of Use to any person, natural or
        legal, by means of simple prior notification to the User, provided that
        the assignee will continue to comply with all obligations assumed by
        Transfero, as well as replacing the Partner, as applicable.{" "}
      </p>
      <h1>14. APPLICABLE LEGISLATION AND JURISDICTION </h1>
      <p>
        14.1. These Terms of Use will be governed by the laws of the Federative
        Republic of Brazil. The uses and customs adopted, practiced and accepted
        in contractual law and Brazilian capital markets will apply to these
        Terms of Use.{" "}
      </p>
    </div>
  );
}
