import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "@/store";
import { IAsyncState, ICurrency } from "@/models";
import { currencyReducer } from "./reducers";
import { useCurrency } from "./hooks";
import { fecthCurrencies, currencyExtraReducerBuilder } from "./thunks";

interface AsyncCurrency extends IAsyncState {
  data: null | ICurrency[];
}

export interface CurrencyState {
  currencies: AsyncCurrency;
}

export const initialState: CurrencyState = {
  currencies: {
    data: null,
    lastUpdate: null,
    error: null,
    loading: false,
  },
};

export const currencySlice = createSlice({
  name: "currency",
  initialState,
  reducers: currencyReducer,
  extraReducers: currencyExtraReducerBuilder,
});

export const { clearCurrency, updateCurrency } = currencySlice.actions;

export { useCurrency, fecthCurrencies };

export const currencySelector = (state: RootState) => state.currency;

export default currencySlice.reducer;
