import { useEffect, useMemo, useState } from "react";
import { useCustomerInfo } from "@/hooks";
import { updateIsMenuOpen, useBase } from "@/store/features/base";
import { useCustomer } from "@/store/features/customer";
import { Icon, Margin, Menu, MenuItem, ProfileInfo } from "@/widgets";
import { useTranslation } from "react-i18next";
import { PJLevels, individualLevels } from "@/utils";
import { useRouter } from "next/router";
import SumsubApi from "@/apis/sumsubApi";
import styles from "./index.module.scss";

export default function LoggedInMenu() {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { baseDispatch } = useBase();
  const { customerState } = useCustomer();
  const { isCompany } = useCustomerInfo();
  const customerLevel = customerState.customer.data?.profileLevel;
  const [upgrade, setUpgrade] = useState(false);
  const [standardFeatures, setStandardFeatures] = useState(true);

  const onCloseMenu = () => {
    baseDispatch(updateIsMenuOpen(false));
  };
  const handleZendeskLanguage = () => {
    const languageMap = {
      pt: "pt-br",
      en: "en-us",
      es: "es",
    };
    const selectedLanguage = languageMap[locale as keyof typeof languageMap];
    return selectedLanguage || "pt-br";
  };

  const customerName = useMemo(() => {
    if (customerState.customer.data !== null) {
      return isCompany
        ? customerState.customer.data?.companyName
        : customerState.customer.data?.fullName;
    }
    return "";
  }, [customerState.customer.data]);

  const customerProfile = useMemo(
    () =>
      customerState.customer.data
        ? customerState.customer.data?.profileLevel
        : "",
    [customerState.customer.data]
  );

  async function createSumsubToken(): Promise<any> {
    await SumsubApi.createToken()
      .then((token) => {
        token && setUpgrade(true);
      })
      .catch(() => {
        setUpgrade(false);
      });
  }

  const setSumsub = () =>
    upgrade && individualLevels.some((level) => level === customerLevel);

  const showStandardFeatures = () =>
    customerLevel === "Basic" && !isCompany
      ? setStandardFeatures(false)
      : setStandardFeatures(true);

  useEffect(() => {
    createSumsubToken();
    showStandardFeatures();
  }, [customerState.customer.data]);

  return (
    <div className={styles.containerMenu}>
      {standardFeatures && (
        <ProfileInfo
          fullname={customerName}
          nickname={customerState.customer.data?.nickname}
          profile="premium" // TODO: implement it when icons is defined
          label={customerProfile}
        />
      )}
      <Margin bottom={20} />
      <Menu>
        {standardFeatures && (
          <MenuItem
            label={t("loggedInMenu.transactionsHistory")}
            href="/history"
            onClick={onCloseMenu}
          />
        )}
        {standardFeatures && (
          <MenuItem
            label={t("loggedInHeader.cards")}
            href="/cardshowcase"
            onClick={onCloseMenu}
          />
        )}
        <MenuItem label={t("loggedInMenu.manageProfile")} arrow="down">
          {standardFeatures && (
            <MenuItem
              label={t("loggedInMenu.profile")}
              href="/userprofile"
              onClick={onCloseMenu}
            />
          )}
          <MenuItem
            label={t("loggedInMenu.changePassword")}
            isActive={false}
            href="/changepassword"
            onClick={onCloseMenu}
          />
          {setSumsub() || (isCompany && !PJLevels.includes(customerLevel)) ? (
            <MenuItem
              label={t("sumsub.title")}
              isActive={false}
              href="/sumsub"
              onClick={onCloseMenu}
            />
          ) : null}
          {standardFeatures && (
            <MenuItem
              label={t("loggedInMenu.changePasswordTransaction")}
              isActive={false}
              href="/changetransactionpassword"
              onClick={onCloseMenu}
            />
          )}
          <MenuItem
            label={t("loggedInMenu.cancelAccount")}
            isActive={false}
            href="/cancelaccount"
            onClick={onCloseMenu}
          />
        </MenuItem>

        <MenuItem label={t("loggedInMenu.helpCenter")}>
          <MenuItem
            label={t("loggedInMenu.limits")}
            href="/help-center/limits-info"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("loggedInMenu.feesInfo")}
            href="/help-center/fees-info"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("loggedInMenu.userTerms")}
            href="/help-center/terms"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("loggedInMenu.privacyPolicy")}
            href="/help-center/privacy-policies"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={t("loggedInMenu.contact")}
            href="/help-center/contact"
            onClick={onCloseMenu}
          />
          <MenuItem
            label={
              <>
                FAQ <Icon icon="link" />
              </>
            }
            href={`https://suporteplataforma.zendesk.com/hc/${handleZendeskLanguage()}`}
            target="blank"
            onClick={onCloseMenu}
          />
        </MenuItem>
        <MenuItem
          label={
            <>
              {t("loggedInMenu.logout")} <Icon icon="logout" />
            </>
          }
          href="/auth/logout"
          onClick={onCloseMenu}
        />
      </Menu>
    </div>
  );
}
