import styles from "./index.module.scss";

type MarginProps = {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  asHr?: boolean;
  display?: "block" | "inline" | "inline-block";
};

export default function Margin({
  top = 0,
  right = 0,
  bottom = 0,
  left = 0,
  asHr = false,
  display = "block",
}: MarginProps) {
  const cssAsHr = asHr ? styles.asHr : styles.asNotHr;

  return (
    <div
      className={`Margin ${styles.container} ${cssAsHr}`}
      style={{
        marginTop: `${top}px`,
        marginRight: `${right}px`,
        marginBottom: `${bottom}px`,
        marginLeft: `${left}px`,
        display: display,
      }}
    >
      {asHr && <hr />}
    </div>
  );
}
