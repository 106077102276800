import scssVariables from "@/styles/variables.module.scss";

export default function close() {
  return (
    <svg viewBox="0 0 17.449 17.449">
      <g transform="translate(-296.628 105.922)">
        <line
          x2="16.035"
          y2="16.035"
          transform="translate(297.335 -105.215)"
          fill="none"
          stroke={scssVariables.primary}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <line
          x1="16.035"
          y2="16.035"
          transform="translate(297.335 -105.215)"
          fill="none"
          stroke={scssVariables.primary}
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
