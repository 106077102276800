export default function brlIcon() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2715_11773)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#6DA544"
        />
        <path
          d="M16.0006 6.26172L29.218 16.0008L16.0006 25.74L2.7832 16.0008L16.0006 6.26172Z"
          fill="#FFDA44"
        />
        <path
          d="M15.9998 21.5649C19.0733 21.5649 21.5649 19.0733 21.5649 15.9998C21.5649 12.9262 19.0733 10.4346 15.9998 10.4346C12.9262 10.4346 10.4346 12.9262 10.4346 15.9998C10.4346 19.0733 12.9262 21.5649 15.9998 21.5649Z"
          fill="#F0F0F0"
        />
        <path
          d="M13.2173 15.6523C12.2496 15.6523 11.3156 15.7995 10.4365 16.0727C10.4755 19.1127 12.9507 21.5654 16 21.5654C17.8855 21.5654 19.5507 20.6268 20.5574 19.1924C18.8351 17.0363 16.185 15.6523 13.2173 15.6523Z"
          fill="#0052B4"
        />
        <path
          d="M21.4619 17.066C21.5289 16.7208 21.565 16.3646 21.565 15.9998C21.565 12.9261 19.0734 10.4346 15.9999 10.4346C13.7065 10.4346 11.7377 11.8221 10.8857 13.8033C11.6389 13.6472 12.4186 13.565 13.2173 13.565C16.4496 13.5649 19.3737 14.9086 21.4619 17.066Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_2715_11773">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
