export default function conversionIcon() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_424_3613)">
        <path
          d="M14.1282 6.586L6.58575 14.1285M6.58575 14.1285L6.58575 10.3572M6.58575 14.1285L10.357 14.1285M1.8717 9.41443L9.41417 1.87195M9.41417 1.87195H5.64294M9.41417 1.87195V5.64319"
          stroke="#475467"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_424_3613">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
