import { ReactNode } from "react";
import styles from "./index.module.scss";

type TableProps = {
  header: ReactNode;
  children: ReactNode;
};

export default function Table({ children, header }: TableProps) {
  return (
    <div className={styles["container-main"]}>
      <div className={`Table ${styles.container}`}>
        <div className={styles.thead}>{header}</div>
        <div className={styles.tbody}>{children}</div>
      </div>
    </div>
  );
}
