import { useI18n } from "@/hooks";
import { LegalText } from "@/widgets";
import { En_i18n, Es_i18n, Pt_i18n } from "./Translations";

export default function PrivacyText() {
  const TranslatedComp = {
    en: <En_i18n />,
    es: <Es_i18n />,
    pt: <Pt_i18n />,
  };

  const { i18nComp } = useI18n(TranslatedComp);

  return <LegalText>{i18nComp(TranslatedComp)}</LegalText>;
}
