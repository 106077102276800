import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { IApiError, IMarket } from "@/models";
import { MarketApi } from "@/apis";
import { MarketState } from ".";

export const fecthMarkets = createAsyncThunk(
  "fetch/markets",
  async (_, { rejectWithValue }) => {
    try {
      return (await MarketApi.getMarkets()) as IMarket[];
    } catch (error: any) {
      return rejectWithValue(error as IApiError);
    }
  }
);

export const marketExtraReducerBuilder = (
  builder: ActionReducerMapBuilder<MarketState>
) => {
  builder.addCase(fecthMarkets.pending, (state) => {
    state.markets.error = null;
    state.markets.loading = true;
  });
  builder.addCase(fecthMarkets.fulfilled, (state, action) => {
    state.markets.data = action.payload as IMarket[];
    state.markets.lastUpdate = moment().format("MM/DD/YYYY HH:mm:ss");
    state.markets.error = null;
    state.markets.loading = false;
  });
  builder.addCase(fecthMarkets.rejected, (state, action) => {
    state.markets.data = null;
    state.markets.error = action.payload as IApiError;
    state.markets.loading = false;
    state.markets.lastUpdate = null;
  });
};
