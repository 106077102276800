import { useMemo } from "react";
import { Icon } from "@/widgets";

import styles from "./index.module.scss";

type InlineMessageProps = {
  message: string;
  variant?: "info" | "error" | "warning" | "success";
  className?: string;
};

export default function InlineMessage({
  message,
  variant = "info",
  className = "",
}: InlineMessageProps) {
  const icon = useMemo(() => {
    return variant === "warning" ? "important" : variant;
  }, [variant]);

  return (
    <div className={`${styles.container} ${className} ${styles[variant]}`}>
      <Icon icon={icon} /> {message}
    </div>
  );
}
