export default function alertCircleBlue() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1782_7209)">
        <path
          d="M10.1663 7.33301V10.6663M10.1663 13.9997H10.1747M18.4997 10.6663C18.4997 15.2687 14.7687 18.9997 10.1663 18.9997C5.56397 18.9997 1.83301 15.2687 1.83301 10.6663C1.83301 6.06397 5.56397 2.33301 10.1663 2.33301C14.7687 2.33301 18.4997 6.06397 18.4997 10.6663Z"
          stroke="#1674B8"
          strokeWidth="1.73333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1782_7209">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.166504 0.666992)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
