import scssVariables from "@/styles/variables.module.scss";

export default function cotation() {
  return (
    <svg viewBox="0 0 13 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.24944 5.44449C5.81824 5.44449 5.46827 5.79294 5.46827 6.22227V8.55562H9.37412C9.80532 8.55562 10.1553 8.16653 10.1553 7.73721V7.71632C10.1553 7.28698 9.80532 7.00006 9.37412 7.00006H7.03062V6.22227C7.03062 5.79294 6.68065 5.44449 6.24944 5.44449ZM11.6575 7.77784C11.2684 7.77784 10.9443 8.0632 10.8888 8.44587C10.5162 11.0195 8.02661 12.9219 5.2394 12.3386C3.47083 11.9684 2.04051 10.5444 1.66867 8.78349C1.03358 5.77348 3.3349 3.11114 6.24944 3.11114V4.6667L10.1553 2.33335L6.24944 0V1.55557C2.38812 1.55557 -0.655305 5.04238 0.121958 9.02617C0.595347 11.4521 2.5592 13.4073 4.99567 13.8786C8.68747 14.5926 11.9543 12.0681 12.4371 8.65361C12.5027 8.19005 12.127 7.77784 11.6575 7.77784Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
