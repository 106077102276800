/* eslint-disable no-unused-vars */
import { ReactElement } from "react";
import { default as SlickSlider } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

type SliderItemProps = {
  children: any;
};

export function SliderItem({ children }: SliderItemProps) {
  return <div>{children}</div>;
}

type SliderProps = {
  children:
    | ReactElement<SliderItemProps>
    | Array<ReactElement<SliderItemProps>>;
  mobileStyle?: boolean; // will disable the overflow hidden and hide the dots
  settings?: any; // check all options at: https://react-slick.neostack.com/docs/api
};

export default function Slider({ children, settings }: SliderProps) {
  const defaultSettings = {
    arrows: false,
    autoplay: false,
    swipeToSlide: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: true,
    ...settings,
  };

  return <SlickSlider {...defaultSettings}>{children}</SlickSlider>;
}
