import scssVariables from "@/styles/variables.module.scss";

export default function unauthorized() {
  return (
    <svg viewBox="0 0 60 60" fill="none">
      <path
        d="M30 0C38.0804 0 45.4146 3.19461 50.8086 8.38974C50.9626 8.49632 51.1086 8.61789 51.2454 8.75463C51.3821 8.89137 51.5037 9.03742 51.6101 9.1909C56.8053 14.5854 60 21.9196 60 30C60 46.5685 46.5685 60 30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0ZM48.8319 15.6349L15.6349 48.8319C19.6194 51.876 24.5986 53.6842 30 53.6842C43.0805 53.6842 53.6842 43.0805 53.6842 30C53.6842 24.8146 52.0178 20.0184 49.1913 16.1177L48.8319 15.6349ZM30 6.31579C16.9195 6.31579 6.31579 16.9195 6.31579 30C6.31579 35.4014 8.124 40.3806 11.1681 44.3651L44.3651 11.1681C40.3806 8.124 35.4014 6.31579 30 6.31579Z"
        fill={scssVariables.primary}
      />
    </svg>
  );
}
